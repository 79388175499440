import { CoreComponents, CoreState, DialogHeader } from "@build-buddy/core";
import { Dialog, useTheme } from "@mui/material";
import PaymentConfirmContent from "./PaymentConfirmContent";

interface PaymentConfirmationProps {
  title: string;
  paymentType: CoreState.Financial.PaymentType;
  paymentSchedule?: CoreState.Financial.PaymentSchedule;
  task?: CoreState.Task.Task;
  senderLabel?: string;
  recipientLabel?: string;
  recipientName: string;
  submitText: string;
  isLoading?: boolean;
  isSaving?: boolean;
  onSubmitClick(): void;
  onCancelClick(): void;
}
const PaymentConfirm = (props: PaymentConfirmationProps) => {
  const { 
    title, 
    paymentType, 
    paymentSchedule, 
    task, 
    senderLabel, 
    recipientLabel, 
    recipientName, 
    submitText, 
    isLoading, 
    isSaving, 
    onSubmitClick, 
    onCancelClick 
  } = props;

  const theme = useTheme();

  return (
    <Dialog
      open={true}
      onClose={() => { }}
      sx={{
        zIndex: theme.zIndex.paymentConfirm
      }}
    >
      <CoreComponents.Loader show={Boolean(isSaving)} />
      <DialogHeader
        title={title}
        onClose={() => onCancelClick()}
      />
      <CoreComponents.PreContent
        isLoading={Boolean(isLoading)}
        loader={<> LOADING </>}
        isEmpty={Boolean(!task || !paymentSchedule)}
        empty={<>SUM EMPTY</>} 
      >
        <PaymentConfirmContent 
          paymentType={paymentType}
          task={task as CoreState.Task.Task}
          paymentSchedule={paymentSchedule as CoreState.Financial.PaymentSchedule}
          senderLabel={senderLabel}
          recipientLabel={recipientLabel}
          recipientName={recipientName}
          submitText={submitText}
          onSubmitClick={onSubmitClick}
          onCancelClick={onCancelClick}
        />
      </CoreComponents.PreContent>
    </Dialog>
  )
}
export default PaymentConfirm;