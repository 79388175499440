import { CoreState } from "@build-buddy/core";
import { useSelector } from "react-redux";
import { TaskViewRouteDefinitions } from "../../..";
import { useTaskViewParams } from "../../../_hooks";
import { selectShowVariationsTab } from "../../../_selectors";

const useTaskViewTypesTabsVariation = () => {
  // hooks
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const variationParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };

  // queries
  const variationsQuery = CoreState.Variation.getProjectTaskVariationList.useQuery(variationParams)

  // selectors
  const showVariationsTab = useSelector((s: CoreState.RootState) => selectShowVariationsTab(s, quoteListParams));

  // Active
  const activeVariations = variationsQuery.data?.filter(variation => {
    return ![
      CoreState.Variation.TaskVariationStatus.Rejected,
      CoreState.Variation.TaskVariationStatus.Completed
    ].includes(variation.status)
  })

  const count = (activeVariations?.length || 0)

  if (!showVariationsTab) return;
  return {
    ...TaskViewRouteDefinitions.TASK_VIEW_VARIATIONS,
    label: "Variations",
    count
  }
}
export default useTaskViewTypesTabsVariation;