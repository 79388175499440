import Calendar from "./Calendar";

const CalendarRouteDefinitions = {
  CALENDAR_ROOT: {
    path: "/project/:pid/calendar/",
    component: Calendar,
    exact: true,
  },
  CALENDAR: {
    path: "/project/:pid/calendar/:filter/:year/:month/",
    component: Calendar,
  },
  CALENDAR_DRAWER: {
    path: "/project/:pid/calendar/:filter/:year/:month/:day/",
  },
}

export default CalendarRouteDefinitions;