import { CoreState } from "@build-buddy/core";
import { useRouting } from '@build-buddy/core/dist/routing';
import { selectTaskListByProjectGrouped } from "@build-buddy/core/dist/state/task/selectors/selectTaskListByProject";
import { useSelector } from "common/state/store";
import { useEffect, useState } from 'react';
import { useTaskViewParams } from "../task-view/_hooks";
import updateTasks from './state/updateTasks';
import { SubTaskStatusTypes } from './types/types';

const TaskViewEdit = () => {
  const [formValues, setFormValues] = useState<Record<string, Record<string, SubTaskStatusTypes>>>({});
  const [open, setOpen] = useState<boolean>(false);

  const { projectId } = useTaskViewParams();
  const routing = useRouting();

  const projectTaskListQuery = CoreState.Task.getTaskListByProject.useQuery({ projectId });
  const [updateAction, updateTasksQuery] = updateTasks.useMutation();

  const val = useSelector((s) => selectTaskListByProjectGrouped(s, { projectId }));

  //change on submit
  const handleSubmit = () => {
    updateAction({
      projectId,
      subTasks: formValues
    })
  }

  useEffect(() => {
    if (updateTasksQuery.isSuccess || updateTasksQuery.isError) {
      setOpen(false);
    }
  }, [updateTasksQuery.isSuccess, updateTasksQuery.isError])

  return null;

  // return (
  //   <Card
  //     sx={{
  //       height: {
  //         md: "80vh",
  //         xs: "75vh"
  //       }
  //     }}
  //   >
  //     <CardContent
  //       sx={{
  //         height: "inherit",
  //         p: 0
  //       }}
  //     >
  //       <CoreComponents.PreContent
  //         isLoading={projectTaskListQuery.isLoading || projectTaskListQuery.isFetching}
  //         isEmpty={val.length === 0}
  //         empty={<CoreComponents.EmptyContent />}
  //         loader={<TaskViewEditSkeleton />}
  //       >
  //         <Stack sx={{
  //           flexDirection: {
  //             xs: "column",
  //             md: "row"
  //           },
  //           height: "inherit"
  //         }}>
  //           <TaskViewEditSideBar />
  //           <Stack
  //             sx={{
  //               height: {
  //                 md: "inherit",
  //                 xs: "70vh"
  //               },
  //               width: {
  //                 xs: "100%",
  //                 md: "80%"
  //               },
  //             }}
  //             direction="column"
  //           >
  //             <TaskViewEditHeader />
  //             <Box
  //               sx={{
  //                 overflowY: "auto",
  //                 flex: 1
  //               }}>
  //               {val.map((item) => (
  //                 <TaskViewEditItem
  //                   item={item}
  //                   key={item.parentId}
  //                   changeFormValues={setFormValues}
  //                 />))
  //               }
  //             </Box>
  //             <TaskViewEditActions
  //               isDisabled={Object.keys(formValues).length === 0}
  //               onSubmitHandler={() => setOpen(true)}
  //               onCancelHandler={() => { routing.go(DashboardRouteDefinitions.DASHBOARD_ROOT) }}
  //             />
  //           </Stack>
  //         </Stack>
  //         <TaskViewEditConfirmationModal
  //           onCloseHandler={() => setOpen(false)}
  //           onSubmitHandler={handleSubmit}
  //           open={open}
  //           isLoading={updateTasksQuery.isLoading}
  //         />
  //       </CoreComponents.PreContent>
  //     </CardContent>
  //   </Card >
  // );
}

export default TaskViewEdit;