import { createSelector } from "@reduxjs/toolkit"
import { CoreState } from "@build-buddy/core"
import { selectProjectTask } from "modules/project/_selectors"
import { selectTaskViewQuoteListAcceptedQuote } from "./selectTaskViewQuoteList"

export const selectShowVariationsTab = createSelector(
  [selectProjectTask, selectTaskViewQuoteListAcceptedQuote],
  (projectTask, acceptedQuote) => {
    const showVariationsTab = Boolean(![
      CoreState.Task.TaskType.Request,
      CoreState.Task.TaskType.Select,
    ].includes(projectTask?.task?.type as CoreState.Task.TaskType) && acceptedQuote)
    return showVariationsTab
  }
)