import { CoreComponents, CoreState } from "@build-buddy/core";
import { Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTask, selectProjectTaskStatus } from "modules/project/_selectors";
import { useState } from "react";
import { useTaskViewParams } from "../../_hooks";
import TaskViewValidation from "../../task-view-validation/TaskViewValidation";

const TasKViewSupplyActions = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId }

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, taskParams));

  // mutations
  const [completeTaskAction, completeTaskQuery] = CoreState.Task.completeTask.useMutation()

  // locals
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<Array<string>>([]);
  const showActions = Boolean(projectTaskStatus !== ProjectTaskStatus.Completed);
  const isSaving = Boolean(completeTaskQuery.isLoading);

  const handleComplete = () => {
    if (!projectTask) return;
    completeTaskAction({
      projectId: projectId,
      parentId: projectTask?.task.parentId,
      taskId: projectTask?.task.id
    })
  }


  if (!showActions) return null;

  return (
    <>
      <TaskViewValidation
        open={open}
        errors={errors}
        onClose={() => setOpen(false)}
      />
      <CoreComponents.Loader show={isSaving} />
      <Button
        fullWidth
        variant="contained"
        onClick={handleComplete}
      >
        Mark Complete
      </Button>
    </>
  )
}
export default TasKViewSupplyActions