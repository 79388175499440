import { CoreModules, CoreRouting } from "@build-buddy/core";
import { Box } from "@mui/material";
import BackButton from "./_components/BackButton";
import { useCompanyParams } from "./_hooks";
import { CompanyRoutesDefinitions } from "./CompanyRoutes";

const CompanyReviews = () => {
  const { companyId } = useCompanyParams();
  const routing = CoreRouting.useRouting();

  const handleBackClick = () => {
    routing.go(CompanyRoutesDefinitions.COMPANY_VIEW)
  }

  return (
    <Box>
      <Box
        sx={{ mb: 2 }}
      >
      <BackButton
        onClick={handleBackClick}
        label="Back to Company"
      />
      </Box>
      <CoreModules.Company.CompanyReviews companyId={companyId} />
    </Box>
  )
}
export default CompanyReviews;