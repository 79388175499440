import WizardCreate from "./wizard-create/WizardCreate";
import WizardView from "./wizard-view/WizardView";
import {Switch} from "react-router-dom";
import {Route} from "react-router";

const WizardRoutesDefinitions = {
    WIZARD_CREATE: {
        path: "/wizard/create",
        component: WizardCreate,
        exact: true
    },
    WIZARD_VIEW: {
        path: "/wizard/:wid",
        component: WizardView,
        exact: true
    }
}


const WizardRoutes = () => {
    return (
        <Switch>
            <Route
                path={WizardRoutesDefinitions.WIZARD_CREATE.path}
                component={WizardRoutesDefinitions.WIZARD_CREATE.component}
                exact={true}
            />
            <Route
                path={WizardRoutesDefinitions.WIZARD_VIEW.path}
                component={WizardRoutesDefinitions.WIZARD_VIEW.component}
                exact={true}
            />
        </Switch>
    )
}

export {WizardRoutesDefinitions, WizardRoutes};
