import { CoreState, CoreComponents } from "@build-buddy/core";
import { Box, Button } from "@mui/material";
import { useTaskViewParams } from "../../../_hooks";

type TaskViewQualityAssuranceDefectsActionsRejectedProps = {
  defect: CoreState.Defect.Defect;
}
// this is declared due to the rule of hooks, since its a chain, if any hooks gets rendered and the number changes, it causes a violation
const TaskViewQualityAssuranceDefectsActionsRejected = (props: TaskViewQualityAssuranceDefectsActionsRejectedProps) => {
  const { defect } = props;
  if (!defect) return null;
  if (defect.status !== CoreState.Defect.DefectStatus.InDispute) return null;
  return <InnerTaskViewQualityAssuranceDefectsActionsRejected defect={defect} />
}
const InnerTaskViewQualityAssuranceDefectsActionsRejected = (props: TaskViewQualityAssuranceDefectsActionsRejectedProps) => {
  const { defect } = props;

  // params
  const { projectId, stageId, taskId } = useTaskViewParams();

  // mutations
  const [updateTaskDefectAction, updateTaskDefectQuery] = CoreState.Defect.updateDefect.useMutation();

  // locals
  const isSaving = updateTaskDefectQuery.isLoading;

  // event handlers
  const handleAcknowledge = (e: any) => {
    e.stopPropagation();
    updateTaskDefectAction({
      projectId,
      stageId,
      taskId,
      defectId: defect.id,
      defect: {
        status: CoreState.Defect.DefectStatus.Cancelled,
      },
    })
  }

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      <Box sx={{ textAlign: "right" }} >
        <Button variant="outlined" color="neutral" sx={{ mr: 1 }} onClick={handleAcknowledge}> Acknowledge </Button>
      </Box>
    </>
  )
}
export default TaskViewQualityAssuranceDefectsActionsRejected;