import { CoreRouting } from "@build-buddy/core";
import CompanyReviews from "./CompanyReviews";
import CompanyView from "./CompanyView";

const CompanyRoutesDefinitions = {
  COMPANY_VIEW: {
    path: "/company/:cid",
    component: CompanyView,
    exact: true,
  },
  COMPANY_REVIEWS: {
    path: "/company/:cid/reviews",
    component: CompanyReviews,
  }
}

const CompanyRoutes = () => {
  return (
      <CoreRouting.Routes
        routes={[
          CompanyRoutesDefinitions.COMPANY_VIEW,
          CompanyRoutesDefinitions.COMPANY_REVIEWS
        ]}
      />
  )
}

export { CompanyRoutes, CompanyRoutesDefinitions };

