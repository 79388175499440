import { CoreState } from '@build-buddy/core';
import { createSelector } from '@reduxjs/toolkit';

export interface IncompleteProfileItems {
  hasWallet: boolean;
  hasBankAccount: boolean;
  notificationReviewed: boolean;
}

const selectIncompleteProfileItems = createSelector(
  [
    CoreState.User.selectCurrentUser,
    CoreState.Wallet.selectWalletDetails,
  ],
  (user, wallet): IncompleteProfileItems  => {
    const validations = {
      hasWallet: Boolean(wallet?.active),
      hasBankAccount: Boolean(user?.accountNumber),
      notificationReviewed: Boolean(user?.flags?.notificationReviewed)
    }
    return validations
  }
);

export default selectIncompleteProfileItems;
