import { CoreComponents, CoreState } from "@build-buddy/core";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { useFinancialRoutesParams } from "../../FinancialsRoutes";
import UnlockedBudgetDraftCostCentre from "./UnlockedBudgetDraftCostCentre";

interface UnlockedBudgetDraftProps {
  budget: CoreState.Financial.Budget;
}
const UnlockedBudgetDraft = (props: UnlockedBudgetDraftProps) => {
  const { budget } = props;
  const { projectId } = useFinancialRoutesParams();
  const getCostCentreEstimateListByProjectParams = { projectId };
  const getCostCentreEstimateListByProjectQuery = CoreState.Financial.getCostCentreEstimateListByProject.useQuery(getCostCentreEstimateListByProjectParams);

  const isLoading = Boolean(!getCostCentreEstimateListByProjectQuery.isUninitialized && getCostCentreEstimateListByProjectQuery.isLoading);
  const costCentreEstimateList = useSelector(s => CoreState.Financial.selectCostCentreEstimateListByProject(s, getCostCentreEstimateListByProjectParams));
  const budgetName = `${budget.version} estimate`;

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography 
            variant="body1" 
            sx={{ 
              flex: 1,
              position: "relative",
              bottom: 8,
              fontWeight: "bold"
            }}
          > 
            {budgetName}
          </Typography>
          <CoreComponents.Textbox 
            name="search" 
            size="small" 
            onChange={() => {}} 
          />
        </Box>      
        <CoreComponents.PreContent
          isLoading={isLoading}
          loader={<> LOADING </>}
          isEmpty={false}
        >
          <Box sx={{ mx: -2 }}>
          {costCentreEstimateList?.map((costCentreEstimate, idx) => (
            <UnlockedBudgetDraftCostCentre
              key={idx}
              costCentreEstimate={costCentreEstimate}
            />
          ))}
          </Box>
        </CoreComponents.PreContent>
      </CardContent>
    </Card>
  )
}
export default UnlockedBudgetDraft;