import { CoreState, CoreUtils } from "@build-buddy/core";
import TaskViewQualityAssuranceDefectActionsInitial from "./TaskViewQualityAssuranceDefectActionsInitial";
import TaskViewQualityAssuranceDefectsActionsInReview from "./TaskViewQualityAssuranceDefectsActionsInReview";
import TaskViewQualityAssuranceDefectsActionsRejected from "./TaskViewQualityAssuranceDefectsActionsRejected";

type TaskViewQualityAssuranceDefectsActionsProps = {
  defect: CoreState.Defect.Defect;
}
const TaskViewQualityAssuranceDefectsActions = (props: TaskViewQualityAssuranceDefectsActionsProps) => {
  const { defect } = props;

  const rtn = CoreUtils.chainOfResponsibility<TaskViewQualityAssuranceDefectsActionsProps>({ defect })
    .add(TaskViewQualityAssuranceDefectActionsInitial)
    .add(TaskViewQualityAssuranceDefectsActionsInReview)    
    .add(TaskViewQualityAssuranceDefectsActionsRejected)
    .execute<any>();

  return rtn || null;
}
export default TaskViewQualityAssuranceDefectsActions;