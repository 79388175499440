import { CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { Route } from "react-router-dom";
import TaskViewPaymentsRouteDefinitions from "../TaskViewPaymentsRouteDefinitions";
import { useTaskViewPaymentsParams } from "../_hooks";
import { selectCurrentPaymentSchedule } from "../_selectors";
import TaskViewQuoteGeneralReceipt from "./TaskViewPaymentGeneralReceipt";
import TaskViewQuotePayToReceipt from "./TaskViewPaymentPayToReceipt";

const TaskViewPaymentPayReceipt = () => {
  const routing = CoreRouting.useRouting();
  // params
  const { projectId, stageId, taskId, paymentScheduleId, receiptType } = useTaskViewPaymentsParams();
  const paymentScheduleParams = { projectId, stageId, taskId, paymentScheduleId };

  // selectors
  const currentPaymentSchedule = useSelector((s) => selectCurrentPaymentSchedule(s, paymentScheduleParams))

  const showPayToReceiptPage = receiptType === CoreState.Wallet.PaymentGateway.Wallet &&
    currentPaymentSchedule?.status !== CoreState.Quote.PaymentScheduleStatus.Paid;

  // event handlers
  const handleClose = () => {
    routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENT_SCHEDULE)
  }

  return (
    <>
      <CoreModules.Task.TaskView.TaskViewSubView
        open={true}
        onClose={() => {
          handleClose();
        }}
      >
        {showPayToReceiptPage ?
          <TaskViewQuotePayToReceipt /> :
          <TaskViewQuoteGeneralReceipt />
        }
      </CoreModules.Task.TaskView.TaskViewSubView>
      <Route
        path={TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_PAY_VIEW_RECEIPT.path}
        component={TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_PAY_VIEW_RECEIPT.component}
      />
    </>
  );
}
export default TaskViewPaymentPayReceipt;