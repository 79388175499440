import TaskViewMessages from "./TaskViewMessages";

const TaskViewMessagesRouteDefinitions = {
  TASK_VIEW_MESSAGES: {
    label: "Messages",
    path: "/*/stage/:sid/task/:tid/messages",
    component: TaskViewMessages
  },
}

export default TaskViewMessagesRouteDefinitions;