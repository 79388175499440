import { Button } from "@mui/material";
import { useState } from "react";
import TaskViewScheduleDialog from "./TaskViewScheduleDialog";

const TaskViewScheduleActions = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        fullWidth
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Schedule
      </Button>
      <TaskViewScheduleDialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
export default TaskViewScheduleActions