import { Stack, SxProps, Theme } from "@mui/material";
import { ProjectTask } from "modules/project/_models";
import ProjectTaskActionsDefects from "./project-task-actions-defects/ProjectTaskActionsDefects";
import ProjectTaskActionsOverdue from "./project-task-actions-overdue/ProjectTaskActionsOverdue";
import ProjectTaskActionsPaymentRequested from "./project-task-actions-payment-requested/ProjectTaskActionsPaymentRequested";
import ProjectTaskActionsVariations from "./project-task-actions-variations/ProjectTaskActionsVariations";
import ProjectTaskActionsWriteReview from "./project-task-actions-write-review/ProjectTaskActionsWriteReview";

type ProjectTaskActionsProps = {
  projectTask?: ProjectTask;
  sx?: SxProps<Theme>;
}
const ProjectTaskActions = (props: ProjectTaskActionsProps) => {
  const { projectTask, sx } = props;
  
  if(!projectTask) return null;

  return (
    <Stack sx={{ mt: 0.5, ...sx }} direction="row" alignItems="center">
      <ProjectTaskActionsOverdue projectTask={projectTask} />
      <ProjectTaskActionsPaymentRequested projectTask={projectTask} />
      <ProjectTaskActionsDefects projectTask={projectTask} />
      <ProjectTaskActionsVariations projectTask={projectTask} />
      <ProjectTaskActionsWriteReview projectTask={projectTask} />
    </Stack>
  )
}
export default ProjectTaskActions;