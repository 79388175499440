import { useEffect } from "react";

import { useFormik } from "formik";

import { DialogContent, Typography } from "@mui/material";

import { CoreComponents, CoreState, CoreUtils } from "@build-buddy/core";

import { useTaskViewParams } from "../../_hooks";
import TaskViewBookingDateTimePickerValidation from "./TaskViewBookingDateTimePickerValidation";
import TaskViewBookingDateTimePicker from "./TaskViewBookingDateTimePicker";

const formikKvp = {"startDate": "start", "endDate": "end", "time": "time"}

type TaskViewBookingDialogFormProps = {
  nextTask: CoreState.Task.Task;
  currentTask: CoreState.Task.Task;
  onClose(): void;
}

const TaskViewBookingDialogForm = (props: TaskViewBookingDialogFormProps) => {
  const { projectId } = useTaskViewParams();
  const { nextTask, currentTask, onClose } = props;

  // mutations
  const [completeBookingAndAdjustDatesAction, completeBookingAndAdjustDatesQuery] = CoreState.Task.completeBookingAndAdjustDates.useMutation();

  // queries 
  const getBookingDatesQuery = CoreState.Task.getBookingTaskDate.useQuery({projectId, taskId: currentTask.id, subTaskDefinitionCode: currentTask.subTaskDefinitionCode});
  const dates = getBookingDatesQuery.data ?? {};

  // locals
  const isSaving = completeBookingAndAdjustDatesQuery.isLoading;
  const isSuccess = completeBookingAndAdjustDatesQuery.isSuccess;
  const isError = completeBookingAndAdjustDatesQuery.isError;

  // formik
  const formik = useFormik({
    initialValues: {
      start: Object.keys(dates)?.[0],
      time: Object.values(dates)[0]?.slots?.[0],
    },
    validationSchema: TaskViewBookingDateTimePickerValidation,
    onSubmit: (values):void => {
      completeBookingAndAdjustDatesAction({
        projectId,
        currentTaskId: currentTask.id,
        nextTaskId: nextTask.id,
        bookingTime: values.time,
        subTaskDefinitionCode: currentTask.subTaskDefinitionCode
      });
    },
    enableReinitialize:true,
  })

  // side effects
  useEffect(() => {
    if (!isSuccess) return;
    props.onClose();
  }, [isSuccess])

  useEffect(() => {
    if (!isError) return;
    const error = CoreUtils.TypeGuards.getFetchBaseQueryError(completeBookingAndAdjustDatesQuery);
    if (error?.form) {
      const errors = CoreUtils.Converter.toKvpRemap(error.form, formikKvp);
      formik.setErrors(errors);
    }
  }, [isError])

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      <DialogContent>
        <CoreComponents.TaskLabel label={nextTask.name} />
        <Typography variant="body1" sx={{ mb: 3 }}>
          {currentTask.description}
        </Typography>
        <TaskViewBookingDateTimePicker
          formik={formik}
          StartProps={{
            id: "start",
            label: "Start Date",
          }}
          TimeProps={{
            id: "time",
            label: "Time slot"
          }}
          dates={dates}
          isLoading={getBookingDatesQuery.isLoading}
        />
        <CoreComponents.Actions
          submitText="Confirm"
          onCancelClick={() => onClose()}
          onSubmitClick={() => formik.handleSubmit()}
        />
      </DialogContent>
    </>
  )
}
export default TaskViewBookingDialogForm;