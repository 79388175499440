import { Box, Skeleton } from "@mui/material";

const BudgetListItemSkeleton = () => {
  const arr = Array(10).fill(0);
  return (
    <Box sx={{ py: 2 }}>
      {arr.map((a, i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          height="50px"
          sx={{ 
            mb: 3,
            "&last-of-type": {
              mb: 0
            }
          }}
        />
      ))}
    </Box>
  )
}
export default BudgetListItemSkeleton;