import { CoreComponents, CoreState } from "@build-buddy/core";
import { Search as SearchIcon } from "@mui/icons-material";
import { Box, MenuItem, TextField } from "@mui/material";
import { useSelector } from "common/state/store";
import { useTaskViewParams } from "../../_hooks";
import { selectDisplayQuoteList, selectTaskViewQuoteListGrouped } from "../../_selectors";
import TaskViewQuotesListEmpty from "./TaskViewQuotesListEmpty";
import TaskViewQuotesListHeader from "./TaskViewQuotesListHeader";
import TaskViewQuotesListItem from "./TaskViewQuotesListItem";

const TaskViewQuotes = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const quoteListParams = { projectId, stageId, taskId };

  // queries
  const getQuoteListByLatestQuoteRequestQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(quoteListParams);

  // selectors
  const grouped = useSelector((s) => selectTaskViewQuoteListGrouped(s, quoteListParams));
  const displayQuotes = useSelector((s) => selectDisplayQuoteList(s, quoteListParams))

  // locals
  const isLoading = getQuoteListByLatestQuoteRequestQuery.isLoading;

  return (
    <>
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={(!grouped || !grouped.length)}
        empty={<TaskViewQuotesListEmpty />}
      >

        {false &&
          <Box sx={{ mb: 1 }}>
            <TextField
              size="small"
              placeholder="Search quotes"
              sx={{
                mr: 1,
              }}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
            <CoreComponents.Select
              size="small"
              name=""
              value="Owner"
              onChange={() => { }}
            >
              <MenuItem value="Owner">Active</MenuItem>
              <MenuItem value="Editor">Declined</MenuItem>
              <MenuItem value="Viewer">All quotes</MenuItem>
            </CoreComponents.Select>
          </Box>
        }
        <TaskViewQuotesListHeader />
        {displayQuotes?.map((quotes, idx) => (
          <TaskViewQuotesListItem
            key={idx}
            quote={quotes.current}
            history={quotes.history}
          />
        ))}
      </CoreComponents.PreContent>
    </>
  )
}
export default TaskViewQuotes;