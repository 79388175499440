import React, { useEffect, useState } from 'react'
import { Confirmation, CoreState } from '@build-buddy/core'
import { useTaskViewParams } from '../../../_hooks'

type ConfirmDIYProps =  {
  updateLastSpecOnDiyConfirmed?: () => void;
  show: boolean;
  onClose: () => void
}

const ConfirmDIY = (props: ConfirmDIYProps) => {
  const { show, onClose  } = props;
  // params
  const { projectId, taskId } = useTaskViewParams()

  // internal states
  const [error, setError] = useState<any>()

  // mutations
  const [setTaskDiyAction, setTaskDiyQuery] = CoreState.Task.setTaskDiy.useMutation()

  const handleDIYConfirm = () => {
    setTaskDiyAction({ projectId, taskId })
  }

  // handles error messages
  useEffect(() => {
    if (setTaskDiyQuery.error) {
      setError(setTaskDiyQuery.error)
    }
  }, [setTaskDiyQuery.error])

  // closes modal on success
  useEffect(() => {
    if (setTaskDiyQuery.isSuccess && !setTaskDiyQuery.error) {
      // This marks the last spec as DIY, only if the whole task is marked as DIY.
      if(props.updateLastSpecOnDiyConfirmed)  props.updateLastSpecOnDiyConfirmed();
      onClose()
    }
  }, [setTaskDiyQuery.isSuccess])
  
  return (
    <Confirmation
      open={show}
      title="Are You Sure ?"
      text="You cannot undo DIY once it's done."
      onCancel={() => onClose()}
      onClose={onClose}
      onConfirm={handleDIYConfirm}
      loading={setTaskDiyQuery.isLoading}
      error={error?.data.server}
    />
  )
}

export default ConfirmDIY