import { CoreComponents } from "@build-buddy/core";
import { useTheme } from "@mui/material";
import { ProjectTask, ProjectTaskSubStatus } from "modules/project/_models";

type ProjectTaskActionsVariationsProps = {
  projectTask: ProjectTask
}
const ProjectTaskActionsVariations = (props: ProjectTaskActionsVariationsProps) => {
  const theme = useTheme();
  if (!props.projectTask.subStatuses.includes(ProjectTaskSubStatus.VariationsRaised)) return null;
  return (
    <CoreComponents.OutlinedIcon
      icon={() => <>V</>}
      sx={{
        height: 24,
        width: 24,
        border: "none",
        mr: 0.5,
        fontWeight: "bold",
        fontSize: 16,
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main
      }}
      tooltip="Variations Raised"
    />
  )
}
export default ProjectTaskActionsVariations;