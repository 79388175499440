import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import TaskViewRouteDefinitions from "../../../TaskViewRouteDefinitions";
import { useTaskViewParams } from "../../../_hooks";

const useTaskViewTypesTabsDescription = () => {
  // hooks
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const projectTaskParams = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, projectTaskParams));

  const label = projectTask?.task.type === CoreState.Task.TaskType.ShowMedia ? "Video" : "Description";

  return {
    ...TaskViewRouteDefinitions.TASK_VIEW_DESCRIPTION,
    label,
    count: 0
  }
}
export default useTaskViewTypesTabsDescription;