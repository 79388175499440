import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

interface SelectTaskViewQuoteListParams {
  projectId: string;
  stageId: string;
  taskId: string;
  quoteId: string;
}
export const selectTaskViewQuote = createSelector(
  [
    (state, params: SelectTaskViewQuoteListParams) => CoreState.Quote.selectQuoteRequestListByTaskLatestQuoteRequest(state, params),
    (state, params: SelectTaskViewQuoteListParams) => ({ state, params })
  ], 
  (quoteRequest, meta) => {
    if(!quoteRequest) return;
    const p = { projectId: meta.params.projectId, quoteRequestId: quoteRequest.id, quoteId: meta.params.quoteId };
    return createSelector(
      [(state: CoreState.RootState) => CoreState.Quote.selectQuote(state, p)],
      (quote) => {
        return quote;
      }
    )(meta.state)
  }
)