import TaskViewEdit from "./TaskViewEdit";

const TaskViewEditRouteDefinitions = {
  TASK_LIST_EDIT:
  {
    path: "/project/:pid/tasks/edit",
    component: TaskViewEdit
  },
}

export default TaskViewEditRouteDefinitions;