import { CoreComponents, CoreState, CoreUtils, useMode } from '@build-buddy/core'
import { PredecessorStatus } from '@build-buddy/core/dist/state/task'
import { Stack, Box, Typography, useTheme } from '@mui/material'
import ProjectTaskStatusPredecessorsStatus from 'modules/project/_components/project-task-status/project-task-status-predecessors/ProjectTaskStatusPredecessorsStatus'
import { GanttTask } from './useGanttChartData'

interface GanttChartDialogContentProps {
  currentTask?: GanttTask;
}

const GanttChartDialogContent = (props: GanttChartDialogContentProps) => {
  const theme = useTheme();
  const mode = useMode()
  const { currentTask } = props;
  const predecessors = currentTask?.predecessors as any;

  // If task is in Outstanding(Pending) status, get the end date to show it in the last pre-requisite task.
  let lastItemDate;
  if (predecessors?.length > 0) {
    lastItemDate = predecessors[predecessors.length - 1].status === PredecessorStatus.Pending ?
      predecessors[predecessors.length - 1].endDate :
      null
  }

  if (!currentTask) return <CoreComponents.EmptyContent />;

  return (
    <>
      {mode.isMobile &&
        <Typography variant='h4'>
          {currentTask?.title}
        </Typography>
      }
      <Stack
        direction='column'
        rowGap={1}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ minWidth: '100px' }} >
            Start Date:
          </Typography>
          <Typography fontWeight={500}>
            {CoreUtils.Formatter.date(currentTask?.start)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ minWidth: '100px' }}>
            End Date:
          </Typography>
          <Typography fontWeight={500}>
            {CoreUtils.Formatter.date(currentTask?.end)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ minWidth: '100px' }}>
            Completed:
          </Typography>
          <Typography fontWeight={500}>
            {(currentTask?.percentComplete || 0) * 100}%
          </Typography>
        </Box>
      </Stack>
      <Stack mt={1}>
        <Typography
          variant='h4'
          py={1}
          sx={{ borderBottom: `1px solid ${theme.palette.grey[200]}` }}
        >
          Pre-Requisite Tasks
        </Typography>
        <CoreComponents.PreContent
          isLoading={false}
          loader={<></>}
          isEmpty={predecessors?.length < 1}
          empty={
            <Typography
              mt={1}
              color={theme.palette.grey[500]}
            >
              No predecessors found for this task!
            </Typography>
          }
        >
          <CoreComponents.Timeline
            lastTimelineItem={{
              label: "",
              date: lastItemDate,
              sx: {
                px: 2,
                pt: 2
              }
            }}
          >
            {currentTask?.predecessors?.map((predecessor: CoreState.Task.Predecessor) => {
              return (
                <CoreComponents.TimelineItem
                  key={predecessor.id}
                  title={predecessor.name}
                  status={
                    <ProjectTaskStatusPredecessorsStatus
                      predecessor={predecessor}
                    />
                  }
                  date={predecessor.status === PredecessorStatus.Complete ?
                    predecessor.endDate :
                    predecessor.startDate
                  }
                  sx={{ px: 2 }}
                  isInProgress={Boolean(predecessor.status === PredecessorStatus.Pending)}
                  isCompleted={Boolean(predecessor.status === PredecessorStatus.Complete)}
                />
              )
            })}
          </CoreComponents.Timeline>
        </CoreComponents.PreContent>
      </Stack>
    </>
  )
}

export default GanttChartDialogContent