import { Card, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem } from "@progress/kendo-react-charts";
import useCulmulativeTasksChart from "./useCulmulativeNoOfTasksChart";

const CulmulativeNoOfTasksChart = () => {
  const theme = useTheme();
  const { chart, isLoading } = useCulmulativeTasksChart();

  if (isLoading) return <> LOADING </>
  if (!chart) return <> EMPTY </>

  return (
    <Card>
      <CardContent>
        <Stack direction="row" sx={{ mt: 0.5, mb: 3 }}>
          <Typography variant="h5" sx={{ flex: 1 }}> Cumulative No. of Tasks </Typography>
        </Stack>
        <Chart style={{ height: "300px" }}>
          <ChartLegend visible={false} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={chart.xAxis}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              min={0}
              name="total"
            />
          </ChartValueAxis>
          <ChartSeries>
            <ChartSeriesItem
              type="line"
              data={chart.data}
              color={theme.palette.primary.main}
            />
          </ChartSeries>
        </Chart>
      </CardContent>
    </Card>
  )
}
export default CulmulativeNoOfTasksChart;

