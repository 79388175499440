import { CoreComponents, CoreModules, CoreState } from "@build-buddy/core";
import { useFinancialRoutesParams } from "modules/project/financials/FinancialsRoutes";
import { selectTaskViewQuoteListAcceptedQuote } from "modules/project/task/task-view/_selectors";
import { useSelector } from "react-redux";
import BudgetItemListTrackingPaymentScheduleActions from "./BudgetItemListTrackingPaymentScheduleActions";
import BudgetItemListTrackingPaymentScheduleSkeleton from "./BudgetItemListTrackingPaymentScheduleSkeleton";

export interface BudgetItemListTrackingPaymentScheduleProps {
  taskId: string;
}
const BudgetItemListTrackingPaymentSchedule = (props: BudgetItemListTrackingPaymentScheduleProps) => {
  const { projectId, stageId } = useFinancialRoutesParams();
  const { taskId } = props;

  const quoteListParams = { projectId, stageId, taskId };

  // queries
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(quoteListParams);

  // selectors
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, quoteListParams));

  const isLoading = Boolean(!quoteListQuery.isUninitialized && quoteListQuery.isLoading);

  return (
    <CoreComponents.PreContent
      isLoading={isLoading}
      loader={<BudgetItemListTrackingPaymentScheduleSkeleton />}
      isEmpty={Boolean(!acceptedQuote?.paymentSchedule)}
      empty={<> ERR </>}
    >
      <CoreModules.Task.TaskView.TaskViewPaymentSchedule
        paymentSchedule={acceptedQuote?.paymentSchedule as Array<CoreState.Quote.PaymentSchedule>}
        actions={(params: any) => (
          <BudgetItemListTrackingPaymentScheduleActions
            taskId={taskId}
            paymentSchedule={params.paymentSchedule}
          />
        )}
        dotProgress={() => <></>}
      />
    </CoreComponents.PreContent>
  )
}
export default BudgetItemListTrackingPaymentSchedule;