import { CoreComponents, CoreState } from "@build-buddy/core";
import { ProjectTask } from "modules/project/_models";
import { ProjectTaskStatus } from "modules/project/_components";
import TaskViewTimelineTaskListItem from "./TaskViewTimelineTaskListItem";

type TaskViewTimelineTaskListProps = {
  projectId: string;
  projectTasks?: Array<ProjectTask>;
  readonly: boolean;
}
const TaskViewTimelineTaskList = (props: TaskViewTimelineTaskListProps) => {
  // locals
  const { projectTasks, projectId } = props;
  const isCompleted = projectTasks?.every(t => t.task.status === CoreState.Task.TaskStatus.Completed)
  const isNotRequired = (pt: ProjectTask) => pt.task.status === CoreState.Task.TaskStatus.NotRequired;
  return (
    <CoreComponents.Timeline
      isCompleted={isCompleted}
      lastTimelineItem={{
        label: "",
        sx: {
          px: 3
        }
      }}
    >
      {projectTasks?.map((pt, idx) => (
        <CoreComponents.TimelineItem
          key={idx}
          title={pt.task.name}
          isInProgress={pt.task.isCurrent}
          isCompleted={Boolean(pt.task.status === CoreState.Task.TaskStatus.Completed)}
          status={<ProjectTaskStatus projectTask={pt} />}
          sx={{
            pl: 3,
            pr: 1,
            opacity: isNotRequired(pt) ? 0.5 : 1,
            pointerEvents: isNotRequired(pt) ? 'none' : "all"
          }}
        >
          <TaskViewTimelineTaskListItem
            task={pt.task}
            projectId={projectId}
            readonly={props.readonly}
          />
        </CoreComponents.TimelineItem>
      ))}
    </CoreComponents.Timeline>
  )
}
export default TaskViewTimelineTaskList;