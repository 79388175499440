import { CoreComponents, CoreState, DialogHeader } from "@build-buddy/core";
import { Dialog, DialogContent } from "@mui/material";
import { useSelector } from "common/state/store";
import { useFormik } from "formik";
import { selectProjectTask } from "modules/project/_selectors";
import { useEffect } from "react";
import { useTaskViewParams } from "../../_hooks";
import TaskViewSupplyUpdateValidation from "./TaskViewOutlineAddCostUpdateValidation";

type TaskViewOutlineAddCostUpdateProps = {
  open: boolean;
  onClose(): void;
}
const TaskViewOutlineAddCostUpdate = (props: TaskViewOutlineAddCostUpdateProps) => {
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const taskParams = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  
  // mutations
  const [updateTaskAction, updateTaskQuery] = CoreState.Task.updateTask.useMutation();

  // locals
  const isSaved = updateTaskQuery.isSuccess;
  const isSaving = updateTaskQuery.isLoading;

  // event handlers
  const handleUpdate = (cost: number) => {
    updateTaskAction({
      projectId,
      stageId,
      taskId,
      task: {
        extra: {
          supplyCost: cost
        }
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      cost: projectTask?.task.extra?.supplyCost || "",
    },
    validateOnChange: true,
    validationSchema: TaskViewSupplyUpdateValidation,
    onSubmit: (values) => {
      handleUpdate(Number(values.cost));
    },
    enableReinitialize: true
  });

  useEffect(() => {
    if (!props.open) formik.resetForm();
  }, [props.open])

  useEffect(() => {
    if (isSaved) props.onClose();
  }, [isSaved])

  return (
    <Dialog open={props.open}>
      <CoreComponents.Loader show={isSaving} />
      <DialogHeader title="Edit Supply Cost" onClose={() => props.onClose()} />
      <DialogContent>
        <CoreComponents.Textbox
          name="cost"
          label="Supply Cost"
          value={formik.values.cost}
          error={Boolean(formik.touched.cost) && Boolean(formik.errors.cost)}
          onChange={formik.handleChange}
          inputComponent={CoreComponents.Money as any}
          fullWidth
        />
        <CoreComponents.Actions
          onCancelClick={() => props.onClose()}
          onSubmitClick={() => formik.handleSubmit()}
        />
      </DialogContent>
    </Dialog>
  );
};
export default TaskViewOutlineAddCostUpdate;
