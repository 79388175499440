import { CoreComponents, CoreRouting, CoreState, CoreUtils, DialogHeader, Loader, PayToLogo, WalletIcon } from '@build-buddy/core'
import { RootState } from '@build-buddy/core/dist/state'
import { selectCurrentUserId } from '@build-buddy/core/dist/state/user'
import { CreditCard as CreditCardIcon } from '@mui/icons-material'
import { Box, Card, CardContent, Dialog, DialogContent, Stack, Typography, useTheme } from '@mui/material'
import { selectProjectTask } from 'modules/project/_selectors'
import { useEffect } from 'react'
import TaskViewPaymentsRouteDefinitions from '../TaskViewPaymentsRouteDefinitions'
import { useTaskViewPaymentsParams } from '../_hooks'
import { selectCompanyByPaymentSchedule, selectCurrentPaymentSchedule } from '../_selectors'
import PaymentBreakdown from './PaymentBreakdown'
import { useSelector } from 'common/state/store'

type PaymentConfirmationProps = {
  show: boolean;
  onClose: () => void;
  confirmationType: string;
  currentMethod: CoreState.Wallet.PaymentGateway;
}

const PaymentConfirmation = (props: PaymentConfirmationProps) => {
  const { show = true, onClose, confirmationType, currentMethod } = props;
  const routing = CoreRouting.useRouting();
  const theme = useTheme()

  // params
  const { projectId, stageId, taskId, paymentScheduleId } = useTaskViewPaymentsParams();
  const taskParams = { projectId, stageId, taskId };
  const paymentScheduleParams = { projectId, stageId, taskId, paymentScheduleId };

  // selectors
  const userId = useSelector(selectCurrentUserId);
  const currentPaymentSchedule = useSelector((s) => selectCurrentPaymentSchedule(s, paymentScheduleParams))
  const walletDetails = useSelector((state: RootState) => CoreState.Wallet.selectWalletDetails(state, { userId: userId || '' }));
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const company = useSelector((s) => selectCompanyByPaymentSchedule(s, paymentScheduleParams));

  // mutations
  const [payPaymentScheduleAction, payPaymentScheduleQuery] = CoreState.Quote.payPaymentSchedule.useMutation();
  const [releasePaymentScheduleAction, releasePaymentScheduleQuery] = CoreState.Quote.releasePaymentByPaymentSchedule.useMutation();

  // Calc
  const amount = currentPaymentSchedule?.amount as number;
  // const creditCardSurcharge = currentMethod === CoreState.Wallet.PaymentGateway.card ? amount / 100 * 1.5 : 0;
  const grandTotal = amount

  const handlePayment = () => {
    if (confirmationType === CoreState.Quote.PaymentMethod.release) {
      releasePaymentScheduleAction({
        projectId: projectId,
        taskId: taskId,
        paymentScheduleId: currentPaymentSchedule!.id,
        paymentType: CoreState.Quote.PaymentMethod.release
      })
      return
    }
    payPaymentScheduleAction({
      projectId: projectId,
      taskId: taskId,
      paymentScheduleId: currentPaymentSchedule!.id,
      paymentType: confirmationType as any
    })
  }

  // Change button label according to action/status.
  const submitButtonText = (currentPaymentSchedule?.status === CoreState.Quote.PaymentScheduleStatus.ReleaseRequested) ?
    'Release Payment' :
    currentMethod === CoreState.Wallet.PaymentGateway.Wallet ?
      "Request Authorisation" :
      'Confirm Payment';

  const dialogTitleKvp: Record<string, string> = {
    [CoreState.Quote.PaymentMethod.allocate]: 'Allocate Funds',
    [CoreState.Quote.PaymentMethod.payNow]: 'Pay Now',
    [CoreState.Quote.PaymentMethod.release]: 'Release Payment',
    [CoreState.Quote.PaymentMethod.express]: 'Pay Now',
  }

  // receipt params
  const receiptParams = {
    psid: currentPaymentSchedule?.id,
    receiptType: currentMethod
  }

  // side effects
  useEffect(() => {
    if (payPaymentScheduleQuery.isSuccess || releasePaymentScheduleQuery.isSuccess) {
      onClose();
      routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_PAY_RECEIPT, { params: receiptParams })
    }
  }, [payPaymentScheduleQuery.isSuccess, releasePaymentScheduleQuery.isSuccess])

  if (!currentPaymentSchedule) return null;

  return (
    <>
      <Dialog open={show}>
        <Loader show={payPaymentScheduleQuery.isLoading || releasePaymentScheduleQuery.isLoading} />
        <DialogHeader
          title={dialogTitleKvp[confirmationType]}
          onClose={onClose}
        />
        <DialogContent >
          <Stack direction={'row'} width={'100%'} gap={2} mb={4}>
            <Card sx={{ width: '100%', border: `1px solid ${theme.palette.grey[200]}` }}>
              <CardContent>
                <Typography variant='body2'>Total Payment</Typography>
                <Stack
                  direction={'row'}
                  width={'100%'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={1}
                  mt={1}
                >
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <WalletIcon />
                  </Box>
                  <Stack
                    direction={'row'}
                    alignItems={'flex-end'}
                    gap={0.5}
                  >
                    <Typography
                      variant='h3'
                      lineHeight={1}
                    >
                      {CoreUtils.Formatter.currency(grandTotal) || '-'}
                    </Typography>
                    <Typography variant='body2'>{walletDetails?.currency}</Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>

            <Card sx={{ width: '100%', border: `1px solid ${theme.palette.grey[200]}` }}>
              <CardContent>
                <Typography variant='body2'>Sending to</Typography>
                <Typography variant='h3' mt={1}>{company?.name}</Typography>
              </CardContent>
            </Card>
          </Stack>
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={2}>
            <Box width="100%">
              <CoreComponents.TaskLabel sx={{ mb: 2, borderRadius: '8px' }} label={projectTask?.task?.name || ''} />
              <Typography variant='h5' my={2}>{currentPaymentSchedule?.description}</Typography>
            </Box>
            {currentMethod === CoreState.Wallet.PaymentGateway.Wallet ?
              <PayToLogo height="30" width='60' /> :
              <CreditCardIcon sx={{ opacity: 0.7, mt: 0.5 }} />
            }
          </Stack>
          <PaymentBreakdown amount={currentPaymentSchedule?.amount} currentMethod={currentMethod} />
          {
            currentMethod === CoreState.Wallet.PaymentGateway.Card &&
            <Typography variant='body1' sx={{ mb: 2, mt: 3 }}>
              We&apos;re ready to process your payment using your selected credit card.
            </Typography>
          }
          <CoreComponents.Actions
            submitText={submitButtonText}
            cancelText={"Cancel"}
            onCancelClick={onClose}
            onSubmitClick={handlePayment}
            sx={{ mt: 4 }}
            cancelButtonProps={{
              variant: "outlined",
              color: "primary"
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PaymentConfirmation