import { CoreState, CoreUtils } from "@build-buddy/core";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Box, Collapse, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import UnlockedBudgetDraftTemplate from "./UnlockedBudgetDraftTemplate";
import UnlockedBudgetDraftEstimateItem from "./UnlockedBudgetDraftEstimateItem";

export interface UnlockedBudgetDraftCostCentreProps {
  costCentreEstimate: CoreState.Financial.CostCentreEstimate;
}
const UnlockedBudgetDraftCostCentre = (props: UnlockedBudgetDraftCostCentreProps) => {
  const { costCentreEstimate } = props;
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const stack = [...costCentreEstimate.estimates];
  let calc = 0;
  while (stack.length) {
    let element = stack.pop();
    if (!element) break;
    if (element.items) stack.push(...element.items);
    calc += element.rate * element.quantity;
  }

  const sum = CoreUtils.Formatter.currency(calc);

  return (
    <>
      <UnlockedBudgetDraftTemplate
        sx={{
          background: theme.palette.grey[100],
          px: 3,
          py: 1.75,
        }}
        onClick={() => setOpen(!open)}
      >
        <Typography
          sx={{ gridArea: "code" }}
        >
          {costCentreEstimate.costCentre.code}
        </Typography>
        <Typography
          sx={{
            gridArea: "name",
            textTransform: "capitalize"
          }}
        >
          {costCentreEstimate.costCentre.name.toLowerCase()}
        </Typography>
        <Typography
          sx={{
            gridArea: "total",
            textAlign: "right",
            fontWeight: "bold"
          }}
        >
          {sum}
        </Typography>
        <Box
          sx={{ gridArea: "icon", textAlign: "right" }}
        >
          <ChevronRightIcon
            sx={{
              transform: `rotate(${open ? "90deg" : "0"})`
            }}
          />
        </Box>
      </UnlockedBudgetDraftTemplate>
      <Collapse
        in={open}
        unmountOnExit={true}
      >
        <UnlockedBudgetDraftTemplate
          sx={{
            px: 3,
            py: 1.5,
            borderBottom: `solid 1px ${theme.palette.grey[200]}`
          }}
        >
          <Typography
            sx={{
              gridArea: "qty",
            }}
          >
            Qty
          </Typography>
          <Typography
            sx={{
              gridArea: "rate",
              textAlign: "right"
            }}
          >
            Rate
          </Typography>
          <Typography
            sx={{
              gridArea: "total",
              textAlign: "right"
            }}
          >
            Total
          </Typography>
        </UnlockedBudgetDraftTemplate>
        {costCentreEstimate.estimates.map((e, i) => (
          <UnlockedBudgetDraftEstimateItem
            key={i}
            costCentreEstimateItem={e}
          />
        ))}
      </Collapse>
    </>
  )
}
export default UnlockedBudgetDraftCostCentre;