import { CoreModules, CoreRouting } from "@build-buddy/core";
import { Box } from "@mui/material";
import { MyTeamsRoutesDefinitions } from "modules/project/my-teams/MyTeamsRoutes";
import BackButton from "./_components/BackButton";
import { useCompanyParams } from "./_hooks";
import { CompanyRoutesDefinitions } from "./CompanyRoutes";

const CompanyView = () => {
  const { companyId } = useCompanyParams();
  const routing = CoreRouting.useRouting();

  const handleReviewsClick = () => {
    routing.go(CompanyRoutesDefinitions.COMPANY_REVIEWS)
  }

  const handleBackClick = () => {
    routing.go(MyTeamsRoutesDefinitions.MY_TEAMS)
  }
  
  return (
    <Box>
      <BackButton
        onClick={handleBackClick}
        label="Back to My Teams"
      />
      <CoreModules.Company.CompanyProfile
        companyId={companyId}
        onReviewsClick={() => handleReviewsClick()}
      />
    </Box>
  )
}
export default CompanyView;
