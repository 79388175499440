import { CoreUtils, CoreState, CoreComponents } from "@build-buddy/core";
import { useEffect } from "react";
import { useFormik } from "formik";
import { Box, Button, useTheme } from "@mui/material"
import TaskViewTimelineTaskListItemEditValidation from "./TaskViewTimelineTaskListItemEditValidation";

type TaskViewTimelineTaskListItemEditProps = {
  projectId: string;
  task: CoreState.Task.Task
  onClose(): void;
}
const TaskViewTimelineTaskListItemEdit = (props: TaskViewTimelineTaskListItemEditProps) => {
  const { projectId, task, onClose } = props;
  const theme = useTheme();

  // mutations 
  const [changeTaskDateAction, changeTaskDateQuery] = CoreState.Task.changeDatesTask.useMutation();

  // vars
  const taskStatus = task?.status;
  const isManage = Boolean([CoreState.Task.TaskType.Manage].includes(task?.type));
  const isStartReadOnly = (isManage) && ([CoreState.Task.TaskStatus.InProgress, CoreState.Task.TaskStatus.AwaitingReview].includes(taskStatus));
  const isSuccess = Boolean(changeTaskDateQuery.isSuccess);
  const isError = Boolean(changeTaskDateQuery.isError);

  const formik = useFormik({
    initialValues: {
      startDate: new Date(task.startDate),
      endDate: new Date(task.endDate)
    },
    validationSchema: TaskViewTimelineTaskListItemEditValidation,
    onSubmit: (values) => {
      changeTaskDateAction({
        projectId: projectId,
        parentTaskId: task.parentId,
        taskId: task.id,
        startDate: values.startDate,
        endDate: values.endDate,
      })
    }
  })

  useEffect(() => {
    if (!isSuccess) return;
    onClose();
  }, [isSuccess])

  useEffect(() => {
    if (!isError) return;
    const errors = CoreUtils.Converter.toFormikErrors([
      CoreUtils.ServiceResponseHelper.getServiceError(changeTaskDateQuery)
    ]);
    formik.setErrors(errors);
  }, [isError])

  const handleFormClose = () => {
    formik.resetForm();
    onClose()
  }

  return (
    <Box>
      <CoreComponents.Loader show={changeTaskDateQuery.isLoading} />
      <CoreComponents.DatePickerRange
        formik={formik}
        StartProps={{
          id: "startDate",
          label: "Start Date",
          readOnly: isStartReadOnly,
          sx: {
            background: theme.palette.common.white
          }
        }}
        EndProps={{
          id: "endDate",
          label: "End Date",
          sx: {
            background: theme.palette.common.white
          }
        }}
      />
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          variant="contained"
          onClick={() => formik.handleSubmit()}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleFormClose()}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  )
}

export default TaskViewTimelineTaskListItemEdit 