import TaskViewQualityAssurance from "./TaskViewQualityAssurance";
import TaskViewQualityAssuranceChecklistAdd from "./task-view-quality-assurance-checklist/TaskViewQualityAssuranceChecklistAdd";
import TaskViewQualityAssuranceChecklistEdit from "./task-view-quality-assurance-checklist/TaskViewQualityAssuranceChecklistEdit";
import TaskViewQualityAssuranceDefectsAdd from "./task-view-quality-assurance-defects/task-view-quality-assurance-defect-add/TaskViewQualityAssuranceDefectsAdd";
import TaskViewQualityAssuranceDefectsView from "./task-view-quality-assurance-defects/task-view-quality-assurance-defects-view/TaskViewQualityAssuranceDefectsView";
import TaskViewQualityAssuranceDefectsViewNoteAdd from "./task-view-quality-assurance-defects/task-view-quality-assurance-defects-view/TaskViewQualityAssuranceDefectsViewNoteAdd";

const TaskViewQualityAssuranceRouteDefinitions = {
  // tabs
  TASK_VIEW_QUALITY_ASSURANCE: {
    label: "Quality Assurance",
    path: "/*/stage/:sid/task/:tid/quality-assurance",
    component: TaskViewQualityAssurance
  },
  // views
  TASK_VIEW_QUALITY_ASSURANCE_DEFECT_ADD: {
    path: "/*/stage/:sid/task/:tid/quality-assurance/defect/add",
    component: TaskViewQualityAssuranceDefectsAdd,
  },
  TASK_VIEW_QUALITY_ASSURANCE_DEFECT_ADD_WITH_QA: {
    path: "/*/stage/:sid/task/:tid/quality-assurance/defect/add/:qaId",
    component: TaskViewQualityAssuranceDefectsAdd
  },  
  TASK_VIEW_QUALITY_ASSURANCE_CHECKLIST_ADD: {
    path: "/*/stage/:sid/task/:tid/quality-assurance/checklist/stage/:tsid/add",
    component: TaskViewQualityAssuranceChecklistAdd
  },
  TASK_VIEW_QUALITY_ASSURANCE_CHECKLIST_EDIT: {
    path: "/*/stage/:sid/task/:tid/quality-assurance/checklist/:cid/edit",
    component: TaskViewQualityAssuranceChecklistEdit,
  },
  // subviews
  TASK_VIEW_QUALITY_ASSURANCE_DEFECT_VIEW: {    
    path: "/*/stage/:sid/task/:tid/quality-assurance/defect/:did/view",
    component: TaskViewQualityAssuranceDefectsView,
  },

  TASK_VIEW_QUALITY_ASSURANCE_DEFECT_VIEW_NOTE_ADD: {
    path: "/*/stage/:sid/task/:tid/quality-assurance/defect/:did/view/note/add",
    component: TaskViewQualityAssuranceDefectsViewNoteAdd,
  },
}


export default TaskViewQualityAssuranceRouteDefinitions;