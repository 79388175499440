import { Box, useTheme } from "@mui/material";
import { CoreComponents } from "@build-buddy/core";
import WizardViewStepContainer from "../wizard-view-step-container/WizardViewStepContainer";

interface WizardViewMilestoneProps {
  steps: Array<any> | undefined;
  currentMilestone: any;
  currentStepIndex: number;
  loading: boolean;
}
const WizardViewMilestone = (props: WizardViewMilestoneProps) => {
  const { steps, currentStepIndex, currentMilestone, loading } = props;
  const theme = useTheme();
  
  if (!steps) return null;

  return (
    <>
      <CoreComponents.Loader
        fullscreen={true}
        show={loading}
      />
      <Box
        sx={{
          mb: `${theme.variables.quoteFooterHeight}px`,
        }}
      >
        <WizardViewStepContainer
          selectedIndex={currentStepIndex}
          steps={steps}
          currentMilestone={currentMilestone}
        />
      </Box>
    </>
  )
}

export default WizardViewMilestone;