import { Box, SxProps, Theme } from "@mui/material";
interface TaskViewQuotesTemplateProps {
  sx?: SxProps<Theme>;
  children: any;
}
const TaskViewQuotesTemplate = (props: TaskViewQuotesTemplateProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        columnGap: 1,
        gridTemplateAreas: {
          xs: `
            'company actions'
          `,
          md: `'company rating quote actions'`
        },
        gridTemplateColumns: {
          xs: "auto 170px",          
          md: "auto 160px 170px 270px",
        },
        mx: -3,
        px: 3,
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  )
}
export default TaskViewQuotesTemplate;