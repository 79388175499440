import { CoreComponents } from "@build-buddy/core"
import { Box, Typography, Stack, Link, useTheme } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";

const PaymentReceiptAuth = () => {
  const theme = useTheme();
  return (
    <Box sx={{ textAlign: "center" }}>
      <Box 
        sx={{ 
          maxWidth: "600px", 
          margin: "0 auto" 
        }}
      >
        <CoreComponents.PayToLogo />
        <Typography variant="h2" sx={{ my: 2 }}> Authorise Payment Request </Typography>
        <Typography variant="body1">
          Please go to your banking App or portal to authorise PayTo agreement request.
        </Typography>
      </Box>
      <Stack 
        direction="row" 
        sx={{ 
          alignItems: "center",
          textAlign: "left",
          maxWidth: "900px",
          margin: "0 auto",
          mt: 3,
          mb: 2,
        }}
        gap={2}
      >
        <ErrorIcon sx={{ color: theme.palette.primary.main, fontSize: 32 }} />        
        <Box>
          <Typography variant="body1" >
            If you don't receive the PayTo agreement request from your bank within 12 hours, please login to your online banking through a web browser. 
            Your bank may not currently have PayTo requests integrated with their mobile banking app.
          </Typography>
          <Typography>
            For more information, click {" "}
            <Link href="https://assets.buildbuddy.au/payto" underline='none' target="_blank" >here.</Link>
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}
export default PaymentReceiptAuth