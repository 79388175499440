import { Rating, Stack, Typography } from "@mui/material";
import { Star as StarIcon } from "@mui/icons-material";
import { useMode } from "@build-buddy/core";

interface TaskViewQuotesListItemRatingProps {
  rating: number
}
const TaskViewQuotesListItemRating = (props: TaskViewQuotesListItemRatingProps) => {
  const mode = useMode();

  // TODO: This can prob be a common component as it gets used in Business Profile
  const label = Boolean(props.rating) ? props.rating.toFixed(2) : "No reviews"
  const rating = mode.md ?
    <Rating
      value={props.rating}
      readOnly
      sx={{
        mb: 0.5,
        mr: 0.5,
      }}
    />
    :
    <>
      <StarIcon
        color="primary"
        sx={{
          position: "relative",
          bottom: 1,
          mr: 1,
          fontSize: "14px"
        }}
      />
      <Typography variant="subtitle2"> {label} </Typography>
    </>
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        gridArea: {
          xs: "company",
          md: "rating"
        },
        position: "relative",
        top: {
          xs: "20px",
          md: 0
        }
      }}
    >
      {rating}
    </Stack>
  )
}
export default TaskViewQuotesListItemRating;
