import { Box, Skeleton } from "@mui/material";
import DashboardTasksListTemplate from "./DashboardTasksListTemplate";

const DashboardTasksListEmpty = () => {
  const arr = [...Array(5).keys()]

  return (
    <>
      {arr.map(x => (
        <DashboardTasksListTemplate key={x}>
          <Box sx={{ gridArea: "name" }}>
            <Skeleton variant="rectangular" sx={{ mr: 3 }} />
          </Box>
          <Box sx={{ gridArea: "status" }}>
            <Skeleton variant="rectangular" width={100} />
          </Box>
          <Box sx={{ gridArea: "start-date" }}>
            <Skeleton variant="rectangular" width={75} />
          </Box>
          <Box sx={{ gridArea: "end-date" }}>
            <Skeleton variant="rectangular" width={75} />
          </Box>
        </DashboardTasksListTemplate>
      ))}
    </>
  )
}
export default DashboardTasksListEmpty;