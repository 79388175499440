import { Formatter } from "@build-buddy/core/dist/utils";
import { FormControl } from "@mui/material";
import { Carousel } from "common/components";
import { useDispatch } from "common/state/store";
import { useFormikContext } from "formik";
import { FC, useEffect, useState } from "react";

interface FormCarouselCalcProps {
  data: any;
  fields: any;
}
const FormCarouselCalc: FC<FormCarouselCalcProps> = (props: FormCarouselCalcProps) => {
  const dispatch = useDispatch();
  const formik = useFormikContext<any>();
  const [items, setItems] = useState<any>();
  const [field, setField] = useState<any>();
  const [selected, setSelected] = useState<number>(0);
  const [adjustment, setAdjustment] = useState<number>(0);

  const getAdjustment = (value: number, current: number) => {
    const adjustment = (value - current);
    const formatted = Formatter.currency(Math.abs(adjustment));
    const operator = (adjustment >= 0) ? "+" : "-";
    return `${operator}${formatted}`;
  }

  useEffect(() => {
    const i = props.data.map((d: any) => ({
      image: d.image.path,
      title: d.header,
      description: getAdjustment(d.value, selected),
      value: d.value,
    }));

    if (!i || !i.length) return;
    setItems(i);
  }, [props.data, selected]);

  useEffect(() => {
    const f = props.fields.find((f: any) => f.inputRef === "value");

    if (!f) return;
    setField(f);
    setSelected(formik.values[f.inputId]);
  }, [props.fields])

  useEffect(() => {
    //dispatch(calculate(adjustment));
  }, [adjustment])

  if (!field || !items) return null;

  return (
    <FormControl fullWidth>
      <Carousel
        items={items}
        value={formik.values[field.inputId]}
        onChange={(val: any) => {
          setSelected(val);
          setAdjustment(Number(val) - Number(selected));
          formik.setFieldValue(field.inputId, val)
        }}
      />
    </FormControl>

  )
}
export default FormCarouselCalc;
