import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { DeleteOutline as DeleteOutlineIcon, Edit as EditIcon, RemoveRedEye as RemoveRedEyeIcon } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { BudgetExpenseRoutesDefinitions, useBudgetExpenseParams } from "../../../budget-expense/BudgetExpenseRoutes";

type BudgetTrackingExpenseActionsProps = {
  expense: CoreState.Financials.Expense;
  taskId?: string;
}

const BudgetTrackingExpenseActions = ({ expense, taskId }: BudgetTrackingExpenseActionsProps) => {
  const routing = CoreRouting.useRouting();
  const budgetTrackingExpenseParams = useBudgetExpenseParams();

  // states
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const handleViewClick = () => {
    const params = {
      tid: taskId,
      psid: expense.id
    }
    routing.go(BudgetExpenseRoutesDefinitions.VIEW_BUDGET_TRACKING_EXPENSE, { params, persistSearch: true });
  }

  const handleEditClick = () => {
    const params = {
      tid: taskId,
      psid: expense.id
    }
    routing.go(BudgetExpenseRoutesDefinitions.EDIT_BUDGET_TRACKING_EXPENSE, { params, persistSearch: true });
  }

  const handleDeleteClick = () => {
    setShowConfirmationModal(true);
  }

  // Mutation
  const [deleteExpenseAction, deleteExpenseQuery] = CoreState.Financials.deleteExpense.useMutation();

  // handle delete
  const deleteExpense = () => {
    deleteExpenseAction({
      projectId: budgetTrackingExpenseParams.projectId,
      expenseId: expense.id
    })
  }

  useEffect(() => {
    if (deleteExpenseQuery.isSuccess) setShowConfirmationModal(false);
  }, [deleteExpenseQuery.isSuccess])

  if (!taskId) return null;

  return (
    <>
      <CoreComponents.Loader show={false} />
      <CoreComponents.TableButton
        responsive={false}
        buttons={[
          {
            variant: "contained",
            label: 'View',
            icon: RemoveRedEyeIcon,
            onClick: handleViewClick
          },
          {
            variant: "contained",
            label: 'Edit',
            icon: EditIcon,
            onClick: handleEditClick
          },
          {
            variant: "contained",
            label: 'Delete',
            icon: DeleteOutlineIcon,
            onClick: handleDeleteClick
          },
        ]}
      />
      <CoreComponents.Confirmation
        open={showConfirmationModal}
        title="Delete Expense"
        text="Are you sure you want to delete?"
        onClose={() => setShowConfirmationModal(false)}
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={deleteExpense}
        cancelText="Cancel"
        confirmText="Delete"
        loading={deleteExpenseQuery.isLoading}
      />
    </>
  )
}

export default BudgetTrackingExpenseActions;