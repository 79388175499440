import { CoreComponents, CoreState } from "@build-buddy/core";
import { InsertDriveFile as FileIcon } from "@mui/icons-material";
import { Chip, DialogActions, DialogContent, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { useFormik } from "formik";
import { selectProject } from "modules/project/_selectors";
import { useEffect } from "react";
import { useTaskViewDocumentsParams } from "../_hooks";
import TaskViewDocumentsAddFormValidation from "./TaskViewDocumentsAddFormValidation";

type TaskViewDocumentsAddFormProps = {
  tag: CoreState.Tag.Tag;
  onSuccess(): void;
}
const TaskViewDocumentsAddForm = (props: TaskViewDocumentsAddFormProps) => {
  const { tag, onSuccess } = props;
  const theme = useTheme();
  const { projectId } = useTaskViewDocumentsParams()

  // Selectors
  const currentProject = useSelector((state) => selectProject(state, { projectId }))

  // mutations
  const [uploadDocListByProjectAction, uploadDocListByProjectQuery] = CoreState.File.uploadDocListByProject.useMutation();

  const { download } = CoreState.File.useDownloadQueue();

  const formik = useFormik({
    initialValues: {
      description: "",
      files: {
        toSave: [],
        toRemove: []
      },
    },
    validationSchema: TaskViewDocumentsAddFormValidation,
    onSubmit: (values) => {
      const docs = values.files.toSave.map((f: any) => ({
        file: f.file,
        name: `${currentProject?.projectNumber}_${tag.name}`,
        description: values.description,
        tags: [tag]
      }));
      uploadDocListByProjectAction({
        projectId,
        docs
      })
    }
  })

  // locals
  const isSaving = Boolean(uploadDocListByProjectQuery.isLoading && !uploadDocListByProjectQuery.isUninitialized);
  const isSuccess = Boolean(uploadDocListByProjectQuery.isSuccess && !uploadDocListByProjectQuery.isUninitialized);

  const handleDownload = (doc: CoreState.File.Doc) => {
    download(doc, () => { })
  }

  useEffect(() => {
    if (!isSuccess) return;
    onSuccess();
  }, [isSuccess])

  return (
    <>
      <DialogContent>
        <CoreComponents.Loader show={Boolean(isSaving)} />
        <CoreComponents.LabelValue
          icon={
            <CoreComponents.OutlinedIcon
              iconStyle={{
                fontSize: "20px",
                color: theme.palette.grey[400]
              }}
              sx={{
                height: 36,
                width: 36
              }}
              icon={FileIcon}
            />
          }
          label={tag.name}
          LabelProps={{
            InputLabelProps: {
              sx: {
                fontSize: "12px"
              }
            }
          }}
          value={
            <Chip
              size="small"
              label={tag.code}
              color="primary"
              sx={{
                position: "relative",
                left: -8,
                background: theme.palette.orange[100],
                color: theme.palette.orange[600],
                maxWidth: {
                  xs: "100px !important",
                  md: "150px !important"
                }
              }}
            />
          }
          sx={{ mb: 2 }}
        />
        <CoreComponents.FormikTextbox
          formik={formik}
          name="description"
          label="Description"
          multiline
          maxRows={5}
          fullWidth
        />
        <CoreComponents.FormikUploadFile
          formik={formik}
          maxFiles={1}
          name="files"
          label="Upload Documents"
          handleDownload={handleDownload}
        />
      </DialogContent>
      <DialogActions>
        <CoreComponents.Actions
          onSubmitClick={() => formik.handleSubmit()}
          submitText="Save"
        />
      </DialogActions>
    </>
  )
}
export default TaskViewDocumentsAddForm;