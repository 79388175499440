import { CoreComponents, CoreState } from "@build-buddy/core";
import { DialogActions, DialogContent } from "@mui/material";
import { useSelector } from "common/state/store";
import { useFormik } from "formik";
import { useProjectParams } from "modules/project/_hooks";
import { selectProject } from "modules/project/_selectors";
import { useEffect } from "react";
import DocumentLibraryAddFormValidation from "./DocumentLibraryAddFormValidation";

type DocumentLibraryAddFormProps = {
  tags: Array<any>;
  onSuccess(): void;
}
const DocumentLibraryAddForm = (props: DocumentLibraryAddFormProps) => {
  const { tags, onSuccess } = props;
  const { projectId } = useProjectParams()

  const { download } = CoreState.File.useDownloadQueue();

  // Selector
  const currentProject = useSelector((state) => selectProject(state, { projectId }))

  // mutations
  const [uploadDocListByProjectAction, uploadDocListByProjectQuery] = CoreState.File.uploadDocListByProject.useMutation();

  // locals
  const isSaving = Boolean(uploadDocListByProjectQuery.isLoading && !uploadDocListByProjectQuery.isUninitialized);
  const isSuccess = Boolean(uploadDocListByProjectQuery.isSuccess && !uploadDocListByProjectQuery.isUninitialized);

  const formik = useFormik({
    initialValues: {
      description: "",
      tag: {
        name: "",
        code: ""
      },
      files: {
        toSave: [],
        toRemove: []
      },
    },
    validationSchema: DocumentLibraryAddFormValidation,
    onSubmit: (values) => {
      const docs = values.files.toSave.map((f: any) => ({
        file: f.file,
        name: `${currentProject?.projectNumber}_${values.tag.name}`,
        description: values.description,
        tags: [values.tag]
      }));
      uploadDocListByProjectAction({
        projectId,
        docs
      })
    }
  })

  const handleDownload = (doc: CoreState.File.Doc) => {
    download(doc, () => { })
  }

  useEffect(() => {
    if (!isSuccess) return;
    onSuccess();
  }, [isSuccess])

  return (
    <>
      <CoreComponents.Loader show={Boolean(isSaving)} />
      <DialogContent>
        <CoreComponents.FormikErrorFocus formik={formik} />
        <CoreComponents.FormikAutocompleteSelect
          formik={formik}
          name="tag"
          label="File"
          options={tags}
          multiple={false}
        />
        <CoreComponents.FormikTextbox
          formik={formik}
          name="description"
          label="Description"
          multiline
          maxRows={5}
          fullWidth
        />
        <CoreComponents.FormikUploadFile
          formik={formik}
          maxFiles={1}
          name="files"
          label="Upload Documents"
          handleDownload={handleDownload}
        />
      </DialogContent>
      <DialogActions>
        <CoreComponents.Actions
          onSubmitClick={() => formik.handleSubmit()}
          submitText="Save"
        />
      </DialogActions>
    </>
  )
}
export default DocumentLibraryAddForm;