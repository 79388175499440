import { useDispatch, useSelector } from "common/state/store";
import { useEffect } from "react";

import { CoreComponents, CoreRouting, CoreState, CoreUtils } from "@build-buddy/core";

import { RouteDefinitions } from "common";
import { useTaskViewParams } from "../../_hooks";
import { useTaskViewQuotesParams } from "../_hooks";

const TaskViewQuotesAcceptSign = () => {
  const routing = CoreRouting.useRouting();
  const dispatch = useDispatch();

  // params
  const { taskId } = useTaskViewParams();
  const { projectId, quoteId } = useTaskViewQuotesParams();
  const quoteDocListParams = { projectId, quoteId };

  // queries
  const quoteDocListQuery = CoreState.File.getDocListByQuote.useQuery(quoteDocListParams);

  // mutations
  const [approveQuoteMutation, approveQuoteQuery] = CoreState.Quote.approveQuote.useMutation();
  const [createContractMutation, createContractQuery] = CoreState.Quote.createContract.useMutation();

  // selectors
  const contract = useSelector((s) => CoreState.File.selectDocListByQuoteContract(s, quoteDocListParams));
  const fileState = useSelector((s) => CoreState.File.selectFileState(s, contract?.id));

  const isLoading = createContractQuery.isLoading || quoteDocListQuery.isLoading || fileState?.isLoading;
  const isSaving = approveQuoteQuery.isLoading;
  const isSaved = approveQuoteQuery.isSuccess && !approveQuoteQuery.isUninitialized;
  const error = CoreUtils.TypeGuards.getFetchBaseQueryError(quoteDocListQuery) || CoreUtils.TypeGuards.getFetchBaseQueryError(createContractQuery);

  const handleSubmit = (value: string) => {
    approveQuoteMutation({
      projectId,
      quoteId,
      signature: value
    })
  }

  const handleClose = () => {
    const params = { tid: taskId };
    routing.go(RouteDefinitions.Task.TASK_VIEW_QUOTES, { params });
  }

  useEffect(() => {
    if(fileState?.isDownloaded) return;
    createContractMutation({ projectId, quoteId })
  }, [])

  useEffect(() => {
    if (!contract) return;
    dispatch(CoreState.File.downloadDocByType(contract));
  }, [contract])

  useEffect(() => {
    if (!isSaved) return;
    handleClose();
  }, [isSaved])

  return (
    <CoreComponents.DocumentSign
      open={true}
      doc={contract}
      url={fileState?.url}
      isLoading={isLoading}
      isSaving={isSaving}
      onClose={handleClose}
      onSubmit={handleSubmit}
      error={error}
    />
  )
}
export default TaskViewQuotesAcceptSign;