import TaskViewVariations from "./TaskViewVariations";

const TaskViewVariationsRouteDefinitions = {
  TASK_VIEW_VARIATIONS: {
    label: 'Variations',
    path: "/*/stage/:sid/task/:tid/variations",
    component: TaskViewVariations
  }
}

export default TaskViewVariationsRouteDefinitions;