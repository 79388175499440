import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useEffect } from "react";
import { useTaskViewParams } from "../../../_hooks";
import { selectTaskViewQuoteListAcceptedQuoteCompanyId } from "../../../_selectors";
import TaskViewQualityAssuranceRouteDefinitions from "../../TaskViewQualityAssuranceRouteDefinitions";
import { addTaskViewQualityAssuranceDefectsNoteItem } from "../_actions";
import { useTaskViewQualityAssuranceDefectsParams } from "../_hooks";

const TaskViewQualityAssuranceDefectsViewNoteAdd = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const { defectId } = useTaskViewQualityAssuranceDefectsParams();

  // mutations
  const [addTaskViewQualityAssuranceDefectsNoteItemMutation, addTaskViewQualityAssuranceDefectsNoteItemQuery] = addTaskViewQualityAssuranceDefectsNoteItem.useMutation()

  // selectors
  const companyId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteCompanyId(s, { projectId, stageId, taskId }));

  // locals
  const isSaving = addTaskViewQualityAssuranceDefectsNoteItemQuery.isLoading;
  const isSuccess = addTaskViewQualityAssuranceDefectsNoteItemQuery.isSuccess;

  // event handlers
  const handleAdd = (values: {
    noteThread: CoreState.Common.Subset<CoreState.NoteThread.NoteThread>,
    docs: Array<CoreState.File.Doc>
  }) => {
    if(!companyId) return;
    
    addTaskViewQualityAssuranceDefectsNoteItemMutation({
      projectId,
      stageId,
      taskId,
      defectId,
      noteThread: values.noteThread,
      docs: values.docs,
      toUserId: companyId,
    })
  }

  const handleClose = () => {
    routing.go(TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_VIEW);
  }

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess]);

  return (
    <CoreComponents.AddNoteThread 
      isOpen={true}
      isSaving={isSaving}
      isSuccess={isSuccess}
      onAdd={handleAdd}
      onClose={handleClose}
    />
  )
}
export default TaskViewQualityAssuranceDefectsViewNoteAdd;