import { useProjectParams } from "modules/project/_hooks";
import { useRouteMatch } from "react-router";
import TaskViewRouteDefinitions from "../TaskViewRouteDefinitions";

const useTaskViewParams = () => {
  const { projectId } = useProjectParams();
  const match = useRouteMatch<any>(TaskViewRouteDefinitions.TASK_VIEW);
  const temp = useRouteMatch<any>();

  return {
    projectId,
    //TODO: Remove stage Id from task urls
    stageId: "x",
    taskId: match?.params.tid,

    // TODO: refactor
    specId: temp?.params.spid,
    specType: temp?.params.type,    
    defectId: temp?.params.defectId,
    paymentScheduleId: temp?.params.psid
  };
}
export default useTaskViewParams