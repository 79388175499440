import { isValid } from "date-fns";
import { useProjectParams } from "modules/project/_hooks";
import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import CalendarRouteDefinitions from "../CalendarRouteDefinitions";

const useCalendarParams = () => {
  const match = useRouteMatch<any>(CalendarRouteDefinitions.CALENDAR_DRAWER.path);

  // params
  const { projectId } = useProjectParams();
  const params = useParams<any>();

  // locals
  const [year, setYear] = useState<Number>(new Date().getFullYear())
  const [month, setMonth] = useState<Number>(new Date().getMonth() + 1)
  const [day, setDay] = useState<Number>()
  const [activeDate, setActiveDate] = useState(new Date());
  const isValidRoute = Boolean(Number(params.year) && Number(params.month));

  // side effects
  useEffect(() => {
    if (params.year && !isNaN(Number(params.year))) setYear(Number(params.year));
  }, [params.year])

  useEffect(() => {
    if (params.month && !isNaN(Number(params.month))) setMonth(Number(params.month));
  }, [params.month])

  useEffect(() => {
    // try to use the day param first
    if (params.day && !isNaN(Number(params.day))) setDay(Number(params.day));
    // if there no day param, try match the route and use that day param
    else if (match?.params.day && !isNaN(Number(match?.params.day))) setDay(Number(match.params.day));
    // reset the day to undefined
    else setDay(undefined);
  }, [params.day, match?.params.day]);

  useEffect(() => {
    // if there is no day, we set today as the date. Thats cos we dont know what the user has selected and we need something
    const str = `${year}/${month}/${day || new Date().getDate()}`;

    const date = new Date(str);
    
    setActiveDate(isValid(date) ? date : new Date());
  }, [day, month, year])

  return {
    projectId,
    day,
    month,
    year,
    activeDate,
    isValidRoute
  };
}
export default useCalendarParams;