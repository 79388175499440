import { Box, Chip, useTheme } from "@mui/material";
import { ProjectTaskStatus } from "../../_models";

interface ProjectTaskStatusChipProps {
  status: ProjectTaskStatus;
}
const ProjectTaskStatusChip = (props: ProjectTaskStatusChipProps) => {
  const { status } = props;
  const theme = useTheme();

  // locals
  const self = {
    bg: theme.palette.primary.main,
    fg: theme.palette.common.white
  };

  const other = {
    bg: theme.palette.common.white,
    fg: theme.palette.primary.main
  }

  const system = {
    bg: theme.palette.grey[600],
    fg: theme.palette.common.white
  }

  const idle = {
    bg: theme.palette.common.white,
    fg: theme.palette.grey[600]
  }

  const completed = {
    bg: theme.palette.success.main,
    fg: theme.palette.common.white
  }

  const statusKvp: any = {
    [ProjectTaskStatus.NA]: {
      label: "Not Applicable",
      color: system
    },
    [ProjectTaskStatus.NotRequired]: {
      label: "Not Required",
      color: system
    },
    [ProjectTaskStatus.AwaitingConstruction]: {
      label: "Awaiting Construction",
      color: system
    },
    [ProjectTaskStatus.AwaitingNextTask]: {
      label: "Stand By",
      color: idle
    },
    [ProjectTaskStatus.UpcomingTask]: {
      label: "Upcoming",
      color: system
    },
    [ProjectTaskStatus.New]: {
      label: "To Do",
      color: self
    },
    [ProjectTaskStatus.QuotesRequested]: {
      label: "Quotes Requested",
      color: other
    },
    [ProjectTaskStatus.QuotesReceived]: {
      label: "Quotes Received",
      color: self
    },
    [ProjectTaskStatus.QuotesAccepted]: {
      label: "Quote Accepted - Pending",
      color: other
    },
    [ProjectTaskStatus.AwaitingStart]: {
      label: "Awaiting Start",
      color: other
    },
    [ProjectTaskStatus.WorkInProgress]: {
      label: "Work In Progress",
      color: other
    },
    [ProjectTaskStatus.InReview]: {
      label: "Review Requested",
      color: self
    },
    [ProjectTaskStatus.InQA]: {
      label: "In Review",
      color: self
    },
    [ProjectTaskStatus.Finalise]: {
      label: "Finalise Task",
      color: self
    },
    [ProjectTaskStatus.Completed]: {
      label: "Completed",
      color: completed
    },
  }

  const chip = statusKvp[status];

  return (
    <Box>
      <Chip
        label={chip.label}
        size="small"
        sx={{
          textTransform: "uppercase",
          fontWeight: "bold",
          background: chip.color.bg,
          color: chip.color.fg,
          border: `solid 1px ${chip.color.fg}`,
          borderColor: chip.color.fg,
          "&.MuiChip-clickable:hover": {
            color: chip.color.fg,
            background: chip.color.bg
          }
        }}
      />
    </Box>
  )
}
export default ProjectTaskStatusChip;