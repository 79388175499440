import { CoreModules, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import { selectProjectDisplayName } from "modules/project/_selectors";
import DashboardProjectBreakdown from "./DashboardProjectBreakdownDesktop";

const DashboardProject = () => {
  const { projectId } = useProjectParams();

  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);
  const userHasAvatar = useSelector(CoreState.User.selectCurrentUserHasAvatar);

  const projectDisplayName = useSelector((s) => selectProjectDisplayName(s, { projectId }));

  // queries
  const projectQuery = CoreState.Project.getProject.useQuery({ projectId });
  const getAvatarByUserQuery = CoreState.File.getAvatarByUser.useQuery({ userId: currentUserId }, { skip: (!currentUserId || !userHasAvatar) });

  if (!projectDisplayName) return null;

  return (
    <CoreModules.Dashboard.DashboardProjectCard
      label={projectDisplayName}
      showActions={false}
      uploadAvatar={false}
      avatarSrc={getAvatarByUserQuery.data}
    >
      <DashboardProjectBreakdown />
    </CoreModules.Dashboard.DashboardProjectCard>
  )
}
export default DashboardProject;