import { CoreComponents, CoreState, CoreUtils, useMode } from "@build-buddy/core";
import { CalendarToday as CalendarIcon } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { ProjectTaskStatus } from "modules/project/_components";
import { ProjectTask } from "modules/project/_models";
import DashboardTasksListTemplate from "./DashboardTasksListTemplate";
import ProjectTaskActions from "modules/project/_components/project-task-actions/ProjectTaskActions";

type DashboardTasksListItemProps = {
  projectTask: ProjectTask;
  onClick(projectTask: CoreState.Task.Task): void;
}
const DashboardTasksListItem = (props: DashboardTasksListItemProps) => {
  const { projectTask, onClick } = props;
  const theme = useTheme();
  const mode = useMode();

  if(!projectTask) return null; 

  return (
    <DashboardTasksListTemplate
      hasHoverStyles={true}
      hasLastTypeStyles={true}
      onClick={(e) => {
        if (e.currentTarget.contains(e.target)) {
          onClick(projectTask.task)
        }
      }}
    >
      <Box sx={{ gridArea: "name", minWidth: 0 }}>
        <Box>
          <CoreComponents.Truncate
            text={projectTask.task.name}
            lines={mode.md ? 1 : 2}
            sx={{
              mr: 3,
              fontWeight: "bold"
            }}
          />
        </Box>
        <ProjectTaskActions projectTask={projectTask} />
      </Box>
      <Box
        sx={{
          gridArea: "status",
          position: "relative",
          right: {
            xs: "4px",
            md: "0px"
          },
          mr: 2
        }}
      >
        <ProjectTaskStatus
          projectTask={projectTask}
        />
      </Box>
      <Typography
        variant="body1"
        sx={{
          gridArea: "start-date",
          fontSize: {
            xs: "13px",
            md: "inherit"
          },
          color: {
            xs: theme.palette.grey[500],
            md: "inherit"
          }
        }}
      >
        <CalendarIcon
          sx={{
            display: {
              xs: "inline-block",
              md: "none"
            },
            fontSize: "16px",
            mr: 0.75,
            position: "relative",
            top: 2.5
          }}
        />
        {CoreUtils.Formatter.date(projectTask.task.startDate, "dd MMM yy")}

      </Typography>
        <Typography
            variant="body1"
            component="span"
            gridArea="separator"
            sx={{
                display: {
                    xs: "inline-block",
                    md: "none"
                }
            }}
        >
            -
        </Typography>
      <Typography
        variant="body1"
        sx={{
          gridArea: "end-date",
          fontSize: {
            xs: "13px",
            md: "inherit"
          },
          color: {
            xs: theme.palette.grey[500],
            md: "inherit"
          },
        }}
      >
        {CoreUtils.Formatter.date(projectTask.task.endDate, "dd MMM yy")}
      </Typography>
    </DashboardTasksListTemplate>
  )
}
export default DashboardTasksListItem;