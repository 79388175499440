import { Card, CardContent, Typography, Grid } from '@mui/material';

const styles = {
  card: {
    maxWidth: 345,
    borderRadius: '16px',
    background: "linear-gradient(93deg, #FF6F00 0%, #FFB800 100%)",
    color: '#fff',
    padding: '20px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '180px',
  },
  number: {
    fontSize: '16px',
    letterSpacing: '2px',
  },
  text: {
    fontSize: '15px',
  },
  expiry: {
    alignSelf: 'flex-end',
  }
};

type CreditCardUIType = {
  cardHolder: string;
  cardNumber: string;
  expiry: string;
}

const CreditCardUI = (props: CreditCardUIType) => (
  <Card sx={styles.card}>
    <CardContent sx={styles.content}>
      <Typography variant="h6" sx={styles.text}>
        {props.cardHolder}
      </Typography>
      <Typography sx={styles.number}>
        {props.cardNumber}
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography sx={styles.text}>
            Expires {props.expiry}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={styles.text} style={styles.expiry}>
            CVV {'***'}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default CreditCardUI;