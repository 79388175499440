import { useMode } from "@build-buddy/core";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

interface HeaderProps {
  avatar?: string;
  onMenuClick(): void;
  onAvatarClick(e: any): void;
}
const Header = (props: HeaderProps) => {
  const mode = useMode();
  return mode.md ?
    <DesktopHeader avatar={props.avatar} onAvatarClick={props.onAvatarClick} /> :
    <MobileHeader />;
};

export default Header;
