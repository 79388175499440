import { CoreModules } from "@build-buddy/core";
import { TaskViewTabs } from "../_common";
import TaskViewOutlineStartEndDate from "../../task-view-outline/TaskViewOutlineStartEndDate";

const TaskViewAdminContent = () => {
  return (
    <>
      <CoreModules.Task.TaskView.TaskViewOutline>
        <TaskViewOutlineStartEndDate />
      </CoreModules.Task.TaskView.TaskViewOutline>
      <TaskViewTabs />
    </>
  )
}
export default TaskViewAdminContent;