import { Route } from "react-router";
import TaskViewDrawerRouteDefinitions from "./TaskViewDrawerRouteDefinitions";

const TaskViewDrawerView = () => {
  return (
    <>
      <Route
        component={TaskViewDrawerRouteDefinitions.TASK_VIEW_TIMELINE.component}
        path={TaskViewDrawerRouteDefinitions.TASK_VIEW_TIMELINE.path}
      />
    </>
  )
}
export default TaskViewDrawerView;
