import { CoreUtils } from "@build-buddy/core";
import LegendItem from "../legend/LegendItem";

export interface CostsPieLegendProps {
  data: Array<{
    key: string;
    value: number;
    color: string;
  }>;
  hideLabels?: boolean;
}
const CostsPieLegend = (props: CostsPieLegendProps) => {
  const { data, hideLabels } = props;
  return (
    <>
      {data.map((d, i) => (
        <LegendItem
          key={i}
          label={hideLabels ? "" : d.key}
          color={d.color}
          value={CoreUtils.Formatter.currency(d.value)}
          sx={{
            mb: {
              xs: 0.5,
              sm: 1,
              md: 1.25,
            },
          }}
        />
      ))}
    </>
  )
}
export default CostsPieLegend;