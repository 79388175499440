import { CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useEffect } from "react";
import TaskViewReview from "./TaskViewReview";
import TaskViewReviewSkeleton from "./TaskViewReviewSkeleton";
import useTaskViewReviewEditParams from "./_hooks/useTaskViewReviewEditParams";

const TaskViewReviewEdit = () => {
  //hooks
  const routing = CoreRouting.useRouting();
  const params = useTaskViewReviewEditParams();

  const { reviewId } = params;

  //selectors
  const userId = useSelector(CoreState.User.selectCurrentUserId);

  //queries
  const getRatingsByCompanyQuery = CoreState.Reviews.getRatingByCompany.useQuery({
    userId,
    organisationRatingPublicId: reviewId,
  });

  //mutations 
  const [updateRatingByCompanyAction, updateRatingByCompanyQuery] = CoreState.Reviews.updateRatingByCompany.useMutation();

  const handleClose = () => {
    routing.back();
  }

  useEffect(() => {
    if (!updateRatingByCompanyQuery.isSuccess) return;
    routing.back();
  }, [updateRatingByCompanyQuery.isSuccess]);

  if (Boolean(getRatingsByCompanyQuery.isLoading)) return (<TaskViewReviewSkeleton />);

  return (
    <CoreModules.Task.TaskView.TaskViewSubView
      open={true}
      onClose={() => handleClose()}
    >
      <TaskViewReview
        initialValues={getRatingsByCompanyQuery.data}
        onSubmit={(values) => updateRatingByCompanyAction({ ...values, companyRatingPublicId: reviewId })}
        isEdit={true}
      />
    </CoreModules.Task.TaskView.TaskViewSubView>
  )
}
export default TaskViewReviewEdit;