import { CoreState } from "@build-buddy/core";
import { ProjectTaskFilterStatus, ProjectTaskStatus } from "modules/project/_models";

const toModel = (
  task: CoreState.Task.Task | undefined,
  status: ProjectTaskStatus | undefined
) => {
  const rtn: Array<ProjectTaskFilterStatus> = [];

  if (!task || !status) return rtn;

  // awaiting
  const awaitingKvp = [
    ProjectTaskStatus.AwaitingNextTask,
    ProjectTaskStatus.AwaitingConstruction
  ]
  if (awaitingKvp.includes(status)) rtn.push(ProjectTaskFilterStatus.Awaiting);

  // completed
  const completedKvp = [
    ProjectTaskStatus.Completed
  ]
  if (completedKvp.includes(status)) rtn.push(ProjectTaskFilterStatus.Completed);

  // inProgress
  const inProgressKvp = [
    ProjectTaskStatus.AwaitingStart,
    ProjectTaskStatus.QuotesAccepted,
    ProjectTaskStatus.QuotesRequested,
    ProjectTaskStatus.QuotesAccepted,
    ProjectTaskStatus.WorkInProgress,
  ];
  if (inProgressKvp.includes(status)) rtn.push(ProjectTaskFilterStatus.InProgress);

  // overdue
  if (task.isOverdue) rtn.push(ProjectTaskFilterStatus.Overdue)

  // todo
  const todoKvp = [
    ProjectTaskStatus.New,
    ProjectTaskStatus.QuotesReceived,
    ProjectTaskStatus.InReview,
    ProjectTaskStatus.InQA,
    ProjectTaskStatus.Finalise,
  ]
  if (todoKvp.includes(status)) rtn.push(ProjectTaskFilterStatus.Todo);

  // upcoming
  const upcomingKvp = [
    ProjectTaskStatus.UpcomingTask
  ];
  if (upcomingKvp.includes(status)) rtn.push(ProjectTaskFilterStatus.Upcoming);

  // payment requested
  if(task.hasPaymentRequested) rtn.push(ProjectTaskFilterStatus.PaymentRequested);

  return rtn;
}

const transformProjectTaskStatus = { toModel };

export default transformProjectTaskStatus;

