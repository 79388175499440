import { Box, Stack, Typography } from "@mui/material";
import TaskViewVariationsList from "./TaskViewVariationsList";

const TaskViewVariations = () => {
  return (
    <>
      <Stack direction={"column"} sx={{ display: "flex", gap: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Stack direction="column" >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: "18px",
                  fontWeight: "600"
                }}>
                Variations
              </Typography>
            </Box>
          </Stack>
          <TaskViewVariationsList />
        </Box>
      </Stack>
    </>
  );
}

export default TaskViewVariations;