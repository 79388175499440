
import { CoreComponents, CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTaskStatus } from "modules/project/_selectors";
import { Route } from "react-router";
import { useTaskViewParams } from "../../_hooks";
import TaskViewQualityAssuranceRouteDefinitions from "../TaskViewQualityAssuranceRouteDefinitions";
import { TaskViewQualityAssuranceDefectDotProgress } from "../_common";
import { TaskViewQualityAssuranceDefectsActions } from "./task-view-quality-assurance-defects-actions";

const TaskViewQualityAssuranceDefects = () => {
  const routing = CoreRouting.useRouting();

  const { projectId, stageId, taskId } = useTaskViewParams();
  const getDefectListByTaskParams = { projectId, stageId, taskId };

  // queries
  const getDefectListByTaskQuery = CoreState.Defect.getDefectListByTask.useQuery(getDefectListByTaskParams);

  // locals
  const isLoading = getDefectListByTaskQuery.isLoading;

  // selectors
  const defects = useSelector((s) => CoreState.Defect.selectDefectListByTask(s, getDefectListByTaskParams));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, getDefectListByTaskParams));

  const handleAdd = () => {
    routing.go(TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_ADD);
  }

  const handleClick = (defect: CoreState.Defect.Defect) => {
    const params = { did: defect.id };
    routing.go(TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_VIEW, { params });
  }

  // Hide add defect button when completed.
  const hideAddDefectButton = ProjectTaskStatus.Completed === projectTaskStatus;

  return (
    <>
      <CoreComponents.CollapseHeader title="Defects" open={true}>
        <CoreComponents.AddButton
          hidden={hideAddDefectButton}
          label="Raise a defect"
          fullWidth
          onClick={() => handleAdd()}
          sx={{ mx: -1, my: 1 }}
        />
        <CoreModules.Task.TaskView.TaskViewDefects
          defects={defects}
          isLoading={isLoading}
          dotProgress={TaskViewQualityAssuranceDefectDotProgress}
          onDefectClick={handleClick}
          actions={TaskViewQualityAssuranceDefectsActions}
        />
      </CoreComponents.CollapseHeader>
      <Route
        exact
        component={TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_VIEW.component}
        path={TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_VIEW.path}
      />
    </>
  )
}
export default TaskViewQualityAssuranceDefects;

