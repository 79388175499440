import { FC } from "react";
import { Box } from "@mui/material";
import DimensionSvg from "./DimensionsSvg";
import DimensionControls from "./DimensionControls";

interface DimensionProps {
  design: any;
  dimensions: any;
  onUpdate(dimensions: any): void;
}
const Dimension: FC<DimensionProps> = (props: DimensionProps) => {
  const handleUpdate = (dimensions: any) => props.onUpdate(dimensions);

  return (
    <Box sx={{ 
      display: "flex",
      flexDirection: {
        xs: "column",
        md: "row"
      }
    }}>
      <Box sx={{ flex: 1 }}>
        <DimensionSvg 
          design={props.design}
          dimensions={props.dimensions}
        />
      </Box>
      <Box sx={{ 
        flex: 1, 
        mt: { xs: 2, md: 0 },
        pl: { xs: 0, md: 2 } 
      }}>
        <DimensionControls
          dimensions={props.dimensions}
          onUpdate={handleUpdate}
        />
      </Box>
    </Box>
  );
};

export default Dimension;