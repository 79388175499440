import { Actions, CoreComponents, CoreRouting, CoreState, CoreUtils, Loader } from "@build-buddy/core";
import { Box, Card, CardContent, Fade, FormControl, InputLabel, Typography, useTheme } from "@mui/material";
import { OptionList } from "common/components";
import { useSelector } from "common/state/store";
import { useFormik } from "formik";
import { useEffect } from "react";
import { WizardRoutesDefinitions } from "../WizardRoutes";
import WizardHeader from "../wizard-view/WizardHeader";
import WizardViewStepContainerStepNumber from "../wizard-view/wizard-view-step-container/WizardViewStepContainerStepNumber";
import wizardCreateValidation from "./wizardCreateValidation";

const WizardCreate = () => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  // params
  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);

  // mutations
  const [createWizardAction, createWizardQuery] = CoreState.Wizard.createWizard.useMutation();

  // locals
  const formik = useFormik({
    initialValues: {
      name: "",
      projectType: "NewHouseQuote",
    },
    validationSchema: wizardCreateValidation,
    onSubmit: (values) => {
      if (!currentUserId) return;
      const wizard: CoreState.Common.Subset<CoreState.Wizard.Wizard> = {
        name: formik.values.name,
        startDate: CoreUtils.Formatter.dateTimeOffset(new Date()) || "",
        type: formik.values.projectType,
        category: "HouseBasic",
        region: "AUS-NSW",
      }
      createWizardAction({
        userId: currentUserId,
        wizard
      })
    },
  });
  const isSaving = Boolean(!createWizardQuery.isUninitialized && createWizardQuery.isLoading);
  const isSuccess = Boolean(!createWizardQuery.isUninitialized && createWizardQuery.isSuccess);

  useEffect(() => {
    if (!isSuccess || !createWizardQuery.data) return;
    const params = { wid: createWizardQuery.data.id };
    routing.go(WizardRoutesDefinitions.WIZARD_VIEW, { params })
  }, [isSuccess])

  return (
    <>
      <WizardHeader steps={["Initialise", "Details", "Design", "Project Creation"]} currentStep={0} />
      <Fade in={true}>
        <Card
          sx={{
            boxShadow: "0px 4px 16px rgba(1, 11, 19, 0.04)",
            borderRadius: "12px",
          }}
        >
          <CardContent
            sx={{
              px: { xs: 3, md: 5 },
              py: 3,
            }}
          >
            <Loader show={isSaving} />
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
              }}
            >
              <Box
                sx={{
                  mb: { xs: 2, md: 0 },
                }}
              >
                <WizardViewStepContainerStepNumber label="1" />
              </Box>
              <Box>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  General info
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                  Start your Build Buddy journey of building a New House or Granny Flat by entering a Project Name that you can return to at any time:
                </Typography>

                <CoreComponents.Textbox
                  fullWidth
                  name="name"
                  label="Project Name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.errors.name}
                />
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    padding: 1,
                    borderRadius: theme.spacing(1),
                    mb: 1.5,
                    background: 'rgb(255, 204, 128, 0.2)'
                  }}
                >
                  <InputLabel variant="standard" sx={{ fontWeight: "bold", fontSize: "14px", mb: 0.5 }} shrink>
                    Project Type
                  </InputLabel>
                  <FormControl
                    fullWidth
                  >
                    <OptionList
                      items={[
                        { title: "Build a New House or Granny Flat", value: "NewHouseQuote" },
                        { title: "Renovations (COMING SOON)", value: "Renovation", disabled: true },
                        { title: "Extensions (COMING SOON)", value: "Extension", disabled: true },
                      ]}
                      value={formik.values.projectType}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Box>
                <Actions submitText="Next" onSubmitClick={() => formik.handleSubmit()} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Fade>
    </>
  )
}
export default WizardCreate;