import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import QualityAssuranceStatus from "@build-buddy/core/dist/state/quality-assurance/models/QualityAssuranceStatus";
import { MoreVert as MoreVertIcon, Undo as UndoIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTask, selectProjectTaskStatus } from "modules/project/_selectors";
import { TaskViewRouteDefinitions, useTaskViewParams } from "modules/project/task/task-view";
import { selectTaskViewQuoteListAcceptedQuote } from "../../../_selectors";
import TaskViewQualityAssuranceChecklistActionsDeleteButton from "./TaskViewQualityAssuranceChecklistActionsDeleteButton";
import TaskViewQualityAssuranceChecklistActionsEditButton from "./TaskViewQualityAssuranceChecklistActionsEditButton";
import TaskViewQualityAssuranceChecklistActionsRaiseADefectNAButtons from "./TaskViewQualityAssuranceChecklistActionsRaiseADefectNAButtons";

interface TaskViewQualityAssuranceChecklistActionsProps {
  qualityAssuranceChecklist: CoreState.QualityAssurance.QualityAssurance;
  isEditable?: boolean;
}

const TaskViewQualityAssuranceChecklistActions = (props: TaskViewQualityAssuranceChecklistActionsProps) => {
  const { isEditable, qualityAssuranceChecklist } = props;

  //vars
  const isDeleted = Boolean(props.qualityAssuranceChecklist?.isDisabled);
  const isCustom = Boolean(!props.qualityAssuranceChecklist?.isStandard);
  const isComplete = Boolean(props.qualityAssuranceChecklist.status === QualityAssuranceStatus.Completed);

  //hooks
  const routing = CoreRouting.useRouting();
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };
  const getDefectListByTaskParams = { projectId, stageId, taskId };

  //selectors
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, taskParams));
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, quoteListParams));
  const isTaskWorkInProgress = useSelector((s) => CoreState.Task.selectTaskListByParentIsWorkInProgress(s, taskParams));
  const defects = useSelector((s) => CoreState.Defect.selectDefectListByTask(s, getDefectListByTaskParams));

  // mutations
  const [updateQualityAssuranceItemAction, updateQualityAssuranceItemQuery] = CoreState.QualityAssurance.updateQualityAssuranceItem.useMutation();
  const [deleteQualityAssuranceItemAction, deleteQualityAssuranceItemQuery] = CoreState.QualityAssurance.deleteQualityAssuranceItem.useMutation();

  //vars
  const showRaiseDefectNaBtn = Boolean(isTaskWorkInProgress && acceptedQuote);
  const showActions = (projectTask?.task.type === CoreState.Task.TaskType.Request || (projectTaskStatus === ProjectTaskStatus.New && !projectTask?.task.parentIsDIY && !acceptedQuote)
    || (projectTask?.task.type === CoreState.Task.TaskType.QualityCheck)) || showRaiseDefectNaBtn;
  const isSaving = updateQualityAssuranceItemQuery.isLoading || deleteQualityAssuranceItemQuery.isLoading;
  const hasAssociatedDefect = defects?.filter((defect) => defect.associatedId === qualityAssuranceChecklist.id);
  const showNotApplicable = Boolean(!(hasAssociatedDefect && hasAssociatedDefect.length > 0));
  const showUndoButton = Boolean(projectTaskStatus && isDeleted && [ProjectTaskStatus.New].includes(projectTaskStatus));

  // event handlers
  const updateDisabled = () => {
    updateQualityAssuranceItemAction({
      projectId,
      stageId,
      taskId,
      qualityAssuranceId: props.qualityAssuranceChecklist.id,
      qualityAssuranceItem: {
        isDisabled: !props.qualityAssuranceChecklist.isDisabled
      }
    })

    return;
  }

  const deleteItem = () => {
    if (!props.qualityAssuranceChecklist.id) return;

    deleteQualityAssuranceItemAction({
      projectId,
      stageId,
      taskId,
      qualityAssuranceId: props.qualityAssuranceChecklist.id,
    })
  }

  const handleRaiseDefect = () => {
    const params = { qaId: qualityAssuranceChecklist?.id }
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_ADD_WITH_QA, { params })
  }

  const handleDelete = () => {
    isCustom ? deleteItem() : updateDisabled();
  }

  if (!showActions) return null;

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      {!isDeleted && !isComplete &&
        <CoreComponents.MoreMenu
          icon={MoreVertIcon}
          IconProps={{
            edge: "end",
            sx: {
              ml: 1,
              position: "relative",
              bottom: -1
            }
          }}
        >
          <TaskViewQualityAssuranceChecklistActionsEditButton
            isEditable={Boolean(isEditable)}
            isCustom={isCustom}
            showRaiseDefectNaBtn={showRaiseDefectNaBtn}
            qualityAssuranceItemId={qualityAssuranceChecklist.id}
          />
          <TaskViewQualityAssuranceChecklistActionsDeleteButton
            showRaiseDefectNaBtn={showRaiseDefectNaBtn}
            handleDelete={handleDelete}
          />
          <TaskViewQualityAssuranceChecklistActionsRaiseADefectNAButtons
            isEditable={Boolean(isEditable)}
            showRaiseDefectNaBtn={showRaiseDefectNaBtn}
            handleRaiseDefect={handleRaiseDefect}
            updateDisabled={updateDisabled}
            showNotApplicable={showNotApplicable}
          />
        </CoreComponents.MoreMenu>}
      {showUndoButton &&
        <IconButton
          edge="end"
          onClick={updateDisabled}
        >
          <UndoIcon />
        </IconButton>
      }
    </>
  )
}
export default TaskViewQualityAssuranceChecklistActions;