import { Chip, Stack, SxProps, Theme, useTheme } from "@mui/material";
import { CheckCircle as DoneIcon, Error as ExclamationIcon, RemoveCircle as RemoveIcon } from "@mui/icons-material";
import { CoreState } from "@build-buddy/core";

type ProjectTaskStatusPredecessorsStatusProps = {
  predecessor?: CoreState.Task.Predecessor;
  sx?: SxProps<Theme>;
}
const ProjectTaskStatusPredecessorsStatus = (props: ProjectTaskStatusPredecessorsStatusProps) => {
  const theme = useTheme();

  if (!props.predecessor) return null;

  const upcoming = {
    bg: theme.palette.primary.main,
    fg: theme.palette.common.white
  }

  const completed = {
    bg: theme.palette.success.main,
    fg: theme.palette.common.white
  }

  const notRequired = {
    bg: theme.palette.grey[600],
    fg: theme.palette.common.white
  }

  const statusKvp: any = {
    [CoreState.Task.PredecessorStatus.Complete]: {
      label: "Completed",
      color: completed,
      icon: <DoneIcon sx={{ color: 'white!important' }} />,
    },
    [CoreState.Task.PredecessorStatus.Pending]: {
      label: "Outstanding",
      color: upcoming,
      icon: <ExclamationIcon sx={{ color: 'white!important' }} />,
    },
    [CoreState.Task.PredecessorStatus.InProgress]: {
      label: "In Progress",
      color: upcoming,
      icon: <ExclamationIcon sx={{ color: 'white!important' }} />,
    },
    [CoreState.Task.PredecessorStatus.NotRequired]: {
      label: "Not Required",
      color: notRequired,
      icon: <RemoveIcon sx={{ color: 'white!important' }} />
    },
  }

  const chip = statusKvp[props.predecessor.status];

  if (!chip) return null;

  return (
    <Stack direction={'row'} alignItems={'center'} sx={{ display: 'flex', gap: 0.5 }}>
      <Chip
        icon={chip.icon}
        label={chip.label}
        size="small"
        sx={{
          textTransform: "uppercase",
          fontWeight: "bold",
          background: chip.color.bg,
          color: chip.color.fg,
          border: `solid 1px ${chip.color.fg}`,
          borderColor: chip.color.fg,
          position: "relative",
          ...props.sx
        }}
      />
    </Stack>
  )
}
export default ProjectTaskStatusPredecessorsStatus;
