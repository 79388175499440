import { CoreState } from "@build-buddy/core";
import transformProjectTaskFilterStatus from "./transformProjectTaskFilterStatus";
import transformProjectTaskStatus from "./transformProjectTaskStatus/transformProjectTaskStatus";
import transformProjectTaskSubStatus from "./transformProjectTaskSubStatus";

const toModel = (
  task: CoreState.Task.Task | undefined,
) => {
  if (!task) return;
  const status = transformProjectTaskStatus.toModel(task);
  const subStatuses = transformProjectTaskSubStatus.toModel(task);
  const filterStatuses = transformProjectTaskFilterStatus.toModel(task, status);
  return {
    task,
    status,
    subStatuses,
    filterStatuses,
  }
}

const transformProjectTask = { toModel };

export default transformProjectTask;

