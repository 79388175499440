import { Collapse } from "@mui/material";
import BudgetItemListTrackingExpenses from "./budget-item-list-tracking-expense/BudgetItemListTrackingExpenses";
import BudgetItemListTrackingPaymentSchedule from "./budget-item-list-tracking-payment-schedule/BudgetItemListTrackingPaymentSchedule";

interface BudgetTrackingTaskExpansionProps {
  taskId: string;
  show?: boolean;
}
const BudgetTrackingTask = (props: BudgetTrackingTaskExpansionProps) => {
  const { taskId, show } = props;

  return (
    <Collapse
      in={show}
      unmountOnExit={true}
    >
      <BudgetItemListTrackingPaymentSchedule
        taskId={taskId}
      />
      <BudgetItemListTrackingExpenses
        taskId={taskId}
      />
    </Collapse>
  )
}
export default BudgetTrackingTask;