import { Dialog } from "@mui/material";

import { useSelector } from "common/state/store";

import { CoreComponents, CoreState } from "@build-buddy/core";

import { selectProjectTask } from "modules/project/_selectors";

import { useTaskViewParams } from "../../_hooks";

import { selectTaskViewNextTaskAdminOrManageOrIntMeetingTask } from "../../_selectors";

import TaskViewBookingDialogForm from "./TaskViewBookingDialogForm";

type TaskViewBookingDialogProps = {
  open: boolean;
  onClose(): void;
}
const TaskViewBookingDialog = (props: TaskViewBookingDialogProps) => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskListParams = { projectId, stageId, taskId };

  // queries 
  const getTaskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskListParams);

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskListParams));
  const nextAdminOrManageTask = useSelector((s) => selectTaskViewNextTaskAdminOrManageOrIntMeetingTask(s, taskListParams));
  const currentTask = projectTask?.task;

  // locals
  const isLoading = getTaskListByParentQuery.isLoading;

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
    >
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={Boolean(!nextAdminOrManageTask || !currentTask)}
      >
        <CoreComponents.DialogHeader
          title="Make a booking"
          onClose={() => props.onClose()}
        />
        <TaskViewBookingDialogForm
          nextTask={nextAdminOrManageTask as CoreState.Task.Task}
          currentTask={currentTask as CoreState.Task.Task}
          onClose={props.onClose}
        />
      </CoreComponents.PreContent>
    </Dialog>
  )
}
export default TaskViewBookingDialog;