import { CoreRouting, CoreState, useMode } from "@build-buddy/core";
import { ProfileMenu } from "@build-buddy/core/dist/components";
import { Box, Container, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { InvitationRouteDefinitions } from "modules/invitation/InvitationRoutes";
import { UserProfileRouteDefinitions } from "modules/user/profile";
import { FC, useState } from "react";
import SideMenu from "./SideMenu";
import Header from "./headers/Header";

interface StandardTemplateProps {
  children: any;
}
const StandardTemplate: FC<StandardTemplateProps> = (props: StandardTemplateProps) => {
  // hooks
  const mode = useMode();
  const routing = CoreRouting.useRouting();

  const [open, setOpen] = useState(false);
  const [avatarEl, setAvatarEl] = useState<Element | null>(null);
  const theme = useTheme();
  const user = useSelector(CoreState.User.selectCurrentUser);
  const userHasAvatar = useSelector(CoreState.User.selectCurrentUserHasAvatar);

  const getAvatarByUserQuery = CoreState.File.getAvatarByUser.useQuery({ userId: user?.id }, { skip: (!user?.id || !userHasAvatar) });

  const avatar = useSelector((state) => CoreState.File.selectAvatarByUser(state, { userId: user?.id || "" }));

  // if there is no user, just return the children (which is all the routes)
  if (!user) return props.children;

  return (
    <Container
      maxWidth={false}
      disableGutters={!mode.sm}
      sx={{
        pb: {
          xs: theme.variables.mobileNavbarSpacing,
          md: 0
        }
      }}
    >
      <Header
        avatar={avatar}
        onMenuClick={() => setOpen(!open)}
        onAvatarClick={(e) => {
          setAvatarEl(e.currentTarget);
        }}
      />
      <ProfileMenu
        user={{
          name: (user.firstName || user.lastName) ? `${user.firstName} ${user.lastName}` : user.email,
          avatar,
          createdDate: user.createdDate,
        }}
        invitationType="Project Invitations"
        anchor={avatarEl}
        onClose={() => setAvatarEl(null)}
        onClick={() => { routing.go(InvitationRouteDefinitions.INVITATION) }}
        onProfileViewClick={() => routing.go(UserProfileRouteDefinitions.USER_PROFILE)}
      />
      <SideMenu open={open} onClose={() => setOpen(false)} />
      <Box
        sx={{
          ml: {
            xs: 0,
            md: theme.variables.drawerWidth / 8,
          },
        }}
      >
        {props.children}
      </Box>
    </Container>
  );
};

export default StandardTemplate;
