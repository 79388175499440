import { Box, Grid, Skeleton } from "@mui/material";

const DashboardFinancials = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7} lg={8} xl={7}>
        <Skeleton 
          variant='rectangular'
          sx={{
            height: {
              xs: "150px",
              md: "250px"
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} lg={4} xl={5}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            gap: 2,
            flexDirection: {
              xs: "row",
              md: "column"
            }
          }}
        >
          <Skeleton variant='rectangular' 
            sx={{ 
              flex: 1,
              height: {
                xs: "150px",
                md: "inherit"
              }
            }} 
          />
          <Skeleton variant='rectangular' 
            sx={{ 
              flex: 1, 
              height: {
                xs: "150px",
                md: "inherit"
              }
            }}  
          />
        </Box>
      </Grid>
    </Grid>
  )
}
export default DashboardFinancials;