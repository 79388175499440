import { CoreState } from "@build-buddy/core";
import { Typography } from "@mui/material";
import { Unavailable } from "common/components";
import { useSelector } from "common/state/store";

type GanttChartGuardProps = {
  children: any
}
const GanttChartGuard = (props: GanttChartGuardProps) => {
  const { children } = props;  
  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);

  if(isLimited) {
    return (
      <Unavailable>
        <Typography variant="body1" sx={{ mb: 1 }}>
          The Gantt Chart provides a timeline of tasks so that you know exactly what you need to do, when you need to do it.
        </Typography> 
        <Typography variant="body1">
          To unlock this feature, complete watching all videos and then schedule and attend your Discovery Appointment with a 
          member of the Build Buddy Expert Guidance and Support Team.
        </Typography>               
      </Unavailable>
    )
  }

  return children;
}
export default GanttChartGuard;