import { CoreComponents, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useFinancialRoutesParams } from "modules/project/financials/FinancialsRoutes";
import BudgetTrackingTask from "./BudgetITemListTrackingTask";
import BudgetItemListTrackingSkeleton from "./BudgetItemListTrackingSkeleton";

interface BudgetTrackingExpansionProps {
  costCentreCode: string;
}
const BudgetTrackingExpansion = (props: BudgetTrackingExpansionProps) => {
  const { costCentreCode } = props;
  const { projectId } = useFinancialRoutesParams();
  const costCentreActualByCodeParams = { projectId, costCentreCode };

  // queries
  const costCentreActualByCodeQuery = CoreState.Financial.getCostCentreActualByCode.useQuery(costCentreActualByCodeParams)

  // locals
  const isLoading = Boolean(!costCentreActualByCodeQuery.isUninitialized && costCentreActualByCodeQuery.isLoading);
  const costCentreActual = useSelector(s => CoreState.Financial.selectCostCentreActualByCode(s, costCentreActualByCodeParams));

  return (
    <CoreComponents.PreContent
      isLoading={isLoading}
      loader={<BudgetItemListTrackingSkeleton />}
      isEmpty={Boolean(!costCentreActual?.taskActuals?.length)}
      empty={<> ERR </>}
    > 
      {costCentreActual?.taskActuals?.map((taskActual, i) => (
        <BudgetTrackingTask
          key={i}
          taskId={taskActual.task.id}
          label={taskActual.task.name}
          accepted={taskActual.actuals.accepted}
          allocated={taskActual.actuals.allocated}
          paid={taskActual.actuals.paid}
        />
      ))}
    </CoreComponents.PreContent>
  )
}
export default BudgetTrackingExpansion;