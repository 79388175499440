import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { selectProjectTask } from "modules/project/_selectors";

export const selectShowQualityAssuranceTab = createSelector(
  [
    selectProjectTask,
    CoreState.Task.selectTaskListByParentHasQualityAssurance,
  ],
  (projectTask, hasQualityAssurance) => {
    const showQualityAssuranceTab = (
      projectTask
      && (["ProfService", "Contractor", "Supply"].includes(projectTask.task.contractType ?? ""))
      && (hasQualityAssurance)
    );
    return showQualityAssuranceTab
  }
)