import { CoreComponents, CoreRouting, CoreState } from '@build-buddy/core';
import { selectTaskViewQuoteListAcceptedQuoteQuoteId } from 'modules/project/task/task-view/_selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useBudgetExpenseParams } from './BudgetExpenseRoutes';

const BudgetTrackingExpenseEdit = () => {
  // hooks
  const { download } = CoreState.File.useDownloadQueue();
  const budgetTrackingExpenseParams = useBudgetExpenseParams();
  const routing = CoreRouting.useRouting();

  // params
  const params = {
    projectId: budgetTrackingExpenseParams.projectId,
    stageId: budgetTrackingExpenseParams.stageId,
    taskId: budgetTrackingExpenseParams.taskId
  }

  // queries
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(params);

  // Selector
  const quoteId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteQuoteId(s, params));
  const expense = useSelector((state) => CoreState.Financials.selectAcceptedQuoteOrTaskExpenseById(state, params, { expenseId: budgetTrackingExpenseParams.paymentScheduleId }));

  // Mutation
  const [updateExpenseAction, updateExpenseQuery] = CoreState.Financials.updateExpense.useMutation();
  const [deleteDocListByExpenseAction, deleteDocListByExpenseQuery] = CoreState.File.deleteDocByExpense.useMutation();

  const handleClose = () => routing.back({ persistSearch: true });

  const handleUpdateExpense = async (values: { expense: CoreState.Financials.Expense, docsToRemove: Array<CoreState.File.Doc> }) => {
    if (!expense) return;
    const updateExpense = updateExpenseAction({
      projectId: params.projectId,
      workOrderId: quoteId || undefined,
      taskId: params.taskId,
      expense: { ...values.expense, id: expense.id }
    });
    const deleteExpenseFiles = deleteDocListByExpenseAction({
      projectId: params.projectId,
      paymentScheduleId: budgetTrackingExpenseParams.paymentScheduleId,
      docs: values.docsToRemove
    })
    try {
      await Promise.all([updateExpense, deleteExpenseFiles]);
    } catch (e) {
      return;
    }
  }

  const handleDownload = (doc: CoreState.File.Doc) => {
    const params = {
      projectId: budgetTrackingExpenseParams.projectId,
      paymentScheduleId: budgetTrackingExpenseParams.paymentScheduleId,
      documentId: doc.id ?? ""
    }
    download(doc, CoreState.File.downloadDocByExpense.initiate(params));
  };

  useEffect(() => {
    if (updateExpenseQuery.isSuccess) handleClose();
  }, [updateExpenseQuery.isSuccess])

  return (
    <CoreComponents.ExpenseFormDialog
      title="Edit Expense"
      expense={expense}
      onClose={handleClose}
      onSubmit={handleUpdateExpense}
      onDownload={handleDownload}
      isSaving={updateExpenseQuery.isLoading}
      isLoading={quoteListQuery.isLoading}
    />
  )
};

export default BudgetTrackingExpenseEdit;