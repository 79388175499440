import { TaskViewTabs } from "../_common";
import TaskViewSupplyOutline from "./TaskViewSupplyOutline";

const TaskViewSupplyContent = () => {
  return (
    <>
      <TaskViewSupplyOutline />
      <TaskViewTabs />
    </>
  )
}
export default TaskViewSupplyContent;