import { FC,  useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import DimensionSlider from "./DimensionsSlider";

interface DimensionControlsProps {
  dimensions: any;
  onUpdate(dimensions: any): void;
}
const DimensionControls: FC<DimensionControlsProps> = (props: DimensionControlsProps) => {
  const [dimensions, setDimensions] = useState<any>();

  useEffect(() => {
    setDimensions(props.dimensions)
  }, [props.dimensions]);

  const handleChange = (key: string, val: any) => {
    const d = {
      ...dimensions,
      [key]: {
        label: props.dimensions[key].label,
        value: Number(val),
      }
    };
    props.onUpdate(d);
  };

  if (!dimensions) return null;

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}> 
        Dimension Details 
      </Typography>
      {Object.keys(dimensions).map((key: string, index: number) => (
        <DimensionSlider
          key={index}
          label={dimensions[key].label}
          color={dimensions[key].color} 
          min={dimensions[key].min}
          max={dimensions[key].max}
          error={dimensions[key].error}
          value={Number(dimensions[key].value)}
          onChange={(val: any) => handleChange(key, val)}
        />
      ))}
    </Box>
  );
};

export default DimensionControls;