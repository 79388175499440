import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { PaymentReceipt } from "modules/project/_components";
import { useBudgetMakePaymentRoutesParams } from "../BudgetMakePaymentRoutes";
import BudgetPaymentSchedulePayReceiptError from "./BudgetPaymentSchedulePayReceiptError";
import BudgetPaymentSchedulePayReceiptSkeleton from "./BudgetPaymentSchedulePayReceiptSkeleton";
import { useSelector } from "common/state/store";

const BudgetPaymentSchedulePayReceipt = () => {
  const routing = CoreRouting.useRouting();
  
  // params
  const { projectId, stageId, taskId, paymentScheduleId, paymentType } = useBudgetMakePaymentRoutesParams();
  const taskListParams = { projectId, stageId, taskId };
  const paymentScheduleParams = { projectId, paymentScheduleId };
  const receiptType = Boolean(CoreState.Financial.PaymentType.PayTo === paymentType) ? "Auth" : "Paid";
  const receiptTitle = Boolean(CoreState.Financial.PaymentType.PayTo === paymentType) ? "Authorisation Required" : "Payment made";

  // queries 
  const taskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskListParams);
  const paymentScheduleQuery = CoreState.Financial.getPaymentSchedule.useQuery(paymentScheduleParams);
  
  // locals
  const isLoading = 
    Boolean(!taskListByParentQuery.isUninitialized && taskListByParentQuery.isLoading) ||
    Boolean(!paymentScheduleQuery.isUninitialized && paymentScheduleQuery.isLoading);
  const task = useSelector(s => CoreState.Task.selectTaskListByParentCurrentTask(s, { projectId, stageId, taskId }))
  const paymentSchedule = useSelector(s => CoreState.Financial.selectPaymentSchedule(s, paymentScheduleParams));

  // event handlers
  const handleBackClick = () => {
    routing.back();
  }

  return (
    <CoreComponents.SubView
      open={true}
      onClose={() => handleBackClick()}
    >
      <CoreComponents.DialogHeader 
        title={receiptTitle} 
        onClose={() => handleBackClick()} 
      />
      <CoreComponents.PreContent
        isLoading={isLoading}
        loader={<BudgetPaymentSchedulePayReceiptSkeleton />}
        isEmpty={Boolean(!task || !paymentSchedule)}
        empty={<BudgetPaymentSchedulePayReceiptError />}
      >
        <PaymentReceipt
          type={receiptType}
          task={task as CoreState.Task.Task}
          paymentSchedule={paymentSchedule as CoreState.Financial.PaymentSchedule}
          sx={{ mt: 4 }}
          onDownloadClick={() => {}}
        />
      </CoreComponents.PreContent>
    </CoreComponents.SubView>
  )
}
export default BudgetPaymentSchedulePayReceipt