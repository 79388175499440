import { CoreModules, CoreRouting, CoreUtils } from "@build-buddy/core";
import { CalendarToday as CalendarIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { IconButton, Stack, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTask, selectProjectTaskStatus } from "modules/project/_selectors";
import { useTaskViewParams } from "../_hooks";
import TaskViewDrawerRouteDefinitions from "../task-view-drawer/TaskViewDrawerRouteDefinitions";

const colorsKvp: any = {
  [ProjectTaskStatus.NA]: "#455a64",
  [ProjectTaskStatus.AwaitingNextTask]: "#f44336",
  [ProjectTaskStatus.New]: "#0091ea",
  [ProjectTaskStatus.QuotesRequested]: "#25C9C4",
  [ProjectTaskStatus.QuotesReceived]: "#10A49F",
  [ProjectTaskStatus.QuotesAccepted]: "#E964FF",
  [ProjectTaskStatus.AwaitingStart]: "#4a148c",
  [ProjectTaskStatus.WorkInProgress]: "#FF6F00",
  [ProjectTaskStatus.InReview]: "#f06292",
  [ProjectTaskStatus.InQA]: "#d81b60",
  [ProjectTaskStatus.Finalise]: "#06C270",
  [ProjectTaskStatus.Completed]: "#05A660"
}

const useVm = () => {
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const taskParams = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, taskParams));
  const isCompleted = (projectTaskStatus === ProjectTaskStatus.Completed);

  let title;
  let value;

  if (projectTask) {
    title = isCompleted ? "Completed on" : "Est completion";
    value = isCompleted ? projectTask.task.completedDate : projectTask.task.endDate;
  }

  return {
    projectTask,
    projectTaskStatus,
    title,
    value
  }
}
const TaskViewOutlineStartEndDate = () => {
  const routing = CoreRouting.useRouting();
  const theme = useTheme();
  const vm = useVm();

  if (!vm.projectTask || !vm.projectTaskStatus || !vm.title || !vm.value) return null;

  const handleDateClick = () => {
    routing.go(TaskViewDrawerRouteDefinitions.TASK_VIEW_TIMELINE);
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
      onClick={() => handleDateClick()}
    >
      <CoreModules.Task.TaskView.TaskViewOutlineItem
        title="Start"
        value={CoreUtils.Formatter.date(vm.projectTask.task.startDate, "d MMM yy")}
        icon={CalendarIcon}
        iconStyle={{
          background: colorsKvp[vm.projectTaskStatus],
          color: theme.palette.common.white
        }}
      />
      <CoreModules.Task.TaskView.TaskViewOutlineItem
        title={vm.title}
        value={CoreUtils.Formatter.date(vm.value, "d MMM yy")}
      />
      <IconButton sx={{ml: -2}}>
        <ExpandMoreIcon />
      </IconButton>
    </Stack>
  )
}
export default TaskViewOutlineStartEndDate;