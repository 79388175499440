import { CoreHooks } from "@build-buddy/core";

const useCostsPieChart = () => {
  const mode = CoreHooks.useMode();
  const large = {
    height: 250,
    hole: 100,
    price: 24,
    forecast: 18
  };

  const small = {
    height: 175,
    hole: 65,
    price: 17,
    forecast: 13
  }
  if (mode.lg || mode.xl) return large;
  if (mode.md) return large;
  if (mode.sm) return large;
  return small
}

export default useCostsPieChart;