import { CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useEffect } from "react";
import { BudgetMakePaymentRoutesDefinition, useBudgetMakePaymentRoutesParams } from "../BudgetMakePaymentRoutes";
import { PaymentConfirm } from "modules/project/_components";

const BudgetMakePaymentConfirm = () => {
  const routing = CoreRouting.useRouting();

  //  params
  const { projectId, stageId, taskId, paymentScheduleId, paymentType } = useBudgetMakePaymentRoutesParams();
  const taskListParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };
  const paymentScheduleParams = { projectId, paymentScheduleId };

  // queries   
  const taskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskListParams);
  const paymentScheduleQuery = CoreState.Financial.getPaymentSchedule.useQuery(paymentScheduleParams);
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(quoteListParams);

  // mutations
  const [payPaymentScheduleAction, payPaymentScheduleQuery] = CoreState.Financial.payPaymentSchedule.useMutation();

  // locals
  const isLoading = 
    Boolean(!taskListByParentQuery.isUninitialized && taskListByParentQuery.isLoading) ||
    Boolean(!paymentScheduleQuery.isUninitialized && paymentScheduleQuery.isLoading) ||
    Boolean(!quoteListQuery.isUninitialized && quoteListQuery.isLoading);
  const isSaving = Boolean(!payPaymentScheduleQuery.isUninitialized && payPaymentScheduleQuery.isLoading);
  const isSuccess = Boolean(!payPaymentScheduleQuery.isUninitialized && payPaymentScheduleQuery.isSuccess);  
  const acceptedQuote = useSelector(s => CoreState.Quote.selectQuoteListByLatestQuoteRequestAcceptedQuote(s, quoteListParams));
  const task = useSelector(s => CoreState.Task.selectTaskListByParentCurrentTask(s, taskListParams))
  const paymentSchedule = useSelector(s => CoreState.Financial.selectPaymentSchedule(s, paymentScheduleParams));
  const company = acceptedQuote?.company
  const companyName = company?.name || "";
  const submitText = paymentType === CoreState.Financial.PaymentType.PayTo ? "Request Authorisation" : "Confirm Payment"

  const handleCancelClick = () => {
    routing.go(BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT)
  }

  const handleSubmitClick = () => {
    payPaymentScheduleAction({ projectId, taskId, paymentScheduleId, paymentType });
  }

  useEffect(() => {
    if(!isSuccess) return;
    routing.go(`${BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_RECEIPT.path}?type=${paymentType}`);
  }, [isSuccess]);

  return (
    <PaymentConfirm 
      title="Pay Now"
      paymentType={paymentType}
      paymentSchedule={paymentSchedule}
      task={task}
      recipientName={companyName}
      isLoading={isLoading}
      isSaving={isSaving}
      submitText={submitText}
      onSubmitClick={() => handleSubmitClick()}
      onCancelClick={() => handleCancelClick()}
    />
  )
}
export default BudgetMakePaymentConfirm;