import { CoreComponents } from "@build-buddy/core";
import { Box, Card, CardContent, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";
import CostsPieData from "./_models/CostsPieData";
import CostsPieCardSkeleton from "./CostsPieCardSkeleton";
import CostsPieChart from "./CostsPieChart";
import CostsPieLegend, { CostsPieLegendProps } from "./CostsPieLegend";

interface CostsPieChartProps {
  data?: CostsPieData;
  hasBoq?: boolean;
  isLoading?: boolean;
  total?: number;
  CostsPieLegendProps?: Partial<CostsPieLegendProps>;
  sx?: SxProps<Theme>;
}
const CostsPie = (props: CostsPieChartProps) => {
  const colours = {
    paid: "#FF6F00",
    committed: "#FFBA85",
    due: "#CB5800",
    allocated: "#757575",
    balance: "#E3E6E8"
  };

  const {
    CostsPieLegendProps,
    sx,
    // defaults
    data = {
      paid: 0,
      committed: 0,
      due: 0,
      allocated: 0,
      balance: 0
    },
    hasBoq = false,
    isLoading = false,
    total = 0,
  } = props;


  const sorted = () => {
    return {
      paid: data.paid,
      committed: data.committed,
      due: data.due,
      allocated: data.allocated,
      balance: data.balance
    }
  };

  const chart = useMemo(() => {
    // if no financials use a blank chart object
    if (!hasBoq) return [{ key: "blank", value: 1, color: "#E3E6E8" }];
    // otherwise transform the data into a chart object
    return Object.entries(sorted()).map(([key, value]) => ({
      key,
      value,
      color: colours[key as keyof typeof data],
    }));    
  }, [data, hasBoq])

  const legend = useMemo(() => {
    return Object.entries(sorted()).map(([key, value]) => ({
      key,
      value,
      color: colours[key as keyof typeof data],
    }));    
  }, [data, hasBoq])


  return (
    <CoreComponents.PreContent
      isLoading={Boolean(isLoading)}
      loader={<CostsPieCardSkeleton />}
      isEmpty={false}
    >
      <Card 
        sx={{ ...sx }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "160px",
                  sm: "250px",
                },
                mr: {
                  xs: 1,
                  sm: 3,
                  md: 5,
                  lg: 2,
                  xl: 5,
                }
              }}
            >
              <CostsPieChart
                data={chart}
                hasFinancials={hasBoq}
                total={total}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
              }}
            >
              <CostsPieLegend
                data={legend}
                {...CostsPieLegendProps}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </CoreComponents.PreContent>
  )
}
export default CostsPie;