import { CoreState } from "@build-buddy/core";
import { useFinancialRoutesParams } from "modules/project/financials/FinancialsRoutes";
import qs from "qs";
import { Route, useLocation, useRouteMatch } from "react-router-dom";
import BudgetMakePayment from "./BudgetMakePayment";
import BudgetMakePaymentAllocate from "./budget-make-payment-confirm/BudgetMakePaymentAllocate";
import BudgetMakePaymentConfirm from "./budget-make-payment-confirm/BudgetMakePaymentConfirm";
import BudgetPaymentSchedulePayReceipt from "./budget-make-payment-receipt/BudgetPaymentSchedulePayReceipt";

export const BudgetMakePaymentRoutesDefinition = {
  BUDGET_MAKE_PAYMENT: {
    path: "/*/task/:tid/make-payment/:psid",
    component: BudgetMakePayment
  },
  BUDGET_MAKE_PAYMENT_RECEIPT: {
    path: "/*/task/:tid/make-payment/:psid/receipt",
    component: BudgetPaymentSchedulePayReceipt
  },
  BUDGET_MAKE_PAYMENT_CONFIRM: {
    path: "/*/task/:tid/make-payment/:psid/pay-confirm",
    component: BudgetMakePaymentConfirm
  },
  BUDGET_MAKE_PAYMENT_ALLOCATE: {
    path: "/*/task/:tid/make-payment/:psid/allocate",
    component: BudgetMakePaymentAllocate
  }
};

export const useBudgetMakePaymentRoutesParams = () => {
  // get params from parent route
  const { projectId, stageId } = useFinancialRoutesParams();
  
  // match route and get params
  const match = useRouteMatch<any>([
    BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT.path,
    BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_RECEIPT.path,
    BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_CONFIRM.path,
    BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_ALLOCATE.path
  ]);
  const { tid, psid } = match?.params;

  // find any querystring params 
  const { search } = useLocation();
  const { type } = qs.parse(search, { ignoreQueryPrefix: true });
  const paymentType = CoreState.Financial.PaymentType[type as keyof typeof CoreState.Financial.PaymentType];

  return {
    projectId,
    stageId,
    taskId: tid,
    paymentScheduleId: psid,
    paymentType
  }
}

export const BudgetMakePaymentRoutes = () => {
  return (
    <>
      <Route
        path={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT.path}
        component={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT.component}
      />
      <Route
        path={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_CONFIRM.path}
        component={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_CONFIRM.component}
      />
      <Route
        path={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_ALLOCATE.path}
        component={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_ALLOCATE.component}
      />      
    </>
  );
};

export const BudgetMakePaymentRoutesSubView = () => {
  return (
    <>
      <Route
        path={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_RECEIPT.path}
        component={BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_RECEIPT.component}
      />
    </>
  );
}