import { CoreRouting, CoreState, FeedbackIcon, Menu, useMode } from "@build-buddy/core";
import { Logout as LogoutIcon } from '@mui/icons-material';
import { SupportAgent as SupportIcon, PersonAddAlt as PersonAddAltIcon } from "@mui/icons-material";
import logo from "assets/bb-icon.png";
import { RouteDefinitions } from "common/RouteDefinitions";
import { useSelector } from "common/state/store";
import { UserProfileRouteDefinitions } from "modules/user/profile";
import { Link, useLocation } from "react-router-dom";
import useSideMenu from "./useSideMenu";
import { InvitationRouteDefinitions } from "modules/invitation";

interface SideMenuProps {
  open: boolean;
  onClose(): void;
}

const SideMenu = (props: SideMenuProps) => {
  const mode = useMode()
  const { menuItems } = useSideMenu();
  const routing = CoreRouting.useRouting();
  const location = useLocation();
  const name = useSelector(CoreState.User.selectCurrentUserName);
  const email = useSelector(CoreState.User.selectCurrentUserEmail);
  const createdDate = useSelector(CoreState.User.selectCurrentUserCreatedDate);

  const footerMenuItems = [
    // {
    //   icon: SupportIcon,
    //   label: "Support",
    //   active: Boolean([RouteDefinitions.Help.SUPPORT.path].some((s) => location.pathname.includes(s))),
    //   disabled: false,
    //   onClick: () => {
    //     routing.go(RouteDefinitions.Help.SUPPORT.path)
    //   }
    // },
    {
      icon: FeedbackIcon,
      label: "Submit Feedback",
      active: Boolean([RouteDefinitions.Help.SUBMIT_FEEDBACK.path].some((s) => location.pathname.includes(s))),
      disabled: false,
      onClick: () => {
        routing.go(RouteDefinitions.Help.SUBMIT_FEEDBACK.path)
      }
    }
  ]

  if (mode.isMobile) {
    footerMenuItems.unshift({
      icon: PersonAddAltIcon as any,
      label: "Project Invitations",
      active: ["/invitation"].some((s) => location.pathname.includes(s)),
      disabled: false,
      onClick: () => {
        routing.go(InvitationRouteDefinitions.INVITATION);
      }
    })
    footerMenuItems.push({
      icon: LogoutIcon as any,
      label: "Logout",
      active: false,
      disabled: false,
      onClick: () => null,
    })
  }

  return (
    <>
      <Menu
        user={{
          name: name || email || "-",
          avatar: "",
          createdDate: createdDate || "-",
          profileLink: UserProfileRouteDefinitions.USER_PROFILE.path
        }}
        open={props.open}
        logo={
          <Link to="/">
            <img src={logo} style={{ width: "32px" }} alt="logo" />
          </Link>
        }
        onClose={() => props.onClose()}
        menuItems={menuItems}
        footerItems={footerMenuItems}
        onAvatarClick={() => { }}
        onNotificationsClick={() => { }}
      />
    </>
  );
};
export default SideMenu;
