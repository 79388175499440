import { CoreComponents, CoreRouting, useMode } from "@build-buddy/core";
import { Circle as CircleIcon } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { SchedulerItem } from "@progress/kendo-react-scheduler";
import { startOfDay } from "date-fns";
import { ProjectTaskFilterStatus } from "modules/project/_models";
import CalendarRouteDefinitions from "../CalendarRouteDefinitions";

const CalendarViewMonthViewItem = (props: any) => {
  const routing = CoreRouting.useRouting();
  const theme = useTheme();
  const mode = useMode();

  // locals
  const colorKvp: any = {
    [ProjectTaskFilterStatus.Upcoming]: theme.palette.grey,
    [ProjectTaskFilterStatus.Awaiting]: theme.palette.orange,
    [ProjectTaskFilterStatus.Overdue]: theme.palette.red,
    [ProjectTaskFilterStatus.Todo]: theme.palette.blue,
    [ProjectTaskFilterStatus.InProgress]: theme.palette.orange,
    [ProjectTaskFilterStatus.Completed]: theme.palette.green
  }

  const color = colorKvp[props.dataItem.type] || theme.palette.grey;

  // event handlers
  const handleClick = () => {
    const d = startOfDay(props.end);
    const params = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    }
    routing.go(CalendarRouteDefinitions.CALENDAR_DRAWER, { params });
  }

  return (
    <SchedulerItem
      {...props}
      style={{
        ...props.style,
        background: "none",
        marginTop: mode.md ? "4px" : "2px",
      }}
      onClick={() => handleClick()}
    >
      <Box
        sx={{
          ml: 1.25,
          px: {
            xs: 0,
            md: 1.25,
          },
          pb: {
            xs: 1,
            md: 0
          },
          background: {
            xs: "auto",
            md: color[200],
          },
          color: color[500],
          borderRadius: 4,
          textOverflow: "ellipsis",
          width: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: {
            xs: "center",
            md: "flex-start"
          }
        }}
      >
        <CircleIcon
          sx={{
            color: color,
            fontSize: 10,
            position: "relative",
            bottom: 0,
            mr: 0.5
          }}
        />
        <CoreComponents.Truncate
          lines={1}
          sx={{
            display: {
              xs: "none",
              md: "block"
            }
          }}
          text={`${props.dataItem.count} ${props.dataItem.type}`}
          TypographyProps={{ variant: "caption" }}
        />
      </Box>
    </SchedulerItem>
  )
}
export default CalendarViewMonthViewItem