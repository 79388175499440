import { Stack, SxProps, Theme, useTheme } from "@mui/material";
import { CommonComponents } from "common";

export interface BudgetLegedProps {
  sx?: SxProps<Theme>;
}
const BudgetLegend = (props: BudgetLegedProps) => {
  const theme = useTheme();
  return (
    <>
      <Stack
        direction="row"
        gap={2.5}
        sx={{
          flex: 1,
          ...props.sx
        }}
      >
        <CommonComponents.LegendItem
          label="Accepted"
          color={theme.palette.primary.main}
          variant="striped"
        />
        <CommonComponents.LegendItem
          label="Allocated"
          color={"#CB5800"}
        />
        <CommonComponents.LegendItem
          label="Paid"
          color={theme.palette.primary.main}
        />
        <CommonComponents.LegendItem
          label="Projected Savings"
          color={theme.palette.success.main}
          variant="striped"
        />
        <CommonComponents.LegendItem
          label="Savings"
          color={theme.palette.success.main}
        />
        <CommonComponents.LegendItem
          label="Projected Over Budget"
          color={theme.palette.error.main}
          variant="striped"
        />
        <CommonComponents.LegendItem
          label="Over Budget"
          color={theme.palette.error.main}
        />
      </Stack>
    </>
  )
}
export default BudgetLegend;