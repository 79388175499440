import { Loader } from "@build-buddy/core";
import WizardComponent from "../wizard-components/WizardComponent";

type WizardViewStepContainerStepContentProps = {
  isLoading: boolean;
  components: any;
}
const WizardViewStepContainerStepContent = (props: WizardViewStepContainerStepContentProps) => {
  const { isLoading, components } = props;

  return (
    <>
      <Loader show={isLoading} />
      {components?.map((comp: any, index: number) => (
        <WizardComponent
          key={index}
          type={comp.type}
          data={comp.data}
          fields={comp.fields}
        />
      ))}
    </>
  )
}

export default WizardViewStepContainerStepContent;