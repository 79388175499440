import { CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useTaskViewParams } from "../_hooks";
import { selectTaskViewQuoteListAcceptedQuoteCompanyId } from "../_selectors";
import TaskViewCompanyRouteDefinitions from "./TaskViewCompanyRouteDefinitions";

const TaskViewCompanyDetailsReviews = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();
  const quoteListParams = { projectId, stageId, taskId };
  const routing = CoreRouting.useRouting();

  // queries
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(quoteListParams);

  const companyId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteCompanyId(s, quoteListParams));
  
  const handleClose = () => {
    routing.go(TaskViewCompanyRouteDefinitions.TASK_VIEW_COMPANY_DETAILS)
  }

  if(!companyId) return null;
  
  return (
    <CoreModules.Task.TaskView.TaskViewSubView
      open={true}
      onClose={() => handleClose()}
    >
      <CoreModules.Company.CompanyReviews companyId={companyId} />
    </CoreModules.Task.TaskView.TaskViewSubView>
  )
}
export default TaskViewCompanyDetailsReviews;