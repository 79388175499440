import { Box, FormControl, FormHelperText, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";

type TaskViewReviewScoreRecommendProps = {
  onChange: (val: number) => void;
  value?: number;
  error?: any;
  fullWidth?: boolean;
}
const TaskViewReviewScoreRecommend = (props: TaskViewReviewScoreRecommendProps) => {
  const { value, error, fullWidth, onChange } = props;
  const theme = useTheme();

  // locals
  const [val, setVal] = useState<number | null | undefined>(value);
  const arr = Array.from({ length: 10 }, (_, i) => i + 1);
  const hasError = Boolean(error);

  const handleChange = (v: number) => {
    setVal(v);
    onChange(v);
  }

  return (
    <FormControl 
      fullWidth={fullWidth}
      error={hasError}
    >
      <Typography
        variant="h2"
        sx={{ 
          mb: 2,
          color: hasError ? 
            theme.palette.error.main :
            "inherit"
        }}
      >
        How likely are you to recommend them? *
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          mb: 0.5,
        }}
      >
        {arr.map((i) => (
          <Box
            key={i}
            onClick={() => handleChange(i)}
            sx={{
              border: `solid 1px ${theme.palette.primary.main}`,
              mr: {
                xs: 0,
                md: 1
              },
              "&:not(:last-of-type)": {
                borderRight: {
                  xs: "none",
                  md: `solid 1px ${theme.palette.primary.main}`,
                }
              },
              borderRadius: {
                xs: 0,
                md: "8px"
              },
              "&:first-of-type": {
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
              },
              "&:last-of-type": {
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
              },
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: (val === i) ? theme.palette.primary.contrastText : theme.palette.text.primary,
              background: (val === i) ? theme.palette.primary.main : "transparent",
              height: {
                xs: 24,
                sm: 36,
              }
            }}
          >
            {i}
          </Box>
        ))}
      </Stack>
      <Stack 
        direction="row" 
        justifyContent="space-between" 
        sx={{ 
          py: 0.75,
          color: hasError ? 
            theme.palette.error.main :
            "inherit"
        }}
      >
        <Typography variant="subtitle2"> Not likely </Typography>
        <Typography variant="subtitle2"> Very likely </Typography>
      </Stack>
      {hasError &&
        <FormHelperText
          sx={{ ml: 0 }}
        >
          {error}
        </FormHelperText>
      }
    </FormControl>
  )
}
export default TaskViewReviewScoreRecommend;