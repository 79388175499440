import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

type SelectDashboardProjectParams = {
  projectId: string;
}

const selectRoot = createSelector(
  [
    (state, params: SelectDashboardProjectParams) => 
      CoreState.Project.getProjectStageList.select(params)(state)
  ],
  (projectStageListQuery) => projectStageListQuery
)

const selectDashboardProjectStageList = createSelector(
  selectRoot,
  (projectStageListQuery) => projectStageListQuery.data || []
)

export {
    selectDashboardProjectStageList
};

