import { useMemo } from "react";
import { useRouteMatch } from "react-router";

const useCompanyParams = () => {
  const match = useRouteMatch<any>();

  const params = useMemo(() => ({
    companyId: match?.params.cid,
  }), [match?.params]);

  return params;
}
export default useCompanyParams;