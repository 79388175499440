import { CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useTaskViewParams } from "../../../_hooks";
import TaskViewQualityAssuranceRouteDefinitions from "../../TaskViewQualityAssuranceRouteDefinitions";
import { TaskViewQualityAssuranceDefectDotProgress } from "../../_common";
import { useTaskViewQualityAssuranceDefectsParams } from "../_hooks";
import { TaskViewQualityAssuranceDefectsActions } from "../task-view-quality-assurance-defects-actions";

const TaskViewQualityAssuranceDefectsView = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const { defectId } = useTaskViewQualityAssuranceDefectsParams();
  const getTaskListByParentParams = { projectId, stageId, taskId };
  const getDefectItemQueryParams = { projectId, stageId, taskId, defectId };
  const getNoteThreadListByDefectQueryParams = { projectId, stageId, taskId, defectId };

  // queries
  const getTaskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(getTaskListByParentParams)
  const getDefectItemQuery = CoreState.Defect.getDefectItem.useQuery(getDefectItemQueryParams);
  const getNoteThreadListByDefectQuery = CoreState.NoteThread.getNoteThreadListByDefect.useQuery(getNoteThreadListByDefectQueryParams)

  // selectors
  const task = useSelector((s) => CoreState.Task.selectTaskListByParentCurrentTask(s, getTaskListByParentParams));
  const defect = useSelector((s) => CoreState.Defect.selectDefectItem(s, getDefectItemQueryParams));
  const noteThreads = useSelector((s) => CoreState.NoteThread.selectNoteThreadListByDefectOrderedByLatest(s, getNoteThreadListByDefectQueryParams));

  // locals
  const isLoading = getTaskListByParentQuery.isLoading || getDefectItemQuery.isLoading || getNoteThreadListByDefectQuery.isLoading;

  const handleAdd = () => {
    routing.go(TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_VIEW_NOTE_ADD);
  }

  const handleBack = () => {
    routing.go(TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE);
  }

  return (
    <CoreModules.Task.TaskView.TaskViewSubView
      open={true}
      onClose={() => handleBack()}
    >
      <CoreModules.Task.TaskView.TaskViewDefectView
        defect={defect}
        task={task}
        noteThreads={noteThreads}
        isLoading={isLoading}
        actions={TaskViewQualityAssuranceDefectsActions}
        dotProgress={TaskViewQualityAssuranceDefectDotProgress}
        onAddClick={handleAdd}
      />
    </CoreModules.Task.TaskView.TaskViewSubView>
  )
}
export default TaskViewQualityAssuranceDefectsView;