import { useProjectParams } from "modules/project/_hooks";
import { useRouteMatch } from "react-router";
import TaskViewRouteDefinitions from "../../TaskViewRouteDefinitions";

const useTaskViewReviewEditParams = () => {
  const { projectId } = useProjectParams();
  const match = useRouteMatch<any>(TaskViewRouteDefinitions.TASK_VIEW_REVIEW_EDIT);

  return {
    projectId,
    stageId: match?.params.sid,
    taskId: match?.params.tid,
    reviewId: match?.params.reviewId,
  };
}
export default useTaskViewReviewEditParams