import { Box, Skeleton } from '@mui/material'

const TaskViewQuotesAcceptStandardSkeleton = () => {
  return (
    <Box px={2} py={4} borderRadius="8px" width="100%">
      <Box display="flex" alignItems="center" mb={2}>
        <Skeleton variant="circular" width={40} height={40} />
        <Box ml={2}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={140} />
        </Box>
      </Box>
      <Box mb={2}>
        <Skeleton variant="rectangular" width={100} height={20} />
        <Skeleton variant="text" width={200} />
      </Box>
      <Box mb={2}>
        <Skeleton variant="rectangular" width={100} height={20} />
        <Skeleton variant="text" width={200} />
      </Box>
      <Box mb={2}>
        <Skeleton variant="rectangular" width="100%" height={10} />
        <Box mt={1}>
          <Skeleton variant="rectangular" width="100%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={30} />
        </Box>
      </Box>
      <Box mb={2}>
        <Skeleton variant="rectangular" width="100%" height={10} />
        <Box mt={1}>
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton variant="rectangular" width="100%" height={20} sx={{mt: 2}} />
        </Box>
      </Box>
      <Box mt={1} sx={{display: 'flex', gap: 2}}>
          <Skeleton variant="rounded" width="100%" height={40} sx={{borderRadius: '2rem'}} />
          <Skeleton variant="rounded" width="100%" height={40} sx={{borderRadius: '2em'}} />
        </Box>
    </Box>
  )
}

export default TaskViewQuotesAcceptStandardSkeleton