import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useState } from "react";
import { selectTaskViewQuoteListAcceptedQuoteQuoteId, selectTaskViewQuoteListAcceptedQuoteQuoteRequestId } from "../_selectors";
import { useTaskViewReviewParams } from "./_hooks";
import TaskViewReviewDetails from "./task-view-review-details/TaskViewReviewDetails";
import TaskViewReviewScore from "./task-view-review-score/TaskViewReviewScore";

interface SubmitParams {
  review: Partial<CoreState.Reviews.Review>;
  quoteId: string;
  quoteRequestId: string;
  projectId: string;
}
interface TaskViewReviewProps {
  initialValues?: CoreState.Reviews.Review;
  onSubmit: (params: SubmitParams) => void;
  isEdit?: boolean;
}
const TaskViewReview: React.FC<TaskViewReviewProps> = (props) => {
  //local state
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState<CoreState.Reviews.Review>(props.initialValues ?? {} as CoreState.Reviews.Review);

  //params
  const params = useTaskViewReviewParams();
  const isEdit = Boolean(props.isEdit);

  //selectors
  const quoteRequestId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteQuoteRequestId(s, params));
  const quoteId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteQuoteId(s, params));

  //handlers
  const nextClickHandler = (values: Partial<CoreState.Reviews.Review>) => {
    const newReviewValue = { ...formValues, ...values };
    setFormValues(newReviewValue);
    if (step === 1) {
      if (!quoteId || !quoteRequestId) return;
      props.onSubmit({
        review: newReviewValue,
        projectId: params.projectId,
        quoteId,
        quoteRequestId,
      })
      return;
    }
    setStep(1);
  }
  const backClickHandler = () => setStep(0)

  if (step === 0) {
    return (
      <TaskViewReviewScore
        onNextClick={nextClickHandler}
        onBackClick={backClickHandler}
        initialValues={formValues}
        ratings={formValues.ratings}
        isEdit={isEdit}
      />
    )
  }

  return (
    <TaskViewReviewDetails
      onNextButtonClick={nextClickHandler}
      onBackButtonClick={backClickHandler}
      initialValues={formValues}
      isEdit={isEdit}
    />)
}

export default TaskViewReview;