import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import TaskViewRouteDefinitions from "../../../TaskViewRouteDefinitions";
import { useTaskViewParams } from "../../../_hooks";
import { selectTaskViewQuoteListAcceptedQuote } from "../../../_selectors";

const useTaskViewTypesTabsScopeOfWork = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const projectTaskParams = { projectId, stageId, taskId };
  const quoteParams = { projectId, stageId, taskId };

  // queries
  const quoteQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(quoteParams);

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, projectTaskParams));
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, projectTaskParams));

  // locals
  const count = quoteQuery.data?.filter(x => x.status === CoreState.Quote.QuoteStatus.Submitted).length || 0;

  const showQuotesTab = projectTask && (projectTask.task.type === CoreState.Task.TaskType.Select || acceptedQuote);

  if (!showQuotesTab) return null;

  return {
    ...TaskViewRouteDefinitions.TASK_VIEW_QUOTES,
    label: "Quotes",
    count
  }
}
export default useTaskViewTypesTabsScopeOfWork;