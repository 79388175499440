import { CoreHooks, CoreState } from "@build-buddy/core";
import { DesktopMac as DesktopIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Unavailable } from "common/components";
import { useSelector } from "common/state/store";

type OverviewGuardProps = {
  children: any
}
const OverviewGuard = (props: OverviewGuardProps) => {
  const { children } = props;
  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);
  const mode = CoreHooks.useMode();

  if (isLimited) {
    return (
      <Unavailable>
        <Typography variant="body1" sx={{ mb: 1 }}>
          The Project Overview provides a snapshot of your project's progress, identifies actionable items and helps you
          monitor your budget.
        </Typography>
        <Typography variant="body1">
          To unlock this feature, complete watching all videos and then schedule and attend your Discovery Appointment with a
          member of the Build Buddy Expert Guidance and Support Team.
        </Typography>
      </Unavailable>
    )
  }

  if (mode.isMobile) {
    return (
      <Unavailable
        icon={DesktopIcon}
        title="Available on desktop only"
      >
        <Typography variant="body1" sx={{ mb: 1 }}>
          The Project Overview provides a snapshot of your project's progress, identifies actionable items and helps you
          monitor your budget.
        </Typography>
      </Unavailable>
    )
  }

  return children;
}
export default OverviewGuard;