import { CoreState, CoreUtils } from "@build-buddy/core";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, Stack, Typography, useTheme } from "@mui/material";

export interface PaymentSummaryProps {
  paymentType: CoreState.Financial.PaymentType;
  paymentSchedule: CoreState.Financial.PaymentSchedule;
  onAllocateClick(): void;
  onPayClick(): void;
}
const PaymentSummary = (props: PaymentSummaryProps) => {
  const { paymentType, paymentSchedule, onAllocateClick, onPayClick } = props;
  const theme = useTheme();

  // locals
  const showAllocate = 
    Boolean(paymentType === CoreState.Financial.PaymentType.PayTo) &&
    Boolean(paymentSchedule.type !== CoreState.Financial.PaymentScheduleType.ImmediatePayment)

  return (
    <Card sx={{ minWidth: "360px", flex: 1 }}>
      <CardContent sx={{ pb: 1 }}> 
        <Typography
          variant="body1"
          sx={{
            px: 2,
            mx: -2,
            py: 1,
            pb: 2,
            mb: 2,
            fontWeight: "bold",
            borderBottom: `solid 1px ${theme.palette.grey[200]}`,
          }}
        >
          {paymentSchedule.description}
        </Typography>

        <Stack
          direction="row"
          alignItems="top"
          sx={{
            position: "relative",
            left: -2,
            mb: 3,
          }}
        >
          <InfoIcon
            sx={{
              mr: 1,
              fontSize: "20px",
            }}
          />
          <Box>
            {showAllocate && 
              <Typography variant="body2" sx={{ fontWeight: "bold", mb: 0.5 }}>
                Allocate: Funds will be released once the task is completed.
              </Typography>
            }
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Pay Now: Complete your payment instantly.
            </Typography>
          </Box>
        </Stack>

        <Stack direction="row" sx={{ mb: 0.5 }}>
          <Typography variant="body1" sx={{ flex: 1 }}> Subtotal:  </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}> {CoreUtils.Formatter.currency(paymentSchedule.subTotal)} </Typography>
        </Stack>
        <Stack direction="row" sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ flex: 1 }}> GST:  </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}> {CoreUtils.Formatter.currency(paymentSchedule.gst)}  </Typography>
        </Stack>
        <Stack direction="row">
          <Typography variant="body1" sx={{ flex: 1, fontSize: "16px" }}> Total Amount:  </Typography>
          <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "bold" }}> {CoreUtils.Formatter.currency(paymentSchedule.total)}  </Typography>
        </Stack>
      </CardContent>
      <CardActions sx={{ flexDirection: "column", rowGap: 1 }}>
        {showAllocate && 
          <Button 
            fullWidth 
            variant="outlined"
            onClick={() => onAllocateClick()}
          > 
            Allocate 
          </Button>
        }
        <Button 
          fullWidth 
          variant="contained"
          onClick={() => onPayClick()}
        > 
          Pay Now 
        </Button>
      </CardActions>
    </Card>
  )
}
export default PaymentSummary;