import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

interface SelectTaskViewDocumentsParams {
  projectId: string;
  stageId: string;
  taskId: string;
}
const selectTaskViewDocumentsRequired = createSelector(
  [
    (state, params: SelectTaskViewDocumentsParams) => CoreState.Tag.selectTagListByTaskDocumentSortedByDocumentType(state, { 
      projectId: params.projectId, 
      stageId: params.stageId, 
      taskId: params.taskId 
    }),
    (state, params: SelectTaskViewDocumentsParams) => CoreState.File.selectDocListByTaskExcludedTags(state, {
      projectId: params.projectId,
      companyId: undefined,
      taskId: params.taskId
    })
  ],
  (tagList, docList) => {
    return tagList.map((tag) => ({
      tag,
      document: docList
        .filter(d => d.documentType === CoreState.File.DocType.Project)
        .find(d => d.tags?.find(t => t.code === tag.code))
    }));
  }
)

export const selectTaskViewDocumentsOutput = createSelector(
  [selectTaskViewDocumentsRequired],
  (docs) => {
    return docs.filter(x => x.tag.extra?.documentType === "Output");
  }
)

export const selectTaskViewDocumentsInput = createSelector(
  [selectTaskViewDocumentsRequired],
  (docs) => {
    return docs.filter(x => x.tag.extra?.documentType === "Input");
  }
)

export const selectTaskViewDocumentsOther = createSelector(
  [CoreState.File.selectDocListByTaskExcludedTags], 
  (docList) => {
    return docList
      // filter out project doc types
      .filter((x) => 
        x.documentType !== CoreState.File.DocType.Project
      )
  }
)