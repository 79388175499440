

import { Box, Card, CardContent, SxProps, Theme, useTheme } from "@mui/material";
import { Help as HelpIcon } from "@mui/icons-material";
import SavingsCardEmpty from "./SavingsCardEmpty";
import SavingsCardValue from "./SavingsCardValue";
import { CoreComponents } from "@build-buddy/core";
import SavingsCardSkeleton from "./SavingsCardSkeleton";

export interface SavingsCardProps {
  savings: number;
  isLoading?: boolean;
  hasBoq: boolean;
  sx?: SxProps<Theme>;
}
const SavingsCard = (props: SavingsCardProps) => {
  const { savings, isLoading, hasBoq, sx } = props;
  const theme = useTheme();
  return (
    <CoreComponents.PreContent
      isLoading={Boolean(isLoading)}
      loader={<SavingsCardSkeleton />}
      isEmpty={false}
    >
      <Card
        sx={{
          background: "linear-gradient(93deg, #FF6F00 0%, #FFB800 100%)",
          color: theme.palette.common.white,
          ...sx
        }}
      >
        <CardContent>
          <Box sx={{ mb: 0.5, textAlign: "right" }}>
            <HelpIcon />
          </Box>
          {hasBoq && <SavingsCardValue savings={savings} /> }
          {!hasBoq && <SavingsCardEmpty /> }
        </CardContent>
      </Card>
    </CoreComponents.PreContent>
  )
}
export default SavingsCard;