import { FC, useEffect, useState } from "react";
import { Box, Slider, Typography, TextField } from "@mui/material";
import DimensionTextField from "./DimensionsTextField";

interface DimensionSliderProps {
  label: string;
  color: string;
  min?: number;
  max?: number;
  value: number;
  error?: string;
  onChange(value: any): void;
}
const DimensionSlider: FC<DimensionSliderProps> = (props: DimensionSliderProps) => {
  const label = props.label;
  const color = props.color;
  const error = props.error;
  const min = props.min || 0;
  const max = props.max || 100;
  const [value, setValue] = useState(props.value);

  const handleChangeCommitted = () => props.onChange(value);
  const handleTextBlur = () => props.onChange(value);
  const handleChange = (e: any, value: any) => setValue(value);
  const handleTextChange = (e: any) => setValue(Number(e.target.value));

  useEffect(() => { 
    setValue(props.value) 
  }, [props.value]);

  return (
    <>
      <Box sx={{ 
        display: "flex",
        alignItems: "center"      
      }}>
        <Box sx={{
          width: 12,
          height: 12,
          borderRadius: "50%",
          background: color,
          mr: 1.5,
          position: "relative",
          top: -1
        }}/>
        <Typography variant="h6" fontWeight={600}> 
          {label} 
        </Typography>
      </Box>

      <Box sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row"
        },
        mt: 0.5,
        mb: {
          xs: 1,
          md: 0
        }
      }}>
        <Box
          sx={{ 
            flex: 1,
            display: "flex",
            alignItems: "flex-top",
            pt: 1,
          }}
        >
          <Typography variant="body2">{min}</Typography>
          <Slider
            sx={{ mx: 3 }}
            onChangeCommitted={handleChangeCommitted}
            onChange={handleChange}
            min={min}
            max={max}
            value={value}
          />
          <Typography variant="body2">{max}</Typography>
        </Box>
             
        <TextField    
          sx={{ 
            width: {
              xs: "100%",
              md: "125px"
            },
            ml: {
              xs: 0,
              md: 3
            },
            my: {
              xs: 1,
              md: 0
            }
          }} 
          inputProps={{
            sx: {
              textAlign: {
                xs: "center",
                md: "left"
              }
            },
            suffix: "mm"
          }}
          InputProps={{
            inputComponent: DimensionTextField as any,
          }}
          size="small" 
          value={value}
          error={Boolean(error)}
          helperText={Boolean(error) ? error : " "}
          onChange={handleTextChange}
          onBlur={handleTextBlur}
        />
      </Box>
    </>
  );
};

export default DimensionSlider;