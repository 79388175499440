import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

export interface SelectTaskViewQuoteListParams {
  projectId: string;
  stageId: string;
  taskId: string;
}
export const selectTaskViewQuoteList = createSelector(
  [(state: CoreState.RootState, params) => CoreState.Quote.selectQuoteListByLatestQuoteRequest(state, params)],
  (quoteList) => quoteList
)

export const selectTaskViewQuoteListGrouped = createSelector(
  selectTaskViewQuoteList,
  (quoteList) => {
    if (!quoteList) return;
    const grouped = _.groupBy(quoteList, "company.id");
    return Object.keys(grouped).map(x => ({
      company: grouped[x][0].company,
      quotes: grouped[x]
    }))
  }
)

export const selectDisplayQuoteList = createSelector(
  selectTaskViewQuoteListGrouped,
  (groupedQuoteList) => {
    if (!groupedQuoteList) return;
    const statusPositions = {
      [CoreState.Quote.QuoteStatus.Accepted]: 1,
      [CoreState.Quote.QuoteStatus.AwaitingConfirmation]: 2,
      [CoreState.Quote.QuoteStatus.Submitted]: 3,
      [CoreState.Quote.QuoteStatus.Declined]: 4
    }
    return groupedQuoteList.map((g) => {
      const found = g.quotes.findIndex(x => x.status !== CoreState.Quote.QuoteStatus.Declined);
      const idx = (found < 0) ? 0 : found;
      const current = g.quotes[idx];
      const history = [...g.quotes];
      history.splice(idx, 1);
      return { current, history }
    }).sort((x, y) => statusPositions[x.current.status] - statusPositions[y.current.status])
  }
)

export const selectTaskViewQuoteListAcceptedQuote = createSelector(
  [
    (state, params: SelectTaskViewQuoteListParams) => CoreState.Quote.selectQuoteListByLatestQuoteRequestAcceptedQuote(state, params),
  ],
  (acceptedQuote) => acceptedQuote
)

export const selectTotalPaymentScheduleAmount = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => acceptedQuote?.paymentSchedule?.reduce((acc, expense) => acc + expense.amount, 0) ?? 0,
)

export const selectTaskViewQuoteListAcceptedQuoteCompanyName = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => acceptedQuote?.company?.name,
)

export const selectTaskViewQuoteListAcceptedQuoteQuoteRequestId = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => acceptedQuote?.quoteRequest?.id,
)

export const selectTaskViewQuoteListAcceptedQuoteQuoteId = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => {
    return acceptedQuote?.id
  },
)

export const selectTaskViewQuoteListAcceptedQuoteCompanyRatingId = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => acceptedQuote?.companyRatingPublicId
)

export const selectTaskViewQuoteListAcceptedQuoteCompanyId = createSelector(
  selectTaskViewQuoteListAcceptedQuote,
  (acceptedQuote) => acceptedQuote?.company?.id
)