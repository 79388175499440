import { CoreState } from "@build-buddy/core";
import { Box, SxProps, Theme } from "@mui/material";
import PaymentReceiptAuth from "./PaymentReceiptAuth";
import PaymentReceiptPaid from "./PaymentReceiptPaid";

export interface PaymentReceiptProps {
  type: "Paid" | "Auth";
  task: CoreState.Task.Task;
  paymentSchedule: CoreState.Financial.PaymentSchedule;
  onDownloadClick(): void;
  sx?: SxProps<Theme>;
}
const PaymentReceipt = (props: PaymentReceiptProps) => {
  const { type, task, paymentSchedule, sx, onDownloadClick } = props;

  return (
    <Box sx={{ ...sx }}>
      {type === "Auth" &&
        <PaymentReceiptAuth />
      }
      {type === "Paid" &&
        <PaymentReceiptPaid
          task={task}
          paymentSchedule={paymentSchedule}
          onDownloadClick={onDownloadClick}
        />
      }
    </Box>
  )
}
export default PaymentReceipt