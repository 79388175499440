import { CoreComponents } from "@build-buddy/core";
import { Lock as LockIcon, CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import { Box, DialogActions, DialogContent, Stack, Typography, useTheme } from "@mui/material";

export interface UnlockedBudgetBannerDialogSuccessProps {
  budgetModifiedDate: string;
  budgetName: string;
  onRedirectClick(): void;
}
const UnlockedBudgetBannerDialogSuccess = (props: UnlockedBudgetBannerDialogSuccessProps) => {
  const { budgetModifiedDate, budgetName, onRedirectClick } = props;
  const theme = useTheme();

  return (
    <>
      <DialogContent
        sx={{ textAlign: "center", py: "0 !important" }}
      >
        <CheckCircleOutlineIcon sx={{ fontSize: 64, color: theme.palette.orange[600], mb: 1 }} />
        <Typography variant="h1" sx={{ mb: 1.5 }}>
          Budget Locked
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 1.5,
          }}
        >
          Congratulations, you have successfully locked your budget. You will now have access to your Budget Tracker.
        </Typography>
        <Stack
          direction="row"
          sx={{
            mt: 2,            
            p: 2,
            textAlign: "left",
            alignItems: "center",
            color: theme.palette.red[600],
            background: theme.palette.yellow[300]
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2">
              {budgetName}
            </Typography>
            <Typography variant="body2">
              <b>DATE LOADED:</b> {budgetModifiedDate}
            </Typography>
          </Box>
          <LockIcon />
        </Stack>
      </DialogContent>
      <DialogActions>
        <CoreComponents.Actions
          submitText="Explore"
          onSubmitClick={() => onRedirectClick()}
        />
      </DialogActions>
    </>
  )
}
export default UnlockedBudgetBannerDialogSuccess