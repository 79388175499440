import { useMode } from "@build-buddy/core";
import { Upload as UploadIcon } from "@mui/icons-material";
import { Box, Button, Fab, Typography, useTheme } from "@mui/material";

type DocumentLibraryUploadButtonProps = {
  onClick(): void;
}
const DocumentLibraryUploadButton = (props: DocumentLibraryUploadButtonProps) => {
  const { onClick } = props;

  const mode = useMode();
  const theme = useTheme()

  // mobile
  if (!mode.md) {
    return (
      <Fab
        color="primary"
        variant="extended"
        sx={{
          position: "fixed",
          bottom: theme.variables.mobileNavbarHeight,
          right: 16,
          mb: 1
        }}
        onClick={() => onClick()}
      >
        <UploadIcon sx={{ mr: 1 }} />
        <Typography variant="body1" sx={{ textTransform: "capitalize" }}>Upload</Typography>
      </Fab>
    );
  }

  // desktop
  return (
    <Box sx={{ flex: 1, textAlign: 'right' }}>
      <Button
        variant="contained"
        onClick={() => onClick()}
      >
        Upload
      </Button>
    </Box>
  )
}
export default DocumentLibraryUploadButton;