import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTask, selectProjectTaskStatus } from "modules/project/_selectors";
import TaskViewRouteDefinitions from "../../TaskViewRouteDefinitions";
import { useTaskViewParams } from "../../_hooks";
import { selectTaskViewQuoteListAcceptedQuote } from "../../_selectors";

interface TaskViewQualityAssuranceChecklistItemAddButtonProps {
  stage?: string;
}
const TaskViewQualityAssuranceChecklistItemAddButton = (props: TaskViewQualityAssuranceChecklistItemAddButtonProps) => {
  const { stage } = props;
  const { projectId, stageId, taskId } = useTaskViewParams();
  const routing = CoreRouting.useRouting();

  // params
  const taskParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, taskParams));
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, quoteListParams));

  // vars
  const showAdd = (projectTask?.task.type === CoreState.Task.TaskType.Request
    || (projectTaskStatus === ProjectTaskStatus.New && !projectTask?.task.parentIsDIY && !acceptedQuote));

  // event handlers
  const handleChecklistItemAdd = (taskStageId?: string) => {
    if (!taskStageId) return;
    const params = { tsid: taskStageId }
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_CHECKLIST_ADD, { params });
  }

  if (!stage || !showAdd) return null;

  return (
    <CoreComponents.AddButton
      label="Add Checklist Item"
      onClick={() => handleChecklistItemAdd(stage)}
      sx={{ mx: -1, my: 1 }}
    />
  )
}

export default TaskViewQualityAssuranceChecklistItemAddButton;