import { Box, Card, CardContent } from "@mui/material";
import { CommonComponents } from "common";
import FinancialsSummaryBudget from "./financials-summary-budget/FinancialsSummaryBudget";
import FinancialsSummaryLegend from "./financials-summary-legend/FinancialsSummaryLegend";
import FinancialsSummaryCompleted from "./financials-summary-completed/FinancialsSummaryCompleted";
import { FinancialsBarGraph, FinancialsLineGraph } from "common/components";
import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useFinancialRoutesParams } from "../FinancialsRoutes";


// TODO: DUMMRY DATA
const monthly = {
  committed: [0, 3000, 0, 1500, 0, 0, 0, 0, 0, 0, 0, 0],
  allocated: [0, 3800, 0, 1900, 0, 0, 0, 0, 0, 0, 0, 0],
  paid: [0, 0, 0, 0, 0, 0, 8000, 0, 0, 0, 0, 0],
  label: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
}

const quarterly = {
  committed: [0, 3000, 0, 0],
  allocated: [0, 2000, 0, 0],
  paid: [0, 0, 0, 3000],
  label: ['Jan-Mar', 'Apr-Jun', 'Jul-Sept', 'Oct-Dec']
}

const fortnightly = {
  committed: [0, 3000, 0, 0],
  allocated: [0, 1000, 0, 0],
  paid: [0, 0, 0, 3000],
  label: ['W1', 'W2', 'W3', 'w4']
}

const barData = {
  '1M': monthly, '3M': quarterly, '2WK': fortnightly
}

const lineData = {
  estimated: [2400, 2900, 3400, 3900, 4400, 4900, 5400],
  forecast: [2300, 2400, 3400, 4400, 4900, 5400, 5900],
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
}

const FinancialsSummary = () => {
  const { projectId } = useFinancialRoutesParams();
  const projectFinancialsByProjectParams = { projectId };

  // queries
  const projectFinancialsByProjectQuery = CoreState.Financial.getProjectFinancialsByProject.useQuery(projectFinancialsByProjectParams);

  // locals
  const isLoading = Boolean(!projectFinancialsByProjectQuery.isUninitialized && projectFinancialsByProjectQuery.isLoading);
  const projectFinancials = useSelector(s => CoreState.Financial.selectProjectFinancialsByProject(s, projectFinancialsByProjectParams));

  return (
    <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: `40% minmax(250px, 20%) auto auto minmax(250px, 20%)`,
        gridTemplateRows: `64px 75px 190px auto`,
        gridTemplateAreas: `
          'budget legend legend legend legend'
          'budget piechart piechart piechart completed'
          'budget piechart piechart piechart savings'
          'budget graph1 graph1 graph2 graph2'
        `
      }}
    >
      <Box
        sx={{
          gridArea: "budget"
        }}
      >
        <FinancialsSummaryBudget />
      </Box>
      <Box
        sx={{
          gridArea: "legend",
        }}
      >
        <FinancialsSummaryLegend />
      </Box>
      <Box
        sx={{
          gridArea: "piechart",
        }}
      >
        <CommonComponents.CostsPieCard
          sx={{ height: "100%" }}
          isLoading={isLoading}
          total={projectFinancials?.budget}
          data={{
            paid: projectFinancials?.paid || 0,
            allocated: projectFinancials?.allocated || 0,
            balance: projectFinancials?.balance || 0,
            due: projectFinancials?.due || 0,
            committed: projectFinancials?.committed || 0
          }}
          hasBoq={Boolean(projectFinancials?.hasBoq)}
          CostsPieLegendProps={{ hideLabels: true }}
        />
      </Box>
      <Box
        sx={{
          gridArea: "completed",
          position: 'relative'
        }}
      >
        <FinancialsSummaryCompleted />
      </Box>
      <Box
        sx={{
          gridArea: "savings"
        }}
      >
        <CommonComponents.SavingsCard
          isLoading={isLoading}
          savings={projectFinancials?.savings || 0}
          hasBoq={Boolean(projectFinancials?.hasBoq)}
          sx={{ height: "100%" }}
        />
      </Box>
      <Box
        sx={{
          gridArea: "graph1",
        }}
      >
        <Card>
          <CardContent sx={{ px: 0, py: '0px !important' }}>
            <FinancialsBarGraph
              data={barData}
            />
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          gridArea: "graph2",
        }}
      >
        <Card>
          <CardContent sx={{ pt: 3, px: 0, pb: '0px !important' }}>
            <FinancialsLineGraph
              data={lineData}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>

  )
}
export default FinancialsSummary;