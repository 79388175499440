import { CoreModules, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useTaskViewParams } from "../../_hooks";
import { TaskViewQualityAssuranceDefectDotProgress } from "../_common";

type TaskViewQualityAssuranceChecklistDotProgressProps = {
  qualityAssuranceItem: CoreState.QualityAssurance.QualityAssurance;
}
const TaskViewQualityAssuranceChecklistDotProgress = (props: TaskViewQualityAssuranceChecklistDotProgressProps) => {
  const { qualityAssuranceItem } = props;

  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const getDefectListByTaskParams = { projectId, stageId, taskId };

  // queries
  CoreState.Defect.getDefectListByTask.useQuery(getDefectListByTaskParams);

  // selectors
  const defects = useSelector((s) => CoreState.Defect.selectDefectListByTask(s, getDefectListByTaskParams));

  return (
    <CoreModules.Task.TaskView.TaskViewQualityAssuranceChecklistDefectsDotProgress 
      defects={defects}
      qualityAssuranceItem={qualityAssuranceItem}
      dotProgress={TaskViewQualityAssuranceDefectDotProgress}
    />
  )
}
export default TaskViewQualityAssuranceChecklistDotProgress;