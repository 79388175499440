import { CoreModules } from "@build-buddy/core";
import { TaskViewOutlineStartEndDate } from "../../task-view-outline";
import { TaskViewTabs } from "../_common";

const TaskViewCertifyContent = () => {
  return (
    <>
      <CoreModules.Task.TaskView.TaskViewOutline>
        <TaskViewOutlineStartEndDate />
      </CoreModules.Task.TaskView.TaskViewOutline>
      <TaskViewTabs />
    </>
  )
}
export default TaskViewCertifyContent;