import { CoreState } from "@build-buddy/core";
import { Warning as WarningIcon } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import UnlockedBudgetBannerDialog from "./UnlockedBudgetBannerDialog";

interface UnlockedBudgetBannerProps {
  budget: CoreState.Financial.Budget;
}
const UnlockedBudgetBanner = (props: UnlockedBudgetBannerProps) => {
  const { budget } = props;
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const canLock = Boolean(budget.version === CoreState.Financial.BudgetVersion.Final);
  const bannerText = canLock ?
    "To access the budget tracking feature, you need to lock in your Final Estimate." :
    "To access all features, you will need to ask your Estimator for a Final Estimate."

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: 4,
          border: `solid 1px ${theme.palette.primary.main}`,
          background: theme.palette.yellow[300],
          p: 2
        }}
      >
        <WarningIcon
          sx={{
            color: theme.palette.primary.main,
            fontSize: "28px",
            mr: 1.5
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            flex: 1
          }}
        >
          {bannerText}
        </Typography>
        {canLock &&
          <Button
            variant="contained"
            onClick={() => setOpen(true)}
          >
            Lock Budget
          </Button>
        }
      </Box>
      <UnlockedBudgetBannerDialog
        budget={budget}
        show={Boolean(open)}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
export default UnlockedBudgetBanner;