import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import TaskViewRouteDefinitions from "../../../TaskViewRouteDefinitions";
import { useTaskViewParams } from "../../../_hooks";
import { selectTaskViewFirstTaskSelectTask } from "../../../_selectors";

const useTaskViewTypesTabsMessages = () => {
  // hooks
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId };

  // selectors
  const selectTask = useSelector((s) => selectTaskViewFirstTaskSelectTask(s, taskParams));
  
  if (!selectTask || selectTask.status !== CoreState.Task.TaskStatus.Completed) return null;

  return {
    ...TaskViewRouteDefinitions.TASK_VIEW_COMPANY_DETAILS,
    label: "Company Details",
    count: 0
  }
}
export default useTaskViewTypesTabsMessages;