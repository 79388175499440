import { Box, Skeleton } from "@mui/material";

const BudgetItemListEstimatedSkeleton = () => {
  return (
    <Box sx={{ py: 2 }}>
      <Skeleton 
        variant="rectangular" 
        width="100%" 
        height="60px"
        sx={{ mb: 2 }} 
      />
      <Skeleton 
        variant="rectangular" 
        width="100%" 
        height="120px"
        sx={{ mb: 2 }} 
      />
      <Skeleton 
        variant="rectangular" 
        width="100%" 
        height="90px"
        sx={{ mb: 2 }} 
      />
      <Skeleton 
        variant="rectangular" 
        width="100%" 
        height="30px"        
      />
    </Box>
  )
}
export default BudgetItemListEstimatedSkeleton;