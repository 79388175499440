import React from "react";
import NormalBar from "./NormalBar";
import StripedBar from "./StripedBar";

interface BarProps {
  color: string | undefined;
  width: number;
  variant?: "striped" | "normal";
}
const Bar = (props: BarProps) => {
  const { color, width, variant } = props;
  if (variant === "striped") return <StripedBar color={color} width={width} />
  return <NormalBar color={color} width={width} />
}
export default Bar;