import { Button } from "@mui/material";
import { useState } from "react";
import TaskViewConfirmOnSiteDialog from "./TaskViewConfirmOnSiteDialog";

const TaskViewConfirmOnSiteActions = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Confirm Dates
      </Button>
      <TaskViewConfirmOnSiteDialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
export default TaskViewConfirmOnSiteActions