import TaskViewLodgeOutline from "./TaskViewLodgeOutline";
import { TaskViewTabs } from "../_common";

const TaskViewLodgeContent = () => {
  return (
    <>
      <TaskViewLodgeOutline />
      <TaskViewTabs />
    </>
  )
}
export default TaskViewLodgeContent;