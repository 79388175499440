import { CoreState } from "@build-buddy/core";
import { Typography } from "@mui/material";
import { Unavailable } from "common/components";
import { useSelector } from "common/state/store";

type SelectionsGuardProps = {
  children: any
}
const SelectionsGuard = (props: SelectionsGuardProps) => {
  const { children } = props;
  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);

  if (isLimited) {
    return (
      <Unavailable>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Our Selections module is where you can explore thousands of products, colours and selections for everything that you need to build and finish your home. 
          Play around with various colour options and see your new home in 3D.
        </Typography>
        <Typography variant="body1">
          To unlock this feature, complete watching all videos and then schedule and attend your Discovery Appointment with a
          member of the Build Buddy Expert Guidance and Support Team.
        </Typography>
      </Unavailable>
    )
  }

  return children;
}
export default SelectionsGuard;