import { CoreState, CoreUtils } from "@build-buddy/core";
import { SxProps, Theme, Typography } from "@mui/material";
import UnlockedBudgetDraftTemplate from "./UnlockedBudgetDraftTemplate";

export interface UnlockedBudgetDraftCostCentreProps {
  costCentreEstimateItem: CoreState.Financial.CostCentreEstimateItem;
  sx?: SxProps<Theme>
}
const UnlockedBudgetDraftCostCentre = (props: UnlockedBudgetDraftCostCentreProps) => {
  const { costCentreEstimateItem, sx } = props;

  const name = costCentreEstimateItem.name;
  const qty = `${costCentreEstimateItem.quantity} ${costCentreEstimateItem.unit.toLowerCase()}`;
  const rate = CoreUtils.Formatter.currency(costCentreEstimateItem.rate);
  const total = CoreUtils.Formatter.currency(costCentreEstimateItem.quantity * costCentreEstimateItem.rate);

  return (
    <>
      <UnlockedBudgetDraftTemplate 
        sx={{ 
          px: 3, 
          py: 2,
          ...sx
        }}
      >
        <Typography
          sx={{ 
            gridArea: "name",
            textAlign: "justify"
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            gridArea: "qty"
          }}
        >
          {qty}
        </Typography>
        <Typography
          sx={{ 
            gridArea: "rate",
            textAlign: "right"
          }}
        >
          {rate}
        </Typography>
        <Typography
          sx={{
            gridArea: "total",
            textAlign: "right"
          }}
        >
          {total}
        </Typography>
      </UnlockedBudgetDraftTemplate>
      {costCentreEstimateItem.items?.map((c, i) => 
        <UnlockedBudgetDraftCostCentre 
          key={i}
          costCentreEstimateItem={c} 
          sx={{
            "& > *:first-of-type": {
              pl: 5
            }
          }}
        />
      )}
    </>
  )
}
export default UnlockedBudgetDraftCostCentre;