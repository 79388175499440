import { Box, Card, CardContent, Stack, useTheme } from "@mui/material";
import { BudgetFilter, BudgetLegend } from "../_components";
import BudgetItemList, { BudgetItemListMode } from "../_components/budget-item-list/BudgetItemList";
import { useState } from "react";

const BudgetTracking = () => {
  const theme = useTheme();
  const [filters, setFilters] = useState({});

  const handleFilterChange = (filters: any) => {
    setFilters(filters);
  }

  return (
    <Card sx={{ overflow: "visible" }}>
      <CardContent sx={{ pt: 1 }}>
        <Stack
          direction="row"
          sx={{
            pt: 1.5,
            pb: 2,
            mx: -2,
            px: 2,
            position: "sticky",
            top: 112,
            zIndex: theme.zIndex.sticky,
            background: theme.palette.common.white,
          }}
        >
          <BudgetLegend sx={{ minWidth: "780px" }} />
          <BudgetFilter
            onChange={(filters) => handleFilterChange(filters)}
          />
        </Stack>

        <Box>
          <BudgetItemList
            defaultMode={BudgetItemListMode.Tracking}
            filters={filters}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default BudgetTracking;