import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { selectTaskViewQuoteListAcceptedQuote } from "../../_selectors";

interface SelectCompanyByPaymentScheduleParams {
  projectId: string;
  stageId: string;
  taskId: string;
}
export const selectActivePaymentSchedule = createSelector(
  [
    (state, params: SelectCompanyByPaymentScheduleParams) => selectTaskViewQuoteListAcceptedQuote(state, { projectId: params.projectId, stageId: params.stageId, taskId: params.taskId })
  ],
  (acceptedQuote) => {
    if (!acceptedQuote) return;
    const rtn = acceptedQuote?.paymentSchedule.filter(x =>
      [
        CoreState.Quote.PaymentScheduleStatus.Requested,
        CoreState.Quote.PaymentScheduleStatus.ReleaseRequested
      ].includes(x.status))
    return rtn
  }
)