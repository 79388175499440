import { CoreComponents, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useBudgetMakePaymentRoutesParams } from "../BudgetMakePaymentRoutes";
import BudgetMakePaymentContentError from "./BudgetMakePaymentContentError";
import BudgetMakePaymentContentForm from "./BudgetMakePaymentContentForm";
import BudgetMakePaymentContentSkeleton from "./BudgetMakePaymentContentSkeleton";

const BudgetMakePaymentContent = () => {
  //  params
  const { projectId, stageId, taskId, paymentScheduleId } = useBudgetMakePaymentRoutesParams();
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const financialDetailsParams = { userId };
  const taskListParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };
  const paymentScheduleParams = { projectId, paymentScheduleId };

  // queries   
  const financialDetailsQuery = CoreState.Wallet.getFinancialDetails.useQuery(financialDetailsParams)
  const taskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskListParams);
  const paymentScheduleQuery = CoreState.Financial.getPaymentSchedule.useQuery(paymentScheduleParams);
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(quoteListParams);

  // locals
  const isLoading = 
    Boolean(!financialDetailsQuery.isUninitialized && financialDetailsQuery.isLoading) ||
    Boolean(!taskListByParentQuery.isUninitialized && taskListByParentQuery.isLoading) ||
    Boolean(!paymentScheduleQuery.isUninitialized && paymentScheduleQuery.isLoading) ||
    Boolean(!quoteListQuery.isUninitialized && quoteListQuery.isLoading);
  const acceptedQuote = useSelector(s => CoreState.Quote.selectQuoteListByLatestQuoteRequestAcceptedQuote(s, quoteListParams));
  const task = useSelector(s => CoreState.Task.selectTaskListByParentCurrentTask(s, taskListParams))
  const paymentSchedule = useSelector(s => CoreState.Financial.selectPaymentSchedule(s, paymentScheduleParams));
  const company = acceptedQuote?.company
  const creditCard = financialDetailsQuery?.data?.creditCardDetails;
 
  return (
    <CoreComponents.PreContent
      isLoading={isLoading}
      loader={<BudgetMakePaymentContentSkeleton />}
      isEmpty={Boolean(!task || !acceptedQuote || !paymentSchedule || !company)}
      empty={<BudgetMakePaymentContentError />}
    >
      <BudgetMakePaymentContentForm       
        company={company as CoreState.Company.Company}
        creditCard={creditCard}
        paymentSchedule={paymentSchedule as CoreState.Financial.PaymentSchedule}
        task={task as CoreState.Task.Task}
      />
    </CoreComponents.PreContent>
  )
}
export default BudgetMakePaymentContent;


