
import { CoreComponents, CoreRouting } from "@build-buddy/core";
import { CalendarToday as CalendarIcon } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ProjectTask } from "modules/project/_models";
import { ProjectTaskActions, ProjectTaskStatus } from "modules/project/_components";
import TaskViewRouteDefinitions from "modules/project/task/task-view/TaskViewRouteDefinitions";

type CalendarDrawerTaskProps = {
  projectTask: ProjectTask;
}
const CalendarDrawerTask = (props: CalendarDrawerTaskProps) => {
  const { projectTask } = props;

  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  // locals
  const isActive = Boolean(!projectTask.task.isHidden);
  const title = projectTask.task.parentName;
  const description =  projectTask.task.name;

  const handleClick = () => {
    if (!isActive) return;
    const params = {
      sid: projectTask.task.stageId,
      tid: projectTask.task.parentId
    }
    routing.go(TaskViewRouteDefinitions.TASK_VIEW, { params })
  }

  return (
    <Box
      sx={{
        px: 2,
        py: 3,
        borderBottom: `solid 1px ${theme.palette.grey[200]}`,
        opacity: isActive ? 1 : 0.75,
        background: isActive ? "inherit" : theme.palette.grey[100],
        "&:last-of-type": {
          borderBottom: "none"
        },
        cursor: isActive ? "pointer" : "no-drop"
      }}
      onClick={() => handleClick()}
    >
      <ProjectTaskStatus
        projectTask={projectTask}
        sx={{ mb: 0.5 }}
      />
      <ProjectTaskActions 
        projectTask={projectTask} 
        sx={{ mb: 1.5 }}
      />
      <Typography
        variant="h3"
        sx={{ mb: 0.5 }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 1, display: "inline-block" }}
      >
        {description}
      </Typography>
      <Stack direction="row" alignItems="center">
        <CalendarIcon sx={{ mr: 0.75 }} />
        <CoreComponents.DateRange
          TypographyProps={{
            variant: "caption",
            sx: {
              fontWeight: "bold",
              color: theme.palette.grey[500],
            }
          }}
          startDate={projectTask.task.startDate}
          endDate={projectTask.task.endDate}
        />
      </Stack>
    </Box>
  );
};

export default CalendarDrawerTask;
