import { CoreComponents } from "@build-buddy/core";
import { Lock as LockIcon, MonetizationOn as MonetizationOnIcon } from "@mui/icons-material";
import { Box, DialogActions, DialogContent, Stack, Typography, useTheme } from "@mui/material";

export interface UnlockedBudgetBannerDialogLockProps {
  budgetModifiedDate: string;
  budgetName: string;
  onLockClick(): void;
}
const UnlockedBudgetBannerDialogLock = (props: UnlockedBudgetBannerDialogLockProps) => {
  const { budgetModifiedDate, budgetName, onLockClick } = props;
  const theme = useTheme();

  return (
    <>
      <DialogContent
        sx={{ textAlign: "center", pt: "0 !important" }}
      >
        <MonetizationOnIcon sx={{ fontSize: 48, color: theme.palette.orange[600], mb: 1 }} />
        <Typography variant="h1" sx={{ mb: 1.5 }}>
          Lock In Your Budget and Start Tracking Your Spending
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 1.5,
            fontWeight: "bold",
            color: theme.palette.red[600],
          }}
        >
          Warning: you cannot undo this action.
        </Typography>
        <Stack
          direction="row"
          sx={{
            mb: 2,
            p: 2,
            textAlign: "left",
            alignItems: "center",
            color: theme.palette.red[600],
            background: theme.palette.yellow[300]
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2">
              {budgetName}
            </Typography>
            <Typography variant="body2">
              <b>DATE LOADED:</b> {budgetModifiedDate}
            </Typography>
          </Box>
          <LockIcon />
        </Stack>
        <Typography variant="body1" sx={{ mb: 1.5 }}>
          You are about to lock this estimate as your budget to track your spending against. Please note that once you have locked your budget,
          you will not be able to load a different estimate.
        </Typography>
        <Typography variant="body1">
          Once locked, if you wish to make any additional changes to your budget, you will need to manually enter them as budget variations.
        </Typography>
      </DialogContent>
      <DialogActions>
        <CoreComponents.Actions
          submitText="Lock Budget"
          onSubmitClick={() => onLockClick()}
        />
      </DialogActions>
    </>
  )
}
export default UnlockedBudgetBannerDialogLock