import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, useTheme } from "@mui/material";

interface BackButtonProps {
  onClick: () => void;
  label: string;
}

const BackButton = (props: BackButtonProps) => {
  // props
  const { onClick, label } = props;

  // hooks
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        background: theme.palette.common.white,
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        onClick={onClick}
        sx={{
          py: 1
        }}
      >
        <IconButton
          size="small"
          sx={{ mx: 1 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Box sx={{ flex: 1 }}>
          {label}
        </Box>
      </Stack>
    </Box>
  )
}

export default BackButton;