import { CoreRouting } from "@build-buddy/core";
import TaskViewRouteDefinitions from "./task-view/TaskViewRouteDefinitions";
import TaskViewEditRouteDefinitions from "./task-view-edit/TaskViewEditRouteDefinitions";
import TaskViewQualityAssuranceRouteDefinitions from "./task-view/task-view-quality-assurance/TaskViewQualityAssuranceRouteDefinitions";

const RootTaskRoutes = [
  TaskViewEditRouteDefinitions.TASK_LIST_EDIT,
  TaskViewRouteDefinitions.TASK_VIEW,
  TaskViewRouteDefinitions.TASK_VIEW_QUOTES_ACCEPT,
  TaskViewRouteDefinitions.TASK_VIEW_QUOTES_VIEW,
  
  TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_ADD,
  TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_VIEW_NOTE_ADD
]

const TaskRoutes = () => {
  return (
    <CoreRouting.Routes routes={RootTaskRoutes} />
  )
}

export default TaskRoutes;