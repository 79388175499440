import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog, DialogContent, Skeleton } from "@mui/material";
import { useEffect } from "react";
import { TaskViewRouteDefinitions } from "../..";
import TaskViewQualityAssuranceChecklistForm from "./TaskViewQualityAssuranceChecklistForm";
import useTaskViewQualityAssuranceChecklistEditParams from "./_hooks/useTaskViewQualityAssuranceCheklistEditParams";

const TaskViewQualityAssuranceChecklistEdit = () => {
  const routing = CoreRouting.useRouting();
  const { projectId, qualityAssuranceId, stageId, taskId } = useTaskViewQualityAssuranceChecklistEditParams();

  //queries
  const getQualityAssuranceItemQuery = CoreState.QualityAssurance.getQualityAssuranceItem.useQuery({ projectId, stageId, qualityAssuranceId, taskId });

  //mutation
  const [updateQualityAssuranceItemAction, updateQualityAssuranceItemQuery] = CoreState.QualityAssurance.updateQualityAssuranceItem.useMutation();

  //event handlers
  const handleClose = () => routing.go(TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE)
  
  const handleSubmit = (values: { description: string }) => {
    if (!qualityAssuranceId) return;

    updateQualityAssuranceItemAction({
      projectId,
      stageId,
      taskId,
      qualityAssuranceId,
      qualityAssuranceItem: {
        ...values,
      }
    })
  }

  useEffect(() => {
    if (!updateQualityAssuranceItemQuery.isSuccess) return;
    handleClose();
  }, [updateQualityAssuranceItemQuery.isLoading]);

  return (
    <Dialog open={true}>
      <CoreComponents.Loader show={updateQualityAssuranceItemQuery.isLoading} />
      <CoreComponents.DialogHeader title="Edit Checklist Item" onClose={handleClose} />
      <DialogContent>
        <CoreComponents.PreContent
          isLoading={getQualityAssuranceItemQuery.isLoading}
          isEmpty={Boolean(!getQualityAssuranceItemQuery.data)}
          loader={<Skeleton variant="rectangular" width="100%" height="200px" />}
          empty={<>EMPTY</>}
        >
          <TaskViewQualityAssuranceChecklistForm
            onSubmit={handleSubmit}
            onClose={handleClose}
            qualityAssurance={getQualityAssuranceItemQuery.data}
          />
        </CoreComponents.PreContent>
      </DialogContent>
    </Dialog>
  );
}

export default TaskViewQualityAssuranceChecklistEdit