import { CoreState } from "@build-buddy/core"
import { createSelector } from "@reduxjs/toolkit"

export const selectAllSubTaskList = createSelector(
  CoreState.Task.selectTaskListByParent,
  (taskList) => {
    return taskList
  }
)

export const selectTaskListNextManageSubTask = createSelector(
  selectAllSubTaskList,
  (taskList) => {
    return taskList?.find((item) => item.type.includes(CoreState.Task.TaskType.Manage));
  }
)

export const selectTaskListNextManageSubTaskName = createSelector(
  selectTaskListNextManageSubTask,
  (subTask) => subTask?.name || ""
)

export const selectTaskListNextManageSubTaskStartDate = createSelector(
  selectTaskListNextManageSubTask,
  (subTask) => subTask?.startDate || ""
)

export const selectTaskListNextManageSubTaskEndDate = createSelector(
  selectTaskListNextManageSubTask,
  (subTask) => subTask?.endDate || ""
)

export const selectTaskListNextAdminOrManageSubTask = createSelector(
  CoreState.Task.selectTaskListByParent,
  (taskList) => {
    return taskList?.find((item) => item.type === CoreState.Task.TaskType.Admin || item.type.includes(CoreState.Task.TaskType.Manage))
  }
)

export const selectTaskListNextManageSubTaskId = createSelector(
  selectTaskListNextManageSubTask,
  (subTask) => subTask?.id
)

export const selectTaskListNextAdminOrManageSubTaskId = createSelector(
  selectTaskListNextAdminOrManageSubTask,
  (subTask) => subTask?.id
)

export const selectTaskListNextAdminOrManageSubTaskName = createSelector(
  selectTaskListNextAdminOrManageSubTask,
  (subTask) => subTask?.name || ""
)

export const selectTaskListNextAdminOrManageSubTaskStartDate = createSelector(
  selectTaskListNextAdminOrManageSubTask,
  (subTask) => subTask?.startDate || ""
)

export const selectTaskListNextAdminOrManageSubTaskEndDate = createSelector(
  selectTaskListNextAdminOrManageSubTask,
  (subTask) => subTask?.endDate || ""
)