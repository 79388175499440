import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { PaymentOptions, PaymentSummary } from "modules/project/_components";
import { BudgetMakePaymentRoutesDefinition } from "../BudgetMakePaymentRoutes";

interface BudgetPaymentSchedulePayContentFormProps {
  creditCard: any;
  company: CoreState.Company.Company;
  paymentSchedule: CoreState.Financial.PaymentSchedule;
  task: CoreState.Task.Task;
}
const BudgetMakePaymentContentForm = (props: BudgetPaymentSchedulePayContentFormProps) => {
  const { creditCard, company, paymentSchedule, task } = props;
  const routing = CoreRouting.useRouting();

  const formik = useFormik({
    initialValues: {
      paymentType: CoreState.Financial.PaymentType.PayTo,
      creditCard: {
        name: creditCard.fullName,
        number: creditCard.number,
        expiry: `${creditCard.expiryMonth}/${creditCard.expiryYear}`,
      },
      payTo: {
        name: company.name,
        address: company.address?.fullAddress,
        bank: `${company.bankDetails?.bsb} ${company.bankDetails?.number}`,
      },
      amount: paymentSchedule.total,
    },
    onSubmit: () => { },
  })

  const handleAllocateClick = () => {
    routing.go(BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_ALLOCATE)
  }

  const handlePayClick = () => {
    routing.go(`${BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_CONFIRM.path}?type=${formik.values.paymentType}`)
  }

  return (
    <>
      <CoreComponents.TaskLabel sx={{ mb: 2 }} label={task.name} />
      <Typography variant="h1" sx={{ mb: 3 }}> Allocate funds for {paymentSchedule.description}</Typography>
      <Grid container columnSpacing={4}>
        <Grid item xs={8}>
          <PaymentOptions
            name="paymentType"
            creditCard={formik.values.creditCard}
            paymentType={formik.values.paymentType}
            payTo={formik.values.payTo}
            amount={formik.values.amount}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <PaymentSummary
            paymentType={formik.values.paymentType}
            paymentSchedule={paymentSchedule}
            onPayClick={() => handlePayClick()}
            onAllocateClick={() => handleAllocateClick()}
          />
        </Grid>
      </Grid>
    </>
  )
}
export default BudgetMakePaymentContentForm;


