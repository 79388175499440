import TaskViewQualityAssuranceChecklist from "./task-view-quality-assurance-checklist/TaskViewQualityAssuranceChecklist";
import TaskViewQualityAssuranceDefects from "./task-view-quality-assurance-defects/TaskViewQualityAssuranceDefects";
import TaskViewQualityAssuranceDefectsGuard from "./task-view-quality-assurance-defects/TaskViewQualityAssuranceDefectsGuard";

const TaskViewQualityAssurance = () => {
  return (
    <>
      <TaskViewQualityAssuranceChecklist />
      <TaskViewQualityAssuranceDefectsGuard>
        <TaskViewQualityAssuranceDefects />
      </TaskViewQualityAssuranceDefectsGuard>
    </>
  )
}
export default TaskViewQualityAssurance;