import { Stack, Typography, LinearProgress } from "@mui/material";

interface ProjectBreakdownItemProps {
  name: string;
  completed: number;
  total: number;
  percent: number
}
const ProjectBreakdownItem = (props: ProjectBreakdownItemProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        mb: 1.75,
        "&:last-of-type": {
          mb: 2
        }
      }}
    >
      <Typography
        variant="body1"
        sx={{ flex: 1 }}
      >
        {props.name}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mr: 1 }}
      >
        <Typography component="span" sx={{ fontWeight: "bold" }}>{props.completed}</Typography>/{props.total}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={props.percent}
        sx={{
          height: "8px",
          borderRadius: 4,
          width: "20%"
        }}
      />
    </Stack>
  )
}
export default ProjectBreakdownItem;