import { Loader } from "@build-buddy/core";
import { Box, Typography } from "@mui/material";

type WizardViewStepContainerStepSummaryProps = {
  isLoading: boolean;
  title: string;
  description: string
}
const WizardViewStepContainerStepSummary = (props: WizardViewStepContainerStepSummaryProps) => {
  const { isLoading, title, description } = props;

  return (
    <>
      <Loader show={isLoading} />
      <Box sx={{
        display: "flex",
        flexDirection: "column"
      }}>
        <Typography variant="h1" sx={{ mb: 0.5 }}> {title} </Typography>
        <Typography variant="caption"> {description} </Typography>
      </Box>
    </>
  )
}

export default WizardViewStepContainerStepSummary;