import { styled } from "@mui/material/styles";

interface OffsetProps {
  space?: number;
}

const Offset = styled("div", {
  shouldForwardProp: (prop) => prop !== "space",
})<OffsetProps>(({ theme, space }) => ({
  ...(theme.mixins.toolbar as any),
  marginBottom: theme.spacing(space || 3),
}));

export default Offset;
