import Dashboard from "./Dashboard"

const DashboardRouteDefinitions = {
  DASHBOARD_ROOT: {
    exact: true,
    path: "/project/:pid/dashboard/",
    component: Dashboard
  },
  DASHBOARD: {
    path: "/project/:pid/dashboard/:filter",
    component: Dashboard
  }
}

export default DashboardRouteDefinitions;