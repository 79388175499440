import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import TaskViewRouteDefinitions from "../../../TaskViewRouteDefinitions";
import { useTaskViewParams } from "../../../_hooks";
import { selectTaskViewFirstTaskSelectTask } from "../../../_selectors";
import { selectActivePaymentSchedule } from "../../../task-view-payments/_selectors/selectActivePaymentSchedule";
import TaskViewPaymentsRouteDefinitions from "../../../task-view-payments/TaskViewPaymentsRouteDefinitions";

const useTaskViewTypesTabsScopeOfWork = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId };

  // selectors
  const selectTask = useSelector((s) => selectTaskViewFirstTaskSelectTask(s, taskParams));
  const activeSchedules = useSelector((s) => selectActivePaymentSchedule(s, taskParams));

  if (!selectTask || selectTask.status !== CoreState.Task.TaskStatus.Completed) return null;

  return {
    ...TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENT_SCHEDULE,
    label: "Payments",
    count: activeSchedules?.length || 0,
  }
}
export default useTaskViewTypesTabsScopeOfWork;