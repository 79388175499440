import { CoreComponents, CoreFeatures } from "@build-buddy/core";
import { Search as SearchIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";

interface BudgetFilterProps {
  onChange(filters: any): void;
}
const BudgetFilter = (props: BudgetFilterProps) => {
  const { onChange } = props;
  const filters = [{
    type: CoreFeatures.Filter.FacetType.Select,
    key: "criteria",
    title: "Filter Budget",
    items: [
      {
        label: "Over Budget",
        value: "OverBudget",
      },
      {
        label: "Under Budget",
        value: "UnderBudget",
      },
      {
        label: "Payments In Progress",
        value: "InProgress",
      },
      {
        label: "Payments Completed",
        value: "Completed",
      },
      {
        label: "Not Started",
        value: "NotStarted",
      },
    ]
  }]

  const [selected, setSelected] = useState({});

  const handleFilterChange = (filters: CoreFeatures.Filter.SelectedFacets) => {
    setSelected({ ...selected, ...filters });
  }

  const handleSearchChange = (val: any) => {
    const obj = { ...selected, "search": val };
    handleFilterChange(obj);
  }

  useEffect(() => {
    if (!Object.keys(selected).length) return;
    onChange(selected);
  }, [selected])

  return (
    <Stack direction="row">
      <CoreFeatures.Filter.Filter
        facets={filters}
        selected={{}}
        onChange={(v) => handleFilterChange(v)}
      />
      <CoreComponents.Textbox
        sx={{ ml: 1, mb: 0 }}
        startAdornment={<SearchIcon sx={{ fontSize: "16px", mr: 1 }} />}
        name=""
        size="small"
        onChange={(e) => handleSearchChange(e.target.value)}
      />
    </Stack>
  )
}
export default BudgetFilter;