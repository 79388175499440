import { Skeleton } from "@mui/material";
import React from "react";

const BudgetItemSkeleton = () => {
  const arr = new Array(20).fill(0);
  return (
    <>
      {arr.map((_, i) => (
        <Skeleton key={i} height="72px" width="100%" />
      ))}
    </>
  )
}
export default BudgetItemSkeleton;