import { CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { useEffect } from "react";
import TaskViewReview from "./TaskViewReview";

const TaskViewReviewAdd = () => {
  //hooks
  const routing = CoreRouting.useRouting();

  //mutations
  const [addRatingByCompanyAction, addRatingByCompanyQuery] = CoreState.Reviews.addRatingByCompany.useMutation();

  const handleClose = () => {
    routing.back();
  }

  useEffect(() => {
    if (!addRatingByCompanyQuery.isSuccess) return;
    handleClose()
  }, [addRatingByCompanyQuery.isSuccess]);

  return (
    <CoreModules.Task.TaskView.TaskViewSubView
      open={true}
      onClose={() => handleClose()}
    >
      <TaskViewReview
        onSubmit={addRatingByCompanyAction}
      />
    </CoreModules.Task.TaskView.TaskViewSubView>
  )
}
export default TaskViewReviewAdd;