import { CoreComponents, CoreRouting } from "@build-buddy/core";
import { Edit as EditIcon } from "@mui/icons-material";
import TaskViewRouteDefinitions from "../../../TaskViewRouteDefinitions";

interface TaskViewQualityAssuranceChecklistActionsEditButtonProps {
  isEditable: boolean;
  showRaiseDefectNaBtn: boolean;
  isCustom: boolean;
  qualityAssuranceItemId: string;
}

const TaskViewQualityAssuranceChecklistActionsEditButton = (props: TaskViewQualityAssuranceChecklistActionsEditButtonProps) => {
  const { isEditable, showRaiseDefectNaBtn, isCustom, qualityAssuranceItemId } = props;
  const routing = CoreRouting.useRouting();

  const handleEdit = () => {
    const params = { cid: qualityAssuranceItemId };
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_CHECKLIST_EDIT, { params })
  }

  if (isEditable || showRaiseDefectNaBtn || !isCustom) return null;

  return <CoreComponents.MoreMenuItem
    text="Edit"
    icon={EditIcon}
    onClick={handleEdit}
  />
}

export default TaskViewQualityAssuranceChecklistActionsEditButton;