import { Route, useRouteMatch } from "react-router-dom";
import BudgetVariation from "./BudgetVariation";
import { useFinancialRoutesParams } from "../../FinancialsRoutes";

export const BudgetVariationRoutesDefinition = {
  BUDGET_VARIATION: {
    path: "/project/:pid/financials/*/:code/item/:itemId/variations",
    component: BudgetVariation
  },
};

// */:code/item/:itemId/variations

// /project/:pid/:code/item/:itemId/variations
// "/project/:pid/financials/*/:code/item/:itemId/variations",
// "/project/:pid/financials/budget-tracking/:code/item/:itemId/variations",
// "/project/:pid/financials/estimated-budget/:code/item/:itemId/variations",

export const useBudgetVariationRoutesDefinitionParams = () => {
  // get params from parent route
  const { projectId, stageId } = useFinancialRoutesParams();
  
  // match route and get params
  const match = useRouteMatch<any>([
    BudgetVariationRoutesDefinition.BUDGET_VARIATION.path,
  ]);
  const { code, itemId } = match?.params;

  return {
    projectId,
    stageId,
    costCentreCode: code,
    itemId
  }
}

export const BudgetVariationRoutes = () => {
  return (
    <>
      <Route
        path={BudgetVariationRoutesDefinition.BUDGET_VARIATION.path}
        component={BudgetVariationRoutesDefinition.BUDGET_VARIATION.component}
      />    
    </>
  );
};