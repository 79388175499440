import { FC } from "react";
import { Box } from "@mui/material";

interface DescriptionProps {
  data: any;
  fields: any;
}
const Description: FC<DescriptionProps> = (props: DescriptionProps) => { 
  if(!props.data || !props.data.length) return null;

  const html = props.data[0].html;

  if(!html) return null;

  return (
    <Box dangerouslySetInnerHTML={{ __html: html }}></Box>
  )
}
export default Description;