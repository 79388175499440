import React from "react";
import { FormOptionList, FormCarousel, FormCarouselCalc, FormAddress, FormDimension } from "./form";
import { MoreDetails, CreateProject, Description, Title } from "./static";

const kvp: any = {
  "OptionList": FormOptionList,
  "Carousel": FormCarousel,
  "CarouselCalc": FormCarouselCalc,
  "ImageList": FormCarousel,
  "ImageButtons": FormCarousel,
  "Address": FormAddress,
  "Dimensions": FormDimension,
  "MoreInfo": MoreDetails,
  "TitleBlock": Title,
  "Description": Description,
  "CreateProj": CreateProject,
  "CreatePre": CreateProject,
}

type WizardComponentProps = {
  type: string;
  data: any;
  fields: any;
}
const WizardComponent = (props: WizardComponentProps) => {
  const { type, data, fields } = props;
  const component = kvp[type];

  if (!component) return null;

  return React.createElement(component, {
    data: data,
    fields: fields,
  })
}

export default WizardComponent;