import { CoreComponents } from "@build-buddy/core";
import { Map } from "@build-buddy/core/dist/components";
import { Box, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { FC, useEffect, useState } from "react";

const defaultMap = {
  zoom: 8,
  longitude: 150.9963723,
  latitude: -33.8156049
}
interface FormAddressAutocompleteProps {
  data: any;
  fields: any;
  setManualAddress: (val: boolean) => void;
}

function getStreetAddress(streetNumber = '', street = '') {
  return streetNumber || street ? `${streetNumber} ${street}`.trim() : undefined;
}

const FormAddressAutocomplete: FC<FormAddressAutocompleteProps> = (props: FormAddressAutocompleteProps) => {
  const [values, setValues] = useState<any>({});

  const formik = useFormikContext<any>();
  const theme = useTheme();

  useEffect(() => {
    let vs: any = {};
    props.fields.forEach((f: any) => {
      vs[f.inputRef] = f.inputId;
    });
    formik.resetForm();
    setValues(vs);
  }, []);

  const handlePlaceChange = (place: any) => {
    formik.setValues({
      [values.address]: place.search,
      [values.street1]: getStreetAddress(place.streetNumber, place.street),
      [values.street2]: place.street2,
      [values.country]: place.country,
      [values.state]: place.state,
      [values.longitude]: place.longitude,
      [values.latitude]: place.latitude,
      [values.suburb]: place.suburb,
      [values.postcode]: place.postcode,
    });
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(values.address, e.target.value)
  }

  if (!Object.keys(values).length) return null;

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ zIndex: 100, width: "100%", mt: -1 }}>
        <CoreComponents.AutocompletePlace
          sx={{ mt: 2 }}
          name="search"
          value={formik.values[values.address]}
          error={formik.errors[values.address] as string}
          onPlaceChange={handlePlaceChange}
          onTextChange={handleTextChange}
          InputProps={{
            sx: {
              borderRadius: 5
            }
          }}
          label="Site Address*"
        />
      </Box>
      <Map
        sx={{ py: 0.5, px: 1 }}
        longitude={formik.values[values.longitude] || defaultMap.longitude}
        latitude={formik.values[values.latitude] || defaultMap.latitude}
        zoom={formik.values[values.longitude] ? 18 : defaultMap.zoom}
        showMarker={Boolean(formik.values[values.longitude])}
        height={350}
        borderRadius={theme.spacing(1)}
      />
      <Typography
        variant="subtitle2"
        sx={{ mt: 1 }}
      >
        Trouble finding your address? &nbsp;
        <Typography
          color={'primary'}
          variant="subtitle2"
          sx={{ cursor: 'pointer' }}
          onClick={() => { props.setManualAddress(true) }}
          component={"span"}
        >
          Enter manually
        </Typography>
      </Typography>
    </Box>
  )
}
export default FormAddressAutocomplete;
