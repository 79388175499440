import * as Yup from 'yup';

const TaskViewBookingDateTimePickerValidation = Yup.object().shape({
  start: Yup.string()
    .required('Start date is required'),
  time: Yup.string()
    .required('Time slot is required')
});

export default TaskViewBookingDateTimePickerValidation;
