import { Box, Skeleton } from "@mui/material";

const BudgetItemListTrackingSkeleton = () => {
  return (
    <Box sx={{ py: 2 }}>
      <Skeleton
        variant="rectangular"
        height="30px"
        sx={{ mb: 2 }}
      />
      <Skeleton
        variant="rectangular"
        height="30px"
      />
    </Box>
  )
}
export default BudgetItemListTrackingSkeleton;