import { CoreComponents, Formatter, useMode } from "@build-buddy/core";
import { Box, Button } from "@mui/material";
import { isAfter, isBefore } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from "react";
import "./GanttChart.scss";
import GanttChartSkeleton from "./GanttChartSkeleton";
import useGanttChartData, { GanttTask } from "./useGanttChartData";

import {
  Gantt,
  GanttExpandChangeEvent,
  GanttRowClickEvent,
  GanttWeekView,
} from "@progress/kendo-react-gantt";
import GanttChartDialog from "./GanttChartDialog";
import GanttChartGuard from "./GanttChartGuard";

const GanttChart = () => {
  const mode = useMode();
  const columns = [
    {
      field: "title",
      title: "Tasks",
      expandable: true,
      width: 300,
    },
  ];

  const ganttStyle = {
    height: mode.lg ? "88vh" : undefined,
    width: "100%",
  };

  // States
  const [currentTask, setCurrentTask] = useState<GanttTask | undefined>()
  const [parentNodes, setParentNodes] = useState<Array<HTMLElement>>([])
  const [nodes, setNodes] = useState<Array<HTMLElement>>([])
  const [showPredecessors, setShowPredecessors] = useState(false)

  const [expanded, setExpanded] = useState<Array<string>>([]);
  const [columnsState, setColumnsState] = useState(columns);
  const { tasks, tasksDependencies, isLoading } = useGanttChartData(expanded);
  const onColumnResize = useCallback((event: any) => event.end && setColumnsState(event.columns), [setColumnsState]);

  // Find the taks that falls under current time.
  const findCurrentTasks = (data: any, currentDate: Date) => {
    const tasks: any = [];
    data?.forEach((stage: any) => {
      stage?.children?.forEach((task: GanttTask) => {
        if (isAfter(task.start, currentDate) || isBefore(currentDate, task.end)) {
          tasks.push(task);
        }
      });
    });

    return tasks;
  }

  const currentDate = new Date();
  const currentTasks = findCurrentTasks(tasks, currentDate);
  const stageToExpand = useMemo(() => tasks?.find(task => task.children.includes(currentTasks[0])), [currentTasks])

  const handleExpand = (id: string, expand: boolean) => {
    if (expand) setExpanded(expanded.filter((val: string) => val !== id));
    else setExpanded(expanded.concat(id));
  };

  const handleExpandChange = (e: GanttExpandChangeEvent) => {
    handleExpand(e.dataItem.id, e.dataItem.isExpanded);
  };

  const handleRowClick = useCallback((e: GanttRowClickEvent) => {
    if (e?.level?.length === 1) {
      handleExpand(e.dataItem.id, e.dataItem.isExpanded);
      return
    }
    const clickedItem = [...nodes].find((y: any) => (y?.innerText?.trim() === e.dataItem?.title?.trim()))
    if (!clickedItem) return;
    clickedItem.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }, [nodes]);


  const handleRecentreButtonClick = () => {
    if (stageToExpand) {
      if (!expanded.includes(stageToExpand.id)) {
        setCurrentTask(undefined)
        setExpanded(prev => [...prev, stageToExpand.id])
      }
    }
    handleRowClick({ dataItem: currentTasks[0] } as any);
  }

  useEffect(() => {
    const parentNodes = document.querySelectorAll('.k-task-summary');
    const nodes = document.querySelectorAll('.k-task-content');
    setParentNodes(parentNodes as any)
    setNodes(nodes as any);
  }, [tasks])

  useEffect(() => {
    if (showPredecessors || currentTask) return;
    if (expanded.length > 1) return
    if (currentTasks.length === 0 && nodes.length === 0) return
    if (stageToExpand && expanded.includes(stageToExpand?.id)) {
      handleRecentreButtonClick()
    }
  }, [expanded, currentTasks]);

  useEffect(() => {
    nodes.forEach(node => {
      const clickedTask = tasks?.reduce((found: any, task: any) => {
        if (found?.percentComplete === 1) {
          const prevSibling = node.previousElementSibling;
          prevSibling?.classList.add('completed')
        }
        if (found) return found;
        return task.children.find((child: GanttTask) => child.title.trim() === node?.innerText.trim());
      }, null);

      node.addEventListener('click', () => {
        if (clickedTask) {
          setCurrentTask(clickedTask)
          setShowPredecessors(true)
        }
      })
    })
  }, [nodes])

  useEffect(() => {
    parentNodes.forEach((node, i) => {
      if (node) {
        const customText = document.createElement('div');
        customText.innerText = `PROJECT STAGE: ${tasks?.[i]?.title}`;
        customText.classList.add('k-task-content')
        if (tasks?.[i]?.percentComplete !== 1) {
          node.classList.add('remove-success-color')
        } else {
          node.classList.remove('remove-success-color')
        }
        node.appendChild(customText)
      }
    })
  }, [parentNodes]);

  return (
    <GanttChartGuard>
      <CoreComponents.PreContent
        isLoading={isLoading}
        loader={<GanttChartSkeleton />}
        isEmpty={!tasks?.length}
        empty={<CoreComponents.EmptyContent />}
      >
        <Box sx={{ m: -3, mt: -2, ml: { xs: 0, md: -3 }, position: 'relative' }}>
          <GanttChartDialog
            currentTask={currentTask}
            show={showPredecessors}
            onClose={() => setShowPredecessors(false)}
          />
          {/* Button to scroll to task in Present Date */}
          <Button
            variant="contained"
            size={`${mode.isMobile ? 'small' : 'large'}`}
            sx={{
              position: 'fixed',
              zIndex: '300',
              left: '50%',
              transform: 'translateX(-50%)',
              bottom: { xs: '5rem', md: '3rem' }
            }}
            onClick={handleRecentreButtonClick}
          >
            Recentre
          </Button>
          <Gantt
            resizable
            onColumnResize={onColumnResize}
            style={ganttStyle}
            taskData={tasks}
            dependencyData={tasksDependencies}
            columns={columnsState}
            onExpandChange={handleExpandChange}
            onRowClick={(e) => handleRowClick(e)}
          >
            <GanttWeekView
              slotWidth={mode.isMobile ? 60 : 100}
              timelineHeaderCell={(props) => {
                const kvpValue: any = {
                  day: Formatter.date(props.range.start, "E"),
                  week: `${Formatter.date(props.range.start)} - ${Formatter.date(props.range.end)}`,
                };
                return <>{kvpValue[props.type] || props.text}</>;
              }}
            />
          </Gantt>
        </Box>
      </CoreComponents.PreContent>
    </GanttChartGuard>
  );
};
export default GanttChart;
