import { CoreModules, CoreState } from "@build-buddy/core";
import TaskViewVariationActions from "./TaskViewVariationActions";
import { useTaskViewParams } from "../_hooks";
import { useSelector } from "common/state/store";

const TaskViewVariationsList = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();
  const variationListParams = { projectId, stageId, taskId };

  // queries
  const getTaskVariationsQuery = CoreState.Variation.getProjectTaskVariationList.useQuery(variationListParams);

  // selectors
  const variationList = useSelector((s) => CoreState.Variation.selectVariationListByProject(s, variationListParams));
  const isLoading = getTaskVariationsQuery.isLoading;

  return (
    <>
      <CoreModules.Task.TaskView.TaskViewVariations
        variations={variationList}
        isLoading={isLoading}
        actions={TaskViewVariationActions}
      />
    </>
  );
}

export default TaskViewVariationsList;