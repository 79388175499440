import { CoreComponents, FormikTextbox } from "@build-buddy/core";
import { Box, Typography } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { FC, useEffect, useState } from "react";

interface FormAddressManualProps {
  data: any;
  fields: any;
  setManualAddress: (val: boolean) => void;
}

const FormAddressManual: FC<FormAddressManualProps> = (props: FormAddressManualProps) => {
  const [values, setValues] = useState<any>({});
  const formik = useFormikContext<any>();

  useEffect(() => {
    let vs: any = {};
    props.fields.forEach((f: any) => {
      vs[f.inputRef] = f.inputId;
    });
    formik.resetForm();
    setValues(vs);
  }, []);

  const handlePlaceChange = (place: any) => {
    formik.setValues({
      ...(formik.values ?? {}),
      [values.address]: place.search,
      [values.country]: place.country,
      [values.state]: place.state,
      [values.longitude]: place.longitude,
      [values.latitude]: place.latitude,
      [values.suburb]: place.suburb,
      [values.postcode]: place.postcode,
    });
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {   
    formik.setFieldValue(values.address, e.target.value);
    formik.setFieldTouched(values.address, true);
  }

  if (!Object.keys(values).length) return null;

  return (
    <Box sx={{ position: "relative" }}>
      <FormikTextbox 
        name={values.street1}
        fullWidth
        label="Street Address*"  
      />
      <CoreComponents.AutocompletePlace 
        onPlaceChange={(place) => handlePlaceChange(place)}
        onTextChange={(e: any) => handleTextChange(e)}  
        label="Suburb / Postcode *"
        value={formik.values.address}
        name="address"
        placeHolder="Search for a suburb or postcode"
        error={getIn(formik.touched, values.address) && getIn(formik.errors, values.address)}
        types={["postal_code", "locality"]}
      />
      <Typography variant="subtitle2">
        Want to see your address faster? &nbsp;
        <Typography
          color={'primary'}
          variant="subtitle2"
          sx={{ cursor: 'pointer', mt: 0.4 }}
          onClick={() => { props.setManualAddress(false) }}
          component={"span"}
        >
          Find your address
        </Typography>
      </Typography>
    </Box>
  )
}
export default FormAddressManual;
