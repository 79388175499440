import { CoreState } from "@build-buddy/core";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface InitialiseParams {
  userId: string;
}
const initialise = createAsyncThunk("app/initialise",
  async (params: InitialiseParams, { getState, rejectWithValue, dispatch }) => {

    // load the user
    const userId = params.userId;
    await dispatch(CoreState.User.getUser.initiate({ userId }));

    // load invites, we just load this initially to help redirect users when onboarding
    await dispatch(CoreState.Membership.getInviteListByUser.initiate({ userId: params.userId }));

    const getUserProjectListResp = await dispatch(CoreState.Project.getUserProjectList.initiate());

    // If projectId is already present in url we use that otherwise default to the 1st companyId
    const urlSplit = window.location.pathname.split('/');
    const prevProjectId = (urlSplit.length > 2) ? urlSplit[2] : undefined;
    const isValidProjectId = getUserProjectListResp.data?.find((project) => project.id === prevProjectId);
    const projectId = isValidProjectId ? prevProjectId : getUserProjectListResp.data?.[0]?.id;

    return { projectId }
  }
);

export default initialise;
