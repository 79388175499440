import { CoreState } from "@build-buddy/core";
import { Actions } from "@build-buddy/core/dist/components";
import { useSelector } from "common/state/store";
import { Formik } from "formik";
import { FC } from "react";
import { useParams } from "react-router-dom";
import WizardViewStepContainerStep from "../wizard-view-step-container/WizardViewStepContainerStep";
import WizardViewStepContainerStepContent from "../wizard-view-step-container/WizardViewStepContainerStepContent";
import WizardViewStepContainerStepSummary from "../wizard-view-step-container/WizardViewStepContainerStepSummary";
import buildWizardValidationSchema from "./buildWizardValidationSchema";

const mapFormikData = (values: any) => {
  const rtn: any = {
    values: {},
    validations: {}
  };
  if (!values) return rtn;
  values.forEach((v: any) => {
    rtn.values[v.inputId] = v.value || "";
    rtn.validations[v.inputId] = v.validations
  });
  return rtn;
}

interface WizardViewMilestoneStepProps {
  step: any;
  milestone: any;
  currentIndex: number;
}
const WizardViewMilestoneStep: FC<WizardViewMilestoneStepProps> = (props: WizardViewMilestoneStepProps) => {
  const { step, milestone, currentIndex } = props;

  // selectors
  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);
  
  // params
  const { wid } = useParams<any>();
  const getWizardStepQueryParams = {
    wizardId: wid,
    userId: currentUserId,
    milestoneKey: milestone.key,
    stepKey: step.key
  };

  // mutations
  const [completeWizardStepAction, completeWizardStepQuery] = CoreState.Wizard.completeWizardStep.useMutation();

  // locals
  const getWizardStepQuery = useSelector((s) => CoreState.Wizard.getWizardStep.select(getWizardStepQueryParams)(s));
  
  const title = step.title;
  const description = step.answer || step.description;
  
  const components: any = step.components;

  const isChanging = Boolean(!getWizardStepQuery.isUninitialized && getWizardStepQuery.isLoading);
  const isSaving = Boolean(!completeWizardStepQuery.isUninitialized && completeWizardStepQuery.isLoading);

  // we need to create a root values object, loop through all components and get their values
  let values: any = {};
  let validations: any = {};

  step.components.forEach((c: any) => {
    const formikData = mapFormikData(c.fields);
    values = { ...values, ...formikData.values };
    validations = { ...validations, ...formikData.validations };
  });

  // events
  const handleSubmit = (values: any) => {
    if (!currentUserId || !milestone || !step) return;
    const payload = {
      wizardId: wid,
      userId: currentUserId,
      milestoneKey: milestone.key,
      stepKey: step.key,
      inputs: values
    }
    completeWizardStepAction(payload);
  };

  if (!step) return null;

  // TODO: hardcoded here, but maybe find a better way later
  const showActions = components.filter((x: any) => x.type === "CreateProj" || x.type === "CreatePre").length <= 0 && step.selected;
  const footerComponent = showActions ? <Actions /> : <></>
  
  return (
    <Formik
      initialValues={{
        ...values
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={buildWizardValidationSchema(validations)}
    >
      {formikProps => (
        <form onSubmit={formikProps.handleSubmit}>
          <WizardViewStepContainerStep
            stepIndex={currentIndex}
            expanded={step.selected}
            summaryComponent={
              <WizardViewStepContainerStepSummary
                isLoading={isChanging}
                title={title}
                description={description}
              />
            }
            contentComponent={
              <WizardViewStepContainerStepContent
                isLoading={isSaving}
                components={components}
              />
            }
            footerComponent={footerComponent}
          />
        </form>
      )}
    </Formik>
  )
}

export default WizardViewMilestoneStep;