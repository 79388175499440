import { Box, useTheme } from "@mui/material";
import React from "react";

interface NormalBarProps {
  color: string | undefined;
  width: number;
}
const NormalBar = (props: NormalBarProps) => {
  const { color, width } = props;
  const theme = useTheme();
  const c = color || theme.palette.grey[600];

  return (
    <Box
      sx={{
        background: `${c}`,
        height: "8px",
        width: `${width}%`,
        border: `solid 1.5px ${c}`,
      }}
    />
  )
}
export default NormalBar;