import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { selectCurrentProjectId } from "./selectAppContext";

const selectIsCurrentUserOwner = createSelector(
  [selectCurrentProjectId, CoreState.User.selectCurrentUserProjects],
  (currentContextProjectId, ownerProjects) => Boolean(ownerProjects?.find(project => project.projectId === currentContextProjectId))
)

export default selectIsCurrentUserOwner;




