import { CoreState } from "@build-buddy/core";
import { Box, Typography } from "@mui/material";
import { ProjectTaskStatusPredecessors } from "modules/project/_components";

interface TaskViewNextTaskDialogHeaderProps {
  title: string;
  task?: CoreState.Task.Task
}

const TaskViewNextTaskDialogHeader = (props: TaskViewNextTaskDialogHeaderProps) => {
  const { title, task } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 1,
        flex: 1
      }}
    >
      {title}
      {task && <ProjectTaskStatusPredecessors task={task} />}
    </Box>
  );
}

export default TaskViewNextTaskDialogHeader