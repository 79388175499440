import { CoreComponents, CoreState } from "@build-buddy/core";
import { Box, Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { useState } from "react";
import { useTaskViewParams } from "../../../_hooks";
import { selectTaskViewQuoteListAcceptedQuoteCompanyId } from "../../../_selectors";
import { addTaskViewQualityAssuranceDefectsNoteItem } from "../_actions";

type TaskViewQualityAssuranceDefectsActionsInReviewProps = {
  defect: CoreState.Defect.Defect;
}
// this is declared due to the rule of hooks, since its a chain, if any hooks gets rendered and the number changes, it causes a violation
const TaskViewQualityAssuranceDefectsActionsInReview = (props: TaskViewQualityAssuranceDefectsActionsInReviewProps) => {
  const { defect } = props;
  if (!defect) return null;
  if (defect.status !== CoreState.Defect.DefectStatus.InReview) return null;
  return <InnerTaskViewQualityAssuranceDefectsActionsInReview defect={defect} />
}
const InnerTaskViewQualityAssuranceDefectsActionsInReview = (props: TaskViewQualityAssuranceDefectsActionsInReviewProps) => {
  const { defect } = props;

  // params
  const { projectId, stageId, taskId } = useTaskViewParams();

  // mutations
  const [updateTaskDefectAction, updateTaskDefectQuery] = CoreState.Defect.updateDefect.useMutation();
  const [addTaskViewQualityAssuranceDefectsNoteItemAction, addTaskViewQualityAssuranceDefectsNoteItemQuery] = addTaskViewQualityAssuranceDefectsNoteItem.useMutation();

  // selectors 
  const companyId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteCompanyId(s, { projectId, stageId, taskId }));

  // locals
  const [open, setOpen] = useState(false);
  const isAcceptSaving = updateTaskDefectQuery.isLoading;
  const isRejectSaving = isAcceptSaving || addTaskViewQualityAssuranceDefectsNoteItemQuery.isLoading;
  const isSuccess = updateTaskDefectQuery.isSuccess && addTaskViewQualityAssuranceDefectsNoteItemQuery.isSuccess;

  // event handlers 
  const handlePopup = (e: React.SyntheticEvent | undefined, open: boolean) => {
    e?.stopPropagation();
    setOpen(open)
  }

  const handleSubmit = async (values: any) => {
    if(!companyId) return;
    
    await updateTaskDefectAction({
      projectId,
      stageId,
      taskId,
      defectId: defect.id,
      defect: {
        status: CoreState.Defect.DefectStatus.Rejected,
      },
    })
    addTaskViewQualityAssuranceDefectsNoteItemAction({
      projectId,
      stageId,
      taskId,
      defectId: defect.id,
      noteThread: values.noteThread,
      docs: values.docs,
      toUserId: companyId,
    })
  }

  const handleApprove = (e: React.SyntheticEvent,) => {
    e.stopPropagation();
    updateTaskDefectAction({
      projectId,
      stageId,
      taskId,
      defectId: defect.id,
      defect: {
        status: CoreState.Defect.DefectStatus.Completed,
      },
    })
  }

  return (
    <>
      <CoreComponents.Loader show={isAcceptSaving} />
      <Box sx={{ textAlign: "right" }} >
        <Button variant="outlined" color="neutral" sx={{ mr: 1 }} onClick={(e) => handlePopup(e, true)}> Reject </Button>
        <Button variant="contained" onClick={handleApprove}> Approve </Button>
      </Box>
      <CoreComponents.AddNoteThread
        isSaving={isRejectSaving}
        isSuccess={isSuccess}
        isOpen={open}
        fields={{
          header: "Reject work",
          note: "Reason for rejecting work",
          files: "Upload supporting documents"
        }}
        onClose={() => handlePopup(undefined, false)}
        onAdd={(values) => handleSubmit(values)}
      />
    </>
  )
}
export default TaskViewQualityAssuranceDefectsActionsInReview;