import TaskViewDescription from "./TaskViewDescription";

const TaskViewDescriptionRouteDefinitions = {
  TASK_VIEW_DESCRIPTION: {
    label: "Description",
    path: "/*/stage/:sid/task/:tid/description",
    component: TaskViewDescription
  },
}

export default TaskViewDescriptionRouteDefinitions;