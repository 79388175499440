import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FinancialRoutesDefinition } from "../FinancialsRoutes";
import { useBudgetTrackingItemRoutesParams } from "./BudgetTrackingItemRoutes";
import { useEffect } from "react";
import { useSelector } from "common/state/store";

const BudgetTrackingItem = () => {
  const routing = CoreRouting.useRouting();

  const { projectId, costCentreCode } = useBudgetTrackingItemRoutesParams();
  const getCostCentreEstimateByParams = { projectId, costCentreCode };

  // queries
  const getCostCentreEstimateByCodeQuery = CoreState.Financial.getCostCentreEstimateByCode.useQuery(getCostCentreEstimateByParams);

  // mutations
  const [upsertCostCentreEstimateItemByCodeAction, upsertCostCentreEstimateItemByCodeQuery] = CoreState.Financial.upsertCostCentreEstimateItemByCode.useMutation();

  // locals
  const costCentreEstimateByCode = useSelector(s => CoreState.Financial.selectCostCentreEstimateByCode(s, getCostCentreEstimateByParams));
  const isLoading = Boolean(!getCostCentreEstimateByCodeQuery.isUninitialized && getCostCentreEstimateByCodeQuery.isLoading); 
  const isSaving = Boolean(!upsertCostCentreEstimateItemByCodeQuery.isUninitialized && upsertCostCentreEstimateItemByCodeQuery.isLoading);
  const isSuccess = Boolean(!upsertCostCentreEstimateItemByCodeQuery.isUninitialized && upsertCostCentreEstimateItemByCodeQuery.isSuccess);
  const formik = useFormik({
    initialValues: {
      description: "",
      amount: 0,
      quantity: 1,
      notes: "",
    },
    onSubmit: (values) => {
      const obj = {
        name: values.description,
        quantity: Number(values.quantity),
        rate: Number(values.amount),
        final: Number(values.amount * values.quantity),
        unit: "ea",
        budget: 0,   
        notes: values.notes,     
      }
      upsertCostCentreEstimateItemByCodeAction({
        projectId,
        costCentreCode,
        costCentreEstimateItem: obj
      })
    }
  })

  const handleBack = () => {
    routing.go(FinancialRoutesDefinition.ESTIMATED_BUDGET)
  }

  useEffect(() => {
    if(!isSuccess) return;
    handleBack();
  }, [isSuccess])

  return (
    <Dialog open={true}>
      <CoreComponents.Loader show={isSaving} />
      <CoreComponents.DialogHeader
        title="Add New Budget Item"
        onClose={() => handleBack()}
      />
      <DialogContent>
        <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2.5 }}> 
          {costCentreEstimateByCode?.costCentre.code} - {costCentreEstimateByCode?.costCentre.name} 
        </Typography>
        <CoreComponents.FormikTextbox
          formik={formik}
          label="Description"
          name="description"
          fullWidth
        />
        <CoreComponents.FormikTextbox
          formik={formik}
          label="Amount"
          name="amount"
          fullWidth
        />
        <CoreComponents.FormikHtmlEditor
          formik={formik}
          label="Notes"
          name="notes"
        />
      </DialogContent>
      <DialogActions>
        <CoreComponents.Actions
          submitText="Save"
          onCancelClick={() => handleBack()}
          onSubmitClick={() => formik.handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  )
}

export default BudgetTrackingItem;