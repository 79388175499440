import { FC } from "react";
import { ReactSVG } from "react-svg";
import { Box } from "@mui/material";

interface DimensionSvgProps {
  design: any;
  dimensions: any;
}
const DimensionSvg: FC<DimensionSvgProps> = (props: DimensionSvgProps) => { 
  const encoded = props.design.replace(/\#/g, "%23");
  const dataUri = `data:image/svg+xml;utf8,${encoded}`;

  if(!props.design || !props.dimensions) return null;

  return (
    <Box sx={{ textAlign: "center" }}>
      <ReactSVG 
        src={dataUri}
        beforeInjection={(svg) => {
          svg.setAttribute("style", "max-width: 600px; max-height: 500px; margin: 0 auto;")
        }}
        afterInjection={(err, svg) => {
          if(!svg) return;
          Object.keys(props.dimensions).forEach((key: string) => {
            const c: any = svg.getElementById(props.dimensions[key].label);
            if(!c) return;
            c.setAttribute("fill", props.dimensions[key].color);
            c.setAttribute("r", 9);
          });
        }}
      />
    </Box>
  )
}

export default DimensionSvg;