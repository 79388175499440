import { CoreUtils } from "@build-buddy/core";
import { Layout, Text } from "@progress/kendo-drawing";
import { Circle as CircleGeometry } from "@progress/kendo-drawing/geometry";
import { Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, RenderEvent, SeriesVisualArgs } from "@progress/kendo-react-charts";
import "./CostsPieChart.scss";
import useCostsPiechart from "./useCostsPieChart";

let center: any;
let radius: any;

const visualHandler = (e: SeriesVisualArgs) => {
  center = e.center;
  radius = e.innerRadius;
  return e.createVisual();
};
const onRender = (e: RenderEvent, sizes: any, total: number, boqItemsNotUploaded: boolean) => {
  if (!e.target.surface) return;

  const circleGeometry = new CircleGeometry(center, radius);
  const bbox = circleGeometry.bbox();

  const heading = new Text(
    CoreUtils.Formatter.currency(total) || "$0", [0, 0], {
    font: `bold ${sizes.price}px Poppins,Roboto`,
  });

  const forecast = new Text(`${boqItemsNotUploaded ? "Costs to date" : "Forecast total"}`, [0, 0], {
    font: `bold ${sizes.forecast}px Poppins,Roboto`,
    fill: {
      color: "#687077"
    }
  });

  const layout = new Layout(bbox, {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    spacing: 100,
  });

  layout.append(heading);
  layout.append(forecast);
  layout.reflow();

  e.target.surface.draw(layout);
};

interface CostsPieChartProps {
  data?: Array<{
    key: string;
    value: number | undefined;
    color: string;
  }>;
  hasFinancials?: boolean;
  total?: number;
}
const CostsPieChart = (props: CostsPieChartProps) => {
  const {
    // defaults
    hasFinancials = false,
    data = [{ key: "blank", value: 1, color: "#E3E6E8" }],
    total = 0,
  } = props;

  const sizes = useCostsPiechart();

  return (
    <Chart
      onRender={(e) => onRender(e, sizes, total, hasFinancials)}
      transitions={false}
    >
      <ChartArea
        height={sizes.height}
      />
      <ChartSeries>
        <ChartSeriesItem
          padding={0}
          type="donut"
          holeSize={sizes.hole}
          data={data}
          categoryField="key"
          field="value"
          visual={visualHandler}
        />
      </ChartSeries>
      <ChartLegend visible={false} />
    </Chart>
  )
}
export default CostsPieChart;