import { CoreState } from "@build-buddy/core";
import { ProjectTaskStatus } from "modules/project/_models";

const transformProjectTaskStatusDefault = (task: CoreState.Task.Task) => {
  const statusKvp: any = {
    [CoreState.Task.TaskStatus.Unknown]: ProjectTaskStatus.NA,
    [CoreState.Task.TaskStatus.New]: ProjectTaskStatus.New,
    [CoreState.Task.TaskStatus.QuotesRequested]: ProjectTaskStatus.QuotesRequested,
    [CoreState.Task.TaskStatus.QuotesReceived]: ProjectTaskStatus.QuotesReceived,
    [CoreState.Task.TaskStatus.AwaitingConfirmation]: ProjectTaskStatus.QuotesAccepted,
    [CoreState.Task.TaskStatus.AwaitingToStart]: ProjectTaskStatus.AwaitingStart,
    [CoreState.Task.TaskStatus.InProgress]: ProjectTaskStatus.WorkInProgress,
    [CoreState.Task.TaskStatus.AwaitingReview]: ProjectTaskStatus.InReview,
    [CoreState.Task.TaskStatus.Completed]: ProjectTaskStatus.Completed,
  }
  return statusKvp[task.status];
}
export default transformProjectTaskStatusDefault;