import { CoreUtils } from "@build-buddy/core";
import { Paid as PaidIcon } from "@mui/icons-material";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import { useState } from "react";
import { useTaskViewParams } from "../../_hooks";
import TaskViewOutlineAddCostUpdate from "./TaskViewOutlineAddCostUpdate";

const TaskViewOutlineAddCost = () => {
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const taskParams = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));

  // locals
  const [open, setOpen] = useState(false);
  
  if (!projectTask) return null;

  return (
    <>
      <Stack
        direction="row"
        alignItems='center'
        sx={{
          mt: {
            xs: 2,
            md: 0
          }
        }}
      >
        <PaidIcon color="success" sx={{ fontSize: "38px", mr: 1.5 }} />
        <Box>
          <Typography variant="caption"> Supply Cost </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {CoreUtils.Formatter.currency(projectTask.task.extra?.supplyCost, "$0.00")}
            <Link sx={{ ml: 1, textDecoration: "none" }} onClick={() => setOpen(true)}> Edit </Link>
          </Typography>
        </Box>
      </Stack>
      <TaskViewOutlineAddCostUpdate
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default TaskViewOutlineAddCost;