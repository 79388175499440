import { Typography } from "@mui/material";

const DashboardTasksListEmpty = () => {
  return (
    <Typography 
      variant="body1" 
      sx={{ 
        mt: 1, 
        mb: 2, 
        ml: 1, 
      }}
    > 
      There are no tasks matching your search criteria. Please adjust your search and try again. 
    </Typography>
  )
}
export default DashboardTasksListEmpty;