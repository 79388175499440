
import TaskViewQuotesAccept from "./task-view-quotes-accept/TaskViewQuotesAccept";
import TaskViewQuoteCompanyReviews from "./task-view-quotes-company/TaskViewQuoteCompanyReviews";
import TaskViewQuotesCompany from "./task-view-quotes-company/TaskViewQuotesCompany";
import TaskViewQuotesView from "./task-view-quotes-view/TaskViewQuotesView";
import TaskViewQuotes from "./TaskViewQuotes";

const TaskViewQuotesRouteDefinitions = {
  // tabs
  TASK_VIEW_QUOTES: {
    label: "Quotes",
    path: "/*/stage/:sid/task/:tid/quotes",
    component: TaskViewQuotes
  },
  // root views
  TASK_VIEW_QUOTES_VIEW: {    
    path: "/*/stage/:sid/task/:tid/quotes/:qid/view",
    component: TaskViewQuotesView
  },
  TASK_VIEW_QUOTES_ACCEPT: {
    path: "/*/stage/:sid/task/:tid/quotes/:qid/accept",
    component: TaskViewQuotesAccept
  },
  // sub views
  TASK_VIEW_QUOTES_COMPANY: {
    path: "/*/stage/:sid/task/:tid/quotes/:qid/company/:cid",
    component: TaskViewQuotesCompany
  },
  TASK_VIEW_QUOTES_COMPANY_REVIEWS: {
    path: "/*/stage/:sid/task/:tid/quotes/:qid/company/:cid/reviews",
    component: TaskViewQuoteCompanyReviews
  }
}

export default TaskViewQuotesRouteDefinitions;