import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit"

/**
 * Uses queries: getTaskListByParent
 *
 * @returns The next Manage Task for the parent 
 */
export const selectTaskViewNextTaskManageTask = createSelector(
  CoreState.Task.selectTaskListByParent,
  (taskList) => taskList?.find((item) => (
    ([
      CoreState.Task.TaskType.Manage,
      CoreState.Task.TaskType.ManageOnsite,
      CoreState.Task.TaskType.ManageOffsite,
      CoreState.Task.TaskType.CertInspect,
    ].includes(item.type))
    && (item.status === CoreState.Task.TaskStatus.Pending)))
);

/**
 * Uses queries: getTaskListByParent
 *
 * @returns The next Receive Delivery Task for the parent 
 */
export const selectTaskViewNextReceiveDeliveryTask = createSelector(
  CoreState.Task.selectTaskListByParent,
  (taskList) => taskList?.find((item) => (item.type.includes(CoreState.Task.TaskType.ReceiveDelivery) && (item.status === CoreState.Task.TaskStatus.Pending)))
);

/**
 * Uses queries: getTaskListByParent
 *
 * @returns The next Admin or Manage Task for the parent 
 */
export const selectTaskViewNextTaskAdminOrManageOrIntMeetingTask = createSelector(
  CoreState.Task.selectTaskListByParent,
  (taskList) => taskList?.find((item) => (
    [
      CoreState.Task.TaskType.IntMeeting,
      CoreState.Task.TaskType.Admin,
      CoreState.Task.TaskType.Manage,
      CoreState.Task.TaskType.Manage,
      CoreState.Task.TaskType.ManageOnsite,
      CoreState.Task.TaskType.ManageOffsite,
    ].includes(item.type))
    && item.status === CoreState.Task.TaskStatus.Pending)
);

/**
 * Uses queries: getTaskListByParent
 *
 * @returns The next Admin or Manage Task for the parent 
 */
export const selectTaskViewNextCertInspectOrAdmin = createSelector(
  CoreState.Task.selectTaskListByParent,
  (taskList) => taskList?.find((item) => (item.type === CoreState.Task.TaskType.CertInspect || item.type.includes(CoreState.Task.TaskType.Admin))
    && item.status === CoreState.Task.TaskStatus.Pending)
);

/**
 * Uses queries: getTaskListByParent
 *
 * @returns The next Complete task for parent
 */
const selectTaskViewNextCompleteTask = createSelector(
  CoreState.Task.selectTaskListByParent,
  (taskList) => taskList?.find((item) => item.type.includes(CoreState.Task.TaskType.Manage))
);