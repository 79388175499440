import { Route } from "react-router-dom"
import TaskViewQuotesRouteDefinitions from "../TaskViewQuotesRouteDefinitions"

const TaskViewQuotesListItemRoutes = () => {
  return (
    <>
      <Route
        exact
        component={TaskViewQuotesRouteDefinitions.TASK_VIEW_QUOTES_COMPANY.component}
        path={TaskViewQuotesRouteDefinitions.TASK_VIEW_QUOTES_COMPANY.path}
      />
      <Route
        exact
        component={TaskViewQuotesRouteDefinitions.TASK_VIEW_QUOTES_COMPANY_REVIEWS.component}
        path={TaskViewQuotesRouteDefinitions.TASK_VIEW_QUOTES_COMPANY_REVIEWS.path}
      />
    </>
  )
}
export default TaskViewQuotesListItemRoutes;