import { CoreState } from "@build-buddy/core";
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import * as AppActions from "../app/actions";

export interface AppContext {
  project: {
    id?: string;
  };
  isLoading: boolean;
}

const initialState: AppContext = {
  project: {},
  isLoading: true,
};

const AppReducer = createReducer(initialState,
  (builder) =>
    builder
      .addCase(AppActions.switchProject, (state, action) => {
        const projectId = action.payload.id;
        if (!projectId) return state;
        state.project.id = projectId;
      })
      .addMatcher(isAnyOf(AppActions.initialise.pending), (state, _) => {
        state.isLoading = true;
      })
      .addMatcher(isAnyOf(AppActions.initialise.fulfilled), (state, action) => {
        const projectId = action.payload.projectId;
        state.project.id = projectId;
        state.isLoading = false;
      })
      .addMatcher(CoreState.Project.getUserProjectList.matchFulfilled, (state, action) => {
        if (!action.payload.length || state.project.id) return state;
        state.project.id = action.payload[0].id;
      })
)

export default AppReducer
