import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import TaskViewRouteDefinitions from "../../../TaskViewRouteDefinitions";
import { useTaskViewParams } from "../../../_hooks";

const useTaskViewTypesTabsScopeOfWork = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const specificationParams = { projectId, stageId, taskId };
  const projectTaskParams = { projectId, stageId, taskId };

  // queries 
  const specificationQuery = CoreState.Specification.getTaskSpecificationList.useQuery(specificationParams)

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, projectTaskParams))

  // locals
  const activeSpecifications = specificationQuery.data?.filter(spec => !spec.isDisabled && spec.specificationSubType === CoreState.Specification.TaskSpecificationSubTypes.Scope)
  const count = activeSpecifications?.length || 0

  if (!projectTask || projectTask.task.parentCategory === CoreState.Task.TaskCategory.Standard) return null;

  return {
    ...TaskViewRouteDefinitions.TASK_VIEW_SCOPE_OF_WORK,
    label: "Scope of Works",
    count
  }
}
export default useTaskViewTypesTabsScopeOfWork;