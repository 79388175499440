import { CoreComponents, CoreRouting } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { selectProjectTaskId } from "modules/project/_selectors";
import { selectProjectTaskCompanyRatingId, selectProjectTaskIsReviewRequired } from "modules/project/_selectors/selectProjectTaskReview";
import { useEffect } from "react";
import { useTaskViewParams } from "../../_hooks";
import { useTaskViewTypesTabs } from "../_hooks";

const TaskViewTabs = () => {
  const tabs = useTaskViewTypesTabs();
  const taskParams = useTaskViewParams();
  const routing = CoreRouting.useRouting();
  const projectTaskId = useSelector((s) => selectProjectTaskId(s, { projectId: taskParams.projectId, taskId: taskParams.taskId, stageId: taskParams.stageId }));
  const isReviewRequired = useSelector((s) => selectProjectTaskIsReviewRequired(s, { projectId: taskParams.projectId, taskId: projectTaskId }));
  const companyRatingId = useSelector((s) => selectProjectTaskCompanyRatingId(s, { taskId: projectTaskId, projectId: taskParams.projectId }));

  const handleRouteChange = () => {
    if (!isReviewRequired || companyRatingId) return;

    routing.replace(`${tabs[0].path}/review`);
  };

  useEffect(() => {
    if (!isReviewRequired) return;

    handleRouteChange();
  }, [isReviewRequired]);

  return (
    <CoreComponents.Tabs
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column"
      }}
      routes={tabs}
      TabsProps={{
        sx: {
          mx: -3,
          px: 1.5
        }
      }}
      onInitialChange={handleRouteChange}
    />
  )
}
export default TaskViewTabs;