import { CoreModules } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import { selectProjectAddress } from "modules/project/_selectors";
import { Route } from "react-router-dom";

export const UserWalletRouteDefinitions = CoreModules.Wallet.UserWalletRouteDefinitions;

const WalletModule = () => {
  const projectParams = useProjectParams();
  const projectAddress = useSelector((s) => selectProjectAddress(s, projectParams));

  // component
  const CreateWalletComponent = UserWalletRouteDefinitions.CREATE_WALLET.component;

  return (
    <>
      <Route
        path={UserWalletRouteDefinitions.WALLET_FILTERS.path}
        component={UserWalletRouteDefinitions.WALLET_FILTERS.component}
      />
      <Route
        path={UserWalletRouteDefinitions.WITHDRAWAL_RECEIPT.path}
        component={UserWalletRouteDefinitions.WITHDRAWAL_RECEIPT.component}
      />
      <Route
        path={UserWalletRouteDefinitions.CREATE_WALLET.path}
        render={() => (
          <CreateWalletComponent
            address={projectAddress}
          />
        )}
      />
      <Route
        path={UserWalletRouteDefinitions.ADD_BANK_ACCOUNT.path}
        component={UserWalletRouteDefinitions.ADD_BANK_ACCOUNT.component}
      />
      <Route
        path={UserWalletRouteDefinitions.WITHDRAW_MONEY.path}
        component={UserWalletRouteDefinitions.WITHDRAW_MONEY.component}
      />
    </>
  )
}

export default WalletModule