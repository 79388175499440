import {
  CoreComponents,
  CoreHooks,
  CoreModules,
  CoreState,
  CoreUtils
} from '@build-buddy/core';
import { useSelector } from '@build-buddy/core/dist/coreReducer';
import { KeyboardArrowUp as KeyboardArrowUpIcon, Summarize as SummarizeIcon } from '@mui/icons-material';
import { Box, Collapse, Stack, Typography, useTheme } from '@mui/material';
import { selectTotalPaymentScheduleAmount } from 'modules/project/task/task-view/_selectors/selectTaskViewQuoteList';
import { useState } from 'react';

interface ExpenseListProps {
  params: {
    projectId: string;
    taskId: string;
    stageId: string;
  },
  onExpenseClick(expense: CoreState.Financials.Expense): void;
  onExpenseAdd(): void;
  ActionsComponent: React.ComponentType<{
    expense: CoreState.Financials.Expense;
    taskId?: string;
  }>
}

const ExpenseList = (props: ExpenseListProps) => {
  //props
  const {
    onExpenseAdd,
    onExpenseClick,
    params,
    ActionsComponent
  } = props;

  // hooks
  const theme = useTheme();
  const mode = CoreHooks.useMode();

  // State
  const [open, setOpen] = useState(true);

  // Query
  const getExpenseByTaskParams = {
    projectId: params.projectId,
    taskId: params.taskId,
  };

  // Query
  const getExpenseListQuery = CoreState.Financials.getExpenseListByAcceptedQuote.useQuery(params);
  const getExpensesByTask = CoreState.Financials.getExpenseListByTaskId.useQuery(getExpenseByTaskParams);

  // Selectors
  const expenses = useSelector((state) => CoreState.Financials.selectExpensesByAcceptedQuoteOrTask(state, params));
  const totalExpenseAmount = useSelector((state) => CoreState.Financials.selectWorkorderTotalExpensesAmount(state, params));
  const totalPaymentScheduleAmount = useSelector((state) => selectTotalPaymentScheduleAmount(state, params));
  const totalAmount = Number(totalExpenseAmount) + Number(totalPaymentScheduleAmount);

  return (
    <>
      <Stack direction="row" onClick={() => setOpen(prev => !prev)} sx={{ cursor: 'pointer' }} mb={2}>
        <KeyboardArrowUpIcon sx={{ transform: `rotate(${open ? 180 : 0}deg)` }} />
        <Typography variant='h4'>Additional Expenses</Typography>
      </Stack>
      <Collapse in={open}>
        <CoreComponents.PreContent
          isLoading={getExpenseListQuery.isLoading || getExpensesByTask.isLoading}
          isEmpty={!expenses?.length}
          loader={<CoreModules.Task.TaskView.PaymentScheduleSkeleton />}
          empty={
            <Box sx={{ display: 'flex', gap: 1, opacity: 0.6 }}>
              <SummarizeIcon sx={{ opacity: 0.4 }} />
              <Typography>No addtional expenses.</Typography>
            </Box>
          }
        >
          <CoreModules.Financials.Expenses.ExpenseList
            expenses={expenses as Array<CoreState.Financials.Expense>}
            actions={(props) => <ActionsComponent expense={props.expense} taskId={params.taskId} />}
            onExpenseClick={onExpenseClick}
          />
        </CoreComponents.PreContent >
      </Collapse>
      <CoreComponents.AddButton
        label='Add Expense'
        onClick={onExpenseAdd}
        sx={{ my: 1 }}
      />
      <CoreModules.Financials.Expenses.ExpenseTemplate
        sx={{
          pb: 2,
          pt: 4,
          px: 3,
          borderTop: `solid 1px ${theme.palette.grey[200]}`,
          mx: -3,
        }}
      >
        <Typography variant="h5" sx={{ width: '50%' }}>Total Payment</Typography>
        {mode.md &&
          <>
            <Typography></Typography>
            <Typography></Typography>
          </>
        }
        <Typography variant="h5" sx={{ width: '50%' }}>{CoreUtils.Formatter.currency(totalAmount)}</Typography>
      </CoreModules.Financials.Expenses.ExpenseTemplate>
    </>
  )
}

export default ExpenseList;