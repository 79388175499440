import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { selectTaskViewQuote } from "../../_selectors";
import { useTaskViewQuotesParams } from "../_hooks";
import TaskViewQuotesAcceptSign from "./TaskViewQuotesAcceptSign";
import TaskViewQuotesAcceptStandard from "./TaskViewQuotesAcceptStandard";

const TaskViewQuotesAccept = () => {
  // params
  const { projectId, stageId, taskId, quoteId } = useTaskViewQuotesParams();
  const quoteParams = { projectId, stageId, taskId, quoteId };

  // queries
  const quoteQuery = CoreState.Quote.getQuoteByLatestQuoteRequest.useQuery(quoteParams);

  // selectors
  const quote = useSelector((s) => selectTaskViewQuote(s, quoteParams));
  return (
    <>
      {quote?.isContractSignatureRequired && 
        <TaskViewQuotesAcceptSign />
      }
      {!quote?.isContractSignatureRequired && 
        <TaskViewQuotesAcceptStandard />
      }
    </>
  )
}
export default TaskViewQuotesAccept;