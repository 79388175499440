import { SubTaskDTO } from "../types/types";


type SubTaskPayload = {
  SubTaskPublicId: string;
  IsRequired: boolean;
}

type SubTaskArray = Array<SubTaskPayload>;

function objMapper(formValues: Record<string, object>): SubTaskArray {
  return Object.values(formValues).flatMap(item => {
    return Object.entries(item)
      .filter(([key, _]) => key !== 'value')
      .map(([key, value]) => ({
        SubTaskPublicId: key,
        IsRequired: value === 'Required'
      }));
  });
}

const toDTO = (values: Record<string, object>):SubTaskDTO  => {
  return objMapper(values)
  
};


export default { toDTO };