import { MenuItem, SxProps, Theme } from "@mui/material";

import { FormikContextType } from "formik";

import { CoreComponents, CoreUtils } from "@build-buddy/core";

type DateRecord = Record<string, { slots: Array<string> }>;

interface DateTimePickerProps {
  formik: FormikContextType<any>;
  StartProps: {
    id: string;
    label: string;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
  };
  TimeProps: {
    id: string;
    label: string;
  };
  dates: DateRecord;
  isLoading?: boolean;
}

const getDistinctYears = (dates: DateRecord) => ([...new Set(Object.keys(dates).map((date) => date.split('-')[0]))]);
const getDistinctMonths = (dates: DateRecord) => ([...new Set(Object.keys(dates).map((date) => date.split('-')[1]))]);

const DateTimePicker: React.FC<DateTimePickerProps> = (props) => {
  const dates = props.dates;

  const formik = props.formik;
  const startId = props.StartProps.id;

  const timeVal = formik.values?.time || Object.values(props.dates)[0]?.slots?.[0] || "";
  const timeSlots = props.dates[(CoreUtils.Formatter.date(formik.values[startId], "yyyy-MM-dd") ?? "")]?.slots
    || Object.values(props.dates)[0]?.slots;

  const shouldDisableYear = (val?: Date) => {
    if (getDistinctYears(dates).includes(CoreUtils.Formatter.date(val, "yyyy") ?? "")) {
      return false;
    }
    return true;
  }

  const shouldDisableDate = (val?: Date) => {
    if (Object.keys(dates).includes(CoreUtils.Formatter.date(val, "yyyy-MM-dd") ?? "")) {
      return false;
    }
    return true;
  }

  const shouldDisableMonth = (val?: Date) => {
    if (getDistinctMonths(dates).includes(CoreUtils.Formatter.date(val, "MM") ?? "")) {
      return false;
    }
    return true;
  }

  return (
    <>
      <CoreComponents.DatePicker
        label={props.StartProps.label}
        name={startId}
        value={formik.values[startId]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          formik.setFieldValue(startId, newValue);
          formik.setFieldValue("time", props.dates[(CoreUtils.Formatter.date(newValue, "yyyy-MM-dd") ?? "")]?.slots?.[0]);
        }}
        onBlur={formik.handleBlur}
        error={formik.errors[startId]}
        fullWidth={props.StartProps.fullWidth}
        sx={props.StartProps.sx}
        shouldDisableDate={shouldDisableDate}
        shouldDisableMonth={shouldDisableMonth}
        shouldDisableYear={shouldDisableYear}
        isLoading={props.isLoading}
      />
      <CoreComponents.Select
        name="time"
        label={props.TimeProps.label}
        value={timeVal}
        error={Boolean(formik.touched?.time) && formik?.errors?.time}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        fullWidth
        disabled={props.isLoading}
      >
        {timeSlots?.map((s: any, idx: any) => (
          <MenuItem key={idx} value={s}>
            {CoreUtils.Formatter.date(s, "H:mm aaa")}
          </MenuItem>
        ))}
      </CoreComponents.Select>
    </>
  )
}

export default DateTimePicker;