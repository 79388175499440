import { CoreComponents, CoreModules, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { selectProjectTaskName, selectProjectTaskType } from "modules/project/_selectors";
import { useTaskViewParams } from "../_hooks";
import { selectTaskViewQuoteListAcceptedQuote } from "../_selectors";

const JobViewMessagesEmpty = () => {
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const taskParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };

  // selectors
  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);  
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, quoteListParams));
  const projectTaskName = useSelector((s) => selectProjectTaskName(s, taskParams));
  const projectTaskType = useSelector((s)=> selectProjectTaskType(s, taskParams));

  // local
    const enableMessaging = ![
      CoreState.Task.TaskType.Request,
      CoreState.Task.TaskType.Select,
    ].includes(projectTaskType as CoreState.Task.TaskType);
  
    // mutations
  const [addQuoteRequestNoteThreadMutation, addQuoteRequestNoteThreadQuery] = CoreState.NoteThread.addQuoteNoteThread.useMutation();

  const handleSave = (values: any) => {
    if (acceptedQuote?.quoteRequest?.id && acceptedQuote?.company?.id) {
      addQuoteRequestNoteThreadMutation({
        projectId,
        stageId,
        taskId,
        quoteRequestId: acceptedQuote.quoteRequest.id,
        toUserId: acceptedQuote.company.id,
        content: values.message,
        title: values.topic,
        // TODO: Maybe fix
        authorOrgId: currentUserId,
      })
    }
  }

  if (!enableMessaging) {
    return <CoreComponents.EmptyContent />
  }

  return (
    <Box sx={{ p: 2 }}>
      <CoreModules.MessagesEmpty
        title={projectTaskName || ""}
        isSuccess={addQuoteRequestNoteThreadQuery.isSuccess}
        isLoading={addQuoteRequestNoteThreadQuery.isLoading}
        handleSave={handleSave}
      />
    </Box>
  )
}
export default JobViewMessagesEmpty;
