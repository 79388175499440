import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

/**
 * Uses queries: getTaskListByParent
 *
 * @returns The first Select Task for the parent 
 */
export const selectTaskViewFirstTaskSelectTask = createSelector(
  CoreState.Task.selectTaskListByParent,
  (taskList) => taskList?.find((item) => item.type === CoreState.Task.TaskType.Select)
);

/**
 * Uses queries: getTaskListByParent
 *
 * @returns The first Select Task for the parent which is not diy
 */
const selectTaskViewFirstTaskSelectTaskAndNotDiy = createSelector(
  selectTaskViewFirstTaskSelectTask,
  (selectTask) => !selectTask?.parentIsDIY ? selectTask : undefined
);

/**
 * Uses queries: getTaskListByParent
 *
 * @returns The first Select Task for the parent which is not diy and not pending
 */
export const selectTaskViewFirstTaskSelectTaskIsNotDiyAndNotPending = createSelector(
  selectTaskViewFirstTaskSelectTaskAndNotDiy,
  (selectTask) => Boolean(selectTask && selectTask.status !== CoreState.Task.TaskStatus.Pending)
);
