import { CoreState } from "@build-buddy/core";
import { ProjectTaskStatus } from "modules/project/_models";

const transformProjectTaskStatusComplete = (task: CoreState.Task.Task) => {
  if (task.status === CoreState.Task.TaskStatus.Completed) return ProjectTaskStatus.Completed;

  if (task.type !== CoreState.Task.TaskType.Complete) return;

  const kvp: any = {
    [CoreState.Task.TaskStatus.AwaitingReview]: ProjectTaskStatus.Finalise,
    [CoreState.Task.TaskStatus.Completed]: ProjectTaskStatus.Completed
  }
  return kvp[task.status];
}

export default transformProjectTaskStatusComplete;