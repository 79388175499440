import { CoreModules, CoreState, useMode } from "@build-buddy/core";
import { Box, Card, CardContent, Grid, Stack } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";

import DashboardFinancials from "./dashboard-financials/DashboardFinancials";
import DashboardProfileCheck from "./dashboard-profile-check/DashboardProfileCheck";
import DashboardProject from "./dashboard-project/DashboardProject";
import DashboardProjectBreakdownMobile from "./dashboard-project/DashboardProjectBreakdownMobile";
import DashboardTasks from "./dashboard-tasks/DashboardTasks";

const Dashboard = () => {
  // hooks
  const mode = useMode();

  // selector
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const isCurrentUserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner);

  // queries
  const getUserQuery = CoreState.User.getUser.useQuery({ userId }, { skip: !userId });

  return (
    <Stack direction="column" gap={2}>
      <DashboardProfileCheck />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8} xl={9}>
          <Box sx={{ mb: 3 }}>
            <DashboardFinancials />
          </Box>
          {mode.isMobile &&
            <Card
              sx={{
                mb: 1,
              }}
            >
              <CardContent>
                <DashboardProjectBreakdownMobile />
              </CardContent>
            </Card>
          }
          <Box>
            <DashboardTasks />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} lg={4} xl={3}>
          {mode.isDesktop &&
            <Box sx={{ mb: 2 }}>
              <DashboardProject />
            </Box>
          }
          {isCurrentUserOwner &&
            <Box sx={{ mb: 2 }}>
              <CoreModules.Wallet.WalletDetails />
            </Box>
          }
          {mode.isDesktop &&
            <Box sx={{ mb: 2 }}>
              <CoreModules.MessagesSummary />
            </Box>
          }
        </Grid>
      </Grid>
    </Stack>
  );
};
export default Dashboard;
