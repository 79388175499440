
import { CoreComponents, CoreRouting, CoreState, useMode } from "@build-buddy/core";
import {
  Chat as ChatIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { Box, Button, Chip, IconButton, Stack } from "@mui/material";
import { CommonState } from "common";
import { selectIncompleteProfileItems, selectIsProjectHaveWallet } from "common/state/app";
import { useSelector } from "common/state/store";
import { UserWalletRouteDefinitions } from "modules/user/wallet/WalletModule";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import TaskViewQuoteListItemActionsValidation from "./TaskViewQuoteListItemActionsValidation";

interface TaskViewQuotesListItemActionsProps {
  status: CoreState.Quote.QuoteStatus;
  onViewClick(): void;
  onMessageClick?(): void;
  onAcceptClick?(): void;
  onDeclineClick?(): void;
}

const TaskViewQuotesListItemActions = (props: TaskViewQuotesListItemActionsProps) => {
  const { status, onViewClick, onMessageClick, onAcceptClick, onDeclineClick } = props;
  const mode = useMode();
  const routing = CoreRouting.useRouting();

  // State
  const [showWalletValidationForMembers, setShowValidationForMembers] = useState(false)

  //  selectors
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const currentProjectId = useSelector(CommonState.App.selectCurrentProjectId);
  const validations = useSelector((s) => selectIncompleteProfileItems(s, { userId }));
  const isCurrentuserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner);
  const hasProjectWallet = useSelector((s) => selectIsProjectHaveWallet(s, { userId, projectId: currentProjectId || '' }));

  // queries
  const getFinancials = CoreState.Wallet.getFinancialDetails.useQuery({ userId, projectId: currentProjectId })

  // locals
  const kvp = {
    [CoreState.Quote.QuoteStatus.Submitted]: undefined,
    [CoreState.Quote.QuoteStatus.Accepted]: { color: "success", label: "Accepted" },
    [CoreState.Quote.QuoteStatus.Declined]: { color: "error", label: "Declined" },
    [CoreState.Quote.QuoteStatus.AwaitingConfirmation]: { color: "info", label: "Awaiting Confirmation" }
  }
  const showButtons = Boolean(status === CoreState.Quote.QuoteStatus.Submitted);
  const showMessage = Boolean(onMessageClick);
  const statusChip = kvp[status];

  // events
  const handleOpenConfirmation = () => {
    if (!onAcceptClick) return;
    if (!isCurrentuserOwner && !hasProjectWallet) {
      setShowValidationForMembers(true)
      return
    }
    if (!hasProjectWallet) {
      routing.go(UserWalletRouteDefinitions.CREATE_WALLET)
      return
    }
    onAcceptClick()
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
        sx={{
          gridArea: "actions",
          textAlign: "right"
        }}
      >
        <CoreComponents.Loader show={getFinancials.isLoading} />
        <Box
          sx={{
            flex: {
              xs: 1,
              md: "inherit"
            }
          }}
        >
          { }
          <IconButton onClick={onViewClick}>
            <VisibilityIcon />
          </IconButton>
          {showMessage &&
            <IconButton
              onClick={onMessageClick}
              sx={{ mr: 0.5 }}
            >
              <ChatIcon />
            </IconButton>
          }
          {showButtons &&
            <>
              <Button
                variant="outlined"
                color="neutral"
                sx={{
                  p: { xs: 1, md: "5px 15px" },
                  minWidth: { xs: "auto", md: "inherit" },
                  mr: 0.75
                }}
                onClick={onDeclineClick}
              >
                {mode.md ? "Decline" : <CloseIcon />}
              </Button>
              <Button
                variant="contained"
                sx={{
                  p: { xs: 1, md: "5px 15px" },
                  minWidth: { xs: "auto", md: "inherit" },
                }}
                onClick={handleOpenConfirmation}
              >
                {mode.md ? "Accept" : <DoneIcon />}
              </Button>
            </>
          }
          {statusChip &&
            <Chip
              label={statusChip.label}
              size="small"
              color={statusChip.color as any}
            />
          }
        </Box>
      </Stack>
      <TaskViewQuoteListItemActionsValidation
        show={showWalletValidationForMembers}
        onClose={() => setShowValidationForMembers(false)}
      />
    </>
  )
}

export default TaskViewQuotesListItemActions;