import { useMemo } from "react";
import { useRouteMatch } from "react-router";
import { MyTeamsRoutesDefinitions } from "../MyTeamsRoutes";

const useMyTeamsParams = () => {
  const match = useRouteMatch<any>([
    MyTeamsRoutesDefinitions.MY_TEAMS_EDIT.path
  ]);

  const params = useMemo(() => ({
    memberId: match?.params.mid,
  }), [match?.params]);

  return params;
}
export default useMyTeamsParams;