import { createSelector } from "@reduxjs/toolkit";
import { selectTaskViewQuoteListAcceptedQuote } from "../../_selectors";

interface SelectCurrentPaymentScheduleParams {
  projectId: string;
  stageId: string;
  taskId: string;
  paymentScheduleId: string;
}
export const selectCurrentPaymentSchedule = createSelector(
  [
    (state, params: SelectCurrentPaymentScheduleParams) => selectTaskViewQuoteListAcceptedQuote(state, {projectId: params.projectId, stageId: params.stageId, taskId: params.taskId}),
    (state, params: SelectCurrentPaymentScheduleParams) => ({ state, params })
  ], 
  (acceptedQuote, meta) => {
    if(!acceptedQuote) return;
    const rtn = acceptedQuote?.paymentSchedule?.find(ps => ps.id === meta.params.paymentScheduleId)
    return rtn
  }
)