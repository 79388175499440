import { useFinancialRoutesParams } from "modules/project/financials/FinancialsRoutes";
import { Route, useRouteMatch } from "react-router-dom";
import BudgetReleasePayment from "./BudgetReleasePayment";

export const BudgetReleasePaymentRoutesDefinition = {
  BUDGET_RELEASE_PAYMENT: {
    path: "/*/task/:tid/release-payment/:psid",
    component: BudgetReleasePayment
  }
};

export const useBudgetReleasePaymentRoutesParams = () => {
  // get params from parent route
  const { projectId, stageId } = useFinancialRoutesParams();
  
  // match route and get params
  const match = useRouteMatch<any>([
    BudgetReleasePaymentRoutesDefinition.BUDGET_RELEASE_PAYMENT.path,
  ]);
  const { tid, psid } = match?.params;

  return {
    projectId,
    stageId,
    taskId: tid,
    paymentScheduleId: psid,
  }
}

export const BudgetReleasePaymentRoutes = () => {
  return (
    <>
      <Route
        path={BudgetReleasePaymentRoutesDefinition.BUDGET_RELEASE_PAYMENT.path}
        component={BudgetReleasePaymentRoutesDefinition.BUDGET_RELEASE_PAYMENT.component}
      />    
    </>
  );
};