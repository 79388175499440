import {
  coreReducer,
  CoreState
} from "@build-buddy/core";
import { ApiSlice } from "@build-buddy/core/dist/state";
import { configureStore } from "@reduxjs/toolkit";
import { OnboardingSlice } from "modules/onboarding/OnboardingSlice";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from "react-redux";
import AppReducer from "./app/AppReducer";

// TODO: Rethink this when testing
// const configureAppStore = (preloadedState?: any) => {
//   return configureStore({
//     reducer: {
//       // core
//       ...coreReducer,

//       // modules
//       ...ProjectQuoteState,
//       ...UserProfilesState,
//       ...currentReducer,

//       // NEW STUFF
//       [AppSlice.reducerPath]: AppSlice.reducer,
//       [ApiSlice.reducerPath]: ApiSlice.reducer,
//       [OnboardingSlice.reducerPath]: OnboardingSlice.reducer,
//     },
//     middleware: (getDefaultMiddleware) => {
//       return getDefaultMiddleware().concat(ApiSlice.middleware);
//     },
//     preloadedState,
//   });
// };

const configureAppStore = configureStore({
  reducer: {
    // core
    ...coreReducer,

    // NEW STUFF
    AppReducer,
    [ApiSlice.reducerPath]: ApiSlice.reducer,
    [OnboardingSlice.reducerPath]: OnboardingSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(ApiSlice.middleware)
      .concat(CoreState.Notification.NotificationHandlerMiddleware);
  },
});

export type BuildBuddyStateType = ReturnType<typeof configureAppStore['getState']>;
export const useDispatch: () => typeof configureAppStore['dispatch'] = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<BuildBuddyStateType> = useReduxSelector;

export default configureAppStore;