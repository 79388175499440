import { CoreModules } from "@build-buddy/core";
import { Route } from "react-router-dom";
import InitialInvitation from "./initial-invitation/InitialInvitation";

const InvitationRouteDefinitions = {
  ...CoreModules.Membership.MembershipRouteDefinitions,
  INVITATION_INITIAL: {
    path: "/invitation-initial",
    component: InitialInvitation
  }
};

const InvitationRoutes = () => {
  return (
    <>
      <CoreModules.Membership.MembershipRoutes />
      <Route 
        path={InvitationRouteDefinitions.INVITATION_INITIAL.path} 
        component={InvitationRouteDefinitions.INVITATION_INITIAL.component} 
      />
    </>
  )
};

export { InvitationRoutes, InvitationRouteDefinitions };
