import { Box, Grid, Skeleton } from '@mui/material';

const GanttChartSkeleton = () => {
  const renderTaskRows = () => {
    const rows = [];
    for (let i = 0; i < 10; i++) {
      rows.push(
        <Grid container spacing={1} alignItems="center" key={i}>
          <Grid item xs={3}>
            <Skeleton height={40} />
          </Grid>
          <Grid item xs={9}>
            <Skeleton variant="rectangular" height={30} />
          </Grid>
        </Grid>
      );
    }
    return rows;
  };

  return (
    <Box sx={{ padding: 0 }}>
      <Box sx={{ marginTop: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
            {renderTaskRows().map((row, index) => (
              <Box key={index} sx={{ marginTop: 1 }}>
                {row}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GanttChartSkeleton;
