import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog } from "@mui/material";
import { useSelector } from "common/state/store";
import { useBudgetVariationRoutesDefinitionParams } from "./BudgetVariationRoutes";
import BudgetVariationForm from "./BudgetVariationForm";

const BudgetVariation = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, costCentreCode, itemId } = useBudgetVariationRoutesDefinitionParams();
  const getCostCentreEstimateByCodeParams = { projectId, costCentreCode };
  const getCostCentreEstimateItemParams = { ...getCostCentreEstimateByCodeParams, itemId };

  // queries
  const getCostCentreEstimateByCodeQuery = CoreState.Financial.getCostCentreEstimateByCode.useQuery(getCostCentreEstimateByCodeParams);

  // locals
  const isLoading = Boolean(!getCostCentreEstimateByCodeQuery.isUninitialized && getCostCentreEstimateByCodeQuery.isLoading)
  const costCentreEstimateItem = useSelector(s => CoreState.Financial.selectCostCentreEstimateItem(s, getCostCentreEstimateItemParams));

  // events
  const handleClose = () => {
    routing.back({ persistSearch: true });
  }

  console.log(costCentreEstimateItem);
  
  return (
    <Dialog open={true}>
      <CoreComponents.PreContent
        isLoading={isLoading}
        loader={<> LOADING </>}
        isEmpty={false}
      >
        <BudgetVariationForm 
          costCentreEstimateItem={costCentreEstimateItem as CoreState.Financial.CostCentreEstimateItem}
          onClose={() => handleClose()}
        />
      </CoreComponents.PreContent>
    </Dialog>
  )
}

export default BudgetVariation;