import { useRouteMatch } from "react-router";
import { useTaskViewParams } from "../../_hooks";

const useTaskViewDocumentsParams = () => {
  const { projectId } = useTaskViewParams();
  const match = useRouteMatch<any>();

  return {
    projectId,
    stageId: match?.params.sid,
    taskId: match?.params.tid,
    tagCode: match?.params.tagCode
  };
}
export default useTaskViewDocumentsParams