import { CoreModules, CoreState, CoreUtils } from "@build-buddy/core";
import { ContentPaste as ClipboardIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, IconButton, useTheme } from "@mui/material";
import { useState } from "react";
import TaskViewTimelineTaskListItemEdit from "./TaskViewTimelineTaskListItemEdit";

type TaskViewTimelineTaskListItemProps = {
  projectId: string;
  task: CoreState.Task.Task;
  readonly?: boolean;
}
const TaskViewTimelineTaskListItem = (props: TaskViewTimelineTaskListItemProps) => {
  const { projectId, task } = props;
  const theme = useTheme()

  // locals
  const [isEdit, setIsEdit] = useState(false);
  const isCompleted = task.status === CoreState.Task.TaskStatus.Completed;
  const isEditableStatus = [
    CoreState.Task.TaskStatus.Pending,
    CoreState.Task.TaskStatus.New,
    CoreState.Task.TaskStatus.QuotesReceived,
    CoreState.Task.TaskStatus.QuotesRequested,
  ].includes(task.status);
  const isManage = Boolean(props?.task?.type?.includes(CoreState.Task.TaskType.Manage));
  const showEdit = Boolean(!isCompleted && isEditableStatus && !props.readonly) || Boolean(isManage && !isCompleted);

  if (!task) return null;

  return (
    <Box sx={{ pt: 2, pb: 1 }}>
      {!isEdit &&
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <CoreModules.Task.TaskView.TaskViewOutlineItem
            title=""
            value={`${CoreUtils.Formatter.date(task.startDate, "d MMM yy")} - ${CoreUtils.Formatter.date(task.endDate, 'd MMM yy')}`}
            icon={ClipboardIcon}
            IconProps={{
              sx: {
                background: theme.palette.common.white
              }
            }}
          />
          {showEdit &&
            <IconButton
              edge="end"
              onClick={() => setIsEdit(!isEdit)}
            >
              <EditIcon sx={{ fontSize: 18 }} />
            </IconButton>
          }
        </Box>
      }
      {isEdit &&
        <TaskViewTimelineTaskListItemEdit
          projectId={projectId}
          task={task}
          onClose={() => setIsEdit(false)}
        />
      }
    </Box>
  )
}

export default TaskViewTimelineTaskListItem