import { CoreState, TypeGuards } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

export interface SelectDocumentLibraryDocListParams extends CoreState.File.SelectDocListByProjectParams {}
export const selectDocumentLibraryDocList = createSelector(
  [
    (state, params: SelectDocumentLibraryDocListParams) => CoreState.File.selectDocListByProject(state, params)
  ],
  (docList) => docList || []
)

export interface SelectDocumentLibraryDocListFilteredParams extends SelectDocumentLibraryDocListParams {
  search?: string;
  tags?: Array<CoreState.Tag.Tag>;
}
export const selectDocumentLibraryDocListFiltered = createSelector(
  [
    (state, params: SelectDocumentLibraryDocListFilteredParams) => selectDocumentLibraryDocList(state, { projectId: params.projectId }),
    (_, params: SelectDocumentLibraryDocListFilteredParams) => ({ search: params.search, tags: params.tags })
  ], 
  (docList, meta) => {
    if(!docList) return [];
    const search = meta.search || "";
    const tags = meta.tags || [];

    return docList
      // filter by tags
      .filter(doc => {
        // if there are no tags in the search, no need to filter
        if(!tags || !tags.length) return true;

        // if there are no tags in the doc, exclude
        if(!doc.tags) return false;

        const docTags = doc.tags.map(x => x.code);
        const compTags = tags.map(x => x.code);
        return docTags.some(dt => compTags.includes(dt));
      })
      // filter by search
      .filter(doc => {
        if(!search) return true;
        return doc.name.search(new RegExp(search, "i")) >= 0;
      })
  }
)

export const selectDocumentLibraryTagListFiltered = createSelector(
  [
    (state, params: SelectDocumentLibraryDocListParams) => selectDocumentLibraryDocList(state, { projectId: params.projectId }),
    (state) => CoreState.Tag.selectTagListByTypeDocument(state)
  ],
  (docList, tagList) => {    
    const excludeTags = docList.map(d => d.tags).flat().filter(TypeGuards.nonNullable);
    return tagList.filter(t => !excludeTags.some(e => e.code === t.code))
  }
)