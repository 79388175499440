import { Typography } from "@mui/material";
import DashboardTasksListTemplate from "./DashboardTasksListTemplate";

const DashboardTasksListHeader = () => {
  return (
    <DashboardTasksListTemplate
      sx={{
        pt: 1,
        display: {
          xs: "block",
          md: "grid"
        }
      }}
    >
      <Typography 
        variant="subtitle2" 
        sx={{ 
          gridArea: "name"
        }}
      > 
        Task Name 
      </Typography>
      <Typography 
        variant="subtitle2" 
        sx={{ 
          gridArea: "status",
          display: {
            xs: "none",
            md: "block"
          }
        }}
      > 
        Status
      </Typography>
      <Typography 
        variant="subtitle2" 
        sx={{ 
          gridArea: "start-date",
          display: {
            xs: "none",
            md: "block"
          }
        }}
      > 
        Start
      </Typography>
      <Typography 
        variant="subtitle2" 
        sx={{ 
          gridArea: "end-date",
          display: {
            xs: "none",
            md: "block"
          }
        }}
      > 
        Est Completion
      </Typography>
    </DashboardTasksListTemplate>
  )
}
export default DashboardTasksListHeader;