import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import TaskViewRouteDefinitions from "../../../TaskViewRouteDefinitions";
import { useTaskViewParams } from "../../../_hooks";
import { selectTaskViewFirstTaskSelectTask } from "../../../_selectors";

const useTaskViewTypesTabsMessages = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();
  
  // params
  const taskParams = { projectId, stageId, taskId };
  const messageParams = { projectId, stageId, taskId };

  // queries
  const messageQuery = CoreState.NoteThread.getNoteThreadListByLatestQuoteRequest.useQuery(messageParams);
  
  // selectors
  const selectTask = useSelector((s) => selectTaskViewFirstTaskSelectTask(s, taskParams));

  // locals
  const count = messageQuery.data?.length || 0;

  if (!selectTask || selectTask.status === CoreState.Task.TaskStatus.Pending) return null;
  
  return {
    ...TaskViewRouteDefinitions.TASK_VIEW_MESSAGES,
    label: "Messages",
    count
  }
}
export default useTaskViewTypesTabsMessages;