import { CoreState, CoreComponents, CoreUtils } from "@build-buddy/core";
import { Info as InfoIcon } from "@mui/icons-material";
import { Box, IconButton, Popover, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import BudgetItemListEstimatedItemVariationTemplate from "./BudgetItemListEstimatedItemVariationTemplate";

export interface BudgetItemListEstimatedItemVariationProps {
  budgetItem: CoreState.Financial.CostCentreEstimateItem;
}
const BudgetItemListEstimatedItemVariation = (props: BudgetItemListEstimatedItemVariationProps) => {
  const { budgetItem } = props;
  const theme = useTheme();

  // locals
  const borderSx = { borderBottom: `solid 1px ${theme.palette.grey[300]}`, mx: -3, px: 3, my: 0.75 }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const qtyEstimate = `${budgetItem.quantity} ${budgetItem.unit}`;
  const qtyVariation = `${budgetItem.variation?.quantity} ${budgetItem.variation?.unit}`;
  const rateEstimate = CoreUtils.Formatter.currency(budgetItem.rate);
  const rateVariation = CoreUtils.Formatter.currency(budgetItem.variation?.rate);
  const finalEstimate = CoreUtils.Formatter.currency(budgetItem.final);
  const userVariation = (budgetItem.variation?.final || 0) - budgetItem.final;
  const userVariationSign = Boolean(userVariation > 0) ? "+" : "";
  const userVariationLabel = `${userVariationSign}${CoreUtils.Formatter.currency(userVariation)}`;
  const budgetVariation = CoreUtils.Formatter.currency(budgetItem.variation?.final)
  const notes = budgetItem.notes || "";

  return (
    <Box>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        slotProps={{
          paper: {
            sx: {
              width: "550px",
              px: 3,
              py: 1
            }
          }
        }}
        onClose={() => setAnchorEl(null)}
      >
        <BudgetItemListEstimatedItemVariationTemplate sx={borderSx}>
          <CoreComponents.Label
            label="Final Estimate"
            sx={{ gridArea: "estimate" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <CoreComponents.Label
            label="Variation"
            sx={{ gridArea: "variation" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
        </BudgetItemListEstimatedItemVariationTemplate>
        <BudgetItemListEstimatedItemVariationTemplate>
          <CoreComponents.Label
            label="Qty"
            sx={{ gridArea: "label", position: "relative" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography
            variant="body1"
            sx={{ gridArea: "estimate" }}
          >
            {qtyEstimate}
          </Typography>
          <Typography
            variant="body1"
            sx={{ gridArea: "variation", fontWeight: "bold" }}
          >
            {qtyVariation}
          </Typography>
        </BudgetItemListEstimatedItemVariationTemplate>
        <BudgetItemListEstimatedItemVariationTemplate>
          <CoreComponents.Label
            label="Rate"
            sx={{ gridArea: "label", position: "relative" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography
            variant="body1"
            sx={{ gridArea: "estimate" }}
          >
            {rateEstimate}
          </Typography>
          <Typography
            variant="body1"
            sx={{ gridArea: "variation", fontWeight: "bold" }}
          >
            {rateVariation}
          </Typography>
        </BudgetItemListEstimatedItemVariationTemplate>
        <BudgetItemListEstimatedItemVariationTemplate sx={borderSx}>
          <CoreComponents.Label
            label="Final Esimate"
            sx={{ gridArea: "label", position: "relative" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography
            variant="body1"
            sx={{ gridArea: "estimate" }}
          >
            {finalEstimate}
          </Typography>
        </BudgetItemListEstimatedItemVariationTemplate>
        <BudgetItemListEstimatedItemVariationTemplate sx={borderSx}>
          <CoreComponents.Label
            label="User Variation"
            sx={{ gridArea: "label", position: "relative" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography
            variant="body1"
            sx={{ gridArea: "variation", fontWeight: "bold" }}
          >
            {userVariationLabel}
          </Typography>
        </BudgetItemListEstimatedItemVariationTemplate>
        <BudgetItemListEstimatedItemVariationTemplate sx={borderSx}>
          <CoreComponents.Label
            label="Budget"
            sx={{ gridArea: "label", position: "relative" }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography
            variant="body1"
            sx={{ gridArea: "variation", fontWeight: "bold" }}
          >
            {budgetVariation}
          </Typography>
        </BudgetItemListEstimatedItemVariationTemplate>
        <Stack direction="row" alignItems="flex-start" sx={{ mt: 2, mb: 1.5 }}>
          <CoreComponents.Label
            label="Notes"
            sx={{ gridArea: "label", position: "relative", top: 2, mr: 4 }}
            InputLabelProps={{ sx: { textTransform: "uppercase" } }}
          />
          <Typography variant="body2">
            {notes}
          </Typography>
        </Stack>
      </Popover>
    </Box>
  )
}
export default BudgetItemListEstimatedItemVariation;