import { CoreComponents, CoreState, CoreUtils } from "@build-buddy/core";
import { DialogContent, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useTaskViewParams } from "../../../_hooks";
import TaskViewNextTaskDialogSingleFormValidation from "./TaskViewNextTaskDialogSingleFormValidation";


type TaskViewNextTaskDialogSingleFormProps = {
  nextTask: CoreState.Task.Task;
  currentTask: CoreState.Task.Task;
  onClose(): void;
}

const TaskViewNextTaskDialogSingleForm = (props: TaskViewNextTaskDialogSingleFormProps) => {
  const { projectId } = useTaskViewParams();
  const { nextTask, currentTask, onClose } = props;

  // mutations
  const [completeTaskAndAdjustDatesAction, completeTaskAndAdjustDatesQuery] = CoreState.Task.completeTaskAndAdjustDates.useMutation();

  // locals
  const isSaving = completeTaskAndAdjustDatesQuery.isLoading;
  const isSuccess = completeTaskAndAdjustDatesQuery.isSuccess;
  const isError = completeTaskAndAdjustDatesQuery.isError;

  // formik
  const formik = useFormik({
    initialValues: {
      startDate: new Date(nextTask.startDate),
    },
    validationSchema: TaskViewNextTaskDialogSingleFormValidation,
    onSubmit: (values) => {
      completeTaskAndAdjustDatesAction({
        projectId,
        currentTaskId: currentTask.id,
        nextTaskId: nextTask.id,
        startDate: values.startDate,
        endDate: values.startDate
      });
    }
  })

  // side effects
  useEffect(() => {
    if (!isSuccess) return;
    props.onClose();
  }, [isSuccess])

  useEffect(() => {
    if (!isError) return;
    const errors = CoreUtils.Converter.toFormikErrors([
      CoreUtils.ServiceResponseHelper.getServiceError(completeTaskAndAdjustDatesQuery)
    ]);
    formik.setErrors(errors);
  }, [isError])

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      <DialogContent>
        <CoreComponents.TaskLabel label={nextTask.name} />
        <Typography variant="body1" sx={{ mb: 3 }}>
          {currentTask.description}
        </Typography>
        <CoreComponents.FormikDatePicker
          formik={formik}
          name="startDate"
          label="Start Date"
        />
        <CoreComponents.Actions
          submitText="Confirm"
          onCancelClick={() => onClose()}
          onSubmitClick={() => formik.handleSubmit()}
        />
      </DialogContent>
    </>
  )
}
export default TaskViewNextTaskDialogSingleForm;