import { Grid } from "@mui/material";
import OverviewGuard from "./OverviewGuard";
import { BudgetChart } from "./_components/budget-chart";
import { CostsChart } from "./_components/costs-chart";
import { CulmulativeNoOfTasksChart } from "./_components/culmulative-no-of-tasks-chart";
import { NoOfTasksChart } from "./_components/no-of-tasks-chart";

const Overview = () => {
  return (
    <OverviewGuard>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NoOfTasksChart />
        </Grid>
        <Grid item xs={12}>
          <CulmulativeNoOfTasksChart />
        </Grid>
        <Grid item xs={12}>
          <CostsChart />
        </Grid>
        <Grid item xs={12}>
          <BudgetChart />
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <SavingsTable />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={6}>*/}
        {/*  <CashflowChart />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={6}>*/}
        {/*  <CulmulativeCashflowChart />*/}
        {/*</Grid>*/}
      </Grid>
    </OverviewGuard>
  );
};
export default Overview;
