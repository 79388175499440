import { CoreHooks } from "@build-buddy/core";
import { Add as AddIcon } from "@mui/icons-material";
import { Button, IconButton, useTheme } from "@mui/material";

interface TaskViewDocumentsItemUploadButtonProps {
  onAdd(): void;
}
const TaskViewDocumentsItemUploadButton = (props: TaskViewDocumentsItemUploadButtonProps) => {
  const { onAdd } = props;
  const theme = useTheme();
  const mode = CoreHooks.useMode();

  if(mode.isMobile) {
    return (
      <IconButton 
        sx={{ 
          mr: 0.65, 
          color: theme.palette.primary.main 
        }}
        onClick={() => onAdd()}
      >
        <AddIcon />
      </IconButton>
    )
  }

  return (
    <Button
      variant="none"
      onClick={() => onAdd()}
      sx={{
        color: theme.palette.primary.main,
        justifyContent: "flex-start",
        borderRadius: 0,
        mr: 1.25 
      }}
    >
      <AddIcon
        sx={{
          mr: 1.25,
        }}
      />
      Upload document
    </Button>
  )
}
export default TaskViewDocumentsItemUploadButton;