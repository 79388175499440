import { CoreRouting } from "@build-buddy/core";
import { Designer } from "./designer";

import { FinancialRoutes } from "./financials/FinancialsRoutes";
import { GanttChart } from "./gantt-chart";
import { Overview } from "./overview";
import { Selections } from "./selections";

import ProjectGuard from "./ProjectGuard";
import TaskRoutes from "./task/TaskRoutes";
import CalendarRouteDefinitions from "./calendar/CalendarRouteDefinitions";
import DashboardRouteDefinitions from "./dashboard/DashboardRouteDefinitions";
import DocumentRouteDefinitions from "./document/DocumentRouteDefinitions";
import { MyTeamsRoutes, MyTeamsRoutesDefinitions } from "./my-teams/MyTeamsRoutes";
import TestProject from "./TestProject";

const ProjectRoutesDefinitions = {
  PROJECT: {
    path: "/project",
    component: () => <></>
  },
  PROJECT_VIEW: {
    path: "/project/:pid",
    component: () => <></>
  },
  OVERVIEW: {
    path: "/project/:pid/overview/",
    component: Overview,
    name: "Overview",
  },
  GANTT: {
    path: "/project/:pid/gantt/",
    component: GanttChart,
    name: "GANTT Chart",
  },
  DESIGNER: {
    path: "/project/:pid/designer/",
    component: Designer,
    name: "Designers",
  },
  SELECTIONS: {
    path: "/project/:pid/selections",
    component: Selections,
    name: "Selections",
  },
  TEST_PROJECT_CREATE_ROUTE: {
    path: "/test-project-creation",
    name: "Test Project",
    component: TestProject,
  },
  ...MyTeamsRoutesDefinitions,
  ...DashboardRouteDefinitions,
  ...DocumentRouteDefinitions,
  ...CalendarRouteDefinitions,
};

const RootProjectRoutes = [
  ProjectRoutesDefinitions.PROJECT_VIEW,
  ProjectRoutesDefinitions.OVERVIEW,
  ProjectRoutesDefinitions.DOCUMENT_LIBRARY,
  ProjectRoutesDefinitions.GANTT,
  ProjectRoutesDefinitions.DESIGNER,
  ProjectRoutesDefinitions.SELECTIONS,

  ProjectRoutesDefinitions.DASHBOARD_ROOT,
  ProjectRoutesDefinitions.DASHBOARD,

  ProjectRoutesDefinitions.CALENDAR_ROOT,
  ProjectRoutesDefinitions.CALENDAR,
];

const ProjectRoutes = () => {
  return (
    <>
      <CoreRouting.Routes routes={[ProjectRoutesDefinitions.TEST_PROJECT_CREATE_ROUTE]} />
      <ProjectGuard path={["/project"]}>
        <CoreRouting.Routes routes={RootProjectRoutes} />
        <TaskRoutes />
        <FinancialRoutes />
        <MyTeamsRoutes />
      </ProjectGuard>
    </>
  );
};

export { ProjectRoutesDefinitions, ProjectRoutes };
