import { Skeleton, Stack } from "@mui/material";

const TaskViewPaymentsPaySkeleton = () => {
  return (
    <>
      <Skeleton variant="rectangular" height={24} sx={{mt: 4}} />
      <Skeleton variant="rectangular" height={34} sx={{mt: 2}} />
      <Stack direction={{md: 'row'}} sx={{ mt: 5, width: '100%' }}>
        <Skeleton 
          variant="rectangular" 
          height={400} 
          sx={{ 
            mr: 4,
            width: {
              xs: '100%',
              md: '60%'
            }
          }}  
        />
        <Skeleton 
          variant="rectangular" 
          height={270} 
          sx={{ 
            mt: {
              xs: 4,
              md: 0
            },
            width: {
              xs: '100%',
              md: '60%'
            }
          }}  
        />
      </Stack>
    </>
  )
}

export default TaskViewPaymentsPaySkeleton