import { FC, useEffect, useState } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import { useFormikContext } from "formik";
import { Carousel } from "common/components";

interface FormCarouselProps {
  data: Array<any> | null;
  fields: any;
}
const FormCarousel: FC<FormCarouselProps> = (props: FormCarouselProps) => {
  const formik = useFormikContext<any>();
  const [items, setItems] = useState<any>();
  const [field, setField] = useState<any>();
  const error = formik.errors[field?.inputId];  

  const handleChange = (val: any) => {
    formik.setFieldValue(field.inputId, val)
  }

  useEffect(() => {
    if (!props.data) return;
    const i = props.data.map((d: any) => ({
      image: d.image?.path,
      title: d.header,
      description: d.text,
      value: d.value
    }));

    if (!i || !i.length) return;
    setItems(i);
  }, [props.data]);

  useEffect(() => {
    const f = props.fields.find((f: any) => f.inputRef === "value");
    if (!f) return;
    setField(f);
  }, [props.fields]);

  if (!field || !items) return null;

  return (
    <FormControl 
      fullWidth 
      error={Boolean(error)}
    >
      <Carousel
        items={items}
        value={formik.values[field.inputId]}
        error={Boolean(error)}
        onChange={handleChange}
      />
      {Boolean(error) && <FormHelperText> {error as any} </FormHelperText>}
    </FormControl>
  )
}
export default FormCarousel;
