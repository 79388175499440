import { FC } from "react";
import { Box, Button, Theme, Typography, useTheme, styled } from "@mui/material";
import { SxProps } from "@mui/system";
import { css } from "@emotion/react";
import cn from "classnames";

const StyledButton = styled(Button)(({ theme }) => css`
  &.error {
    border: solid 1px ${theme.palette.error.main};
    color: ${theme.palette.error.main};
  }
`)

interface OptionItemProps {
  sx?: SxProps<Theme>;
  active?: boolean;
  title: string;
  caption?: string;
  description?: string;
  image?: any;
  error?: boolean;
  onClick(): void;
  disabled?: boolean;
}
const OptionItem: FC<OptionItemProps> = (props: OptionItemProps) => {
  const handleClick = () => props.onClick();
  const theme = useTheme();
  return (
    <Box sx={{ ...props.sx, background:"white", borderRadius:"0.75rem" }}>
      <StyledButton
        disabled={props.disabled}
        className={cn(
          { "error": props.error }
        )}
        sx={{
          textAlign: "left",
          textTransform: "none",
          width: "100%",
          justifyContent: "flex-start",
          color: (theme) => theme.palette.text.primary,
          background: (theme) => theme.palette.background.paper,
          border: (theme) => props.active ? 
            `solid 1px ${theme.palette.primary.main}` :
            `solid 1px ${theme.palette.grey[300]}`,
          borderRadius: 3,
          px: 3,
          py: 2,
          "&:hover": {
            border: `solid 1px transparent`,
            boxShadow: (theme) => theme.selected.primary,
          }
        }}
        startIcon={
          <Box
            sx={{
              width: "32px",
              height: "32px",
              border: `solid 2px ${theme.palette.grey[300]}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 50,
              mr: 1
            }}
          >
            {props.active &&
              <Box
                sx={{
                  width: "22px",
                  height: "22px",
                  background: theme.palette.primary.main,
                  borderRadius: 50
                }}
              />
            }
          </Box>
        }
        onClick={handleClick}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center"
          }}
        >
          <Box
            sx={{ flex: 1 }}
          >
            <Typography variant="h5"> {props.title} </Typography>
            {props.caption &&
              <Typography variant="caption"> {props.caption} </Typography>
            }
            {props.description &&
              <Typography variant="body1"> {props.description} </Typography>
            }
          </Box>
          {props.image && (
            <Box
              sx={{
                background: "#555",
                borderRadius: 100,
                height: { xs: 48, md: 64 },
                width: { xs: 48, md: 64 },
                my: { xs: 1, md: 1.5 },
              }}
            >
              <img
                src={props.image}
                onError={(e: any) => { e.target.onerror = null; e.target.src = "/assets/placeholder.png" }}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover"
                }}
              />
            </Box>
          )}
        </Box>
      </StyledButton>
    </Box>
  );
};

export default OptionItem;
