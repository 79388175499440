import { CoreUtils } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { add, differenceInDays, endOfDay, startOfDay } from "date-fns";
import { SelectProjectTaskListParams, selectProjectTaskListRequired } from "modules/project/_selectors";

export const selectCalendarTaskListData = createSelector(
  [selectProjectTaskListRequired],
  (calenderTaskList) => {
    if(!calenderTaskList) return;
    
    // get the min & max dates & calculate the difference to get total duration of days
    const min = new Date(Math.min.apply(null, calenderTaskList.map(x => new Date(x.task.startDate).getTime())));
    const max = new Date(Math.max.apply(null, calenderTaskList.map(x => new Date(x.task.endDate).getTime())));
    const duration = differenceInDays(max, min);

    const rtn: any = [];
    for (let i = 0; i < duration; i++) {
      const currentDay = add(new Date(min), { days: i });
      const temp: any = []
      calenderTaskList
        // find the tasks which fall between the current day
        .filter(task => {
          const start = startOfDay(new Date(task.task.startDate));
          const end = endOfDay(new Date(task.task.endDate));
          return CoreUtils.isBetween(currentDay, start, end);
        })
        // go through each found task and add them to the temp array
        .forEach(task => {
          const filterStatus = task.filterStatuses[0];
          // see if the filterStatus obj exists within temp
          let obj = temp.find((x: any) => x.type === filterStatus);
          // if it doesnt exist, create it and add it to the temp array
          if(!obj) {
            obj = {
              id: `${filterStatus}-${currentDay.getTime()}`,
              type: filterStatus,
              start: startOfDay(currentDay),
              end: endOfDay(currentDay),
              count: 0
            }
            temp.push(obj);
          }
          // increment the count of the filterStatus obj
          obj.count = obj.count + 1;
        })
      rtn.push(temp);
    }
    return rtn.flat();
  }
)

interface SelctCalendarTaskListByDateParams extends SelectProjectTaskListParams {
  date: Date;
}
export const selctCalendarTaskListByDate = createSelector(
  [
    (state, params: SelctCalendarTaskListByDateParams) => selectProjectTaskListRequired(state, { projectId: params.projectId }),
    (state, params: SelctCalendarTaskListByDateParams) => ({ state, params })
  ],
  (taskList, meta) => {
    if (!taskList) return [];
    return taskList.filter(x => CoreUtils.isBetween(
      startOfDay(meta.params.date),
      startOfDay(new Date(x.task.startDate)),
      endOfDay(new Date(x.task.endDate)),
      "[]"
    ))
  }

)