import { CoreState } from "@build-buddy/core";
import React from "react";
import { TaskViewAdminActions, TaskViewAdminContent } from "./task-view-admin";
import { TaskViewAwaitingDetailSTContent } from "./task-view-awaiting-detailST";
import { TaskViewBookingActions, TaskViewBookingContent } from "./task-view-booking";
import { TaskViewCertifyActions, TaskViewCertifyContent } from "./task-view-certify";
import { TaskViewCompleteActions, TaskViewCompleteContent } from "./task-view-complete";
import { TaskViewConfirmActions, TaskViewConfirmContent } from "./task-view-confirm";
import { TaskViewConfirmDeliveryActions, TaskViewConfirmDeliveryContent } from "./task-view-confirm-delivery";
import { TaskViewConfirmOffSiteActions, TaskViewConfirmOffSiteContent } from "./task-view-confirm-offsite";
import { TaskViewConfirmOnSiteActions, TaskViewConfirmOnSiteContent } from "./task-view-confirm-onsite";
import { TaskViewConfirmOrderActions, TaskViewConfirmOrderContent } from "./task-view-confirm-order";
import { TaskViewLodgeActions, TaskViewLodgeContent } from "./task-view-lodge";
import { TaskViewManageActions, TaskViewManageContent } from "./task-view-manage";
import { TaskViewManageOffSiteActions, TaskViewManageOffSiteContent } from "./task-view-manage-off-site";
import { TaskViewManageOnSiteActions, TaskViewManageOnSiteContent } from "./task-view-manage-on-site";
import { TaskViewOrderActions, TaskViewOrderContent } from "./task-view-order";
import { TaskViewQualityCheckActions, TaskViewQualityCheckContent } from "./task-view-quality-check";
import { TaskViewRecieveDeliveryActions, TaskViewRecieveDeliveryContent } from "./task-view-receive-delivery";
import { TaskViewRecieveUploadActions, TaskViewRecieveUploadContent } from "./task-view-recieve-upload";
import { TaskViewRequestActions, TaskViewRequestContent } from "./task-view-request";
import { TaskViewScheduleActions, TaskViewScheduleContent } from "./task-view-schedule";
import { TaskViewSelectContent } from "./task-view-select";
import { TaskViewShowMediaActions, TaskViewShowMediaContent } from "./task-view-show-media";
import { TaskViewSupplyActions, TaskViewSupplyContent } from "./task-view-supply";
import { TaskViewIntMeetingActions, TaskViewIntMeetingContent } from "./task-view-intmeeting";

type KVP = Record<CoreState.Task.TaskType, {
  content: React.ReactNode,
  actions: React.ReactNode,
}>
const kvp: KVP = {
  [CoreState.Task.TaskType.Admin]: {
    content: <TaskViewAdminContent />,
    actions: <TaskViewAdminActions />,
  },
  [CoreState.Task.TaskType.AwaitingDetailST]: {
    content: <TaskViewAwaitingDetailSTContent />,
    actions: <></>,
  },
  [CoreState.Task.TaskType.Booking]: {
    content: <TaskViewBookingContent />,
    actions: <TaskViewBookingActions />,
  },
  [CoreState.Task.TaskType.Certify]: {
    content: <TaskViewCertifyContent />,
    actions: <TaskViewCertifyActions />,
  },
  [CoreState.Task.TaskType.CertInspect]: {
    content: <TaskViewCertifyContent />,
    actions: <TaskViewCertifyActions />,
  },
  [CoreState.Task.TaskType.Complete]: {
    content: <TaskViewCompleteContent />,
    actions: <TaskViewCompleteActions />
  },
  [CoreState.Task.TaskType.Confirm]: {
    content: <TaskViewConfirmContent />,
    actions: <TaskViewConfirmActions />,
  },
  [CoreState.Task.TaskType.ConfirmDelivery]: {
    content: <TaskViewConfirmDeliveryContent />,
    actions: <TaskViewConfirmDeliveryActions />,
  },
  [CoreState.Task.TaskType.ConfirmOffsite]: {
    content: <TaskViewConfirmOffSiteContent />,
    actions: <TaskViewConfirmOffSiteActions />,
  },
  [CoreState.Task.TaskType.ConfirmOnsite]: {
    content: <TaskViewConfirmOnSiteContent />,
    actions: <TaskViewConfirmOnSiteActions />,
  },
  [CoreState.Task.TaskType.ConfirmOrder]: {
    content: <TaskViewConfirmOrderContent />,
    actions: <TaskViewConfirmOrderActions />,
  },
  [CoreState.Task.TaskType.Lodge]: {
    content: <TaskViewLodgeContent />,
    actions: <TaskViewLodgeActions />
  },
  [CoreState.Task.TaskType.Manage]: {
    content: <TaskViewManageContent />,
    actions: <TaskViewManageActions />,
  },
  [CoreState.Task.TaskType.ManageOffsite]: {
    content: <TaskViewManageOffSiteContent />,
    actions: <TaskViewManageOffSiteActions />,
  },
  [CoreState.Task.TaskType.ManageOnsite]: {
    content: <TaskViewManageOnSiteContent />,
    actions: <TaskViewManageOnSiteActions />,
  },
  [CoreState.Task.TaskType.Order]: {
    content: <TaskViewOrderContent />,
    actions: <TaskViewOrderActions />,
  },
  [CoreState.Task.TaskType.QualityCheck]: {
    content: <TaskViewQualityCheckContent />,
    actions: <TaskViewQualityCheckActions />,
  },
  [CoreState.Task.TaskType.ReceiveAndUpload]: {
    content: <TaskViewRecieveUploadContent />,
    actions: <TaskViewRecieveUploadActions />,
  },
  [CoreState.Task.TaskType.Request]: {
    content: <TaskViewRequestContent />,
    actions: <TaskViewRequestActions />,
  },
  [CoreState.Task.TaskType.ReceiveDelivery]: {
    content: <TaskViewRecieveDeliveryContent />,
    actions: <TaskViewRecieveDeliveryActions />,
  },
  [CoreState.Task.TaskType.Schedule]: {
    content: <TaskViewScheduleContent />,
    actions: <TaskViewScheduleActions />,
  },
  [CoreState.Task.TaskType.Select]: {
    content: <TaskViewSelectContent />,
    actions: <></>,
  },
  [CoreState.Task.TaskType.ShowMedia]: {
    content: <TaskViewShowMediaContent />,
    actions: <TaskViewShowMediaActions />,
  },
  [CoreState.Task.TaskType.Supply]: {
    content: <TaskViewSupplyContent />,
    actions: <TaskViewSupplyActions />,
  },
  [CoreState.Task.TaskType.IntMeeting]:{
    content: <TaskViewIntMeetingContent />,
    actions: <TaskViewIntMeetingActions />
  }
}

const taskViewTypeFactory = (type: CoreState.Task.TaskType | undefined) => {
  if(!type) return;
  return kvp[type];
}

export default taskViewTypeFactory;