import TaskViewDocuments from "./TaskViewDocuments";
import TaskViewDocumentsAdd from "./task-view-documents-add/TaskViewDocumentsAdd";

const TaskViewDocumentsRouteDefinitions = {
  TASK_VIEW_DOCUMENTS: {
    label: "Documents",
    path: "/*/stage/:sid/task/:tid/documents",
    component: TaskViewDocuments
  },
  TASK_VIEW_DOCUMENTS_ADD: {
    path: "/*/stage/:sid/task/:tid/documents/add/:tagCode",
    component: TaskViewDocumentsAdd
  },
}

export default TaskViewDocumentsRouteDefinitions;