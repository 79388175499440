import { TypeGuards } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import { useTaskViewParams } from "../../../_hooks";
import useTaskViewTypesTabsCompany from "./useTaskViewTypesTabsCompany";
import useTaskViewTypesTabsVariation from "./useTaskViewTypesTabsVariation";
import useTaskViewTypesTabsDescription from "./useTaskViewTypesTabsDescription";
import useTaskViewTypesTabsDocument from "./useTaskViewTypesTabsDocument";
import useTaskViewTypesTabsMessages from "./useTaskViewTypesTabsMessages";
import useTaskViewTypesTabsPayments from "./useTaskViewTypesTabsPayments";
import useTaskViewTypesTabsQualityAssurance from "./useTaskViewTypesTabsQualityAssurance";
import useTaskViewTypesTabsQuotes from "./useTaskViewTypesTabsQuotes";
import useTaskViewTypesTabsScopeOfWork from "./useTaskViewTypesTabsScopeOfWork";

const useTaskViewTypesTabs = () => {

  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId };

  const defectsVariationTab = useTaskViewTypesTabsVariation();
  const descriptionTab = useTaskViewTypesTabsDescription();
  const documentTab = useTaskViewTypesTabsDocument();
  const scopeOfWorkTab = useTaskViewTypesTabsScopeOfWork();
  const companyTab = useTaskViewTypesTabsCompany();
  const messagesTab = useTaskViewTypesTabsMessages();
  const paymentsTab = useTaskViewTypesTabsPayments();
  const quotesTab = useTaskViewTypesTabsQuotes();
  const qualityAssuranceTab = useTaskViewTypesTabsQualityAssurance();

  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));

  // TODO: move logic to show hide the tabs inside their individual hooks.
  if (projectTask?.task.parentIsDIY) {
    return [
      descriptionTab,
      scopeOfWorkTab,
      documentTab,
      qualityAssuranceTab,
      paymentsTab
    ].filter(TypeGuards.nonNullable);
  }

  return [
    descriptionTab,
    scopeOfWorkTab,
    documentTab,
    quotesTab,
    companyTab,
    defectsVariationTab,
    messagesTab,
    paymentsTab,
    qualityAssuranceTab
  ].filter(TypeGuards.nonNullable);
}

export default useTaskViewTypesTabs