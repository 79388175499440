import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import TaskViewRouteDefinitions from "../../../TaskViewRouteDefinitions";
import { useTaskViewParams } from "../../../_hooks";

const useTaskViewTypesTabsDescription = () => {
  // hooks
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const docParams = { projectId, taskId };
  const projectTaskParams = { projectId, stageId, taskId };

  // queries
  CoreState.File.getDocListByTask.useQuery(docParams);

  // selectors
  const count = useSelector((s) => CoreState.File.selectDocListByTaskExcludedTags(s, docParams)).length
  const projectTask = useSelector((s) => selectProjectTask(s, projectTaskParams));

  if (projectTask?.task.type === CoreState.Task.TaskType.ShowMedia) return null;

  return {
    ...TaskViewRouteDefinitions.TASK_VIEW_DOCUMENTS,
    label: "Documents",
    count
  }
}
export default useTaskViewTypesTabsDescription;