import { CoreState, CoreUtils } from "@build-buddy/core";
import { useTheme } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { format } from "date-fns";
import { chain, groupBy, sortBy } from "lodash";
import { useMemo } from "react";
import getChartColour from "../../getChartColour";

const useNoOfTaskChart = () => {
  const theme = useTheme();
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);
  const getProjectStageListQuery = CoreState.Project.getProjectStageList.useQuery(
    { projectId: projectId },
    { skip: !projectId }
  )
  const getTaskListByProjectQuery = CoreState.Task.getTaskListByProject.useQuery({
    projectId: projectId,
    filter: {
      includeStatus: [CoreState.Task.TaskStatus.Initial],
      pageNumber: 1,
      pageSize: 9999,
    }
  })

  const chart = useMemo(() => {
    if (getProjectStageListQuery.isFetching || getProjectStageListQuery.isLoading) return;
    if (getTaskListByProjectQuery.isFetching || getTaskListByProjectQuery.isLoading) return;
    if (!getProjectStageListQuery.data || !getTaskListByProjectQuery.data) return;
    
    const tasks = getTaskListByProjectQuery.data;
    const stages = getProjectStageListQuery.data;
    const grouped = chain(tasks).groupBy("stageId").toArray().value();
    const kvpStages = CoreUtils.Converter.toKvp("id", stages);

    const initial = grouped.map((group, idx) => {
      // sort by date
      const sorted = sortBy(group, ["endDate"]);

      // group all objects by month & year
      const g = groupBy(sorted, (o) => {
        if (!o.endDate) return;
        const date = new Date(o.endDate);
        const month = format(date, "MMM");
        const year = format(date, "yyyy");
        return `${month} ${year}`
      });

      const keyValuePair: any = {};

      Object.keys(g).forEach((x) => {
        keyValuePair[x] = g[x].length
      })

      return {
        name: kvpStages[group[0].stageId]?.name ?? "No name",
        data: keyValuePair
      };
    })

    // using the initial object, get all unique keys so we can create the chart x-axis
    const xAxis = [...new Set(initial.map(x => Object.keys(x.data)).flat())]

    const data = initial.map((x, idx) => {
      // create an array of 0's with a consistent x-axis categories
      const data: any = new Array(xAxis.length).fill(0);

      Object.keys(x.data).forEach((key) => {
        const idx = xAxis.findIndex(x => x === key);
        if (idx === -1) return;
        data[idx] = x.data[key];
      })

      // populate the chart object
      return {
        name: x.name,
        stack: { type: "normal" },
        data,
        color: getChartColour(idx, theme)
      }
    });

    const legend = data.map(x => ({ name: x.name, color: x.color }))

    return {
      data,
      legend,
      xAxis
    };
  }, [getTaskListByProjectQuery.data, getProjectStageListQuery.data])

  return {
    chart,
    isLoading: (getTaskListByProjectQuery.isLoading || getProjectStageListQuery.isLoading || getTaskListByProjectQuery.isFetching || getProjectStageListQuery.isFetching)
  }
}
export default useNoOfTaskChart;