import * as Yup from "yup";

const DocumentLibraryAddFormValidation = Yup.object()
  .shape({
    tag: Yup
      .mixed()
      .test("tagRequired", "Please select an option", (value) => value.code && value.name),
    files: Yup
      .mixed()
      .test("fileRequired", "File is required.", (value) => value.toSave?.length)
  })

export default DocumentLibraryAddFormValidation;