import { CoreComponents, CoreRouting } from "@build-buddy/core";
import { Box, Card, CardContent, Stack, useTheme } from "@mui/material";
import { BudgetLegend } from "../_components";
import BudgetItemList, { BudgetItemListMode } from "../_components/budget-item-list/BudgetItemList";
import { BudgetTrackingItemRoutesDefinition } from "../budget-tracking-item/BudgetTrackingItemRoutes";

const EstimatedBudget = () => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  const handleClick = () => {
    const params = { code: "999999" };
    routing.go(BudgetTrackingItemRoutesDefinition.BUDGET_TRACKING_ITEM, { params })
  }

  return (
    <Card sx={{ overflow: "visible" }}>
      <CardContent sx={{ pt: 1 }}>
        <Stack
          direction="row"
          sx={{
            pt: 1.5,
            pb: 2,
            mx: -2,
            px: 2,
            position: "sticky",
            top: 112,
            zIndex: theme.zIndex.sticky,
            background: theme.palette.common.white,
          }}
        >
          <BudgetLegend
            sx={{ minWidth: "780px" }}
          />
          <Box
            sx={{
              display: "flex",
              textAlign: "right",
            }}
          >
            <CoreComponents.AddButton
              label="Add Budget Item"
              sx={{
                ml: 1,
                mr: 1,
                maxWidth: {
                  xs: "initial",
                  lg: "150px",
                  xl: "initial",
                },
                fontSize: {
                  xs: "14px",
                  lg: "12px",
                  xl: "14px"
                }
              }}
              onClick={() => handleClick()}
            />
            <CoreComponents.Textbox
              name=""
              size="small"
              sx={{
                mb: 0,
                width: {
                  xs: "100%",
                  lg: "auto",
                  xl: "100%"
                }
              }}
              onChange={() => { }}
            />
          </Box>
        </Stack>
        <Box>
          <BudgetItemList defaultMode={BudgetItemListMode.Estimate} />
        </Box>
      </CardContent>
    </Card>
  )
}
export default EstimatedBudget;