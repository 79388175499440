import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog } from "@mui/material";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import DocumentRouteDefinitions from "../../DocumentRouteDefinitions";
import { selectDocumentLibraryTagListFiltered } from "../_selectors/selectDocumentLibrary";
import DocumentLibraryAddForm from "./DocumentLibraryAddForm";

const DocumentLibraryAdd = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId } = useProjectParams();
  const getDocListByProjectParams = { projectId };

  // queries
  const getDocListByProjectQuery = CoreState.File.getDocListByProject.useQuery(getDocListByProjectParams)
  const getTagListByTaskDocumentQuery = CoreState.Tag.getTagListByTypeDocument.useQuery();

  // locals
  const tagList = useSelector((s) => selectDocumentLibraryTagListFiltered(s, getDocListByProjectParams));
  const isLoading = 
    Boolean(getTagListByTaskDocumentQuery.isLoading || getTagListByTaskDocumentQuery.isUninitialized) ||
    Boolean(getDocListByProjectQuery.isLoading || getDocListByProjectQuery.isUninitialized)

  // events
  const handleClose = () => {
    routing.go(DocumentRouteDefinitions.DOCUMENT_LIBRARY);
  };

  return (
    <Dialog
      open={true}
      onClose={() => { }}
    >
      <CoreComponents.DialogHeader title="Upload a document" onClose={() => handleClose()} />
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={false}
      >
        <DocumentLibraryAddForm
          tags={tagList}
          onSuccess={() => handleClose()}
        />
      </CoreComponents.PreContent>
    </Dialog>
  )
}
export default DocumentLibraryAdd;