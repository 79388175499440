import { CoreComponents, CoreState } from "@build-buddy/core";
import { Dialog } from "@mui/material";
import TaskViewNextTaskDialogHeader from "../TaskViewNextTaskDialogHeader";
import TaskViewNextTaskDialogForm from "./TaskViewNextTaskDialogRangeForm";

type TaskViewNextTaskDialogRangeProps = {
  title: string;
  nextTask: CoreState.Task.Task | undefined;
  currentTask: CoreState.Task.Task | undefined;
  isLoading: boolean;
  open: boolean;
  onClose(): void;
}
/**
 * @description This is the dialog to show 2 date picker ranges for when going to the next task
 */
const TaskViewNextTaskDialogRange = (props: TaskViewNextTaskDialogRangeProps) => {
  const { nextTask, currentTask, isLoading, title } = props;

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
    >
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={Boolean(!nextTask || !currentTask)}
      >
        <CoreComponents.DialogHeader
          title={
            <TaskViewNextTaskDialogHeader
              task={nextTask}
              title={title}
            />
          }
          onClose={() => props.onClose()}
        />
        <TaskViewNextTaskDialogForm
          nextTask={nextTask as CoreState.Task.Task}
          currentTask={currentTask as CoreState.Task.Task}
          onClose={props.onClose}
        />
      </CoreComponents.PreContent>
    </Dialog>
  )
}
export default TaskViewNextTaskDialogRange;