import { CoreComponents, CoreState, Truncate } from '@build-buddy/core';
import { Stack, Typography } from '@mui/material'
import ProjectSkeleton from './ProjectSkeleton';

type ProjectProps = {
  project: CoreState.Project.Project;
  isBuildBuddyHelpDesk?: boolean;
  isLoading: boolean;
}

const Project = (props: ProjectProps) => {
  return (
    <CoreComponents.PreContent
      isLoading={props.isLoading}
      loader={<ProjectSkeleton />}
      isEmpty={!props.project}
      empty={
        <Stack direction={'column'}>
          <Truncate
            lines={2}
            text={'No Project Found!'}
            TypographyProps={{
              variant: "subtitle2",
              fontWeight: 600,
            }}
          />
        </Stack>
      }
    >
      <Stack direction={'column'}>
        <Typography
          variant="subtitle1"
          fontWeight={600}
          color={'primary'}
        >
          {props.isBuildBuddyHelpDesk ?
            `${props.project?.siteOwner.lastName}, ${props.project?.siteOwner.firstName}` :
            `${props.project?.name}`}
        </Typography>
        <Truncate
          lines={2}
          text={
            props.isBuildBuddyHelpDesk ?
              `${props.project?.address?.fullAddress} - ${props.project?.name}` :
              `${props.project?.address?.fullAddress}`
          }
          TypographyProps={{
            variant: "subtitle2",
            fontWeight: 600,
          }}
        />
      </Stack>
    </CoreComponents.PreContent>
  )
}

export default Project