import { CoreRouting, CoreUtils } from "@build-buddy/core";
import { Box, useTheme } from "@mui/material";
import { SchedulerViewSlot } from "@progress/kendo-react-scheduler";
import { startOfDay } from "date-fns";
import CalendarRouteDefinitions from "../CalendarRouteDefinitions";
import { useCalendarParams } from "../_hooks";

const CalendarViewMonthViewSlot = (props: any) => {
  const routing = CoreRouting.useRouting();
  const theme = useTheme();

  // params
  const { day, activeDate } = useCalendarParams();

  // locals
  const date = startOfDay(props.zonedStart._utcDate);
  const today = startOfDay(new Date());
  const selected = day && startOfDay(activeDate);

  const isToday = Boolean(date.getTime() === today.getTime());
  const isActive = Boolean(selected && (date.getTime() === selected.getTime()));

  const handleClick = () => {
    const params = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }
    routing.go(CalendarRouteDefinitions.CALENDAR_DRAWER, { params });
  }

  return (
    <SchedulerViewSlot
      {...props}
      style={{
        ...props.style,
        justifyContent: "center",
        cursor: "pointer",
        borderWidth: isActive ? "2px" : "1px",
        borderColor: isActive ? theme.palette.primary.main : "inherit",
        transition: "border-color 0.25s"
      }}
      onClick={() => handleClick()}
    >
      <Box
        sx={{
          borderRadius: 100,
          width: 24,
          height: 24,
          background: isToday ? theme.palette.primary.main : "inherit",
          color: isToday ? theme.palette.primary.contrastText : "inherit",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {CoreUtils.Formatter.date(props.zonedStart._utcDate, "d")}
      </Box>
    </SchedulerViewSlot>
  )
}
export default CalendarViewMonthViewSlot;