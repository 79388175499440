import { CoreUtils } from "@build-buddy/core";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import { selectProject } from "modules/project/_selectors";

const DashboardFinancialsDetail = () => {
  const params = useProjectParams();
  const project = useSelector(s => selectProject(s, { projectId: params.projectId }));
  return (
    <Card
      sx={{
        flex: 1,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Box>
          <Typography
            variant="body1"
          >
            Projected Completion Date
          </Typography>
          <Typography
            variant="h1"
            sx={{
              mt: 1,
              fontSize: {
                xs: "18px",
                sm: "24px"
              }
            }}
          >
            {CoreUtils.Formatter.date(project?.endDate) || "Not Applicable"}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
export default DashboardFinancialsDetail;