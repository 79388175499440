import {
  CoreRouting,
  CoreState
} from '@build-buddy/core';
import { CommonComponents } from "common";
import { BudgetExpenseRoutesDefinitions, useBudgetExpenseParams } from '../../../budget-expense/BudgetExpenseRoutes';
import BudgetTrackingExpenseActions from './BudgetItemListTrackingExpenseActions';

interface BudgetTrackingExpensesProps {
  taskId: string;
}

const BudgetItemListTrackingExpenses = (props: BudgetTrackingExpensesProps) => {
  //props
  const { taskId } = props;

  // hooks
  const routing = CoreRouting.useRouting();
  
  // params
  const { projectId, stageId } = useBudgetExpenseParams();

  const params = {
    taskId,
    stageId,
    projectId,
  }

  // handlers
  const handleAddClick = () => {
    routing.go(BudgetExpenseRoutesDefinitions.ADD_BUDGET_TRACKING_EXPENSE, { params: { tid: taskId }, persistSearch: true });
  }

  const handleExpenseClick = (expense: CoreState.Financials.Expense) => {
    const params = {
      tid: taskId,
      psid: expense.id
    }
    routing.go(BudgetExpenseRoutesDefinitions.VIEW_BUDGET_TRACKING_EXPENSE, { params, persistSearch: true })
  }

  return (
    <CommonComponents.ExpenseList
      params={params}
      onExpenseClick={handleExpenseClick}
      onExpenseAdd={handleAddClick}
      ActionsComponent={BudgetTrackingExpenseActions}
    />
  )
}

export default BudgetItemListTrackingExpenses;