import { CoreComponents, CoreState } from "@build-buddy/core";
import { Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTask, selectProjectTaskStatus } from "modules/project/_selectors";
import { useTaskViewParams } from "../../_hooks";

const TaskViewOrderActions = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, taskParams));

  // mutations
  const [completeTaskAction, completeTaskQuery] = CoreState.Task.completeTask.useMutation()

  // vars
  const showActions = (projectTaskStatus !== ProjectTaskStatus.Completed)
  const isSaving = completeTaskQuery.isLoading;

  const handleComplete = () => {
    if (!projectTask) return;
    completeTaskAction({
      projectId: projectId,
      parentId: projectTask.task.parentId,
      taskId: projectTask.task.id
    })
  }

  if (!showActions) return null;

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      <Button
        fullWidth
        variant="contained"
        onClick={handleComplete}
      >
        Mark Complete
      </Button>
    </>
  )
}
export default TaskViewOrderActions;