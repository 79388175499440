import { CoreRouting, CoreState } from "@build-buddy/core";
import { Add as AddIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTask, selectProjectTaskStatus } from "modules/project/_selectors";
import { TaskViewRouteDefinitions } from "modules/project/task/task-view";
import { Route } from "react-router";
import { useTaskViewParams } from "../../_hooks";
import { selectTaskViewQuoteListAcceptedQuote } from "../../_selectors/selectTaskViewQuoteList";
import TaskViewScopeOfWorkSpecList from "./TaskViewScopeOfWorkSpecList";

const TaskViewScopeOfWorkSpec = () => {
  const routing = CoreRouting.useRouting();
  const theme = useTheme();

  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const taskParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, taskParams));
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, quoteListParams));

  // locals
  const showAdd = (projectTask?.task.type === CoreState.Task.TaskType.Request
    || (projectTaskStatus === ProjectTaskStatus.New && !acceptedQuote && !projectTask?.task.parentIsDIY));

  // event handlers
  const handleAdd = () => {
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_SCOPE_OF_WORK_SPEC_ADD);
  }

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 1.5 }}>
        <KeyboardArrowDownIcon sx={{ mr: 1.25 }} />
        <Typography variant="h2">
          Specifications
        </Typography>
      </Stack>
      {showAdd &&
        <Button
          variant="none"
          onClick={() => handleAdd()}
          sx={{
            color: theme.palette.primary.main,
            justifyContent: "flex-start",
            borderRadius: 0,
            mb: 1,
            mx: -1
          }}
        >
          <AddIcon sx={{ mr: 1.25 }} />
          Add a specification
        </Button>
      }
      <TaskViewScopeOfWorkSpecList />
      <Route
        path={TaskViewRouteDefinitions.TASK_VIEW_SCOPE_OF_WORK_SPEC_ADD.path}
        component={TaskViewRouteDefinitions.TASK_VIEW_SCOPE_OF_WORK_SPEC_ADD.component}
      />
      <Route
        path={TaskViewRouteDefinitions.TASK_VIEW_SCOPE_OF_WORK_SPEC_EDIT.path}
        component={TaskViewRouteDefinitions.TASK_VIEW_SCOPE_OF_WORK_SPEC_EDIT.component}
      />
    </>
  )
}
export default TaskViewScopeOfWorkSpec;