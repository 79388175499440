import { CoreHooks, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useMemo } from "react";

export interface GanttTask {
  id: string;
  title: string;
  stageId: string;
  start: Date;
  end: Date;
  percentComplete: number;
  predecessors: Array<CoreState.Task.Predecessor>
}

const useGanttChartData = (expanded: Array<string>) => {
  const mode = CoreHooks.useMode()
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);
  const getProjectStageListQuery = CoreState.Project.getGanttChartByProject.useQuery(
    { projectId: projectId },
    { skip: !projectId }
  )

  const tasks = useMemo(() => {
    return getProjectStageListQuery?.data?.map((ganttItem: CoreState.Task.GanttChart) => {
      const isExpanded = expanded.includes(ganttItem.projectStage.id);
      const children = ganttItem.taskStages.map((s: CoreState.Task.TaskStage) => ({
        id: s.id,
        title: s.name,
        stageId: ganttItem.projectStage.id,
        start: new Date(s.startDate),
        end: new Date(s.endDate),
        percentComplete: s.percentage / 100,
        predecessors: s.predecessors
      }));
      return {
        id: ganttItem.projectStage.id,
        title: ganttItem.projectStage.name,
        start: ganttItem.projectStage.startDate ? new Date(ganttItem.projectStage.startDate) : null,
        end: ganttItem.projectStage.endDate ? new Date(ganttItem.projectStage.endDate) : null,
        isExpanded: mode.isDesktop ? isExpanded : true,
        percentComplete: ganttItem.projectStage.progress.completedPercent / 100,
        children: children,
      }
    })
  }, [getProjectStageListQuery.data, expanded, projectId])

  const tasksDependencies = useMemo(() => {
    const data: any = []

    getProjectStageListQuery?.data?.forEach(x => {
      x.taskStages.forEach((stage: CoreState.Task.TaskStage) => {
        stage.predecessors.forEach((predecessor: CoreState.Task.Predecessor) => {
          data.push({
            fromId: predecessor.id,
            toId: stage.id,
            id: predecessor.id,
            type: 1
          })
        })
      })
    });
    return data;
  }, [getProjectStageListQuery.data, projectId]);

  return {
    tasks,
    tasksDependencies,
    isLoading: getProjectStageListQuery.isLoading || getProjectStageListQuery.isFetching,
  }
}
export default useGanttChartData;