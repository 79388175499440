import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

export interface UnlockedBudgetDraftTemplateProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  onClick?(): void;
}
const UnlockedBudgetDraftTemplate = (props: UnlockedBudgetDraftTemplateProps) => {
  return (
    <Box    
      sx={{
        display: "grid",
        gap: 3,
        gridTemplateAreas: `
          'code name qty rate total icon'
        `,
        gridTemplateColumns: "100px auto 100px 100px 100px 50px",
        cursor: props.onClick ? "pointer" : "default",
        ...props.sx
      }}
      onClick={() => props.onClick && props.onClick()}
    >
      {props.children}
    </Box>
  )
}
export default UnlockedBudgetDraftTemplate;