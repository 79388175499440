import { CoreComponents, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import { selectProjectCompletedPercent } from "modules/project/_selectors/selectProject";
import { useState } from "react";
import { selectDashboardProjectStageList } from "./_selectors";
import DashboardProjectBreakdownMobileHeader from "./DashboardProjectBreakdownMobileHeader";
import { ProjectBreakdownItem } from "modules/project/_components";

const DashboardProjectBreakdownMobile = () => {
  const params = useProjectParams();
  const [open, setOpen] = useState(false);

  // queries
  const getProjectStageListQuery = CoreState.Project.getProjectStageList.useQuery(
    { projectId: params.projectId },
    { skip: !params.projectId }
  );

  // selectors
  const projectCompletedPercent = useSelector((s) => selectProjectCompletedPercent(s, { projectId: params.projectId }));
  const projectStageList = useSelector((s) => selectDashboardProjectStageList(s, { projectId: params.projectId }));

  // vars
  const isLoading = getProjectStageListQuery.isLoading;

  if (isLoading) return null;

  return (
    <>
      <CoreComponents.CollapseHeader
        sx={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          gap: 2
        }}
        TypographyProps={{
          sx: {
            flex: 1
          }
        }}
        title={
          <DashboardProjectBreakdownMobileHeader
            onClick={() => setOpen(prevVal => !prevVal)}
            projectCompletedPercent={projectCompletedPercent}
          />
        }
        open={open}
      >
        <Box
          sx={{
            mt: 3,
            px: 1,
          }}
        >
          {projectStageList.map((ps, idx) => (
            <ProjectBreakdownItem
              key={idx}
              name={ps.name}
              total={ps.progress.totalTasks}
              completed={ps.progress.completedTasks}
              percent={ps.progress.completedPercent}
            />
          ))}
        </Box>
      </CoreComponents.CollapseHeader>
    </>
  )
}
export default DashboardProjectBreakdownMobile;
