import { CoreState } from "@build-buddy/core";
import { useTheme } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useMemo } from "react";
import getChartColour from "../../getChartColour";

const useCostsChart = () => {
  const theme = useTheme();
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);
  const getProjectFinancialsQuery = CoreState.ProjectFinancials.getProjectFinancials.useQuery(
    { projectId: projectId },
    { skip: !projectId }
  );
  
  const chart = useMemo(() => {
    if (!getProjectFinancialsQuery.data) return;
    const financials = getProjectFinancialsQuery.data.financials;
    return [
      {
        name: "Paid",
        data: [financials.paid],
        color: getChartColour(0, theme),
        stack: { type: "normal" }
      },
      {
        name: "Payments Due",
        data: [financials.due],
        color: getChartColour(1, theme),
      },
      {
        name: "Committed",
        data: [financials.committed],
        color: getChartColour(2, theme),
      },
      {
        name: "Project Forecast",
        data: [financials.forecast],
        color: getChartColour(3, theme),
      },
      {
        name: "Project Variation",
        data: [financials.variations],
        color: getChartColour(4, theme),
      },
      {
        name: "Savings",
        data: [financials.savings],
        color: getChartColour(5, theme),
      }
    ]
  }, [getProjectFinancialsQuery.data])

  return {
    chart,
    isLoading: getProjectFinancialsQuery.isLoading
  }
}
export default useCostsChart;
