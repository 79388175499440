import { CoreModules } from "@build-buddy/core";
import { TaskViewOutlineStartEndDate } from "../../task-view-outline";
import TaskViewTabs from "../_common/TaskViewTabs";

const TaskViewRecieveUploadContent = () => {
  return (
    <>
      <CoreModules.Task.TaskView.TaskViewOutline>
        <TaskViewOutlineStartEndDate />
      </CoreModules.Task.TaskView.TaskViewOutline>
      <TaskViewTabs />
    </>
  )
}
export default TaskViewRecieveUploadContent;