import { CoreComponents, CoreState } from "@build-buddy/core";
import { MenuItem, useTheme } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { selectProject } from "modules/project/_selectors";
import { selectIsBuildBuddyHelpDesk } from "modules/project/_selectors/selectProjectAccessLevel";
import { useState } from "react";
import ProjectSelectListItem from "./ProjectSelectListItem";

interface ProjectSelectListProps {
  onClick(): void;
}
const ProjectSelectList = ({ onClick }: ProjectSelectListProps) => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState('')
  const getUserProjectListQuery = CoreState.Project.getUserProjectList.useQuery();
  const projects = getUserProjectListQuery.data;

  // Selectors
  const currentProjectId = useSelector(CommonState.App.selectCurrentProjectId);
  const currentProject = useSelector((state) => selectProject(state, { projectId: currentProjectId || '' }))
  const isBuildBuddyHelpDesk = useSelector((state) => selectIsBuildBuddyHelpDesk(state, { projectId: currentProject?.id }));

  // TODO: fix this as this is slow when list is large. We should use API search with debounce instead.
  const filteredProjects = (() => {
    if (!searchText) return projects;
    const query = searchText.replace(/\s+/g, '').toLowerCase();
    return projects?.filter(project => {
      const projectName = project.name.replace(/\s+/g, '').toLowerCase();
      if(projectName.includes(query)) return true;

      const fullName = (project.siteOwner.firstName + project.siteOwner.lastName).toLowerCase();
      if(fullName.includes(query)) return true;

      return project.members.some(member => member.name.replace(/\s/g,'').toLowerCase().includes(query));
    });
  })();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  return (
    <>
      {isBuildBuddyHelpDesk &&
        <MenuItem sx={{ borderBottom: `1px solid ${theme.palette.grey[200]}`, pb: 1 }}>
          <CoreComponents.Textbox
            name="searchText"
            size="small"
            fullWidth
            value={searchText}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            placeholder="Search Projects"
            sx={{ mb: 0, mt: 0.5 }}
          />
        </MenuItem>
      }
      {filteredProjects?.map((project) => (
        <ProjectSelectListItem
          key={project.id}
          project={project}
          onClick={onClick}
        />
      ))}
    </>
  );
};
export default ProjectSelectList;
