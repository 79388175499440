import { CoreComponents, CoreState } from "@build-buddy/core";
import { Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTask, selectProjectTaskStatus } from "modules/project/_selectors";
import { useTaskViewParams } from "../../_hooks";

const TaskViewManageActions = () => {
  const { projectId, taskId, stageId } = useTaskViewParams();

  // params 
  const projectTaskParams = { projectId, stageId, taskId };
  const variationListParams = { projectId, stageId, taskId };

  // queries
  const getTaskVariationListQuery = CoreState.Variation.getProjectTaskVariationList.useQuery(variationListParams);

  // mutations
  const [completeTaskAction, completeTaskQuery] = CoreState.Task.completeTask.useMutation()
  const [rejectReviewTaskAction, rejectReviewTaskQuery] = CoreState.Task.rejectReviewTask.useMutation();
  const [startTaskAction, startTaskQuery] = CoreState.Task.startTask.useMutation();

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, projectTaskParams));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, projectTaskParams));

  // locals
  const isSaving = Boolean(completeTaskQuery.isLoading || rejectReviewTaskQuery.isLoading || startTaskQuery.isLoading);
  const isLoading = Boolean(getTaskVariationListQuery.isLoading);

  // handle actions buttons
  const showActions = [
    ProjectTaskStatus.InReview,
    ProjectTaskStatus.AwaitingStart,
    ProjectTaskStatus.WorkInProgress
  ].includes(projectTaskStatus as ProjectTaskStatus);


  const showRejectAndQAActions = !projectTask?.task.parentIsDIY && (ProjectTaskStatus.InReview === projectTaskStatus)
  const showStartTaskAction = projectTask?.task.parentIsDIY && (ProjectTaskStatus.AwaitingStart === projectTaskStatus)
  const showCompleteTaskAction = projectTask?.task.parentIsDIY && (ProjectTaskStatus.WorkInProgress === projectTaskStatus)

  // event handlers
  const handleApprove = () => {
    if (!projectTask) return;
    completeTaskAction({
      projectId: projectId,
      parentId: projectTask.task.parentId,
      taskId: projectTask.task.id
    })
  }

  const handleReject = () => {
    if (!projectTask) return;

    rejectReviewTaskAction({
      projectId: projectId,
      taskId: projectTask.task.parentId,
      subTaskId: projectTask.task.id
    })
  }
  // startJob : DIY
  const handleStart = () => {
    startTaskAction({
      projectId: projectId,
      taskId,
      subTaskId: projectTask?.task.id
    })
  }

  if (isLoading) return null;
  if (!projectTask) return null;
  if (!showActions) return null;

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      {/* Normal flow: NOT DIY */}
      {showRejectAndQAActions &&
        <>
          <Button
            sx={{
              width: {
                xs: "100%",
                md: "auto"
              }
            }}
            variant="outlined"
            color="neutral"
            onClick={() => handleReject()}
          >
            Reject
          </Button>
          <Button
            sx={{
              width: {
                xs: "100%",
                md: "auto"
              }
            }}
            variant="contained"
            onClick={() => handleApprove()}
          >
            Proceed to QA
          </Button>
        </>
      }
      {/* Show Start Task Button: DIY */}
      {showStartTaskAction &&
        <Button
          sx={{
            width: {
              xs: "100%",
              md: "auto"
            }
          }}
          variant="contained"
          color="primary"
          onClick={() => handleStart()}
        >
          Start Task
        </Button>
      }
      {/* Show Complete Button: DIY */}
      {showCompleteTaskAction &&
        <Button
          sx={{
            width: {
              xs: "100%",
              md: "auto"
            }
          }}
          variant="contained"
          color="primary"
          onClick={() => handleApprove()}
        >
          Complete Task
        </Button>}
    </>
  )
}
export default TaskViewManageActions