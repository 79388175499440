import TaskViewAdminOutline from "./TaskViewAdminOutline";
import { TaskViewTabs } from "../_common";

const TaskViewAdminContent = () => {
  return (
    <>
      <TaskViewAdminOutline />
      <TaskViewTabs />
    </>
  )
}
export default TaskViewAdminContent;