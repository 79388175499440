import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import { useTaskViewParams } from "../../_hooks";
import { selectTaskViewNextReceiveDeliveryTask } from "../../_selectors";
import { TaskViewNextTaskDialogSingle } from "../_common";

type TaskViewConfirmDialogProps = {
  open: boolean;
  onClose(): void;
}
const TaskViewConfirmOrderDialog = (props: TaskViewConfirmDialogProps) => {
  const { open, onClose } = props;
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskListParams = { projectId, stageId, taskId };

  // queries 
  const getTaskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskListParams);

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskListParams));
  const nextManageTask = useSelector((s) => selectTaskViewNextReceiveDeliveryTask(s, taskListParams));

  // locals
  const isLoading = getTaskListByParentQuery.isLoading;

  return (
    <TaskViewNextTaskDialogSingle
      title="Confirm dates"
      isLoading={isLoading}
      nextTask={nextManageTask}
      currentTask={projectTask?.task}
      onClose={onClose}
      open={open}
    />
  )
}
export default TaskViewConfirmOrderDialog;