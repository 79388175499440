import { CoreComponents } from "@build-buddy/core";
import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { useSelector } from "common/state/store";
import { selectProjectTaskIsDiy } from "modules/project/_selectors";
import { useTaskViewParams } from "../../../_hooks";

interface TaskViewQualityAssuranceChecklistActionsRaiseADefectNAButtonsProps {
  isEditable: boolean;
  showRaiseDefectNaBtn: boolean;
  handleRaiseDefect: () => void;
  updateDisabled: () => void;
  showNotApplicable: boolean;
}

const TaskViewQualityAssuranceChecklistActionsRaiseADefectNAButtons = (props: TaskViewQualityAssuranceChecklistActionsRaiseADefectNAButtonsProps) => {
  const { isEditable, showRaiseDefectNaBtn, handleRaiseDefect, updateDisabled, showNotApplicable } = props;

  // hooks
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId };

  // vars
  const isDiy = useSelector((s) => selectProjectTaskIsDiy(s, taskParams));
  const showNaButton = isDiy || showNotApplicable
  const hideButtons = isDiy ? false : !isEditable || !showRaiseDefectNaBtn;

  if (hideButtons) return null;

  return <>
    {!isDiy && <CoreComponents.MoreMenuItem
      text="Raise a Defect"
      icon={AddIcon}
      onClick={handleRaiseDefect}
    />}
    {showNaButton && <CoreComponents.MoreMenuItem
      text="Not Applicable"
      icon={CloseIcon}
      color="secondary"
      onClick={updateDisabled}
    />}
  </>
}

export default TaskViewQualityAssuranceChecklistActionsRaiseADefectNAButtons;