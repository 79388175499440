import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { BuildBuddyStateType } from "common/state/store";

export type SelectProjectParams = {
  projectId?: string;
}
export const selectProject = createSelector(
  [
    (state:BuildBuddyStateType, params: SelectProjectParams) =>
      CoreState.Project.getProject.select({ projectId: params.projectId })(state)
  ],
  (projectQuery) => projectQuery.data
)

export const selectProjectAddress = createSelector(
  selectProject,
  (project) => project?.address?.fullAddress
)

export const selectProjectCompletedPercent = createSelector(
  selectProject,
  (project) => project?.completedPercent || 0
)

export const selectProjectDisplayName = createSelector(
  selectProject,
  (project) => project ? `${project?.name} - ${project?.address?.fullAddress}` : '-'
)

export const selectProjectBillOfQuantityVersion = createSelector(
  selectProject,
  (project) => project?.billOfQuantityVersion ?? "BuildBuddyDefault"
)