import { endOfDay, startOfDay, differenceInDays } from "date-fns";
import * as Yup from "yup";

const TaskViewNextTaskDialogSingleFormValidation = Yup.object()
  .shape({
    "startDate": Yup.date()
      .required("Date is required")
      .test("validExpiry", "Date cannot be in the past", (value: any) => {
        const today = endOfDay(new Date());
        const date = startOfDay(new Date(value));
        return differenceInDays(today, date) <= 0;
      })    
  })

export default TaskViewNextTaskDialogSingleFormValidation;