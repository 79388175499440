import TaskViewPayments from "./TaskViewPayments";
import TaskViewPaymentsPay from "./task-view-payments-pay/TaskViewPaymentsPay";
import TaskViewPaymentsReceiptViewer from "./task-view-payments-receipts/TaskViewPaymentReceiptViewer";
import TaskViewPaymentPayReceipt from "./task-view-payments-receipts/TaskViewPaymentPayReceipt";
import TaskViewExpenseAdd from "./task-view-payments-expenses/TaskViewExpenseAdd";
import TaskViewExpenseEdit from "./task-view-payments-expenses/TaskViewExpenseEdit";
import TaskViewExpenseView from "./task-view-payments-expenses/TaskViewExpenseView";

const TaskViewPaymentsRouteDefinitions = {
  TASK_VIEW_PAYMENT_SCHEDULE: {
    label: "Payment Schedule",
    path: "/*/stage/:sid/task/:tid/payment-schedule",
    component: TaskViewPayments
  },
  TASK_VIEW_PAYMENTS_PAY: {
    path: "/*/stage/:sid/task/:tid/payment-schedule/:qid/pay/:psid",
    component: TaskViewPaymentsPay
  },
  TASK_VIEW_PAYMENTS_VIEW_RECEIPT: {
    path: "/*/stage/:sid/task/:tid/*/:qid/payment-receipt/:psid",
    component: TaskViewPaymentsReceiptViewer
  },
  TASK_VIEW_PAYMENTS_PAY_RECEIPT: {
    path: "/*/stage/:sid/task/:tid/payment-schedule/:qid/pay/:psid/:receiptType",
    component: TaskViewPaymentPayReceipt
  },
  TASK_VIEW_PAYMENTS_PAY_VIEW_RECEIPT: {
    path: "/*/stage/:sid/task/:tid/payment-schedule/:qid/pay/:psid/*/receipt",
    component: TaskViewPaymentsReceiptViewer
  },
  ADD_EXPENSE: {
    path: "/*/stage/:sid/task/:tid/payment-schedule/add",
    component: TaskViewExpenseAdd
  },
  EDIT_EXPENSE: {
    path: "/*/stage/:sid/task/:tid/payment-schedule/edit/:psid",
    component: TaskViewExpenseEdit
  },
  VIEW_EXPENSE: {
    path: "/*/stage/:sid/task/:tid/payment-schedule/:psid",
    component: TaskViewExpenseView
  },
}

export default TaskViewPaymentsRouteDefinitions;