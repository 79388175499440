import { Box, useTheme } from "@mui/material";

type WizardViewStepContainerStepNumberProps = {
  label: string;
}
const WizardViewStepContainerStepNumber = (props: WizardViewStepContainerStepNumberProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: 36,
        height: 36,
        borderRadius: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 18,
        fontWeight: "bold",
        mr: 3
      }}
    >
      {props.label}
    </Box>
  )
}
export default WizardViewStepContainerStepNumber;