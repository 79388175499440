enum ProjectTaskFilterStatus {  
  Todo = "Todo", 
  InProgress = "InProgress",
  Completed = "Completed",
  Upcoming = "Upcoming",
  Awaiting = "Awaiting",
  Overdue = "Overdue",
  PaymentRequested = "Payment Requested"
}
export default ProjectTaskFilterStatus;
