import { CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { Route } from "react-router-dom";
import { useTaskViewParams } from "../_hooks";
import { selectTaskViewQuoteListAcceptedQuote } from "../_selectors";
import TaskViewCompanyRouteDefinitions from "./TaskViewCompanyRouteDefinitions";

const TaskViewCompanyDetails = () => {
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const quoteListParams = { projectId, stageId, taskId };
  const routing = CoreRouting.useRouting();

  // queries
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(quoteListParams);

  // selectors
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, quoteListParams))

  // locals
  const company = acceptedQuote?.company;

  const handleReviewsClick = () => {
    routing.go(TaskViewCompanyRouteDefinitions.TASK_VIEW_COMPANY_DETAILS_REVIEWS);
  }

  if (!company) return null;

  return (
    <>
      <Box sx={{ mx: -3, mt: -6 }}>
        <CoreModules.Company.CompanyProfile
          companyId={company?.id}
          onReviewsClick={handleReviewsClick}
        />
      </Box>
      <Route
        exact
        component={TaskViewCompanyRouteDefinitions.TASK_VIEW_COMPANY_DETAILS_REVIEWS.component}
        path={TaskViewCompanyRouteDefinitions.TASK_VIEW_COMPANY_DETAILS_REVIEWS.path}
      />
    </>
  )
}
export default TaskViewCompanyDetails;