import { CoreState } from "@build-buddy/core";
import { Box, Grid } from "@mui/material";
import { CommonComponents } from "common";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import DashboardFinancialsDetail from "./dashboard-financials-detail/DashboardFinancialsDetail";
import DashboardPie from "./dashboard-financials-pie/DashboardFinancialsPie";
import DashboardFinancialsSkeleton from "./DashboardFinancialsSkeleton";

const DashboardFinancials = () => {
  const { projectId } = useProjectParams();
  const projectFinancialsByProjectParams = { projectId };

  // queries
  const projectFinancialsByProjectQuery = CoreState.Financial.getProjectFinancialsByProject.useQuery(projectFinancialsByProjectParams);

  // locals
  const isLoading = Boolean(!projectFinancialsByProjectQuery.isUninitialized && projectFinancialsByProjectQuery.isLoading);
  const projectFinancials = useSelector(s => CoreState.Financial.selectProjectFinancialsByProject(s, projectFinancialsByProjectParams));

  if (isLoading) return <DashboardFinancialsSkeleton />

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} xl={7}>
          <DashboardPie />
        </Grid>
        <Grid item xs={12} lg={4} xl={5}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              gap: 2,
              flexDirection: {
                xs: "row",
                lg: "column"
              }
            }}
          >
            <DashboardFinancialsDetail />
            <CommonComponents.SavingsCard
              isLoading={isLoading}
              savings={projectFinancials?.savings || 0}
              hasBoq={Boolean(projectFinancials?.hasBoq)}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
export default DashboardFinancials;