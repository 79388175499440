import { Tooltip, useTheme } from "@mui/material";
import { MonetizationOn as MoneyIcon } from "@mui/icons-material";
import { ProjectTask, ProjectTaskSubStatus } from "modules/project/_models";

type ProjectTaskActionsPaymentRequestedProps = {
  projectTask: ProjectTask
}
const ProjectTaskActionsPaymentRequested = (props: ProjectTaskActionsPaymentRequestedProps) => {
  const theme = useTheme();
  if (!props.projectTask.subStatuses.includes(ProjectTaskSubStatus.PaymentRequested)) return null;
  return (
    <Tooltip title="Payment Required">
      <MoneyIcon sx={{ fontSize: 28, color: theme.palette.primary.main }} />
    </Tooltip>
  )
}
export default ProjectTaskActionsPaymentRequested;