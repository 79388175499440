import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { useState } from "react";
import { BudgetFilter, BudgetItem, BudgetItemSkeleton } from "../../_components";
import { FinancialRoutesDefinition, useFinancialRoutesParams } from "../../FinancialsRoutes";

const FinancialsSummaryBudget = () => {
  const routing = CoreRouting.useRouting();
  const theme = useTheme();

  // params
  const { projectId } = useFinancialRoutesParams();
  const [filters, setFilters] = useState();
  const costCentreListByProjectParams = { projectId, filters };

  // queries
  const costCentreListByProjectQuery = CoreState.Financial.getCostCentreSummaryListByProject.useQuery(costCentreListByProjectParams);

  // locals
  const isLoading = Boolean(!costCentreListByProjectQuery.isUninitialized && costCentreListByProjectQuery.isLoading);
  const costCentreSummaryList = useSelector(s => CoreState.Financial.selectCostCentreSummaryListByProject(s, costCentreListByProjectParams));

  const handleClick = (code: string) => {
    routing.go(`${FinancialRoutesDefinition.BUDGET_TRACKING.path}?code=${code}`)
  }

  const handleFilterChange = (filters: any) => {
    setFilters(filters);
  }

  console.log({ costCentreSummaryList, costCentreListByProjectQuery });

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 1.5
          }}
        >
          <Typography
            variant="body1"
            sx={{
              flex: 1,
              minWidth: "120px"
            }}
          >
            Current Budget
          </Typography>
          <BudgetFilter
            onChange={(filters: any) => handleFilterChange(filters)}
          />
        </Box>
        <Box
          sx={{
            maxHeight: "70vh",
            overflowY: "scroll",
            mx: -2,
            px: 2
          }}
        >

          <CoreComponents.PreContent
            isLoading={isLoading}
            loader={<BudgetItemSkeleton />}
            isEmpty={Boolean(!costCentreSummaryList?.length)}
            empty={<> ERR </>}
          >
            {costCentreSummaryList?.map((costCentreSummary, i) =>
              <BudgetItem
                key={i}
                code={costCentreSummary.costCentre.code}
                name={costCentreSummary.costCentre.name}
                allocated={costCentreSummary.summary.allocated}
                accepted={costCentreSummary.summary.accepted}
                paid={costCentreSummary.summary.paid}
                budget={costCentreSummary.summary.budget}
                overBudget={costCentreSummary.summary.overBudget}
                projectedOverBudget={costCentreSummary.summary.projectedOverBudget}
                savings={costCentreSummary.summary.savings}
                projectedSavings={costCentreSummary.summary.projectedSavings}
                onClick={() => handleClick(costCentreSummary.costCentre.code)}
                sx={{
                  py: 1.5,
                  mx: -2,
                  px: 2,
                  borderBottom: `solid 1px ${theme.palette.grey[200]}`
                }}
              />
            )}
          </CoreComponents.PreContent>
        </Box>
      </CardContent>
    </Card>
  )
}
export default FinancialsSummaryBudget;