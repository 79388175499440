import { CoreState } from "@build-buddy/core";
import { useTheme } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useMemo } from "react";
import getChartColour from "../../getChartColour";

const useBudgetChart = () => {
  const theme = useTheme();
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);
  const getProjectFinancialsQuery = CoreState.ProjectFinancials.getProjectFinancials.useQuery(
    { projectId: projectId },
    { skip: !projectId }
  );

  const chart = useMemo(() => {
    if (!getProjectFinancialsQuery.data) return;
    const raw = getProjectFinancialsQuery.data;
    const data = [];
    data.push({
      name: "Budget",
      data: [raw.financials.budget],
      color: getChartColour(0, theme),
      stack: { type: "normal" }
    });
    data.push({
      name: "Variations",
      data: [raw.financials.variations],
      color: getChartColour(1, theme),
    });
    return { data }
  }, [getProjectFinancialsQuery.data])

  return {
    chart,
    isLoading: getProjectFinancialsQuery.isLoading,
  }
}
export default useBudgetChart;