import { Container } from "@mui/material";
interface MinimalProps {
  children: any;
}
const Minimal = (props: MinimalProps) => {
  return (
    <>
      <Container 
        sx={{ 
          maxWidth: {
            xs: "none",
            md: "800px",
          },
          px: {
            xs: 0,
            md: 3
          }
        }}
      >
        {props.children}
      </Container>
    </>
  )
}
export default Minimal;