import * as yup from "yup";

const errorMessage = (field: string) => `${field} must be greater than or equal to 1.`

const TaskViewReviewScoreValidationSchema = yup.object().shape({
  qualityOfWork: yup.number().min(1, errorMessage('Quality of Work')).required('Quality of Work is required.'),
  communication: yup.number().min(1, errorMessage('Communication')).required('Communication is required.'),
  value: yup.number().min(1, errorMessage('Value')).required('Value is required.'),
  reliability: yup.number().min(1, errorMessage('Reliability')).required('Reliability is required.'),
  recommendationRating: yup.number().min(1, errorMessage('Recommendation Rating')).required('Recommendation Rating is required.'),
});

export default TaskViewReviewScoreValidationSchema;
