import DocumentLibrary from "./document-library/DocumentLibrary";
import DocumentLibraryAdd from "./document-library/document-library-add/DocumentLibraryAdd";

const DocumentRouteDefinitions = {
  DOCUMENT_LIBRARY: {
    path: "/project/:pid/document/",
    component: DocumentLibrary
  },
  DOCUMENT_LIBRARY_ADD: {
    path: "/project/:pid/document/add",
    component: DocumentLibraryAdd
  },
}

export default DocumentRouteDefinitions;