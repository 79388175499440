import { Button } from "@mui/material";

import { useState } from "react";

import TaskViewBookingDialog from "./TaskViewBookingDialog";

const TaskViewBookingActions = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        fullWidth
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Book a meeting
      </Button>
      <TaskViewBookingDialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
export default TaskViewBookingActions