import { AppBar, Box, Toolbar, useTheme } from "@mui/material";
import { CoreComponents, useMode } from "@build-buddy/core";
import { Offset, Logo } from "common/components"

interface WizardHeaderProps {
  steps: Array<string>;
  currentStep: number;
}
const WizardHeader = (props: WizardHeaderProps) => {
  const theme = useTheme();
  const mode = useMode();
  const offsetSpace = mode.md ? 4 : 12;

  return (
    <>
      <AppBar
        sx={{
          background: theme.palette.background.paper,
          boxShadow: "none"
        }}
      >
        <Toolbar
          sx={{
            minHeight: "64px",
            px: 3,
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
              py: { xs: 2, md: 0 },
              flexDirection: {
                xs: "column",
                md: "row"
              }
            }}
          >
            <Box
              sx={{
                position: { xs: "relative", md: "absolute" },
                left: { xs: 0, md: "24px" },
                mb: { xs: 1, md: 0 },
                mr: { xs: 0, md: 3 }
              }}
            >
              <Logo />
            </Box>
            <Box
              sx={{
                overflow: "hidden",
                width: "100%",
                maxWidth: "900px",
              }}
            >
              <CoreComponents.Stepper
                steps={props.steps}
                current={props.currentStep}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Offset space={offsetSpace} />
    </>
  )
}
export default WizardHeader;