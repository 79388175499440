import { CoreModules } from "@build-buddy/core";
import { TaskViewOutlineStartEndDate } from "../../task-view-outline";

const TaskViewSupplyOutline = () => {
  return (
    <CoreModules.Task.TaskView.TaskViewOutline>
      <TaskViewOutlineStartEndDate />
    </CoreModules.Task.TaskView.TaskViewOutline>
  )
}
export default TaskViewSupplyOutline;