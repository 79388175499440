import { CoreComponents } from "@build-buddy/core";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useSelector } from "common/state/store";
import { selectProjectTaskIsDiy } from "modules/project/_selectors";
import { useTaskViewParams } from "../../../_hooks";

interface TaskViewQualityAssuranceChecklistActionsDeleteButtonProps {
  showRaiseDefectNaBtn: boolean;
  handleDelete: () => void;
}

const TaskViewQualityAssuranceChecklistActionsDeleteButton = (props: TaskViewQualityAssuranceChecklistActionsDeleteButtonProps) => {
  const { showRaiseDefectNaBtn, handleDelete } = props;

  // hooks
  const { projectId, stageId, taskId } = useTaskViewParams();
  
  // params
  const taskParams = { projectId, stageId, taskId };

  // vars
  const isDiy = useSelector((s)=> selectProjectTaskIsDiy(s, taskParams));

  if (showRaiseDefectNaBtn || isDiy) return null;

  return <CoreComponents.MoreMenuItem
    text="Delete"
    icon={DeleteIcon}
    onClick={handleDelete}
  />
}

export default TaskViewQualityAssuranceChecklistActionsDeleteButton;