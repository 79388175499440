
import { FC } from "react";
import { Box, Typography } from "@mui/material";

interface TitleProps {
  fields: any;
  data: any;
}
const Title: FC<TitleProps> = (props: TitleProps) => {
  const item = props.data?.length ? props.data[0] : null;
  if(!item) return null;

  const title = item.header;
  const text = item.text;

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h2"> {title} </Typography>
      {text && <Typography variant="body1" sx={{ mt: 1 }}> {text} </Typography>}
    </Box>
  )
}
export default Title;