import { CoreState } from "@build-buddy/core";
import { Typography } from "@mui/material";
import { Unavailable } from "common/components";
import { useSelector } from "common/state/store";

type MyTeamsGuardProps = {
  children: any
}
const MyTeamsGuard = (props: MyTeamsGuardProps) => {
  const { children } = props;  
  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);

  if(isLimited) {
    return (
      <Unavailable>
        <Typography variant="body1" sx={{ mb: 1 }}>
          My Team organises all your selected Build Buddy Pro's in one easily accessible location and 
          helps to ensure efficient communication.
        </Typography> 
        <Typography variant="body1">
          To unlock this feature, complete watching all videos and then schedule and attend your Discovery Appointment 
          with a member of the Build Buddy Expert Guidance and Support Team.
        </Typography>               
      </Unavailable>
    )
  }

  return children;
}
export default MyTeamsGuard;