import { CoreState } from '@build-buddy/core';
import { Box, IconButton, Menu, Tooltip } from '@mui/material';
import { PredecessorIcon } from 'assets/icons';
import { useProjectParams } from 'modules/project/_hooks';
import React, { useState } from 'react';
import ProjectTaskStatusPredecessorsList from './ProjectTaskStatusPredecessorsList';

type ProjectTaskStatusPredecessorsProps = {
  task: CoreState.Task.Task
}
const ProjectTaskStatusPredecessors = (props: ProjectTaskStatusPredecessorsProps) => {
  const { task } = props;
  const { projectId } = useProjectParams();

  const predecessorParams = {
    projectId: projectId,
    taskId: task.parentId,
    stageId: task.stageId,
    subTaskId: task.id
  }

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'relative' }}>
      <Tooltip title="Pre-requisite Tasks">
        <IconButton onClick={handleClick}>
          <PredecessorIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          '& .MuiList-root': {
            maxWidth: '400px',
            maxHeight: '600px',
            pb: 0
          }
        }}
      >
        <ProjectTaskStatusPredecessorsList
          predecessorParams={predecessorParams}
        />
      </Menu>
    </Box>
  )
}

export default ProjectTaskStatusPredecessors