import { Route, useRouteMatch } from "react-router-dom";
import { useFinancialRoutesParams } from "../FinancialsRoutes";
import BudgetTrackingItem from "./BudgetTrackingItem";

export const BudgetTrackingItemRoutesDefinition = {
  BUDGET_TRACKING_ITEM: {
    path: "/project/:pid/financials/estimated-budget/:code/add",
    component: BudgetTrackingItem
  },
};

export const useBudgetTrackingItemRoutesParams = () => {
  // get params from parent route
  const { projectId, stageId } = useFinancialRoutesParams();
  
  // match route and get params
  const match = useRouteMatch<any>([
    BudgetTrackingItemRoutesDefinition.BUDGET_TRACKING_ITEM.path,
  ]);
  const { code } = match?.params;

  return {
    projectId,
    stageId,
    costCentreCode: code,
  }
}

export const BudgetTrackingItemRoutes = () => {
  return (
    <>
      <Route
        path={BudgetTrackingItemRoutesDefinition.BUDGET_TRACKING_ITEM.path}
        component={BudgetTrackingItemRoutesDefinition.BUDGET_TRACKING_ITEM.component}
      />    
    </>
  );
};