import { useProjectParams } from "modules/project/_hooks";
import { useRouteMatch } from "react-router";

const useTaskViewQuotesCompanyParams = () => {
  const { projectId } = useProjectParams();
  const match = useRouteMatch<any>();

  return {
    projectId,
    stageId: match?.params.sid,
    taskId: match?.params.tid,
    quoteId: match?.params.qid,
    companyId: match?.params.cid,
  };
}
export default useTaskViewQuotesCompanyParams;