import { FC, useEffect, useState, useCallback } from "react";
import Slider from "react-slick";
import { Button, Box, Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material"
import { Image } from "@build-buddy/core/dist/components";

interface CarouselModalProps {
  items: Array<{
    image: string;
    title: string;
    description: string;
    value: any;
  }>;
  show: boolean;
  currentIndex: number;
  onClose(): void;
  onSelect(item: any): void;
}
const CarouselModal: FC<CarouselModalProps> = (props: CarouselModalProps) => {
  // vars
  const headerHeight = "60px";
  const footerHeight = "95px";
  const padding = "64px";
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // hooks
  const [currentItem, setCurrentItem] = useState(props.items[props.currentIndex]);
  const [contentHeight, setContentHeight] = useState(`calc(100vh - ${headerHeight} - ${footerHeight} - ${padding})`);
  const descriptionRef = useCallback((node: any) => {
    if (!node) return;
    const height = node.getBoundingClientRect().height;
    setContentHeight(`calc(100vh - ${headerHeight} - ${footerHeight} - ${padding} - ${height}px)`);
  }, [])

  // events
  const handleBeforeChange = (oldIndex: number, newIndex: number) => setCurrentItem(props.items[newIndex]);
  const handleClose = () => props.onClose();
  const handleSelect = () => props.onSelect(currentItem);

  useEffect(() => {
    if (!props.items.length) return;
    setCurrentItem(props.items[props.currentIndex]);
  }, [props.currentIndex, props.items]);

  if (!props.items.length) return null;

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          mt: 0,
          textAlign: "left",
          fontSize: 18,
          px: 3,
          py: 2,
          display: "flex",
          alignItems: "center"
        }}
      >
        <Box sx={{ flex: 1 }}> {currentItem.title} </Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <Box>
          {currentItem.description &&
            <Typography
              variant="body1"
              ref={descriptionRef}
              sx={{
                textAlign: "left",
                mb: 2,
              }}
            >
              {currentItem.description}
            </Typography>
          }
          <Slider
            {...settings}
            initialSlide={props.currentIndex}
            beforeChange={handleBeforeChange}
          >
            {props.items.map((c, ci) =>
              <Box key={ci}>
                <Box sx={{
                  height: contentHeight,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <Image
                    image={c.image}
                    height="auto"
                    sx={{
                      maxHeight: contentHeight,
                      objectFit: "contain",
                      background: "#fff"
                    }}
                  />
                </Box>
              </Box>
            )}
          </Slider>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: "column",
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: {
              xs: "100%",
              md: 259
            }
          }}
          onClick={handleSelect}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CarouselModal;
