import { CoreComponents, CoreHooks, CoreRouting, CoreState } from "@build-buddy/core";
import { MoreVert as MoreVertIcon, Add as AddIcon } from "@mui/icons-material";
import { Button, useTheme } from "@mui/material";
import TaskViewDocumentsRouteDefinitions from "./TaskViewDocumentsRouteDefinitions";
import { useProjectParams } from "modules/project/_hooks";
import TaskViewDocumentsItemUploadButton from "./TaskViewDocumentsItemUploadButton";

interface TaskViewDocumentsItemProps {
  tag: CoreState.Tag.Tag;
  document: CoreState.File.Doc | undefined;
}
const TaskViewDocumentsItem = (props: TaskViewDocumentsItemProps) => {
  const { document, tag } = props;
  const mode = CoreHooks.useMode();

  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  // params
  const { projectId } = useProjectParams();

  // mutations
  const [deleteDocListByProjectAction, deleteDocListByProjectQuery] = CoreState.File.deleteDocListByProject.useMutation();

  // locals
  const isDeleting = deleteDocListByProjectQuery.isLoading;
  const isDeleted = deleteDocListByProjectQuery.isSuccess;
  const isOutput = Boolean(tag.extra?.documentType === "Output");
  const iconBoxStyle = isOutput ?
    {} :
    {
      sx: {
        background: "transparent",
        border: `solid 1px ${theme.palette.primary.main}`,
      },
      IconProps: {
        sx: {
          color: theme.palette.primary.main
        }
      }
    }

  const requiredDocIconBoxStyle = isOutput ?
    {} :
    {
      sx: {
        background: 'white',
        border: `solid 1px ${theme.palette.grey[400]}`,
      },
      IconProps: {
        sx: {
          color: theme.palette.grey[400]
        }
      }
    }

  // events
  const handleDelete = (doc: CoreState.File.Doc) => {
    if (!doc) return;
    deleteDocListByProjectAction({ projectId, docs: [doc] });
  }

  const handleAdd = (tag: CoreState.Tag.Tag) => {
    const params = { tagCode: tag.code };
    routing.go(TaskViewDocumentsRouteDefinitions.TASK_VIEW_DOCUMENTS_ADD, { params })
  };

  if (document) return (
    <CoreComponents.DocumentListItem
      document={document}
      sx={{
        mx: -3,
        px: 3,
        borderBottom: `solid 1px ${theme.palette.grey[200]}`,
        "&:last-of-type": {
          borderBottom: "none",
          mb: -2
        }
      }}
      IconBoxProps={iconBoxStyle}
      actions={(doc) => (
        <CoreComponents.MoreMenu
          icon={MoreVertIcon}
          IconProps={{ edge: "end", sx: { ml: 1 } }}
        >
          <CoreComponents.DocumentListActionsDownload
            doc={doc}
          />
          <CoreComponents.DocumentListActionsView
            doc={doc}
          />
          {isOutput && 
            <CoreComponents.DocumentListActionsDelete
              doc={doc}
              isDeleted={isDeleted}
              isDeleting={isDeleting}
              onDelete={(doc) => handleDelete(doc)}
            />
          }
        </CoreComponents.MoreMenu>
      )}
    />
  )

  return (
    <CoreComponents.DocumentListItemRequired
      tag={tag}
      IconBoxProps={requiredDocIconBoxStyle}
      sx={{
        mx: -3,
        px: 3,
        borderBottom: `solid 1px ${theme.palette.grey[200]}`,
        "&:last-of-type": {
          borderBottom: "none",
          mb: -2
        }
      }}
      actions={tag => (
        <TaskViewDocumentsItemUploadButton onAdd={() => handleAdd(tag)} />
      )}
    />
  )
}

export default TaskViewDocumentsItem;