import { CoreState } from "@build-buddy/core";
import { ProjectTaskSubStatus } from "modules/project/_models";

const toModel = (
  task: CoreState.Task.Task | undefined,
) => {
  const rtn: Array<ProjectTaskSubStatus> = [];
  if (!task) return rtn;
  if (task.isOverdue) rtn.push(ProjectTaskSubStatus.Overdue);
  if (task.hasPaymentRequested) rtn.push(ProjectTaskSubStatus.PaymentRequested);
  if (task.hasReviewRequested) rtn.push(ProjectTaskSubStatus.ReviewRequested);
  if (task.hasDefects) rtn.push(ProjectTaskSubStatus.DefectsRaised);
  if (task.hasVariations) rtn.push(ProjectTaskSubStatus.VariationsRaised);

  return rtn;
}

const transformProjectTaskSubStatus = { toModel };

export default transformProjectTaskSubStatus;

