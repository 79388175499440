import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { DownloadIcon } from '@build-buddy/core/dist/components';
import { Payment as PaymentIcon } from "@mui/icons-material";
import { IconButton, Skeleton } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { selectProjectAccessLevelIsEditor } from "modules/project/_selectors/selectProjectAccessLevel";
import { useState } from "react";
import TaskViewQualityAssuranceRouteDefinitions from "../task-view-quality-assurance/TaskViewQualityAssuranceRouteDefinitions";
import TaskViewPaymentsRouteDefinitions from "./TaskViewPaymentsRouteDefinitions";
import { useTaskViewPaymentsParams } from "./_hooks";

type TaskViewQuotePaymentScheduleActionsProps = {
  paymentSchedule: CoreState.Quote.PaymentSchedule;
}
const TaskViewPaymentsActions = (props: TaskViewQuotePaymentScheduleActionsProps) => {
  const routing = CoreRouting.useRouting();

  // state
  const [showConfirmationModal, setShowConfirmationModal] =  useState(false);

  // params
  const { projectId, stageId, taskId } = useTaskViewPaymentsParams();
  const quoteRequestListParams = { projectId, stageId, taskId };

  // selectors
  const quoteRequest = useSelector((s) => CoreState.Quote.selectQuoteRequestListByTaskLatestQuoteRequest(s, quoteRequestListParams));
  const isEditor = useSelector((s) => selectProjectAccessLevelIsEditor(s, { projectId }));
  const isCurrentUserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner);

  // Mutation
  const [resendPaymentAction, resendPaymentQuery] = CoreState.Quote.resendPaymentByPaymentSchedule.useMutation()

  // event handlers
  const handleQaTabNavigationClick = ()=>{
    if(!quoteRequest) return;
    setShowConfirmationModal(false);
    
    const params = { qid: quoteRequest.id, psid: props.paymentSchedule.id, pid: projectId };
    routing.go(TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE, {params})
  }

  // handle resend payment
  const handleResendPaymentClick = () => {
    resendPaymentAction({paymentScheduleId: props.paymentSchedule.id, paymentType: CoreState.Quote.PaymentMethod.allocate})
  }

  const handlePayClick = ()=>{
    if (!quoteRequest) return;
    setShowConfirmationModal(false);

    const params = { qid: quoteRequest.id, psid: props.paymentSchedule.id, pid: projectId };
    routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_PAY, { params })
  }

  const handleClick = () => {
    if (!quoteRequest) return;
    if(props.paymentSchedule.openQualityAssuranceItems) return setShowConfirmationModal(true);

    handlePayClick();
  }

  const handleDownloadButtonClick = () => {
    if (!quoteRequest) return;
    const params = {
      qid: quoteRequest.id,
      psid: props.paymentSchedule.id,
      pid: projectId
    }
    routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENTS_VIEW_RECEIPT, {
      params
    })
  }

  const isPaid = props.paymentSchedule.status === CoreState.Quote.PaymentScheduleStatus.Paid
  const isPaymentRequested = props.paymentSchedule.status === CoreState.Quote.PaymentScheduleStatus.Requested
  const isReleaseRequested = props.paymentSchedule.status === CoreState.Quote.PaymentScheduleStatus.ReleaseRequested
  const isAuthorizationRequired = props.paymentSchedule.status === CoreState.Quote.PaymentScheduleStatus.AuthorizationRequired

  if (isEditor) return null;
  if(resendPaymentQuery.isLoading) return <Skeleton variant="rounded" sx={{ borderRadius: '2rem' }} height="35px" width="100px" />
  return (
    <>
      {(isPaymentRequested && isCurrentUserOwner) &&
        <CoreComponents.TableButton
          buttons={[
            {
              variant: "contained",
              label: 'Pay',
              icon: PaymentIcon,
              onClick: handleClick
            }
          ]}
        />
      }
      {(isReleaseRequested && props.paymentSchedule.paymentType === CoreState.Quote.PaymentScheduleType.StagePayment) &&
        <CoreComponents.TableButton
          buttons={[
            {
              variant: "contained",
              label: 'Release Payment',
              icon: PaymentIcon,
              onClick: handleClick
            }
          ]}
        />
      }
      {(isReleaseRequested &&
        props.paymentSchedule.paymentType === CoreState.Quote.PaymentScheduleType.ImmediatePayment &&
        isCurrentUserOwner) &&
        <CoreComponents.TableButton
          buttons={[
            {
              variant: "contained",
              label: 'Pay Now',
              icon: PaymentIcon,
              onClick: handleClick
            }
          ]}
        />
      }
      {(isAuthorizationRequired &&
        props.paymentSchedule.paymentType === CoreState.Quote.PaymentScheduleType.StagePayment &&
        isCurrentUserOwner) &&
        <CoreComponents.TableButton
          buttons={[
            {
              variant: "contained",
              label: 'Resend',
              icon: PaymentIcon,
              onClick: handleResendPaymentClick
            }
          ]}
        />
      }
      <IconButton
        hidden={!isPaid}
        size='small'
        onClick={handleDownloadButtonClick}
        sx={{
          maxHeight: '40px',
        }}
      >
        <DownloadIcon />
      </IconButton>
      <CoreComponents.Confirmation 
        open={showConfirmationModal} 
        loading={false}
        title="Check QA Items Before Proceeding" 
        text="There are items that have not been checked off in your Quality Assurance checklist. Please ensure that the work has been completed correctly before releasing or making any payments."
        cancelText="Go to QA checklist"
        confirmText="Pay Now"
        onCancel={handleQaTabNavigationClick}
        onConfirm={handlePayClick}
        onClose={()=> setShowConfirmationModal(false)}
      />
    </>
  )
}

export default TaskViewPaymentsActions;