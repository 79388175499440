const getChartColour = (index: number, theme: any) => {
  const cs = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.primary.dark,
    theme.palette.secondary.light,
    theme.palette.secondary.main,
    theme.palette.secondary.dark,
    theme.palette.info.light,
    theme.palette.info.main,
    theme.palette.info.dark,
    theme.palette.error.light,
    theme.palette.error.main,
    theme.palette.error.dark,
  ]
  return cs[index] || theme.palette.grey[500];
}
export default getChartColour;