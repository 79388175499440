import { CoreUtils } from "@build-buddy/core";
import { Typography, useTheme } from "@mui/material";

export interface SavingsCardValueProps {
  savings: number;
}
const SavingsCardValue = (props: SavingsCardValueProps) => {
  const { savings } = props;
  const val = CoreUtils.Formatter.currency(savings)

  return (
    <>
      <Typography variant="h2" sx={{ mb: 0.75 }}> Market Comparison </Typography>
      <Typography variant="body2" sx={{ mb: 0.5 }}> Build Buddy has already saved you </Typography>
      <Typography variant="h1" sx={{ mb: 0.5 }}> {val} </Typography>
      <Typography variant="body2"> in comparsion to traditional builders </Typography>
    </>
  )
}
export default SavingsCardValue;
