import { CoreState, TypeGuards } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { ProjectTask, ProjectTaskFilterStatus, ProjectTaskStatus } from "../_models";
import { transformProjectTask } from "../_transformers";

/**
 * Select a list of ProjectTasks
 * 
 * @params projectId: Id of the project to get tasks
 * 
 * @returns Array of ProjectTasks with a transformed ProjectTaskStatus
 */
export interface SelectProjectTaskListParams extends CoreState.Task.SelectTaskListByProjectParams { }
export const selectProjectTaskList = createSelector(
  CoreState.Task.selectTaskListByProject,
  (taskList): Array<ProjectTask> | undefined => {
    if (!taskList) return;
    return taskList.map(task => transformProjectTask.toModel(task)).filter(TypeGuards.nonNullable)
  }
)

/**
 * Select a list of required ProjectTasks 
 * 
 * @params projectId: Id of the project to get tasks
 * 
 * @returns Array of ProjectTasks with a status as required
 */
export const selectProjectTaskListRequired = createSelector(
  [selectProjectTaskList],
  (taskList) => taskList?.filter((subTaks) => subTaks.task.isRequired)
)

/**
 * Orders tasks by status
 */
const selectProjectTaskListOrderedByStatus = createSelector(
  [
    (state, params: SelectProjectTaskListFilteredByStatusParams) => selectProjectTaskListRequired(state, { projectId: params.projectId }),
  ],
  (taskList) => {
    if (!taskList) return [];

    const requiredSortOrder = [
      ProjectTaskStatus.InQA,
      ProjectTaskStatus.QuotesReceived,
      ProjectTaskStatus.QuotesRequested,
      ProjectTaskStatus.QuotesAccepted,
      ProjectTaskStatus.AwaitingStart,
      ProjectTaskStatus.AwaitingNextTask,
      ProjectTaskStatus.AwaitingConstruction,
      ProjectTaskStatus.Completed,
    ];

    // groups taskList by status
    const taskListGroupedByStatus = _.groupBy(taskList, "status");

    // creates an array of all the tasks sorted in sort order provided
    const sortTaskListByStatuses = (requiredSortOrder: Array<ProjectTaskStatus>) => requiredSortOrder.reduce((acc, val) => (
      acc.concat(taskListGroupedByStatus[val]?.sort((a, b) => new Date(a.task?.startDate).getTime() - new Date(b.task?.startDate).getTime()) || [])
    ), [] as Array<any>);

    // gets remaining tasks which are not in the sort order and filters by start date
    const sortRemainingTasks = (requiredSortOrder: Array<ProjectTaskStatus>) =>
      Object.entries(taskListGroupedByStatus)
        .filter(([key, val]) => !requiredSortOrder.includes(key as any) ? val : null)
        .filter(Boolean)
        .flatMap(([_, val]) => val)
        .sort((a, b) => new Date(a.task?.startDate).getTime() - new Date(b.task?.startDate).getTime()) ?? [];

    const remainingTasks = sortRemainingTasks(requiredSortOrder);
    const orderedTasks = sortTaskListByStatuses(requiredSortOrder);

    return [
      ...remainingTasks,
      ...orderedTasks
    ];
  }
)

/**
 * Select a list of filtered ProjectTasks
 * 
 * @params projectId: Id of the project to get tasks
 * @params status: Status to filter
 * 
 * @returns Array of filtered ProjectTasks with a transformed ProjectTaskStatus
 */
export interface SelectProjectTaskListFilteredByStatusParams extends SelectProjectTaskListParams {
  status?: ProjectTaskFilterStatus | undefined;
}
export const selectProjectTaskListFilteredByStatus = createSelector(
  [
    (state, params: SelectProjectTaskListFilteredByStatusParams) => selectProjectTaskListOrderedByStatus(state, { projectId: params.projectId }),
    (state, params: SelectProjectTaskListFilteredByStatusParams) => ({ state, params })
  ],
  (taskList, meta) => {
    if (!taskList) return [];

    if (!meta.params.status) return taskList;

    if (!Object.values(ProjectTaskFilterStatus).includes(meta.params.status)) return taskList;

    // TODO: Hack
    if (ProjectTaskFilterStatus.Todo === meta.params.status) {
      const x = taskList.filter(x =>
        Boolean(x.subStatuses.length) ||
        Boolean(x.filterStatuses.includes(meta.params.status)) ||
        Boolean(x.task.parentIsDIY && ![ProjectTaskStatus.AwaitingNextTask, ProjectTaskStatus.Completed].includes(x.status))
      );
      return x;
    }

    return taskList.filter(x => x.filterStatuses.includes(meta.params.status))
  }
)

/**
 * Select a list of ProjectTasks by the Parent Task
 * 
 * @params projectId: Id of the project to get tasks
 * @params stageId: Id of the project stage
 * @params taskId: Id of the parent task
 * 
 * @returns Array of ProjectTasks with a transformed ProjectTaskStatus
 */
interface SelectProjectTaskListByParent extends CoreState.Task.SelectTaskListByParentParams { }
export const selectProjectTaskListByParent = createSelector(
  CoreState.Task.selectTaskListByParent,
  (taskList) => {
    if (!taskList) return;
    return taskList.map(task => transformProjectTask.toModel(task)).filter(TypeGuards.nonNullable)
  }
)

//TODO: We will remove this it was a hack
export const selectFirstBookingTaskId = createSelector(
  selectProjectTaskListRequired,
  (taskList) => taskList?.find((task) => task.task.type === CoreState.Task.TaskType.Booking && task.status === ProjectTaskStatus.New)?.task.id
)

export const selectFirstTaskId = createSelector(
  selectProjectTaskListRequired,
  (taskList) => taskList?.sort(x => x.task.sortOrder).find((task) => task.task.type === CoreState.Task.TaskType.ReceiveAndUpload)?.task.id
)