import { CoreComponents, CoreState, CoreUtils } from "@build-buddy/core";
import { Card, CardContent, DialogActions, DialogContent, Stack, Typography } from "@mui/material";

interface PaymentConfirmContentProps {
  paymentType: CoreState.Financial.PaymentType;
  paymentSchedule: CoreState.Financial.PaymentSchedule;
  task: CoreState.Task.Task;
  senderLabel?: string;
  recipientLabel?: string;
  recipientName: string;
  submitText: string;
  onSubmitClick(): void;
  onCancelClick(): void;
}
const PaymentConfirmContent = (props: PaymentConfirmContentProps) => {
  const { 
    // defaults
    senderLabel = "Total Payment", 
    recipientLabel = "Sending to",
    paymentType, 
    paymentSchedule, 
    task, 
    recipientName, 
    submitText, 
    onSubmitClick, 
    onCancelClick 
  } = props;

  const taskName = task.name;
  const description = paymentSchedule.description;
  const subtotal = CoreUtils.Formatter.currency(paymentSchedule.subTotal);
  const gst = CoreUtils.Formatter.currency(paymentSchedule.gst);
  const total = CoreUtils.Formatter.currency(paymentSchedule.total);

  return (
    <>
      <DialogContent sx={{ pb: 0 }}>
        <Stack direction="row" gap={2} sx={{ mb: 3 }} >
          <Card sx={{ flex: 1 }}>
            <CardContent>
              <Typography variant="body2" sx={{ mb: 1 }}> {senderLabel} </Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="body1" sx={{ fontSize: "18px", fontWeight: "bold" }}> {total} </Typography>
                <Typography variant="body2" sx={{ ml: 0.5, position: "relative", top: 2 }}> AUD </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card sx={{ flex: 1 }}>
            <CardContent>
              <Typography variant="body2" sx={{ mb: 1.5 }}> {recipientLabel} </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}> {recipientName} </Typography>
            </CardContent>
          </Card>
        </Stack>

        <Stack direction="row" gap={2} sx={{ mb: 3 }}>
          <CoreComponents.TaskLabel label={taskName} sx={{ flex: 1 }} />
        </Stack>

        <Typography variant="body1" sx={{ mb: 2, fontWeight: "bold" }}> {description} </Typography>

        <Stack direction="row" sx={{ mb: 0.5 }}>
          <Typography variant="body1" sx={{ flex: 1 }}> Subtotal:  </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}> {subtotal} </Typography>
        </Stack>
        <Stack direction="row" sx={{ mb: 0.5 }}>
          <Typography variant="body1" sx={{ flex: 1 }}> GST: </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}> {gst}  </Typography>
        </Stack>
        {paymentType === CoreState.Financial.PaymentType.CreditCard &&
          <Stack direction="row" sx={{ mb: 0.5 }}>
            <Typography variant="body1" sx={{ flex: 1 }}> Credit Card Surcharge: </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}> Waived  </Typography>
          </Stack>
        }
        <Stack direction="row" sx={{ mt: 1.5 }}>
          <Typography variant="body1" sx={{ flex: 1, fontSize: "16px" }}> Total Amount:  </Typography>
          <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "bold" }}> {total}  </Typography>
        </Stack>
        {paymentType === CoreState.Financial.PaymentType.CreditCard &&
          <Typography variant="body1" sx={{ mt: 2 }}>
            We're ready to process your payment using your selected credit card
          </Typography>
        }
      </DialogContent>
      <DialogActions>
        <CoreComponents.Actions
          submitText={submitText}
          onCancelClick={() => onCancelClick()}
          onSubmitClick={() => onSubmitClick()}
        />
      </DialogActions>
    </>
  )
}
export default PaymentConfirmContent;