
import { CoreRouting } from "@build-buddy/core";
import { Box, Slide, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { useState } from "react";
import CalendarRouteDefinitions from "../CalendarRouteDefinitions";
import { useCalendarParams } from "../_hooks";
import { selctCalendarTaskListByDate } from "../_selectors";
import CalendarDrawerHeader from "./CalendarDrawerHeader";
import CalendarDrawerTask from "./CalendarDrawerTask";

const CalendarDrawer = () => {
  const routing = CoreRouting.useRouting();
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  // params
  const { projectId, activeDate } = useCalendarParams();
  const taskListParams = { projectId, date: activeDate };

  // selectors
  const taskList = useSelector((s) => selctCalendarTaskListByDate(s, taskListParams))

  if (!taskList) return null;

  return (
    <Slide
      in={open}
      direction="left"
      onExited={() => {
        routing.go(CalendarRouteDefinitions.CALENDAR)
      }}
    >
      <Box
        sx={{
          background: theme.palette.background.paper,
          mx: {
            xs: 0,
            sm: -3
          },
          mt: {
            xs: 2,
            md: -2,
          },
          mb: {
            xs: 0,
            md: -2,
          },
          ml: {
            md: 3,
          },
          flex: 1,
          minHeight: {
            xs: "auto",
            md: `calc(100vh - 64px)`
          }
        }}
        boxShadow={1}
      >
        <CalendarDrawerHeader
          handleClose={() => setOpen(false)}
        />
        <Box
          sx={{ 
            height: `calc(100vh - 120px)`,
            overflowY: "auto"
          }}
        >
          {taskList.map((pt, idx) =>
            <CalendarDrawerTask
              key={idx}
              projectTask={pt}
            />
          )}
        </Box>
      </Box>
    </Slide>
  );
};

export default CalendarDrawer;
