import { CoreComponents, CoreState } from "@build-buddy/core";
import { Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTask, selectProjectTaskStatus } from "modules/project/_selectors";
import { useState } from "react";
import { useTaskViewParams } from "../../_hooks";
import { selectTaskViewQuoteListAcceptedQuote } from "../../_selectors";
import TaskViewValidation from "../../task-view-validation/TaskViewValidation";

const TaskViewCompleteActions = () => {
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const params = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, params));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, params));
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, params));
  const unresolvedDefects = useSelector((s) => CoreState.Defect.selectDefectListByTaskUnresolvedDefects(s, params))
  const unresolvedVariations = useSelector((s) => CoreState.Variation.selectVariationListByProjectUnresolvedVariationList(s, params))

  // mutations
  const [completeTaskAction, completeTaskQuery] = CoreState.Task.completeTask.useMutation()

  // locals
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<Array<string>>([]);
  const showActions = Boolean((ProjectTaskStatus.Finalise === projectTaskStatus));
  const hasNoPayments = Boolean(acceptedQuote?.paymentSchedule.every(x => (x.status === CoreState.Quote.PaymentScheduleStatus.Paid || x.status === CoreState.Quote.PaymentScheduleStatus.NotRequired)))
  const hasNoDefects = Boolean(!unresolvedDefects?.length);
  const hasNoVariations = Boolean(!unresolvedVariations?.length);

  const handleComplete = () => {
    if (!projectTask) return;

    if (!projectTask.task.parentIsDIY) {
      const errs = [];
      if (!hasNoPayments) {
        errs.push("All payments must be paid.")
      }

      if (!hasNoVariations) {
        errs.push("All variations must be solved.")
      }

      if (!hasNoDefects) {
        errs.push("All defects must be solved.")
      }

      if (errs.length) {
        setOpen(true)
        setErrors(errs);
        return;
      }
    }

    completeTaskAction({
      projectId: projectId,
      parentId: projectTask.task.parentId,
      taskId: projectTask.task.id,
      quoteId: acceptedQuote?.id
    })
  }

  if (!showActions) return null;

  return (
    <>
      <TaskViewValidation
        open={open}
        onClose={() => setOpen(false)}
        errors={errors}
      />
      <CoreComponents.Loader show={completeTaskQuery.isLoading} />
      <Button
        fullWidth
        variant="contained"
        onClick={handleComplete}
      >
        Mark Complete
      </Button>
    </>
  )
}
export default TaskViewCompleteActions