import * as Yup from "yup";

const TaskViewOutlineAddCostUpdateValidation = Yup.object()
  .shape({
    cost: Yup.string()  
      .required("Please enter valid number")
      .matches(/^[0-9]*\.?[0-9]+$/, {
        message: "Please enter valid number.",
      }),
  })

export default TaskViewOutlineAddCostUpdateValidation;
