import { createSelector } from "@reduxjs/toolkit";
import { BuildBuddyStateType } from "common/state/store";

const selectRoot = (state: BuildBuddyStateType) => state.AppReducer;

export const selectCurrentProjectId = createSelector(
  selectRoot,
  (root) => root.project.id
)

export const selectIsLoading = createSelector(
  selectRoot,
  (root) => root.isLoading
)