enum ProjectTaskStatus {
  NA = "NA",
  New = "New",
  QuotesAccepted = "QuotesAccepted",
  QuotesRequested = "QuotesRequested",
  QuotesReceived = "QuotesReceived",
  AwaitingStart = "AwaitingStart",
  AwaitingNextTask = "AwaitingNextTask",
  UpcomingTask = "Upcoming",
  WorkInProgress = "WorkInProgress",
  InReview = "InReview",
  InQA = "InQA",
  Finalise = "Finalise",
  Completed = "Completed",
  AwaitingConstruction = "AwaitingConstruction",
  NotRequired = "NotRequired"
}
export default ProjectTaskStatus;
