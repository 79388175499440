import { CoreModules, CoreState } from "@build-buddy/core";
import { Box, Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { useTaskViewParams } from "modules/project/task/task-view";
import { selectTaskStandardSpecificationList, selectTaskUserSpecificationList } from "../../_selectors/selectTaskSpecificationList";
import TaskViewScopeOfWorkSpecListActions from "./TaskViewScopeOfWorkSpecListActions";

const useVm = () => {
  const params = useTaskViewParams();
  const getTaskSpecificationListQuery = CoreState.Specification.getTaskSpecificationList.useQuery(params);
  const standardSpecifications = useSelector((s) => selectTaskStandardSpecificationList(s, params));
  const userSpecifications = useSelector((s) => selectTaskUserSpecificationList(s, params));

  return {
    isLoading: getTaskSpecificationListQuery.isLoading,
    standardSpecifications,
    userSpecifications,
  }
}

const TaskViewScopeOfWorkSpecList = () => {
  const vm = useVm();
  return (
    <Box sx={{ p: 0.5 }}>
      <CoreModules.Task.TaskView.TaskViewSpecs 
        specs={vm.standardSpecifications}
        isLoading={vm.isLoading}
        actions={TaskViewScopeOfWorkSpecListActions}
      />
      <Typography variant="h3" sx={{ mt: 2, mb: 2 }}>User Specifications:</Typography>
      <CoreModules.Task.TaskView.TaskViewSpecs
        specs={vm.userSpecifications}
        isLoading={vm.isLoading}
        actions={TaskViewScopeOfWorkSpecListActions}
        isUserSpec={true}
      />
    </Box>
  )
}
export default TaskViewScopeOfWorkSpecList;