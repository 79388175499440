import { Box, Card, CardContent, SvgIcon, Typography, useTheme } from "@mui/material";
import { Lock as LockIcon } from "@mui/icons-material";
import LockedMascot from '../../../../assets/locked-mascot.svg'

type UnavailableProps = {
  title?: string;
  icon?: any;
  image?: any;
  children: any;
}
const Unavailable = (props: UnavailableProps) => {
  const theme = useTheme();
  const { children, title, icon, image } = props;
  return (
    <Box
      sx={{
        minHeight: '88vh',
        display: 'flex',
        alignItems: { md: 'center' }
      }}
    >
      <Card
        sx={{
          textAlign: "center",
          p: {
            xs: 1,
            md: 4
          },
          maxWidth: 600,
          margin: "0 auto"
        }}
      >
        <CardContent>
          {icon ?
            <SvgIcon
              component={icon}
              sx={{
                fontSize: "64px",
                color: theme.palette.primary.main
              }}
            /> :
            <Box>
              <img src={image || LockedMascot} alt="Section Locked" />
            </Box>
          }
          <Typography variant="h1" sx={{ mt: 3, mb: 2 }}>
            {title || "This section is currently locked"}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Box>
  )
}
export default Unavailable;