
import { FC, useState } from "react";
import { Box, useTheme } from "@mui/material";
import Slider from "react-slick";
import CarouselItem from "./CarouselItem";
import CarouselModal from "./CarouselModal";

interface CarouselProps {
  items: Array<{
    image: string;
    title: string;
    description: string;
    value: any;
  }>;
  value: any;
  error?: boolean;
  onChange(val: any): void;
}
const CarouselItems: FC<CarouselProps> = (props: CarouselProps) => {
  const theme = useTheme();
  const [slider, setSlider] = useState<any>();
  const [opened, setOpened] = useState(false);
  const [openedIndex, setOpenedIndex] = useState<number>(0);

  const slidesToShow = (props.items.length >= 4) ? 4 : props.items.length;
  const rows = (props.items.length >= 8) ? 2 : 1;
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 2,
    rows: rows,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  };

  const handleClick = (value: any) => props.onChange(value)
  const handleClose = () => setOpened(false);
  const handleSelect = (item: any) => {
    props.onChange(item.value);
    setOpened(false);
    slider.slickGoTo(props.items.findIndex(i => i.value === item.value));
  }
  const handleZoomClick = (e: React.SyntheticEvent, index: number) => {
    e.stopPropagation();
    setOpened(true);
    setOpenedIndex(index);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .slick-dots": {
            bottom: "-30px"
          },
          "& .slick-dots li": {
            margin: "0 4px"
          },
          "& .slick-dots li button:before": {
            fontSize: "12px"
          }
        }}
      >
        <Slider
          {...settings}
          ref={s => setSlider(s)}
        >
          {props.items.map((c, ci) =>
            <CarouselItem
              key={ci}
              title={c.title}
              caption={c.description}
              image={c.image}
              value={c.value}
              width={100}
              error={props.error}
              selected={c.value === props.value}
              onClick={(value: any) => handleClick(value)}
              onZoomClick={(e: React.SyntheticEvent) => handleZoomClick(e, ci)}
            />
          )}
        </Slider>
      </Box>
      <CarouselModal
        show={opened}
        currentIndex={openedIndex}
        items={props.items}
        onClose={handleClose}
        onSelect={handleSelect}
      />
    </>
  )
}

export default CarouselItems;