import { Box, Skeleton } from '@mui/material'

const ProjectTaskStatusPredecessorsSkeleton = () => {
  return (
    <Box sx={{ px: 2, minWidth: '400px' }}>
      <Skeleton height={50} width='100%' />
      <Skeleton height={100} width='100%' />
    </Box>
  )
}

export default ProjectTaskStatusPredecessorsSkeleton