import { CoreComponents, CoreState } from "@build-buddy/core";
import { useFormik } from "formik";
import TaskViewQualityAssuranceChecklistFormValidation from "./TaskViewQualityAssuranceChecklistFormValidation";

interface TaskViewQualityAssuranceChecklistFormProps {
  qualityAssurance?: CoreState.QualityAssurance.QualityAssurance;
  onSubmit(values: any): void;
  onClose(): void;
}
const TaskViewQualityAssuranceChecklistForm = (props: TaskViewQualityAssuranceChecklistFormProps) => {
  const formik = useFormik({
    initialValues: {
      description: props.qualityAssurance?.description || ""
    },
    validationSchema: TaskViewQualityAssuranceChecklistFormValidation,
    onSubmit: (values) => {
      props.onSubmit(values)
    }
  })

  return (
    <>
      <CoreComponents.Textbox
        fullWidth
        multiline
        label="Description*"
        name="description"
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched.description && formik.errors.description) && formik.errors.description}
      />
      <CoreComponents.Actions
        sx={{ mt: 2 }}
        submitText="Save"
        onCancelClick={() => props.onClose()}
        onSubmitClick={() => formik.handleSubmit()}
      />
    </>
  )

}
export default TaskViewQualityAssuranceChecklistForm;