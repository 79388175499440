import { SxProps, Theme, Box } from '@mui/material';
import SmallLogo from 'assets/bb-icon.png';
import FullLogo from 'assets/bb-logo.jpg';

interface LogoProps {
  type?: "small" | "full";
  sx?: SxProps<Theme>;
}
const Logo = (props: LogoProps) => {
  const type = props.type || "small";
  const kvp = {
    "small": SmallLogo,
    "full": FullLogo
  }; 
  return (
    <Box
      sx={{
        width: "32px",
        ...props.sx
      }}
    >
      <img
        src={kvp[type]}
        alt='Build Buddy'
        style={{
          "objectFit": "cover",
          "height": "100%",
          "width": "100%",
          "overflow": "hidden"
        }}
      />
    </Box>
  )
}
export default Logo;