import { CoreComponents, CoreRouting, CoreState, CoreUtils } from "@build-buddy/core";
import { Dialog } from "@mui/material";
import { useSelector } from "common/state/store";
import { FinancialRoutesDefinition, useFinancialRoutesParams } from "../../FinancialsRoutes";
import UnlockedBudgetBannerDialogLock from "./UnlockedBudgetBannerDialogLock";
import UnlockedBudgetBannerDialogSuccess from "./UnlockedBudgetBannerDialogSuccess";

export interface UnlockedBudgetBannerLockProps {
  budget: CoreState.Financial.Budget;
  show?: boolean
  onClose(): void;
}
const UnlockedBudgetBannerLock = (props: UnlockedBudgetBannerLockProps) => {
  const { budget, show, onClose } = props;
  const routing = CoreRouting.useRouting();

  // params
  const { projectId } = useFinancialRoutesParams();
  const getProjectParams = { projectId };

  // mutations
  const [lockBudgetMutation, lockBudgetQuery] = CoreState.Project.lockBudget.useMutation();

  // queries
  const getProjectQuery = CoreState.Project.getProject.useQuery({ projectId: projectId }, { skip: !projectId });

  // locals
  const project = useSelector(s => CoreState.Project.selectProject(s, getProjectParams));
  const isSaving = Boolean(!lockBudgetQuery.isUninitialized && lockBudgetQuery.isLoading);
  const isLoading = Boolean(!getProjectQuery.isUninitialized && getProjectQuery.isFetching);
  const isLocked = Boolean(project?.budgetLocked);
  const budgetName = `${budget.version} estimate`;
  const budgetModifiedDate = CoreUtils.Formatter.date(budget.dateModified) || "";

  const handleLock = () => {
    lockBudgetMutation({ projectId });
  }

  const handleRedirect = () => {
    routing.go(FinancialRoutesDefinition.FINANCIALS_ROOT);
  }

  const handleClose = () => {
    // if its locked and they close, redirect them
    if(isLocked) return handleRedirect();
    onClose();
  }

  return (
    <Dialog
      open={Boolean(show)}
      onClose={() => handleClose()}
    >
      <CoreComponents.Loader show={isSaving || isLoading} />
      <CoreComponents.DialogHeader
        title=""
        sx={{ border: "none", mb: 0 }}
        onClose={() => handleClose()}
      />
      {!isLocked &&
        <UnlockedBudgetBannerDialogLock
          budgetModifiedDate={budgetModifiedDate}
          budgetName={budgetName}
          onLockClick={() => handleLock()}
        />
      }
      {isLocked &&
        <UnlockedBudgetBannerDialogSuccess
          budgetModifiedDate={budgetModifiedDate}
          budgetName={budgetName}
          onRedirectClick={() => handleRedirect()}
        />
      }
    </Dialog>
  )
}
export default UnlockedBudgetBannerLock