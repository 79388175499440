import * as Yup from "yup";

const TaskViewQualityAssuranceChecklistFormValidation = Yup.object()
  .shape({
    description: Yup.string()
      .required("Please enter a description")
      .max(500, "Please enter less than 500 characters")
  })

export default TaskViewQualityAssuranceChecklistFormValidation;
