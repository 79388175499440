import { CoreRouting, CoreState, CoreUtils, Loader } from "@build-buddy/core";
import { Box, Button } from "@mui/material";
import { CommonState } from "common";
import { useDispatch, useSelector } from "common/state/store";
import { ProjectRoutesDefinitions } from "modules/project/ProjectRoutes";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const CreateProject = () => {  
  const { wid } = useParams<any>();
  const dispatch = useDispatch();
  const routing = CoreRouting.useRouting();
  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);
  const getWizardQueryParams = { wizardId: wid, userId: currentUserId };

  // mutations
  const [createProjectAction, createProjectQuery] = CoreState.Project.createProject.useMutation();

  // locals
  const wizard = useSelector((s) => CoreState.Wizard.selectWizard(s, getWizardQueryParams));
  const isLoading = Boolean(createProjectQuery.isLoading);
  const isSuccess = Boolean(!createProjectQuery.isUninitialized && createProjectQuery.isSuccess);

  // events
  const handleClick = async () => {
    if (!wizard) return;
    const project: CoreState.Common.Subset<CoreState.Project.Project> = {
      startDate: wizard.startDate || CoreUtils.Formatter.dateTimeOffset(new Date()),
    }
    createProjectAction({
      wizardId: wid,
      project
    })
  };

  useEffect(() => {
    if (!isSuccess) return;
    
    const params = { pid: createProjectQuery.data?.id };
    const project = createProjectQuery.data;
    
    if (project) dispatch(CommonState.App.switchProject({ project }));

    routing.go(ProjectRoutesDefinitions.DASHBOARD_ROOT, { params })
  }, [isSuccess])

  return (
    <Box>
      <Loader show={isLoading} />
      <iframe
        width="100%"
        height="328px"
        style={{ marginTop: "16px", border: "none" }}
        src={`https://www.youtube.com/embed/tWquwEVWJJE?autoplay=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Build Buddy"
      />
      <Button
        variant="contained"
        onClick={() => handleClick()}
        fullWidth
        sx={{ mt: 2 }}
      >
        Generate Workflow
      </Button>
    </Box>
  );
};
export default CreateProject;
