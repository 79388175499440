import { Box, Typography, useTheme } from "@mui/material"
import BudgetItemListEstimatedTemplate from "./BudgetItemListEstimatedTemplate"

const BudgetItemListEstimatedHeader = () => {
  const theme = useTheme();
  return (
    <BudgetItemListEstimatedTemplate
      sx={{ 
        textTransform: "uppercase",
        borderBottom: `solid 1px ${theme.palette.grey[200]}`
      }}
    >
      <Box
        sx={{
          gridArea: "name"
        }}
      />
      <Typography
        variant="body2"
        sx={{
          gridArea: "qty"
        }}
      >
        QTY
      </Typography>
      <Typography
        variant="body2"
        sx={{
          gridArea: "rate"
        }}
      >
        Rate
      </Typography>
      <Typography
        variant="body2"
        sx={{
          gridArea: "final"
        }}
      >
        Final Estimate
      </Typography>
      <Typography
        variant="body2"
        sx={{
          gridArea: "variation"
        }}
      >
        User Variation
      </Typography>
      <Typography
        variant="body2"
        sx={{
          gridArea: "budget"
        }}
      >
        Budget
      </Typography>
      <Box
        sx={{
          gridArea: "action"
        }}
      >
        &nbsp;
      </Box>
    </BudgetItemListEstimatedTemplate>
  )
}
export default BudgetItemListEstimatedHeader