import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { MoreVert as MoreVertIcon, Search as SearchIcon } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import DocumentRouteDefinitions from "../DocumentRouteDefinitions";
import DocumentLibraryUploadButton from "./DocumentLibraryUploadButton";
import { selectDocumentLibraryDocListFiltered } from "./_selectors/selectDocumentLibrary";

const DocumentLibrary = () => {
  const { projectId } = useProjectParams();
  const routing = CoreRouting.useRouting();

  // params
  const getDocListByProjectParams = { projectId };

  // queries
  const getTagListByTypeDocumentQuery = CoreState.Tag.getTagListByTypeDocument.useQuery();
  const getDocListByProjectQuery = CoreState.File.getDocListByProject.useQuery(getDocListByProjectParams)

  // mutations
  const [deleteDocListByProjectAction, deleteDocListByProjectQuery] = CoreState.File.deleteDocListByProject.useMutation();

  // locals
  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState("");
  const isDeleting = deleteDocListByProjectQuery.isLoading;
  const isDeleted = deleteDocListByProjectQuery.isSuccess;

  // selectors
  const tagList = useSelector(CoreState.Tag.selectTagListByTypeDocument);
  const docList = useSelector((s) => selectDocumentLibraryDocListFiltered(s, {
    ...getDocListByProjectParams,
    search,
    tags,
  }));

  const handleClick = () => {
    routing.go(DocumentRouteDefinitions.DOCUMENT_LIBRARY_ADD);
  }

  const handleTagsChange = (values: any) => {
    setTags(values);
  }

  const handleSearch = (value: any) => {
    setSearch(value);
  }

  const handleDelete = (doc: CoreState.File.Doc) => {
    if (!doc) return;
    deleteDocListByProjectAction({ projectId, docs: [doc] });
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h1" sx={{ mb: 2 }}> Document Library </Typography>
          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: {
                  xs: "column",
                  md: "row"
                }
              }}
            >
              <TextField
                size="small"
                placeholder="Search documents"
                sx={{
                  mr: 1,
                  width: {
                    xs: "100%",
                    md: "300px"
                  },
                }}
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                }}
              />
              <CoreComponents.AutocompleteSelect
                onChange={(values) => handleTagsChange(values)}
                multiple={true}
                size="small"
                limitTags={2}
                options={tagList}
                placeholder="Tags"
                isLoading={getTagListByTypeDocumentQuery.isLoading}
                sx={{
                  width: {
                    xs: "100%",
                    md: "450px"
                  }
                }}
              />
            </Box>
            <DocumentLibraryUploadButton onClick={handleClick} />
          </Stack>
          <CoreComponents.DocumentList
            documents={docList}
            isLoading={getDocListByProjectQuery.isLoading}
            actions={(doc) => (
              <CoreComponents.MoreMenu
                icon={MoreVertIcon}
                IconProps={{ edge: "end", sx: { ml: 1 } }}
              >
                <CoreComponents.DocumentListActionsDownload
                  doc={doc}
                />
                <CoreComponents.DocumentListActionsView
                  doc={doc}
                />
                <CoreComponents.DocumentListActionsDelete
                  doc={doc}
                  isDeleted={isDeleted}
                  isDeleting={isDeleting}
                  onDelete={(doc) => handleDelete(doc)}
                />
              </CoreComponents.MoreMenu>
            )}
          />
        </CardContent>
      </Card>
      <Switch>
        <Route
          path={DocumentRouteDefinitions.DOCUMENT_LIBRARY_ADD.path}
          component={DocumentRouteDefinitions.DOCUMENT_LIBRARY_ADD.component}
        />
      </Switch>
    </>
  )
}
export default DocumentLibrary;