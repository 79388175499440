import { Box, Popper, PopperProps, Stack, styled, Typography } from '@mui/material';
import { ChartsReferenceLine, ChartsTooltip, ChartsXAxis, LineHighlightPlot, LinePlot, MarkPlot, ResponsiveChartContainer } from '@mui/x-charts';
import {
  MarkElement
} from '@mui/x-charts/LineChart';

interface FinancialsLineGraphProps {
  data: {
    estimated: number[],
    forecast: number[],
    labels: string[]
  }
}

const FinancialsLineGraph = (props: FinancialsLineGraphProps) => {
  const { data: { estimated, forecast, labels } } = props;

  return (
    <Stack>
      <Typography fontSize={12} fontWeight={600} px={2}>Cumulative Spending</Typography>
      <Box sx={{ height: "300px", aspectRatio: "1/1", mt: 1.5 }}>
        <ResponsiveChartContainer
          series={[
            { data: estimated, type: 'line', color: '#687077' },
            { data: forecast, type: 'line', color: '#0085FF' },
          ]}
          xAxis={[{ scaleType: 'point', data: labels }]}
        >
          <LinePlot />
          <MarkPlot
            slots={{
              mark: (props) => <MarkElement {...props} sx={{ fill: props.color }} />
            }}
          />
          <LineHighlightPlot />

          <ChartsTooltip slots={{ popper: CustomPopperRoot }} />

          <ChartsReferenceLine
            x="Apr"
            label=""
            lineStyle={{ stroke: '#eee' }}
          />
          <ChartsReferenceLine y={3900} label="" lineStyle={{ stroke: '#eee' }} />
          <ChartsXAxis disableLine disableTicks />

        </ResponsiveChartContainer>
      </Box>
    </Stack>
  )
}

// Custom Tooltip for Graph
const ChartsTooltipRoot = styled(Popper, {
  name: "MuiChartsTooltip",
  slot: "Root",
  overridesResolver: (_, styles) => styles.root,
})(({ theme }) => ({
  pointerEvents: "none",
  zIndex: theme.zIndex.tooltip,
  "& .MuiChartsTooltip-root": {
    background: 'rgba(0, 0, 0, .3)',
    padding: '4px 8px'
  },
  'thead': {
    "tr": {
      "td": {
        padding: '0px !important',
        "p": {
          color: 'white'
        }
      }
    },
    mb: 8
  },
  "& .MuiChartsTooltip-markCell": {
    padding: '5px 5px 0px 5px !important',
    "& .MuiChartsTooltip-mark": {
      border: 'none',
      height: '12px',
      width: '12px'
    }
  },
  '& .MuiChartsTooltip-valueCell': {
    padding: '5px 5px 0px 5px !important',
    "& .MuiTypography-body1": {
      color: 'white',
      fontWeight: '500'
    }
  }
}));

const CustomPopperRoot = (props: PopperProps) => {
  return (
    <ChartsTooltipRoot
      {...props}
      anchorEl={{
        getBoundingClientRect: () => ({
          ...(props.anchorEl as any)?.getBoundingClientRect(),
        }),
      }}
    />
  );
};

export default FinancialsLineGraph