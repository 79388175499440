import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Delete as DeleteIcon, Edit as EditIcon, Engineering as EngineeringIcon, MoreVert as MoreVertIcon, Undo as UndoIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import { TaskViewRouteDefinitions, useTaskViewParams } from "modules/project/task/task-view";
import { useState } from "react";
import { selectActiveTaskUserSpecificationList } from "../../_selectors/selectTaskSpecificationList";
import ConfirmDIY from "../../task-view-types/_common/task-view-diy/ConfirmDIY";

interface TaskViewScopeOfWorkSpecListActionsProps {
  taskSpec: CoreState.Specification.TaskSpecification;
}
enum ActionType {
  DIY = 'DIY',
  Delete = 'Delete',
  Update = 'Update'
}
const TaskViewScopeOfWorkSpecListActions = (props: TaskViewScopeOfWorkSpecListActionsProps) => {
  const routing = CoreRouting.useRouting();
  const params = useTaskViewParams();
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const projectTaskParams = { projectId, stageId, taskId };

  // State
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [currentAction, setCurrentAction] = useState<ActionType>()

  const activeTaskSpecs = useSelector((s) => selectActiveTaskUserSpecificationList(s, params));

  // Checks if the Spec is last NotDIY item.
  const isLastNotDiySpec = activeTaskSpecs?.filter(spec => (!spec.isDIY && spec.specificationSubType === "Scope")).length === 1

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, projectTaskParams));

  // mutations
  const [updateTaskSpecificationAction, updateTaskSpecificationQuery] = CoreState.Specification.updateTaskSpecification.useMutation();
  const [deleteTaskSpecifcationAction, deleteTaskSpecifcationQuery] = CoreState.Specification.deleteTaskSpecification.useMutation();

  // locals
  const showActions = (projectTask?.task.type === CoreState.Task.TaskType.Request);
  const isSaving = updateTaskSpecificationQuery.isLoading || deleteTaskSpecifcationQuery.isLoading;

  // event handlers
  const handleEdit = () => {
    const params = { spid: props.taskSpec.id };
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_SCOPE_OF_WORK_SPEC_EDIT, { params })
  }

  const handleDIY = () => {
    if (isLastNotDiySpec && !props.taskSpec.isDIY) {
      setCurrentAction(ActionType.DIY)
      setShowConfirmModal(true)
      return
    }
    updateTaskSpecificationAction({
      projectId,
      stageId,
      taskId,
      specId: props.taskSpec.id,
      taskSpecification: { isDIY: !props.taskSpec.isDIY }
    })
  }

  const handleDelete = () => {
    props.taskSpec.isStandard ? updateDisabled() : deleteSpec();
  }

  const updateDisabled = () => {
    if (isLastNotDiySpec && !props.taskSpec.isDIY && !props.taskSpec.isDisabled) {
      setCurrentAction(ActionType.Update)
      setShowConfirmModal(true)
      return
    }
    updateTaskSpecificationAction({
      projectId,
      stageId,
      taskId,
      specId: props.taskSpec.id,
      taskSpecification: { isDisabled: !props.taskSpec.isDisabled }
    })
    return;
  }

  const deleteSpec = () => {
    if (isLastNotDiySpec && !props.taskSpec.isDIY) {
      setCurrentAction(ActionType.Delete)
      setShowConfirmModal(true)
      return
    }
    deleteTaskSpecifcationAction({
      projectId,
      stageId,
      taskId,
      specId: props.taskSpec.id,
    })
  }

  const handleSpecUpdatesOnDiyConfirmed = () => {
    if (currentAction === ActionType.DIY) updateTaskSpecificationAction({
      projectId,
      stageId,
      taskId,
      specId: props.taskSpec.id,
      taskSpecification: { isDIY: true }
    })
    if (currentAction === ActionType.Delete) deleteTaskSpecifcationAction({
      projectId,
      stageId,
      taskId,
      specId: props.taskSpec.id,
    })
    if (currentAction === ActionType.Update) updateTaskSpecificationAction({
      projectId,
      stageId,
      taskId,
      specId: props.taskSpec.id,
      taskSpecification: { isDisabled: true }
    })
  }

  if (!showActions) return null;

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      {!props.taskSpec.isDisabled &&
        <CoreComponents.MoreMenu icon={MoreVertIcon} IconProps={{ edge: "end", sx: { ml: 1 } }}>
          {!props.taskSpec.isStandard && <CoreComponents.MoreMenuItem
            text="Edit"
            icon={EditIcon}
            onClick={() => handleEdit()}
          />}
          <CoreComponents.MoreMenuItem
            text={props.taskSpec.isDIY ? "Assign To Pro" : "Do It Yourself"}
            icon={EngineeringIcon}
            onClick={() => handleDIY()}
          />
          <CoreComponents.MoreMenuItem
            text="Delete"
            icon={DeleteIcon}
            color="secondary"
            onClick={() => handleDelete()}
          />
        </CoreComponents.MoreMenu>
      }
      {props.taskSpec.isDisabled &&
        <IconButton
          edge="end"
          onClick={() => updateDisabled()}
        >
          <UndoIcon />
        </IconButton>
      }

      <ConfirmDIY
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        // passed this function as props because the last spec(NOT DIY) needs to be marked as DIY
        // after the whole task is DIY
        updateLastSpecOnDiyConfirmed={handleSpecUpdatesOnDiyConfirmed}
      />
    </>
  )
}
export default TaskViewScopeOfWorkSpecListActions;