import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog, DialogContent } from "@mui/material";
import { TaskViewRouteDefinitions } from "modules/project/task/task-view";
import TaskViewQualityAssuranceChecklistForm from "./TaskViewQualityAssuranceChecklistForm";
import { useEffect } from "react";
import useTaskViewChecklistAddParams from "../../_hooks/useTaskViewQualityAssuranceChecklistAddParams";

const TaskViewQualityAssuranceChecklistAdd = () => {
  const routing = CoreRouting.useRouting();
  const { taskId, taskStageId, projectId, stageId } = useTaskViewChecklistAddParams();

  const [addQualityAssuranceItemAction, addQualityAssuranceItemQuery] = CoreState.QualityAssurance.addQualityAssuranceItem.useMutation();

  const handleClose = () => {
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE);
  }

  const handleSubmit = (values: { description: string }) => {
    if (!values.description) return;
    addQualityAssuranceItemAction({
      taskId,
      projectId,
      stageId,
      qualityAssuranceItem: {
        ...values,
        isStandard: false,
        stage: taskStageId,
      },
    });
  }

  useEffect(() => {
    if (!addQualityAssuranceItemQuery.isSuccess) return;
    handleClose();
  }, [addQualityAssuranceItemQuery.isLoading]);

  return (
    <Dialog open={true}>
      <CoreComponents.Loader show={addQualityAssuranceItemQuery.isLoading} />
      <CoreComponents.DialogHeader title="Add Checklist Item" onClose={handleClose} />
      <DialogContent>
        <TaskViewQualityAssuranceChecklistForm
          onSubmit={(values) => handleSubmit(values)}
          onClose={() => handleClose()}
        />
      </DialogContent>
    </Dialog>
  )

}
export default TaskViewQualityAssuranceChecklistAdd;