import { CoreModules, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { ProjectRoutesDefinitions } from "modules/project/ProjectRoutes";
import { useMemo } from "react";
import OnboardingRoutesDefinitions from "./OnboardingRouteDefinitions";

const OnboardingModule = () => {
  // locals
  const user = useSelector((s) => CoreState.User.selectCurrentUser(s));

  // order of the steps
  const steps = useMemo(() => {
    const rtn = [OnboardingRoutesDefinitions.INITIAL_PROFILE_DETAILS, OnboardingRoutesDefinitions.AWAITING_VERIFICATION];
    return rtn;
  }, []);
  
  // determine the initial step
  const getInitialStep = useMemo(() => {
    if (!user) return "";
    if (!user.mobile) return OnboardingRoutesDefinitions.INITIAL_PROFILE_DETAILS.path;
    if (!user.isVerified) return OnboardingRoutesDefinitions.AWAITING_VERIFICATION.path;
    return OnboardingRoutesDefinitions.ONBOARDING_COMPLETE.path;
  }, []);

  return (
    <CoreModules.Onboarding.OnboardingModule
      routes={OnboardingRoutesDefinitions}
      steps={steps}
      initialStep={getInitialStep}
      redirectUrl={ProjectRoutesDefinitions.PROJECT.path}
    />
  );
};
export default OnboardingModule