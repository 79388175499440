import { CoreRouting, PayToLogo } from '@build-buddy/core';
import { Avatar, Box, Button, Link, Stack, Typography, useTheme } from '@mui/material';
import TaskViewPaymentsRouteDefinitions from '../TaskViewPaymentsRouteDefinitions';
import { PriorityHigh as PriorityHighIcon } from '@mui/icons-material';

const TaskViewQuotePayToReceipt = () => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  // event handlers
  const handleClose = () => {
    routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENT_SCHEDULE)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        p: 2,
        mt: 4
      }}>
      <PayToLogo />
      <Typography variant="h2" mt={2}>Authorise Payment Request</Typography>
      <Typography textAlign={'center'}>
        Please go to your banking App or portal to authorise PayTo agreement request.
      </Typography>
      <Box
        sx={{
          maxWidth: '800px',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'center', md: 'flex-start' },
          justifyContent: 'center',
          gap: 1,
          mt: 2,
          textAlign: 'center'
        }}
      >
        <Avatar
          sx={{
            bgcolor: theme.palette.primary.main,
            height: 24,
            width: 24
          }}
        >
          <PriorityHighIcon sx={{ color: theme.palette.common.white, fontSize: 16 }} />
        </Avatar>
        <Stack>
          <Typography variant="body1" >
            If you don't receive the PayTo agreement request from your bank within 12 hours, please login to your online banking through a web browser. Your bank may not currently have PayTo requests integrated with their mobile banking app.
          </Typography>
          <Typography mt={1.3}>
            For more information, click {" "}
            <Link href="https://assets.buildbuddy.au/payto" underline='none' target="_blank" >here.</Link>
          </Typography>
        </Stack>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleClose()}
        sx={{ mt: 2 }}
      >
        Back to the Task
      </Button>
    </Box>
  )
}

export default TaskViewQuotePayToReceipt