import { CoreState } from "@build-buddy/core";
import { MenuItem, useTheme } from "@mui/material";
import { CommonState } from "common";
import { Project } from "common/components/display";
import { useDispatch, useSelector } from "common/state/store";
import { selectIsBuildBuddyHelpDesk } from "modules/project/_selectors/selectProjectAccessLevel";
import React from "react";

interface ProjectSelectListItemProps {
  project: CoreState.Project.Project;
  onClick(): void;
}

const ProjectSelectListItem: React.FC<ProjectSelectListItemProps> = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  // Selector
  const isBuildBuddyHelpDesk = useSelector((state) => selectIsBuildBuddyHelpDesk(state, { projectId: props.project?.id }));

  const handleClick = (project: CoreState.Project.Project) => {
    dispatch(CommonState.App.switchProject({ project }));
    props.onClick();
  };

  // Query
  const getUserProjectListQuery = CoreState.Project.getUserProjectList.useQuery();

  return <>
    <MenuItem
      sx={{
        py: 1.5,
        borderBottom: `dashed 1px ${theme.palette.divider}`,
        "&:last-child": {
          borderBottom: "none",
        },
      }}
      onClick={() => handleClick(props.project)}
    >
      <Project
        project={props.project}
        isBuildBuddyHelpDesk={isBuildBuddyHelpDesk}
        isLoading={getUserProjectListQuery.isLoading}
      />
    </MenuItem>
  </>
}

export default ProjectSelectListItem;