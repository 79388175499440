import { CoreRouting } from "@build-buddy/core";
import { Switch } from "react-router";

import BudgetTrackingExpenseAdd from "./BudgetExpenseAdd";
import BudgetTrackingExpenseEdit from "./BudgetExpenseEdit";
import BudgetTrackingExpenseView from "./BudgetExpenseView";

import { useRouteMatch } from "react-router";
import { useFinancialRoutesParams } from "../../FinancialsRoutes";

const BudgetExpenseRoutesDefinitions = {
  ADD_BUDGET_TRACKING_EXPENSE: {
    path: "/*/task/:tid/payment-schedule/add",
    component: BudgetTrackingExpenseAdd
  },
  EDIT_BUDGET_TRACKING_EXPENSE: {
    path: "/*/task/:tid/payment-schedule/:psid/edit",
    component: BudgetTrackingExpenseEdit
  },
  VIEW_BUDGET_TRACKING_EXPENSE: {
    path: "/*/task/:tid/payment-schedule/:psid/view",
    component: BudgetTrackingExpenseView,
  },
};

const useBudgetExpenseParams = () => {
  const match = useRouteMatch<any>([
    BudgetExpenseRoutesDefinitions.ADD_BUDGET_TRACKING_EXPENSE.path,
    BudgetExpenseRoutesDefinitions.EDIT_BUDGET_TRACKING_EXPENSE.path,
    BudgetExpenseRoutesDefinitions.VIEW_BUDGET_TRACKING_EXPENSE.path
  ]);

  const { projectId, stageId } = useFinancialRoutesParams();

  const taskId = match?.params?.tid;
  const paymentScheduleId = match?.params?.psid;

  return {
    projectId,
    stageId,
    taskId,
    paymentScheduleId,
  };
}

const BudgetExpenseRoutes = () => {
  return (
    <Switch>
      <CoreRouting.Routes
        routes={Object.values(BudgetExpenseRoutesDefinitions)}
      />
    </Switch>
  );
};

export { BudgetExpenseRoutes, BudgetExpenseRoutesDefinitions, useBudgetExpenseParams };

