import { Stack, SxProps, Theme } from "@mui/material";
import { ProjectTask } from "../../_models";
import ProjectTaskStatusChip from "./ProjectTaskStatusChip";
import ProjectTaskStatusDIY from "./ProjectTaskStatusDIY";
import ProjectTaskStatusPredecessors from "./project-task-status-predecessors/ProjectTaskStatusPredecessors";

type ProjectTaskStatusProps = {
  projectTask?: ProjectTask;
  sx?: SxProps<Theme>;
}
const ProjectTaskStatus = (props: ProjectTaskStatusProps) => {
  const { projectTask, sx } = props;

  if (!projectTask || !projectTask.status) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ 
        display: "flex", 
        gap: 0.5,
        maxHeight: "24px",
        ...sx
      }}
    >
      <ProjectTaskStatusDIY 
        task={projectTask.task}
      />      
      <ProjectTaskStatusChip 
        status={projectTask.status}
      />
      <ProjectTaskStatusPredecessors 
        task={projectTask.task} 
      />      
    </Stack>
  )
}
export default ProjectTaskStatus;
