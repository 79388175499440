import { Box, BoxProps, Stack, SxProps, Theme, Typography, TypographyProps, useTheme } from "@mui/material";
interface CostsPieChartLegendItemProps {
  color: string;
  label: string;
  value?: string;
  sx?: SxProps<Theme>;
  variant?: "striped" | "normal";
  LabelProps?: TypographyProps;
  CircleProps?: BoxProps;
}
const CostsPieChartLegendItem = (props: CostsPieChartLegendItemProps) => {
  const { label, color, value, sx, LabelProps, CircleProps, variant = "normal" } = props;

  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        ...sx
      }}
    >
      <Box
        sx={{
          borderRadius: "50%",
          border: `solid 1.5px ${color}`,
          background: variant === "striped"
            ? `repeating-linear-gradient(115deg, ${color}, ${color} 1.5px, ${theme.palette.common.white} 1.5px, ${theme.palette.common.white} 4.5px)`
            : color,
          mr: 1,
          width: {
            xs: "12px",
            sm: "16px",
            lg: "12px",
            xl: "16px"
          },
          height: {
            xs: "12px",
            sm: "16px",
            lg: "12px",
            xl: "16px"
          },
          ...CircleProps?.sx
        }}
      />
      <Typography
        variant="body1"
        sx={{
          flex: 1,
          textTransform: "capitalize",
          fontSize: {
            xs: "12px",
            sm: "14px",
            lg: "12px",
            xl: "14px"
          },
          ...LabelProps?.sx
        }}
      >
        {label}
      </Typography>
      {value &&
        <Typography
          variant="h2"
          sx={{
            fontSize: {
              xs: "14px",
              sm: "18px",
              lg: "14px",
              xl: "18px",
            }
          }}
        >
          {value}
        </Typography>
      }
    </Stack>
  )
}

export default CostsPieChartLegendItem;