import { CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { format } from "date-fns";
import { groupBy, sortBy } from "lodash";
import { useMemo } from "react";

const useCulmulativeTasksChart = () => {
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);
  const getProjectStageListQuery = CoreState.Project.getProjectStageList.useQuery(
    { projectId: projectId },
    { skip: !projectId }
  )
  const getTaskListByProjectQuery = CoreState.Task.getTaskListByProject.useQuery({
    projectId: projectId,
    filter: {
      includeStatus: [CoreState.Task.TaskStatus.Initial],
      pageNumber: 1,
      pageSize: 9999,
    }
  })
  const chart = useMemo(() => {
    if (!getProjectStageListQuery.data || !getTaskListByProjectQuery.data) return;
    const tasks = getTaskListByProjectQuery.data;

    const sorted = sortBy(tasks, ["completedDate"]);

    // group all objects by month & year
    const grouped = groupBy(sorted, (o) => {
      if (!o.completedDate) return;
      const date = new Date(o.completedDate);
      const month = format(date, "MMM");
      const year = format(date, "yyyy");
      return `${month} ${year}`
    });

    const data: any = [];

    // loop through all grouped keys, and reconstruct the date object  
    Object.keys(grouped).forEach((x, idx) => {
      let val = grouped[x].length

      // use the previous value to add it up
      if (idx !== 0) val = data[idx - 1] + val;
      data.push(val);
    })

    const xAxis = Object.keys(grouped);

    return {
      xAxis,
      data
    };
  }, [getTaskListByProjectQuery.data, getProjectStageListQuery.data])

  return {
    chart,
    isLoading: getTaskListByProjectQuery.isLoading || getProjectStageListQuery.isLoading
  }
}
export default useCulmulativeTasksChart;