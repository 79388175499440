import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Search as SearchIcon } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import { RouteDefinitions } from "common";
import { useSelector } from "common/state/store";
import { useState } from "react";
import DashboardTaskListEmpty from "./DashboardTasksListEmpty";
import DashboardTaskListHeader from "./DashboardTasksListHeader";
import DashboardTasksListItem from "./DashboardTasksListItem";
import DashboardTaskListSkeleton from "./DashboardTasksListSkeleton";
import useDashboardTasksList from "./_hooks/useDashboardTasksList";
import { selectDashboardTaskListFilteredAndSorted } from "./_selectors";

const DashboardTasksList = () => {
  const routing = CoreRouting.useRouting();
  const { projectId, filterStatus } = useDashboardTasksList();

  // state
  const [search, setSearch] = useState("");

  // queries
  const taskListQuery = CoreState.Task.getTaskListByProject.useQuery({ projectId });

  // selectors 
  const dashboardTaskList = useSelector((s) =>
    selectDashboardTaskListFilteredAndSorted(s, {
      projectId: projectId,
      status: filterStatus,
      search: search,
    })
  )

  // vars
  const showHeader = Boolean(!taskListQuery.isLoading && dashboardTaskList.length);
  const isLoading = taskListQuery.isLoading || taskListQuery.isFetching;

  // events
  const handleClick = (task: CoreState.Task.Task) => {
    const params = {
      sid: task.stageId,
      tid: task.parentId
    }
    routing.go(RouteDefinitions.Task.TASK_VIEW, { params });
  }

  return (
    <>
      <Box>
        <TextField
          size="small"
          placeholder="Search tasks"
          sx={{
            mr: 1,
            width: {
              xs: "100%",
              sm: "auto"
            }
          }}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1 }} />,
          }}
        />
      </Box>
      {showHeader &&
        <DashboardTaskListHeader />
      }
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={!dashboardTaskList.length}
        empty={<DashboardTaskListEmpty />}
        loader={<DashboardTaskListSkeleton />}
      >
        {dashboardTaskList.map((dt, idx) => (
          <DashboardTasksListItem
            key={idx}
            projectTask={dt}
            onClick={handleClick}
          />
        ))}
      </CoreComponents.PreContent>
    </>
  )
}
export default DashboardTasksList;