import React from "react";
import { Box, Card } from "@mui/material";
import SelectionsGuard from "./SelectionsGuard";

const ProjectSelections = () => {
  return (
    <SelectionsGuard>
      <Box>
        <Card>
          <iframe
            title="selections"
            src="https://selections.buildbuddy.au/"
            style={{
              display: "block",
              width: "94.5vw",
              height: "90vh",
              border: "none",
            }}
          ></iframe>
        </Card>
      </Box>
    </SelectionsGuard>
  );
};

export default ProjectSelections;
