import { CoreComponents, CoreState } from "@build-buddy/core";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { Route, Switch } from "react-router-dom";
import { useTaskViewParams } from "../_hooks";
import TaskViewDocumentsItem from "./TaskViewDocumentsItem";
import TaskViewDocumentsRouteDefinitions from "./TaskViewDocumentsRouteDefinitions";
import { selectTaskViewDocumentsInput, selectTaskViewDocumentsOther, selectTaskViewDocumentsOutput } from "./_selectors";

const TaskViewDocuments = () => {
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const getTagListByTaskDocumentParams = { projectId, stageId, taskId };
  const getDocListByTaskParams = { projectId, taskId };

  // queries  
  const getTagListByTaskDocumentQuery = CoreState.Tag.getTagListByTaskDocument.useQuery(getTagListByTaskDocumentParams);
  const getDocListByTaskQuery = CoreState.File.getDocListByTask.useQuery(getDocListByTaskParams);
  const isLoading =
    Boolean(getTagListByTaskDocumentQuery.isLoading || getTagListByTaskDocumentQuery.isUninitialized) ||
    Boolean(getDocListByTaskQuery.isLoading || getDocListByTaskQuery.isUninitialized)

  // locals
  const outputDocs = useSelector((s) => selectTaskViewDocumentsOutput(s, { projectId, stageId, taskId }))
  const inputDocs = useSelector((s) => selectTaskViewDocumentsInput(s, { projectId, stageId, taskId }))
  const otherDocs = useSelector((s) => selectTaskViewDocumentsOther(s, getDocListByTaskParams))

  return (
    <>
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={false}
      >
        <Box sx={{ mb: 3 }}>
          <CoreComponents.CollapseHeader
            title="Documents Expected for Quoting"
            tooltip="It is recommended to have the following files uploaded to the document library to ensure an accurate quote"
            open={true}
          >
            <CoreComponents.DocumentListHeader />
            {inputDocs.map((o, idx) => (
              <TaskViewDocumentsItem
                key={idx}
                tag={o.tag}
                document={o.document}
              />
            ))}
          </CoreComponents.CollapseHeader>
        </Box>
        <Box sx={{ mb: 3 }}>
          <CoreComponents.CollapseHeader
            title="Documents Expected to be Produced"
            tooltip="After receiving the final approved file from your PRO, please upload the document here"
            open={true}
          >
            <CoreComponents.DocumentListHeader />
            {outputDocs.map((o, idx) => (
              <TaskViewDocumentsItem
                key={idx}
                tag={o.tag}
                document={o.document}
              />
            ))}
          </CoreComponents.CollapseHeader>
        </Box>        
        <CoreComponents.CollapseHeader
          title="Other Documents"
          open={true}          
        >
          <CoreComponents.DocumentList
            documents={otherDocs}
            actions={(doc) => (
              <CoreComponents.MoreMenu
                icon={MoreVertIcon}
                IconProps={{ edge: "end", sx: { ml: 1 } }}
              >
                <CoreComponents.DocumentListActionsDownload
                  doc={doc}
                />
                <CoreComponents.DocumentListActionsView
                  doc={doc}
                />
              </CoreComponents.MoreMenu>
            )}
          />
        </CoreComponents.CollapseHeader>
      </CoreComponents.PreContent>
      <Switch>
        <Route
          path={TaskViewDocumentsRouteDefinitions.TASK_VIEW_DOCUMENTS_ADD.path}
          component={TaskViewDocumentsRouteDefinitions.TASK_VIEW_DOCUMENTS_ADD.component}
        />
      </Switch>
    </>
  )
}
export default TaskViewDocuments;