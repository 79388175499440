import { CoreTheme } from "@build-buddy/core";
import { createTheme } from '@mui/material/styles';
import palette from './palette';

const defaultTheme = CoreTheme.coreTheme;

palette(defaultTheme);
CoreTheme.variables(defaultTheme);

export default createTheme(defaultTheme);
