import { CoreComponents, CoreRouting, CoreState, CoreUtils } from "@build-buddy/core";
import { Dialog, DialogActions, DialogContent, MenuItem, Stack } from "@mui/material";
import { useSelector } from "common/state/store";
import { add } from "date-fns";
import { Formik } from "formik";
import { useEffect } from "react";
import { useTaskViewParams } from "../../../_hooks";
import { selectTaskViewQuoteListAcceptedQuoteCompanyId, selectTaskViewQuoteListAcceptedQuoteQuoteId } from "../../../_selectors";
import TaskViewQualityAssuranceRouteDefinitions from "../../TaskViewQualityAssuranceRouteDefinitions";
import { addTaskViewQualityAssuranceDefectsItem } from "../_actions";
import { useTaskViewQualityAssuranceDefectsParams } from "../_hooks";
import TaskViewQualityAssuranceDefectsAddValidation from "./TaskViewQualityAssuranceDefectsAddValidation";

const TaskViewQualityAssuranceDefectsAdd = () => {
  // hooks
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const { qualityAssuranceId } = useTaskViewQualityAssuranceDefectsParams();
  const getQuoteListAcceptedQuoteParam = { projectId, stageId, taskId };
  const getQualityAssuranceListByTaskQueryParams = { projectId, stageId, taskId };

  // queries
  const getTagListByTypeDefectQuery = CoreState.Tag.getTagListByTypeDefect.useQuery();
  const getQualityAssuranceListByTaskQuery = CoreState.QualityAssurance.getQualityAssuranceListByTask.useQuery(getQualityAssuranceListByTaskQueryParams);

  // mutations
  const [addTaskViewQualityAssuranceDefectsItemMutation, addTaskViewQualityAssuranceDefectsItemAction] = addTaskViewQualityAssuranceDefectsItem.useMutation();

  // selectors
  const quoteId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteQuoteId(s, getQuoteListAcceptedQuoteParam));
  const locationTagList = useSelector(CoreState.Tag.selectTagListByTypeDefect);
  const qualityAssuranceList = useSelector((s) => CoreState.QualityAssurance.selectQualityAssuranceActiveListByTask(s, getQualityAssuranceListByTaskQueryParams));
  const companyId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteCompanyId(s, { projectId, stageId, taskId }));

  // locals
  const isSaving = Boolean(addTaskViewQualityAssuranceDefectsItemAction.isLoading);
  const isSuccess = Boolean(addTaskViewQualityAssuranceDefectsItemAction.isSuccess && !addTaskViewQualityAssuranceDefectsItemAction.isUninitialized)
  const isQaLoading = Boolean(getQualityAssuranceListByTaskQuery.isLoading);
  const isLocationsLoading = Boolean(getTagListByTypeDefectQuery.isLoading);

  // event handlers
  const handleAdd = (values: any) => {
    if (!companyId || !quoteId) return;

    const defect: CoreState.Common.Subset<CoreState.Defect.Defect> = {
      name: values.title,
      description: values.description,
      startDate: CoreUtils.Formatter.dateTimeOffset(new Date()),
      endDate: values.finish,
      category: values.category,
      associatedId: values.associatedId,
      locations: values.locations
    }

    const noteThread: CoreState.Common.Subset<CoreState.NoteThread.NoteThread> = {
      notes: [{ content: "" }]
    }

    const docs = values.files.toSave.map((f: any) => ({
      file: f.file,
      name: `${f.file.name}`,
      description: f.file.name
    }));

    addTaskViewQualityAssuranceDefectsItemMutation({
      projectId,
      stageId,
      taskId,
      defect,
      noteThread,
      docs,
      quoteId,
      toUserId: companyId,
    })
  }

  const handleClose = () => {
    routing.go(TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE);
  }

  // side effects
  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess]);

  return (
    <Formik
      initialValues={{
        category: "",
        finish: add(new Date(), { days: 10 }),
        title: "",
        description: "",
        locations: [],
        associatedId: qualityAssuranceId || "",
        files: {
          toSave: [],
          toRemove: []
        },
      }}
      validationSchema={TaskViewQualityAssuranceDefectsAddValidation}
      onSubmit={(values) => handleAdd(values)}
    >
      {formik => (
        <Dialog open={true}>
          <CoreComponents.Loader show={isSaving} />
          <CoreComponents.DialogHeader title="Raise Defect" onClose={() => handleClose()} />
          <DialogContent>
            <Stack direction="row" columnGap={1}>
              <CoreComponents.FormikErrorFocus formik={formik} />
              <CoreComponents.FormikSelect
                name="category"
                fullWidth
                label="Category*"
                displayEmpty={true}
                sx={{ flex: 1 }}
              >
                <MenuItem value="Minor"> Minor </MenuItem>
                <MenuItem value="Major"> Major </MenuItem>
              </CoreComponents.FormikSelect>
              <CoreComponents.FormikDatePicker
                name="finish"
                fullWidth
                label="To be rectified by*"
              />
            </Stack>
            <CoreComponents.FormikTextbox
              name="title"
              label="Title*"
              InputComponentProps={{
                maxLength: 101
              }}
              fullWidth
            />
            <CoreComponents.FormikTextbox
              name="description"
              label="Description*"
              InputComponentProps={{
                maxLength: 501
              }}
              fullWidth
              multiline
            />
            <CoreComponents.FormikAutocompleteSelect
              name="locations"
              label="Locations"
              isLoading={isLocationsLoading}
              options={locationTagList}
            />
            <CoreComponents.FormikSelect
              name="associatedId"
              label="Associated with"
              isLoading={isQaLoading}
              displayEmpty
              fullWidth
            >
              <MenuItem value=""> None </MenuItem>
              {qualityAssuranceList?.map((qa, index) =>
                <MenuItem value={qa.id} key={index}> {qa.name || qa.description} </MenuItem>
              )}
            </CoreComponents.FormikSelect>
            <CoreComponents.FormikUploadFile
              label="Upload supporting documents"
              name="files"
              handleDownload={() => { }}
            />
          </DialogContent>
          <DialogActions>
            <CoreComponents.Actions
              submitText="Publish"
              onSubmitClick={() => formik.handleSubmit()}
            />
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}
export default TaskViewQualityAssuranceDefectsAdd;