import { CoreComponents } from "@build-buddy/core";
import { DialogContent, Skeleton } from "@mui/material";

type TaskViewQuotesViewSkeletonProps = {
  onClose(): void;
}
const TaskViewQuotesViewSkeleton = (props: TaskViewQuotesViewSkeletonProps) => {
  return (
    <>
      <CoreComponents.DialogHeaderSkeleton onClose={props.onClose} />
      <DialogContent>
        <CoreComponents.LabelValueSkeleton icon={{ show: true }} sx={{ mb: 3 }} />
        <CoreComponents.LabelValueSkeleton icon={{ show: true }} sx={{ mb: 3 }} />
        <CoreComponents.LabelValueSkeleton icon={{ show: true }} sx={{ mb: 3 }} />
        <Skeleton variant="rectangular" height={125} width="100%" sx={{ mb: 3 }} />
        <CoreComponents.LabelValueSkeleton icon={{ show: true }} sx={{ mb: 3 }} />
        <Skeleton variant="rectangular" height={50} width="100%" />
      </DialogContent>
    </>
  )
}
export default TaskViewQuotesViewSkeleton;