
import { CoreRouting, CoreUtils } from "@build-buddy/core";
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, Close as CloseIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { add, startOfDay } from "date-fns";
import CalendarRouteDefinitions from "../CalendarRouteDefinitions";
import { useCalendarParams } from "../_hooks";

type CalendarDrawerHeaderProps = {
  handleClose(): void
}
const CalendarDrawerHeader = (props: CalendarDrawerHeaderProps) => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();
  const { activeDate } = useCalendarParams();

  const handleNavigate = (direction: number) => {
    const d = startOfDay(add(activeDate, { days: direction }));
    const params = {
      month: d.getMonth() + 1,
      day: d.getDate()
    }
    routing.go(CalendarRouteDefinitions.CALENDAR_DRAWER, { params })
  }

  return (
    <Stack
      alignItems="center"
      direction="row"
      sx={{
        px: 2,
        py: 1,
        borderTop: `solid 1px ${theme.palette.grey[200]}`,
        borderBottom: `solid 1px ${theme.palette.grey[200]}`
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ flex: 1 }}
      >
        <IconButton onClick={() => handleNavigate(-1)}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography
          sx={{
            mx: 1,
            position: "relative",
            top: 0.5,
            width: "140px",
            textAlign: "center"
          }}
          variant="body1"
        >
          {CoreUtils.Formatter.date(activeDate, "dd MMMM yyyy")}
        </Typography>
        <IconButton onClick={() => handleNavigate(1)}>
          <ChevronRightIcon />
        </IconButton>
      </Stack>
      <IconButton onClick={props.handleClose}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

export default CalendarDrawerHeader;
