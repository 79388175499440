
import { CoreComponents } from "@build-buddy/core";
import { SxProps, Theme } from "@mui/material";

type DashboardTasksListTemplateProps = { 
  children: any;
  sx?: SxProps<Theme>;
  hasLastTypeStyles?: boolean;
  hasHoverStyles?: boolean;
  onClick?(e?: any): void;
}
const DashboardTasksListTemplate = (props: DashboardTasksListTemplateProps) => {
  return (
    <CoreComponents.GridListTemplate
      sx={{
        display: "grid",
        gridTemplateAreas: {
          xs: `
            'status start-date separator end-date'
            'name name name name'  
          `,
          md: `
            'name status start-date end-date'
          `
        },
        gridTemplateColumns: {
          xs: `auto  84px 10px 60px`,
          md: `auto 220px 100px 15%`,
        },
        rowGap: 1,
        ...props.sx
      }}
      hasLastTypeStyles={props.hasLastTypeStyles}
      hasHoverStyles={props.hasHoverStyles}
      onClick={props.onClick}
    >
      {props.children}
    </CoreComponents.GridListTemplate>
  )
}
export default DashboardTasksListTemplate;