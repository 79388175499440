import { Tooltip, useTheme } from "@mui/material";
import { Stars as StarIcon } from "@mui/icons-material";
import { ProjectTask, ProjectTaskSubStatus } from "modules/project/_models";

type ProjectTaskActionsWriteReviewProps = {
  projectTask: ProjectTask
}
const ProjectTaskActionsWriteReview = (props: ProjectTaskActionsWriteReviewProps) => {
  const theme = useTheme();
  if (!props.projectTask.subStatuses.includes(ProjectTaskSubStatus.ReviewRequested)) return null;
  return (
    <Tooltip title="Write Review">
      <StarIcon sx={{ fontSize: 28, color: theme.palette.primary.main }} />
    </Tooltip>
  )
}
export default ProjectTaskActionsWriteReview;