import { CoreComponents, CoreRouting, CoreState } from '@build-buddy/core'
import { Box } from '@mui/material'
import { CommonState } from 'common'
import { selectIncompleteProfileItems } from 'common/state/app'
import { IncompleteProfileItems } from 'common/state/app/selectors/selectIncompleteProfileItems'
import { useSelector } from 'common/state/store'
import { UserProfileRouteDefinitions } from 'modules/user/profile'
import { UserWalletRouteDefinitions } from 'modules/user/wallet/WalletModule'
import { NotificationsNone as NotificationsNoneIcon} from '@mui/icons-material';

const useIncompleteValidations = (validations: IncompleteProfileItems, isCurrentUserOwner: boolean, isLimited: boolean, routing: any) => {
  const incompleteValidations: Array<CoreState.Common.ProfileCompletionCheckItem> = [];

  const addValidationItem = (condition: boolean, item: CoreState.Common.ProfileCompletionCheckItem) => {
    if (condition) {
      incompleteValidations.push(item);
    }
  };

  addValidationItem(
    !validations.hasWallet && isCurrentUserOwner && !isLimited,
    {
      title: "Create Wallet",
      description: 'Setup your wallet to make secure payment transactions.',
      actionLabel: 'Create Wallet',
      handleClick: () => routing.go(UserWalletRouteDefinitions.CREATE_WALLET),
      icon: <CoreComponents.WalletIcon />
    }
  );

  addValidationItem(
    !validations.hasBankAccount && isCurrentUserOwner && !isLimited,
    {
      title: "Add Bank Account",
      description: 'Add your bank account to make secure payment transactions.',
      actionLabel: 'Add Account',
      handleClick: () => routing.go(UserWalletRouteDefinitions.ADD_BANK_ACCOUNT),
      icon: <CoreComponents.BankIcon />,
      disableAction: !validations.hasWallet
    }
  );

  addValidationItem(
    !validations.notificationReviewed && !isLimited,
    {
      title: "Review Notifications",
      description: 'Review your notifications settings to customise.',
      actionLabel: 'Review Notifications',
      handleClick: () => routing.go(UserProfileRouteDefinitions.USER_PROFILE_SETTINGS_EDIT),
      icon: <NotificationsNoneIcon sx={{fontSize: '25px'}}/>,
      disableAction: false
    }
  );

  return incompleteValidations;
};

const DashboardProfileCheck = () => {
  const routing = CoreRouting.useRouting();

  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const isCurrentUserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner);
  const validations = useSelector((s) => selectIncompleteProfileItems(s, { userId }));
  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);

  // Queries
  const getUserQuery = CoreState.User.getUser.useQuery({ userId });
  const getFinancialsDetails = CoreState.Wallet.getFinancialDetails.useQuery({ userId })

  const incompleteValidations = useIncompleteValidations(validations, isCurrentUserOwner, isLimited, routing);

  return (
    <Box px={{ xs: 1, sm: 0 }}>
      <CoreComponents.ProfileCompletionChecker
        validations={validations as any}
        incompleteValidationItems={incompleteValidations}
        loading={getUserQuery.isLoading || getFinancialsDetails.isLoading}
      />
    </Box>
  )
}

export default DashboardProfileCheck