import { useState } from "react";
import {  CoreComponents } from "@build-buddy/core";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { DiyIcon } from "assets/icons";
import ConfirmDIY from "./ConfirmDIY";

const TaskViewDiy = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  return (
    <>
      <CoreComponents.MoreMenu 
        icon={MoreVertIcon} 
        IconProps={{ edge: "end", sx: { ml: 1 } }}
      >
        <CoreComponents.MoreMenuItem
          text="Do The Task Yourself"
          icon={DiyIcon}
          onClick={() => setShowConfirmModal(true)}
        />
      </CoreComponents.MoreMenu>
      {/* Confirmation for DIY */}
      <ConfirmDIY show={showConfirmModal} onClose={() => setShowConfirmModal(false)}/>
    </>
  )
}

export default TaskViewDiy