
import { CoreState } from "@build-buddy/core";
import transformSubTask from "../transformers/transformSubTask";

interface UpdateTasksParams {
  projectId: string;
  subTasks: Record<string, object>
}
const updateTasks = CoreState.ApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateTasks: builder.mutation<any, UpdateTasksParams>({
      queryFn: async (params, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        const updateTasks = await fetchWithBaseQuery({
          url: [`${CoreState.baseUrl("worksite")}/worksite-management/v1/projects/${params.projectId}/subTasks/markRequired`].join(""),
          method: "PUT",
          body: transformSubTask.toDTO(params.subTasks)
        });

        return { data: null }
      },
      invalidatesTags: ["Task"],
    }),
  }),
});

export default updateTasks.endpoints.updateTasks;
