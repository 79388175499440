import { FC, useEffect, useState } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import { useFormikContext } from "formik";
import { OptionList } from "common/components";

interface FormOptionListProps {
  data: any;
  fields: any;
  onAnswer(answer: string): void;
}
const FormOptionList: FC<FormOptionListProps> = (props: FormOptionListProps) => {
  const formik = useFormikContext<any>();
  const [field, setField] = useState<any>();
  const error = formik.errors[field?.inputId];
  const value = formik.values[field?.inputId];
  const items = props.data.map((d: any) => ({
    title: d.header,
    caption: d.text,
    value: d.value
  }));

  const handleChange = (val: any) => {
    // we initially just set the value
    let fv = val;

    // javascript 'goodness' - we can change the logic to set it to an array instead
    if(Array.isArray(value)) {
      // if the item exists in the array, remove it, otherwise add it
      fv = value.includes(val) ? value.filter(v => v !== val) : [...value, val];
    }

    formik.setFieldValue(field.inputId, fv)
  }

  useEffect(() => {
    const f = props.fields.find((f: any) => f.inputRef === "value");
    if (!f) return;
    setField(f);
  }, [props.fields]);

  if (!field) return null;

  return (
    <FormControl 
      fullWidth
      error={Boolean(error)}
    >
      <OptionList
        items={items}
        value={value}
        error={Boolean(error)}
        onChange={handleChange}
      />
      {Boolean(error) && <FormHelperText> {error as any} </FormHelperText>}
    </FormControl>

  )
}
export default FormOptionList;
