import { Dialog, DialogContent, Stack, Typography, useTheme } from "@mui/material";
import { CoreComponents } from "@build-buddy/core";
import { Warning as WarningIcon } from "@mui/icons-material";

type TaskViewValidationProps = {
  open: boolean;
  errors: Array<string>;
  onClose(): void;
}
const TaskViewValidation = (props: TaskViewValidationProps) => {
  const theme = useTheme();
  return (
    <Dialog open={props.open}>
      <CoreComponents.DialogHeader title="Cannot complete task" onClose={() => props.onClose()} />
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}> Task cannot be closed until the following items have been resolved </Typography>
        {props.errors.map((err, idx) => (
          <Stack 
            key={idx}
            direction="row" 
            alignItems="center"             
            sx={{ background: theme.palette.yellow[200], p: 2, mb: 2 }}
          >
            <WarningIcon fontSize="small" sx={{ mr: 1, position: "relative", bottom: 1 }} />
            <Typography variant="h5">
              {err}
            </Typography>
          </Stack>
        ))}
      </DialogContent>
    </Dialog>
  )
}
export default TaskViewValidation;