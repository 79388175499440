import { Route } from "react-router";
import MyTeams from "./MyTeams";
import MyTeamsInviteAdd from "./MyTeamsInviteAdd";
import MyTeamsMemberEdit from "./MyTeamsMemberEdit";

const MyTeamsRoutesDefinitions = {
  MY_TEAMS: {
    path: "/project/:pid/my-teams/",
    component: MyTeams
  },
  MY_TEAMS_INVITE: {
    path: "/project/:pid/my-teams/invite",
    component: MyTeamsInviteAdd
  },
  MY_TEAMS_EDIT: {
    path: "/project/:pid/my-teams/:mid/edit",
    component: MyTeamsMemberEdit
  }
}

const MyTeamsRoutes = () => {
  return (
    <>
      <Route
        path={MyTeamsRoutesDefinitions.MY_TEAMS.path}
        component={MyTeamsRoutesDefinitions.MY_TEAMS.component}
      />    
      <Route
        path={MyTeamsRoutesDefinitions.MY_TEAMS_INVITE.path}
        component={MyTeamsRoutesDefinitions.MY_TEAMS_INVITE.component}
      />
      <Route
        path={MyTeamsRoutesDefinitions.MY_TEAMS_EDIT.path}
        component={MyTeamsRoutesDefinitions.MY_TEAMS_EDIT.component}
      />
    </>
  );
};

export { MyTeamsRoutesDefinitions, MyTeamsRoutes };
