import { CoreState } from "@build-buddy/core";
import { PaymentReceipt } from "modules/project/_components";
import { Slide, DialogContent } from "@mui/material";

export interface BudgetReleasePaymentReceiptProps {
  task: CoreState.Task.Task;
  paymentSchedule: CoreState.Financial.PaymentSchedule;
  onDownloadClick(): void;
}
const BudgetReleasePaymentReceipt = (props: BudgetReleasePaymentReceiptProps) => {
  const { task, paymentSchedule, onDownloadClick } = props;
  return (
    <Slide in={true} direction="left">
      <DialogContent>
        <PaymentReceipt
          type="Paid"
          task={task}
          paymentSchedule={paymentSchedule}
          onDownloadClick={onDownloadClick}
        />
      </DialogContent>
    </Slide>
  )
}
export default BudgetReleasePaymentReceipt