import { useFormik } from "formik";
import { CoreComponents, CoreState } from "@build-buddy/core";
import TaskViewScopeOfWorkSpecFormValidation from "./TaskViewScopeOfWorkSpecFormValidation";

interface TaskViewScopeOfWorkSpecFormProps {
  taskSpec?: CoreState.Specification.TaskSpecification | null;
  onSubmit(values: any): void;
  onClose(): void;
}
const TaskViewScopeOfWorkSpecForm = (props: TaskViewScopeOfWorkSpecFormProps) => {
  const formik = useFormik({
    initialValues: {
      description: props.taskSpec?.description || "",
      name: props.taskSpec?.name || ""
    },
    validationSchema: TaskViewScopeOfWorkSpecFormValidation,
    onSubmit: (values) => {
      props.onSubmit(values)
    }
  })

  return (
    <>
      <CoreComponents.Textbox
        fullWidth
        multiline
        label="Description*"
        name="description"
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched.description && formik.errors.description) && formik.errors.description}
      />
      <CoreComponents.Actions
        sx={{ mt: 2 }}
        submitText="Save"
        onCancelClick={() => props.onClose()}
        onSubmitClick={() => formik.handleSubmit()}
      />
    </>
  )

}
export default TaskViewScopeOfWorkSpecForm;