import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useEffect } from "react";
import { useProjectParams } from "../_hooks";
import { useMyTeamsParams } from "./_hooks";
import { MyTeamsRoutesDefinitions } from "./MyTeamsRoutes";

const MyTeamsMemberEdit = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId } = useProjectParams();
  const { memberId } = useMyTeamsParams();
  const getMemberByProjectParams = { projectId, memberId };

  // queries
  const getMemberByProjectQuery = CoreState.Membership.getMemberByProject.useQuery(getMemberByProjectParams)

  // mutations  
  const [updateMemberByProjectAction, updateMemberByProjectQuery] = CoreState.Membership.updateMemberByProject.useMutation();

  // locals
  const member = useSelector((s) => CoreState.Membership.selectMemberByProject(s, getMemberByProjectParams));
  const currentMember = member?.find(m => m.id === memberId)
  const isLoading = Boolean(getMemberByProjectQuery.isLoading);
  const isSaving = Boolean(updateMemberByProjectQuery.isLoading);
  const isSuccess = Boolean(updateMemberByProjectQuery.isSuccess);

  // events
  const handleClose = () => {
    routing.go(MyTeamsRoutesDefinitions.MY_TEAMS);
  }

  const handleSubmit = (values: any) => {
    const member: CoreState.Common.Subset<CoreState.Membership.Member> = {
      name: values.name,
      email: values.email,
      contact: values.contact,
      type: values.access
    }

    updateMemberByProjectAction({ projectId, memberId, member })
  }

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess])

  return (
    <CoreComponents.TeamMembersFormDialog 
      title="Edit Member"
      accessTypes={[CoreState.Project.ProjectAccessLevel.Manager, CoreState.Project.ProjectAccessLevel.Supervisor]}
      defaults={{
        name: currentMember?.name,
        email: currentMember?.email,
        contact: currentMember?.contact,
        access: currentMember?.type
      }}      
      isSaving={isSaving || isLoading}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  )
}
export default MyTeamsMemberEdit;