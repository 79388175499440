import { CoreState } from "@build-buddy/core";
import addTaskViewQualityAssuranceDefectsNoteItem from "./addTaskViewQualityAssuranceDefectsNoteItem";

interface AddTaskViewQualityAssuranceDefectsItemParams {
  projectId: string;
  stageId: string;
  taskId: string;
  quoteId: string;
  defect: CoreState.Common.Subset<CoreState.Defect.Defect>;
  noteThread: CoreState.Common.Subset<CoreState.NoteThread.NoteThread>;
  docs: Array<CoreState.Common.Subset<CoreState.File.Doc>>;
  toUserId: string;
}
const addTaskViewQualityAssuranceDefectsItem = CoreState.ApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addTaskViewQualityAssuranceDefectsItem: builder.mutation<any | undefined, AddTaskViewQualityAssuranceDefectsItemParams>({
      queryFn: async (params, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        const { projectId, stageId, taskId, defect, noteThread, docs, toUserId, quoteId } = params;
        const addDefectItemPayload = {
          projectId,
          stageId,
          taskId,
          quoteId,
          defect,
        }

        const addDefectItemResponse = await _queryApi.dispatch(CoreState.Defect.addDefectItem.initiate(addDefectItemPayload));
        if ('error' in addDefectItemResponse) return CoreState.Common.transformErrorResponse();
        if (!addDefectItemResponse.data) return CoreState.Common.transformErrorResponse();

        // if there are no docs, we dont need to create the note thread, return the success response
        if(!docs || !docs.length) return CoreState.Common.generateSuccessResponse();
        
        const addTaskViewQualityAssuranceDefectsNoteItemPayload = {
          projectId,
          stageId,
          taskId,
          defectId: addDefectItemResponse.data.id,
          noteThread: {...noteThread, title: `Default NoteThread for ${addDefectItemResponse.data.id}` },
          docs,
          toUserId,
        }

        const addTaskViewQualityAssuranceDefectsNoteItemResponse = await _queryApi.dispatch(
          addTaskViewQualityAssuranceDefectsNoteItem.initiate(addTaskViewQualityAssuranceDefectsNoteItemPayload)
        );

        return CoreState.Common.generateSuccessResponse();
      }
    }),
  }),
});

export default addTaskViewQualityAssuranceDefectsItem.endpoints.addTaskViewQualityAssuranceDefectsItem;
