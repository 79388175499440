import { CoreModules, CoreRouting } from '@build-buddy/core';
import { useSelector } from 'common/state/store';
import { selectProjectTaskListByParent } from 'modules/project/_selectors';
import { useTaskViewParams } from '../_hooks';
import TaskViewTimelineTaskList from './TaskViewTimelineTaskList';

const TaskViewTimeline = () => {
  const routing = CoreRouting.useRouting();
  const { projectId, stageId, taskId } = useTaskViewParams();
  const taskParams = { projectId, stageId, taskId };

  const projectTasks = useSelector((s) => selectProjectTaskListByParent(s, taskParams));
  
  const handleClose = () => {
    routing.back();
  }

  if (!projectTasks) return null;

  return (
    <CoreModules.Task.TaskView.TaskViewDrawer
      title="Task Timeline"
      description=""
      open={true}
      onClose={handleClose}      
    >
      <TaskViewTimelineTaskList
        projectId={projectId}
        projectTasks={projectTasks}
        readonly={false}
      />      
    </CoreModules.Task.TaskView.TaskViewDrawer>
  )
}

export default TaskViewTimeline