import { useProjectParams } from "modules/project/_hooks";
import { useRouteMatch } from "react-router";
import TaskViewRouteDefinitions from "../../../TaskViewRouteDefinitions";

const useTaskViewQualityAssuranceChecklistEditParams = () => {
  const { projectId } = useProjectParams();
  const match = useRouteMatch<any>(TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_CHECKLIST_EDIT);
  
  return {
    projectId,
    stageId: match?.params.sid,
    taskId: match?.params.tid,
    qualityAssuranceId: match?.params.cid,
  };
}
export default useTaskViewQualityAssuranceChecklistEditParams