import * as Core from "@build-buddy/core";
import Sandbox from "./Sandbox";

const OtherRouteDefinitions = {
  SANDBOX: {
    path: "/sandbox",
    component: Sandbox,
  },
};
const RootOtherRoutes = [OtherRouteDefinitions.SANDBOX];

const OtherRoutes = () => {
  return <Core.CoreRouting.Routes routes={RootOtherRoutes} />;
};

export { OtherRoutes,  };
