import React from "react";
import NumberFormat from "react-number-format";

interface DimensionTextFieldProps {
  onChange: (values: any) => void;
  name: string;
  suffix: string;
}
const DimensionTextField = React.forwardRef<
  NumberFormat<any>,
  DimensionTextFieldProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix={` ${props.suffix}`}
    />
  );
});
export default DimensionTextField;
