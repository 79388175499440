import { Avatar } from "@build-buddy/core";
import { AppBar, Box, IconButton, Toolbar, useTheme } from "@mui/material";
import Offset from "../../Offset";
import ProjectSelect from "../ProjectSelect";

type DesktopProps = {
  avatar?: string;
  onAvatarClick(e: any): void;
}

const DesktopHeader = (props: DesktopProps) => {
  const theme = useTheme();
  return (
    <>
      <AppBar position="fixed" sx={{ boxShadow: 0, background: theme.palette.common.white }}>
        <Toolbar
          sx={{
            display: "flex",
            minHeight: "64px",
            px: 3,
            ml: `${theme.variables.drawerWidth}px`,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <ProjectSelect />
          </Box>
          <IconButton edge="end" onClick={props.onAvatarClick} sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              sx={{
                fontSize: "28px",
                bgcolor: "common.black",
                color: "common.white",
              }}
              src={props.avatar}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Offset space={2} />
    </>
  );
};

export default DesktopHeader
