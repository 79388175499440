import { CoreComponents, CoreState, CoreUtils } from "@build-buddy/core";
import { Box, Collapse, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";

interface PaymentOptionsPayToProps {
  amount: number;
  name: string;
  address?: string;
  bank?: string;
  show?: boolean;
}
const PaymentOptionsPayTo = (props: PaymentOptionsPayToProps) => {
  const { amount, name, address, bank, show } = props;

  return (
    <>
      <FormControlLabel
        value={CoreState.Financial.PaymentType.PayTo}
        control={<Radio />}
        label={
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
          >
            <CoreComponents.PayToIcon height={20} width={20} />
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>PayTo </Typography>
          </Stack>
        }
      />
      <Collapse in={show}>
        <Box sx={{ mt: 1.5, mb: 1.5, ml: 3.5 }}>
          <Typography variant="h2" sx={{ mb: 1.5 }}> {CoreUtils.Formatter.currency(amount)} </Typography>
          <Typography variant="body2" sx={{ mb: 0.75 }}> Send to: </Typography>
          <Typography variant="h5" sx={{ mb: 0.25 }}> {name} </Typography>
          {/* TODO: Should add this in
            <Typography variant="body1" sx={{ mb: 0.25 }}> {address} </Typography>
            <Typography variant="body1" sx={{ mb: 0.25 }}> {bank} </Typography>           
          */}
        </Box>
        <Stack direction="row" alignItems="center" sx={{ ml: 3, mb: 1.5 }}>
          <InfoIcon sx={{ mr: 1, fontSize: "20px", position: "relative", bottom: 1 }} />
          <Typography variant="body2" sx={{ fontWeight: "bold" }}> No transaction fee </Typography>
        </Stack>
      </Collapse>
    </>
  )
}
export default PaymentOptionsPayTo;