import { CoreUtils } from "@build-buddy/core";
import { Link, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";

type CalendarViewHeaderProps = {
  date: Date;
  onNavigate(direction: number): void;
  onTodayNavigate(): void;
}
const CalendarViewHeader = (props: CalendarViewHeaderProps) => {
  const theme = useTheme();

  const handleTodayClick = () => {
    props.onTodayNavigate();
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        px: 2,
        py: 1,
        borderTop: `solid 1px ${theme.palette.grey[200]}`,
        background: theme.palette.common.white
      }}
    >
      <IconButton onClick={() => props.onNavigate(-1)}>
        <ChevronLeftIcon />
      </IconButton>
      <Typography
        sx={{
          mx: 1,
          position: "relative",
          top: 0.5,
          width: "125px",
          textAlign: "center"
        }}
        variant="body1"
      >
        {CoreUtils.Formatter.date(props.date, "MMMM yyyy")}
      </Typography>
      <IconButton onClick={() => props.onNavigate(1)}>
        <ChevronRightIcon />
      </IconButton>
      <Link 
        sx={{ ml: 2, textDecoration: "none", fontWeight: "bold", cursor: "pointer" }}
        onClick={() => handleTodayClick()}
      > 
        Today 
      </Link>
    </Stack>
  )
}
export default CalendarViewHeader;