import { CoreState, CoreUtils } from "@build-buddy/core";
import { ProjectTaskStatus } from "modules/project/_models";
import transformProjecTaskStatusConfirm from "./transformProjecTaskStatusConfirm";
import transformProjecTaskStatusDefault from "./transformProjecTaskStatusDefault";
import transformProjectTaskStatusComplete from "./transformProjectTaskStatusComplete";
import transformProjectTaskStatusAwaitingNext from "./transformProjectTaskStatusAwaitingNext";
import transformProjectTaskStatusUpcoming from "./transformProjectTaskStatusUpcoming";
import transformProjectTaskStatusQualityCheck from "./transformProjectTaskStatusQualityCheck";
import transformProjectTaskStatusAwaitingDetailST from "./transformProjectTaskStatusAwaitingDetailST";
import transformProjectTaskStatusNotRequired from "./transformProjectTaskStatusNotRequired";

const toModel = (
  task: CoreState.Task.Task
) => {
  const status = CoreUtils.chainOfResponsibility<CoreState.Task.Task>(task)
    .add(transformProjectTaskStatusAwaitingDetailST)
    .add(transformProjectTaskStatusNotRequired)
    .add(transformProjectTaskStatusAwaitingNext)
    .add(transformProjectTaskStatusUpcoming)
    .add(transformProjectTaskStatusComplete)
    .add(transformProjecTaskStatusConfirm)
    .add(transformProjectTaskStatusQualityCheck)    
    .add(transformProjecTaskStatusDefault)
    .execute<ProjectTaskStatus>();

  return status;
}

const transformProjectTaskStatus = { toModel };

export default transformProjectTaskStatus;