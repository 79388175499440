import { CoreComponents, CoreRouting, CoreState, CoreUtils } from "@build-buddy/core";
import { useEffect, useState } from "react";
import { useProjectParams } from "../_hooks";
import { MyTeamsRoutesDefinitions } from "./MyTeamsRoutes";

const MyTeamsInviteAdd = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId } = useProjectParams();

  // mutations
  const [createInviteByProjectAction, createInviteByProjectQuery] = CoreState.Membership.createInviteByProject.useMutation();

  // locals  
  const [errors, setErrors] = useState<any>({});
  const isSaving = Boolean(createInviteByProjectQuery.isLoading);
  const isSuccess = Boolean(createInviteByProjectQuery.isSuccess);
  const isError = Boolean(createInviteByProjectQuery.isError);

  // events
  const handleClose = () => {
    routing.go(MyTeamsRoutesDefinitions.MY_TEAMS);
  }

  const handleSubmit = (values: any) => {
    const invite: CoreState.Common.Subset<CoreState.Membership.Invite> = {
      name: values.name,
      email: values.email,
      contact: values.contact,
      type: values.access
    }

    createInviteByProjectAction({ projectId, invite })
  }

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess])

  useEffect(() => {
    const errors = CoreUtils.Converter.toFormikErrors([
      CoreUtils.ServiceResponseHelper.getServiceError(createInviteByProjectQuery)      
    ]);
    setErrors(errors);
  }, [isError])


  return (
    <CoreComponents.TeamMembersFormDialog
      title="Invite Member"
      accessTypes={[CoreState.Project.ProjectAccessLevel.Manager, CoreState.Project.ProjectAccessLevel.Supervisor]}
      isSaving={isSaving}
      errors={errors}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  )
}
export default MyTeamsInviteAdd;