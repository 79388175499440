import { useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { TaskViewRouteDefinitions, useTaskViewParams } from "modules/project/task/task-view"
import TaskViewScopeOfWorkSpecForm from "./TaskViewScopeOfWorkSpecForm";

const TaskViewScopeOfWorkSpecAdd = () => {
  // params
  const params = useTaskViewParams();
  const taskSpecificationParams = {
    projectId: params.projectId,
    stageId: params.stageId,
    taskId: params.taskId
  }

  // hooks
  const routing = CoreRouting.useRouting();

  // mutations
  const [addTaskSpecificationAction, addTaskSpecificationQuery] = CoreState.Specification.addTaskSpecification.useMutation();

  // local
  const isSaving = addTaskSpecificationQuery.isLoading;
  const isSaved = addTaskSpecificationQuery.isSuccess;

  // handlers
  const addTaskSpecification = (values: any) => {
    addTaskSpecificationAction({
      ...taskSpecificationParams,
      taskSpecification: {
        name: values.name,
        isStandard: false,
        description: values.description
      }
    })
  }

  const handleClose = () => {
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_SCOPE_OF_WORK);
  }

  const handleSubmit = (values: any) => {
    addTaskSpecification(values)
  }

  useEffect(() => {
    if (!isSaved) return;
    handleClose();
  }, [isSaved]);

  return (
    <Dialog open={true}>
      <CoreComponents.Loader show={isSaving} />
      <CoreComponents.DialogHeader title="Add Specification" onClose={() => handleClose()} />
      <DialogContent>
        <TaskViewScopeOfWorkSpecForm
          onSubmit={(values) => handleSubmit(values)}
          onClose={() => handleClose()}
        />
      </DialogContent>
    </Dialog>
  )

}
export default TaskViewScopeOfWorkSpecAdd;