import { CoreState, CoreComponents } from "@build-buddy/core";
import { Box, Button } from "@mui/material";
import { useTaskViewParams } from "../../../_hooks";
import { useState } from "react";

type TaskViewQualityAssuranceDefectActionsInitialProps = {
  defect: CoreState.Defect.Defect;
}
// this is declared due to the rule of hooks, since its a chain, if any hooks gets rendered and the number changes, it causes a violation
const TaskViewQualityAssuranceDefectActionsInitial = (props: TaskViewQualityAssuranceDefectActionsInitialProps) => {
  const { defect } = props;
  if (!defect) return null;
  if (defect.status !== CoreState.Defect.DefectStatus.AwaitingApproval) return null;
  return <InnerTaskViewQualityASsuranceDefectActionsInitial defect={defect} />
}
const InnerTaskViewQualityASsuranceDefectActionsInitial = (props: TaskViewQualityAssuranceDefectActionsInitialProps) => {
  const { defect } = props;

  const [open, setOpen] = useState(false)

  // params
  const { projectId, stageId, taskId } = useTaskViewParams();

  // mutations
  const [updateTaskDefectAction, updateTaskDefectQuery] = CoreState.Defect.updateDefect.useMutation();

  // locals
  const isSaving = updateTaskDefectQuery.isLoading;

  // event handlers
  const handlePopup = (e: any, open: boolean) => {
    e.stopPropagation();
    setOpen(open);
  }

  const handleDelete = async (e: any) => {
    e.stopPropagation();
    await updateTaskDefectAction({
      projectId,
      stageId,
      taskId,
      defectId: defect.id,
      defect: {
        status: CoreState.Defect.DefectStatus.Cancelled,
      },
    })
  }

  return (
    <>
      <Box sx={{ textAlign: "right" }} >
        <Button
          variant="outlined"
          color="neutral"
          sx={{ mr: 1 }}
          onClick={(e) => handlePopup(e, true)}
        >
          Delete
        </Button>
      </Box>
      <CoreComponents.Confirmation
        title="Confirm delete"
        text="Deleting this will set the status of the defect to cancelled"
        open={open}
        loading={isSaving}
        onConfirm={handleDelete}
        onCancel={(e) => handlePopup(e, false)}
        onClose={(e) => handlePopup(e, false)}
      />
    </>
  )
}
export default TaskViewQualityAssuranceDefectActionsInitial;