import { FC } from "react";
import cn from "classnames";
import { Box, Typography, Theme, SxProps } from "@mui/material";
import { Truncate, Image } from "@build-buddy/core/dist/components";

interface CarouselItemProps {
  title: string;
  caption: string;
  value: any;
  image: string;
  sx?: SxProps<Theme>;
  error?: boolean;
  width?: number;
  selected?: boolean;
  onClick(value: any): void;
  onZoomClick(e: React.SyntheticEvent): void;
}
const CarouselItem: FC<CarouselItemProps> = (props: CarouselItemProps) => {
  const handleClick = () => props.onClick(props.value);
  return (
    <Box
      sx={{
        padding: 1,
      }}
      onClick={handleClick}
    >
      <Box
        className={cn({ "error": props.error })}
        sx={{
          position: "relative",
          borderRadius: 1,
          border: theme => props.selected ?
            `solid 1px ${theme.palette.primary.main}` :
            `solid 1px ${theme.palette.grey[400]}`,
          background: theme => props.selected ?
            theme.palette.blue[300] :
            theme.palette.background.paper,
          transition: `all 0.25s ease`,
          "&:hover": {
            border: theme => `solid 1px ${theme.palette.primary.main}`,
            boxShadow: theme => theme.selected.primary,
            background: theme => theme.palette.blue[200]
          },
          "& .error": {
            border: theme => `solid 1px ${theme.palette.error.main}`,
            color: theme => theme.palette.error.main
          }
        }}
      >
        <Image
          image={`/${props.image}`}
          showZoom={true}
          onZoomClick={props.onZoomClick}
          height="175px"
          sx={{
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        />
        <Box sx={{
          textAlign: "center",
          p: 1.5
        }}>
          <Typography
            variant="h5"
            sx={{ flex: 1 }}
          >
            {props.title}
          </Typography>
          {props.caption &&
            <Truncate
              lines={2}
              text={props.caption}
              TypographyProps={{
                variant: "body2"
              }}
            />
          }
        </Box>
      </Box>
    </Box>
  );
};

export default CarouselItem;
