import { CoreComponents } from "@build-buddy/core";
import { LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';

interface DashboardProjectBreakdownMobileHeaderProps {
  onClick(): void;
  projectCompletedPercent: number;
}

const DashboardProjectBreakdownMobileHeader = (props: DashboardProjectBreakdownMobileHeaderProps) => {
  const { onClick, projectCompletedPercent } = props;

  const theme = useTheme();

  return (
    <Stack
      direction="row"
      onClick={onClick}
    >
      <CoreComponents.OutlinedIcon
        icon={HomeIcon}
        iconStyle={{
          color: "white"
        }}
        IconProps={{
          sx: {
            border: "none"
          }
        }}
        sx={{
          mr: 1.5,
          backgroundColor: theme.palette.primary.main
        }}
      />
      <Stack
        direction="column"
        flex={1}
      >
        <Typography
          variant="body1"
          sx={{
            mb: 0.5
          }}
        >
          {projectCompletedPercent}% of all tasks completed
        </Typography>
        <LinearProgress
          variant="determinate"
          value={projectCompletedPercent}
          sx={{
            height: "10px",
            borderRadius: 4,
          }}
        />
      </Stack>
    </Stack>
  )
}

export default DashboardProjectBreakdownMobileHeader;