import { useProjectParams } from "modules/project/_hooks";
import { useRouteMatch } from "react-router";

const useTaskViewPaymentsParams = () => {
  const { projectId } = useProjectParams();
  const match = useRouteMatch<any>();

  return {
    projectId,
    stageId: match?.params.sid,
    taskId: match?.params.tid,  
    quoteId: match?.params.qid,
    paymentScheduleId: match?.params.psid,
    receiptType: match?.params.receiptType,
  };
}
export default useTaskViewPaymentsParams