import { CoreComponents, CoreState } from "@build-buddy/core";
import { Chip } from "@mui/material";

type ProjectTaskStatusProps = {
  task: CoreState.Task.Task;
}
const ProjectTaskStatus = (props: ProjectTaskStatusProps) => {
  const { task } = props;

  if (!task.parentIsDIY) return null;

  return (
    <Chip
      icon={<CoreComponents.DiyLightIcon />}
      size="small"
      sx={{
        background: "#455a64",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: 1,
        "& span": {
          display: "none"
        }
      }}
    />
  )
}
export default ProjectTaskStatus;
