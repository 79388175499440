import { AxisConfig, BarPlot, ChartsTooltip, ChartsXAxis, ChartsXAxisProps, PopperProps, ResponsiveChartContainer } from "@mui/x-charts";
import React, { useState } from "react";

import { CoreComponents } from "@build-buddy/core";
import { Box, Popper, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import {
  useDrawingArea,
  useYScale
} from '@mui/x-charts';
import { ScaleLinear } from 'd3-scale';

const chartStyles = {
  sx: {
    '& .MuiChartsAxis-line': {
      stroke: '#E9EBED !important',
    },
    "& .MuiChartsAxis-tickLabel": {
      fill: '#979FA5 !important',
      "tspan": {
        fontSize: '11.5px',
      }
    },
  },
};

interface FinancialsBarGraphProps {
  data: {
    '1M': {
      committed: number[],
      allocated: number[],
      paid: number[],
      label: string[]
    },
    '3M': {
      committed: number[],
      allocated: number[],
      paid: number[],
      label: string[]
    },
    '2WK': {
      committed: number[],
      allocated: number[],
      paid: number[],
      label: string[]
    }
  }
}

const FinancialsBarGraph = (props: FinancialsBarGraphProps) => {
  const frequency = Object.keys(props.data);
  const routes = frequency.map(f => ({ label: f }))
  const [currentTab, setCurrentTab] = useState<{ label: string }>(routes[0])

  const currentDataset = props.data[currentTab.label as keyof typeof props.data]

  return (
    <>
      <Stack direction='row' alignItems='center' gap={3} px={2} width={'100%'} >
        <Typography fontSize={12} fontWeight={600}>Spend</Typography>
        <CoreComponents.Tabs
          TabsProps={{
            sx: {
              mb: 1,
              "& .MuiTab-root": {
                minWidth: '60px !important'
              }
            }
          }}
          type="component"
          defaultRoute={currentTab}
          TabItemProps={{
            sx: {
              fontSize: '12px',
              textTransform: 'capitalize',
            }
          }}
          routes={routes}
          // onChange={setCurrentTab}
        />
      </Stack>
      <Box sx={{height:"300px", width: '100%', aspectRatio:"1/1"}}>
        <ResponsiveChartContainer
          series={[
            { data: currentDataset.committed, type: 'bar', color: '#FFB882', stack: 'total' },
            { data: currentDataset.allocated, type: 'bar', color: '#CB5800', stack: 'total' },
            { data: currentDataset.paid, type: 'bar', color: 'orange', stack: 'total' },
          ]}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: 'month',
              categoryGapRatio: currentDataset.label.length > 8 ? 0.6 : 0.87,
              data: currentDataset.label,
            } as AxisConfig<'band', any, ChartsXAxisProps>,
          ]}
          {...chartStyles}
        >
          <CartesianAxis />
          <BarPlot
            borderRadius={12}
          />
          <ChartsXAxis disableTicks zoom={true} />
          <ChartsTooltip
            slots={{ popper: CustomPopperRoot }}
          />
        </ResponsiveChartContainer>
      </Box>
    </>
  )
}

// Custom Tooltip for Graph
const ChartsTooltipRoot = styled(Popper, {
  name: "MuiChartsTooltip",
  slot: "Root",
  overridesResolver: (_, styles) => styles.root,
})(({ theme }) => ({
  pointerEvents: "none",
  zIndex: theme.zIndex.modal,
  "& .MuiChartsTooltip-root": {
    background: 'rgba(0, 0, 0, .3)',
    padding: '4px 8px'
  },
  'thead': {
    display: 'none'
  },
  "& .MuiChartsTooltip-markCell": {
    padding: '0px !important',
    "& .MuiChartsTooltip-mark": {
      border: 'none',
      height: '12px',
      width: '12px'
    }
  },
  '& .MuiChartsTooltip-valueCell': {
    padding: '0px !important',
    "& .MuiTypography-body1": {
      color: 'white',
      fontWeight: '500'
    }
  }
}));

const CustomPopperRoot = (props: PopperProps) => {
  return (
    <ChartsTooltipRoot
      {...props}
      anchorEl={{
        getBoundingClientRect: () => ({
          ...(props.anchorEl as any)?.getBoundingClientRect(),
        }),
      }}
    />
  );
};

// Lines
const StyledPath = styled('path')(
  () => ({
    stroke: '#E9EBED',
    strokeWidth: 1,
    pointerEvents: 'none',
  }),
);

function CartesianAxis() {
  // Get the drawing area bounding box
  const { left, width } = useDrawingArea();

  // Get the two scale
  const yAxisScale = useYScale() as ScaleLinear<any, any>;

  const yTicks = [2000, 4000, 6000, 8000]

  return (
    <React.Fragment>
      {yTicks.map((value) => (
        <StyledPath
          key={value}
          d={`M ${left} ${yAxisScale(value)} l ${width} 0`}
        />
      ))}
    </React.Fragment>
  );
}

export default FinancialsBarGraph