import { CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import axios from "axios";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useEffect, useState } from "react";
import DesignerGuard from "./DesignerGuard";

const Designer = () => {
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);
  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);
  const getProject = CoreState.Project.getProject.useQuery({ projectId });
  const [url, setUrl] = useState<any>();

  useEffect(() => {
    if (isLimited || !getProject.data || !getProject.data.address) return;

    const fetchData = async () => {
      if (!getProject.data || !getProject.data.address) return;
      const storeysKvp = {
        "SingleStorey": 1,
        "DoubleStorey": 2,
      }
      const url = [
        "https://api.prod.au.archistar.ai/app-auth-proxy/",
        "?action=launch-siting",
        `&lon=${getProject.data.address.longitude}`,
        `&lat=${getProject.data.address.latitude}`,
        `&storeys=${storeysKvp[getProject.data.homeType]}`
      ].join("");
      const resp = await axios.get(url, { headers: { "x-api-key": "EZnBEFwDrr6f3IlZLsMECaphS5mboz9Ja11pdCDf" } })
      setUrl(resp.data);
    }
    fetchData();
  }, [getProject.data]);

  return (
    <DesignerGuard>
      <Box
        sx={{
          height: `calc(100vh - 64px)`,
          my: -4,
          mx: -3,
        }}
      >
        {url &&
          <iframe
            title="designer"
            src={url}
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              border: "none",
            }}
          />
        }
      </Box>
    </DesignerGuard>
  );
};

export default Designer;
