import { CoreComponents } from "@build-buddy/core";

const UnlockedBudgetEmpty = () => {
  return (
    <CoreComponents.DisabledContent
      title="Waiting for your Estimator to submit an Initial or Final Estimate"
      subTitle=" You won't be able to view anything here until your commence working with an Estimator."
      sx={{
        height: '80vh'
      }}
    />
  )
}
export default UnlockedBudgetEmpty;