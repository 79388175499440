import { CoreState, CoreComponents } from "@build-buddy/core";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

type TaskViewPaymentsDotProgressProps = {
  status: CoreState.Quote.PaymentScheduleStatus;
  sx?: SxProps<Theme>
  size?: number;
  hideLabel?: boolean;
}
const TaskViewPaymentsDotProgress = (props: TaskViewPaymentsDotProgressProps) => {
  const { status, size, hideLabel, sx } = props;

  const na = {};
  const success = { color: "success" };
  const primary = { color: "primary" };
  const primaryOutlined = { color: "primary", variant: "outlined" };
  const successOutlined = { color: "success", variant: "outlined" };
  const error = { color: "error" };

  const kvp: any = {
    [CoreState.Quote.PaymentScheduleStatus.NA]: {
      color: [na, na, na, na],
      label: "Upcoming"
    },
    [CoreState.Quote.PaymentScheduleStatus.Pending]: {
      color: [primary, primary, primary, primary],
      label: "Processing"
    },
    [CoreState.Quote.PaymentScheduleStatus.Requested]: {
      color: [primary, na, na, na],
      label: "Funds Requested"
    },
    [CoreState.Quote.PaymentScheduleStatus.AuthorizationRequired]: {
      color: [success, primaryOutlined, na, na],
      label: "Authorization Required"
    },
    [CoreState.Quote.PaymentScheduleStatus.Allocated]: {
      color: [success, success, na, na],
      label: "Allocated"
    },
    [CoreState.Quote.PaymentScheduleStatus.ReleaseRequested]: {
      color: [success, success, primary, na],
      label: "Release Requested"
    },
    [CoreState.Quote.PaymentScheduleStatus.Released]: {
      color: [success, success, success, success],
      label: "Completed"
    },
    [CoreState.Quote.PaymentScheduleStatus.Paid]: {
      color: [success, success, success, success],
      label: "Completed"
    },
    [CoreState.Quote.PaymentScheduleStatus.Failed]: {
      color: [error, error, error, error],
      label: "Failed"
    },
    [CoreState.Quote.PaymentScheduleStatus.Cancelled]: {
      color: [primary, na, na, na],
      label: "Cancelled"
    },
    [CoreState.Quote.PaymentScheduleStatus.CreditCardPaymentPending]: {
      color: [success, success, primaryOutlined, na],
      label: "Credit Card Payment Pending"
    },
    [CoreState.Quote.PaymentScheduleStatus.NotRequired]: {
      color: [successOutlined, successOutlined, successOutlined, successOutlined],
      label: "Not Required"
    }
  }

  return (
    <CoreComponents.DotProgress
      label={hideLabel ? "" : kvp[status]?.label}
      statuses={kvp[status]?.color}
      size={size}
      sx={{ ...sx }}
    />
  )
}
export default TaskViewPaymentsDotProgress;