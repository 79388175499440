import { CoreComponents, CoreModules, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { selectProjectTaskStageDefinitionId } from "modules/project/_selectors";
import { TaskViewRouteDefinitions, useTaskViewParams } from "modules/project/task/task-view";
import { Route } from "react-router";
import { selectTaskViewQuoteListAcceptedQuote } from "../../_selectors";
import TaskViewQualityAssuranceChecklistDotProgress from "./TaskViewQualityAssuranceChecklistDotProgress";
import TaskViewQualityAssuranceChecklistItemAddButton from "./TaskViewQualityAssuranceChecklistItemAddButton";
import TaskViewQualityAssuranceChecklistActions from "./actions/TaskViewQualityAssuranceChecklistActions";
import TaskViewQualityAssuranceChecklistActionsCheckbox from "./actions/TaskViewQualityAssuranceChecklistActionsCheckbox";

const TaskViewQualityAssuranceChecklist = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };
  const getQualityAssuranceListByTaskParams = { projectId, stageId, taskId };

  // queries
  const getQualityAssuranceListByTaskQuery = CoreState.QualityAssurance.getQualityAssuranceListByTask.useQuery(getQualityAssuranceListByTaskParams);

  // selectors
  const checklistGrouped = useSelector((s) => CoreState.QualityAssurance.selectQualityAssuranceListByTaskGrouped(s, getQualityAssuranceListByTaskParams));
  const currentStage = useSelector((s) => selectProjectTaskStageDefinitionId(s, taskParams));
  const isTaskWorkInProgress = useSelector((s) => CoreState.Task.selectTaskListByParentIsWorkInProgress(s, taskParams));
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, quoteListParams));

  //local vars
  const isEditable = Boolean(acceptedQuote && isTaskWorkInProgress);

  return (
    <Box sx={{ mb: 1.5 }}>
      <CoreComponents.CollapseHeader
        title="Quality Assurance Checklist"
        open={true}
        sx={{ mb: 1 }}
      >
        <CoreModules.Task.TaskView.TaskViewQualityAssuranceChecklist
          isEditable={isEditable}
          isLoading={getQualityAssuranceListByTaskQuery.isLoading}
          checklist={checklistGrouped}
          currentStage={currentStage}
          addChecklistItem={TaskViewQualityAssuranceChecklistItemAddButton}
          actions={TaskViewQualityAssuranceChecklistActions}
          dotProgress={TaskViewQualityAssuranceChecklistDotProgress}
          checkbox={TaskViewQualityAssuranceChecklistActionsCheckbox}
        />
      </CoreComponents.CollapseHeader>
      <Route
        path={TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_CHECKLIST_ADD.path}
        component={TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_CHECKLIST_ADD.component}
      />
      <Route
        path={TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_CHECKLIST_EDIT.path}
        component={TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_CHECKLIST_EDIT.component}
      />
    </Box>
  )
}

export default TaskViewQualityAssuranceChecklist;