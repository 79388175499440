import TaskViewScopeOfWorkSpecAdd from "./task-view-scope-of-work-spec/TaskViewScopeOfWorkSpecAdd";
import TaskViewScopeOfWorkSpecEdit from "./task-view-scope-of-work-spec/TaskViewScopeOfWorkSpecEdit";
import TaskViewScopeOfWork from "./TaskViewScopeOfWork";

const TaskViewScopeOfWorkRouteDefinitions = {
  TASK_VIEW_SCOPE_OF_WORK: {
    label: "Scope of Works",
    path: "/*/stage/:sid/task/:tid/scope-of-work",
    component: TaskViewScopeOfWork
  },
  TASK_VIEW_SCOPE_OF_WORK_SPEC_ADD: {
    path: "/*/stage/:sid/task/:tid/scope-of-work/spec/add",
    component: TaskViewScopeOfWorkSpecAdd
  },
  TASK_VIEW_SCOPE_OF_WORK_SPEC_EDIT: {
    path: "/*/stage/:sid/task/:tid/scope-of-work/spec/:spid/edit",
    component: TaskViewScopeOfWorkSpecEdit
  },
}

export default TaskViewScopeOfWorkRouteDefinitions;