import { CoreComponents, CoreState } from "@build-buddy/core";
import { Checkbox } from "@mui/material";
import { useSelector } from "common/state/store";
import { selectProjectTaskIsDiy } from "modules/project/_selectors";
import { useTaskViewParams } from "../../../_hooks";

interface TaskViewQualityAssuranceChecklistActionsCheckboxProps {
  qualityAssuranceItem: CoreState.QualityAssurance.QualityAssurance;
  isEditable?: boolean;
}
const TaskViewQualityAssuranceChecklistActionsCheckbox = (props: TaskViewQualityAssuranceChecklistActionsCheckboxProps) => {
  const { qualityAssuranceItem, isEditable } = props;
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId };
  const getDefectListByTaskParams = { projectId, stageId, taskId };

  // mutations
  const [updateQualityAssuranceItemAction, updateQualityAssuranceItemQuery] = CoreState.QualityAssurance.updateQualityAssuranceItem.useMutation();
  CoreState.Defect.getDefectListByTask.useQuery(getDefectListByTaskParams);
  
  // selectors
  const defects = useSelector((s) => CoreState.Defect.selectDefectListByTask(s, getDefectListByTaskParams));
  const isDiy = useSelector((s)=> selectProjectTaskIsDiy(s, taskParams));

  // vars
  const isChecked = Boolean(qualityAssuranceItem.status === CoreState.QualityAssurance.QualityAssuranceStatus.Completed);
  const isAssociatedDefectOpen = defects
    ?.filter(x => x.associatedId === qualityAssuranceItem.id)
    ?.some((defect => [
      CoreState.Defect.DefectStatus.AwaitingApproval,
      CoreState.Defect.DefectStatus.InReview,
      CoreState.Defect.DefectStatus.InDispute,
      CoreState.Defect.DefectStatus.Approved,
      CoreState.Defect.DefectStatus.Rejected
    ].includes(defect.status)));
  const isQualityAssuranceItemNA = qualityAssuranceItem.isDisabled;
  
  const isDisabled = (!isDiy && (!isEditable || Boolean(isAssociatedDefectOpen) || isQualityAssuranceItemNA))

    // event handlers
  const handleChecklistItemCheck = (checked: boolean, qualityAssurance: CoreState.QualityAssurance.QualityAssurance | undefined) => {
    if (!qualityAssurance || !qualityAssurance.id) return;
    updateQualityAssuranceItemAction({
      ...taskParams,
      qualityAssuranceId: qualityAssurance.id,
      qualityAssuranceItem: {
        ...qualityAssurance,
        status: checked ? CoreState.QualityAssurance.QualityAssuranceStatus.Completed : CoreState.QualityAssurance.QualityAssuranceStatus.Initial,
      }
    })
  }

  return <>
    <CoreComponents.Loader show={updateQualityAssuranceItemQuery.isLoading} />
    <Checkbox
      edge="start"
      sx={{ mr: 1 }}
      disabled={isDisabled}
      checked={isChecked}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChecklistItemCheck(e.target.checked, qualityAssuranceItem)}
    />
  </>
}

export default TaskViewQualityAssuranceChecklistActionsCheckbox;