import { CoreModules, CoreState } from '@build-buddy/core';
import { CommonState } from 'common';
import { selectIncompleteProfileItems } from 'common/state/app';
import { useSelector } from 'common/state/store';

type JobViewActionsQuoteValidationProps = {
  show: boolean;
  onClose: () => void;
}

const TaskViewQuoteListItemActionsValidation = (props: JobViewActionsQuoteValidationProps) => {
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const validations = useSelector((s) => selectIncompleteProfileItems(s, { userId }));
  const isCurrentuserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner)

  if (!validations.hasWallet && !isCurrentuserOwner) {
    return (
      <CoreModules.Wallet.WalletValidationDialog
        show={props.show}
        onClose={() => props.onClose()}
        onConfirm={() => props.onClose()}
        title="Setup Wallet To Accept Quote."
        content="You can not accept quotes if you don&apos;t have Wallet. Please contact owner to setup Wallet and Bank Account so you can continue with the task."
        actionLabel='Got it.'
      />
    )
  }

  if (!validations.hasBankAccount && !isCurrentuserOwner) {
    return (
      <CoreModules.Wallet.WalletValidationDialog
        show={props.show}
        onClose={() => props.onClose()}
        onConfirm={() => props.onClose()}
        title="Add Bank Account To Accept Quote."
        content="You can not accept quotes if you don&apos;t have Bank Account. Please contact owner to add Bank Account so you can continue with the task."
        actionLabel='Got it.'
      />
    )
  }

  return null
}

export default TaskViewQuoteListItemActionsValidation