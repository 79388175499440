import { CoreComponents, CoreUtils } from "@build-buddy/core";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Box, Stack, useTheme } from "@mui/material";
import { CommonComponents } from "common";
import { useState } from "react";
import BudgetTrackingTaskExpansion from "./BudgetITemListTrackingTaskExpansion";

interface BudgetTrackingTaskProps {
  taskId: string;
  label: string;
  accepted: number;
  allocated: number;
  paid: number;
}
const BudgetTrackingTask = (props: BudgetTrackingTaskProps) => {
  const { taskId, label, accepted, allocated, paid } = props;
  const theme = useTheme();

  // locals
  const [show, setShow] = useState(false);

  // events
  const handleShow = () => {
    setShow(!show);
  }
  
  return (
    <>
      <Box
        sx={{
          cursor: "pointer",
          my: 2,
          display: "grid",
          alignItems: "center",
          gridTemplateAreas: `
            'task task cost cost icon'
          `,
          gridTemplateColumns: {
            xs: `75px minmax(120px, 400px) minmax(120px, 275px) minmax(100px, auto) 30px`,
            lg: `75px minmax(120px, 400px) minmax(120px, 300px) minmax(100px, auto) 30px`,
            xl: `75px minmax(120px, 600px) minmax(120px, 300px) minmax(100px, auto) 30px`,
          }
        }}
        onClick={handleShow}
      >
        <Box sx={{ gridArea: "task" }}>
          <Box>
            <CoreComponents.TaskLabel
              sx={{ mb: 0 }}
              label={label}
            />
          </Box>
        </Box>
        <Stack
          direction="row"
          sx={{
            gridArea: "cost"
          }}
        >
          <CommonComponents.LegendItem
            sx={{ width: "120px" }}
            LabelProps={{ sx: { fontSize: "13px" }}}
            CircleProps={{ sx: { width:"16px", height:"16px" }}}
            label={CoreUtils.Formatter.currency(accepted) as string}
            color={theme.palette.primary.main}
            variant="striped"
            />
          <CommonComponents.LegendItem
            sx={{ width: "120px" }} 
            LabelProps={{ sx: { fontSize: "13px" }}}
            CircleProps={{ sx: { width:"16px", height:"16px" }}}
            label={CoreUtils.Formatter.currency(allocated) as string}
            color={"#CB5800"}
            />
          <CommonComponents.LegendItem
            sx={{ width: "120px" }}
            LabelProps={{ sx: { fontSize: "13px" }}}
            CircleProps={{ sx: { width:"16px", height:"16px" }}}
            label={CoreUtils.Formatter.currency(paid) as string}
            color={theme.palette.primary.main}
            />
        </Stack>
        <ChevronRightIcon
          sx={{
            gridArea: "icon",
            ml: 1.5,
            transform: `rotate(${Boolean(show)}) ? "90deg" : "0"})`
          }}
        />
      </Box>
      <BudgetTrackingTaskExpansion 
        show={show} 
        taskId={taskId}
      />
    </>
  )
}
export default BudgetTrackingTask;