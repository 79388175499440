import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { SelectProjectTaskListParams, selectProjectTaskList } from "./selectProjectTaskList";
import { BuildBuddyStateType } from "common/state/store";

/**
 * Selects a list of ProjectTasks which are completed having a quote associated with them
 * 
 * @params projectId: Id of the project to get tasks
 * 
 * @returns Array of ProjectTasks with a status as required
 */
interface SelectProjectTaskCompletedListWithQuote extends SelectProjectTaskListParams {
  taskId: string;
}
const selectProjectTaskCompletedListWithQuote = createSelector(
  [
    (s: BuildBuddyStateType, params: SelectProjectTaskCompletedListWithQuote) => selectProjectTaskList(s, {
      projectId: params.projectId,
    }),
    (_, params: SelectProjectTaskCompletedListWithQuote) => params,
  ],
  (taskList, meta) => {
    return {
      task: taskList?.filter((subTask) => subTask.task.status === CoreState.Task.TaskStatus.Completed && subTask.task.hasQuote),
      meta,
    }
  }
)

export const selectProjectTaskIsReviewRequired = createSelector(
  [selectProjectTaskCompletedListWithQuote],
  (completedTaskListWithQuote) => {
    return completedTaskListWithQuote.task?.find((item) => item.task.id === completedTaskListWithQuote.meta.taskId)
  }
)

export const selectProjectTaskCompanyRatingId = createSelector(
  [selectProjectTaskIsReviewRequired],
  (task) => task?.task.companyRatingPublicId
)