import { Theme } from "@emotion/react";
import { Box, SxProps } from "@mui/material";
import "./CreditCard.scss";

export interface CreditCardProps {
  name: string;
  number: string;
  expiry: string;
  sx?: SxProps<Theme>;
}
const CreditCard = (props: CreditCardProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <div className="card">
        <div className="card__front card__part">        
          <p className="card_number">{props.number}</p>
          <div className="card__space-75">
            <span className="card__label">Card holder</span>
            <p className="card__info">{props.name}</p>
          </div>
          <div className="card__space-25">
            <span className="card__label">Expires</span>
            <p className="card__info">{props.expiry}</p>
          </div>
        </div>
      </div>
    </Box>
  )
}
export default CreditCard;