import { CoreComponents } from "@build-buddy/core";
import { Card, CardContent } from "@mui/material";
import DashboardTasksList from "./DashboardTasksList";

const DashboardTasks = () => {
  const tabs = {
    DASHBOARD_ALL_TASKS: {
      label: "All tasks",
      path: "/project/:pid/dashboard/all",
      component: () => <DashboardTasksList />
    },
    DASHBOARD_TODO: {
      label: "To do",
      path: "/project/:pid/dashboard/to-do",
      component: () => <DashboardTasksList />
    },
    DASHBOARD_OVERDUE: {
      label: "Overdue",
      path: "/project/:pid/dashboard/overdue",
      component: () => <DashboardTasksList />
    },
    DASHBOARD_IN_PROGRESS: {
      label: "In Progress",
      path: "/project/:pid/dashboard/in-progress",
      component: () => <DashboardTasksList />
    },
    DASHBOARD_PAYMENT_REQUESTED: {
      label: "Payment Requests",
      path: "/project/:pid/dashboard/payment-requested",
      component: () => <DashboardTasksList />
    }
  }
  
  return (
    <Card>
      <CardContent sx={{ py: "0 !important" }}>
        <CoreComponents.Tabs
          TabsProps={{
            sx: { mx: -2 }
          }}
          routes={[
            tabs.DASHBOARD_TODO,
            tabs.DASHBOARD_OVERDUE,
            tabs.DASHBOARD_IN_PROGRESS,
            tabs.DASHBOARD_PAYMENT_REQUESTED,
            tabs.DASHBOARD_ALL_TASKS,
          ]}
        />
      </CardContent>
    </Card>
  )
}
export default DashboardTasks;