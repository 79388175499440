import { CoreComponents, CoreModules, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useTaskViewParams } from "../_hooks";
import TaskViewMessagesEmpty from "./TaskViewMessagesEmpty";

const TaskViewMessages = () => {
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const messageListParams = { projectId, stageId, taskId };

  // queries
  const messageListQuery = CoreState.NoteThread.getNoteThreadListByLatestQuoteRequest.useQuery({ projectId, stageId, taskId });

  // selectors
  const messageList = useSelector((s) => CoreState.NoteThread.selectNoteThreadListByLatestQuoteRequest(s, messageListParams));

  // locals
  const isEmpty = Boolean(!messageList?.length);

  return (
    <Box sx={{ m: -2 }}>
      <CoreComponents.PreContent
        isLoading={messageListQuery.isLoading}
        isEmpty={isEmpty}
        empty={<TaskViewMessagesEmpty />}
      >
        <CoreModules.MessageList messages={messageList} />
      </CoreComponents.PreContent>
    </Box>
  )
};
export default TaskViewMessages;
