import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

type SelectTaskSpecificationListParams = {
  projectId: string;
  stageId: string;
  taskId: string;
}

const selectTaskSpecificationList = createSelector(
  [(state: CoreState.RootState, params: SelectTaskSpecificationListParams) => CoreState.Specification.getTaskSpecificationList.select(params)(state)],
  (taskSpecificationQuery) => {
    if (!taskSpecificationQuery.data) return null;
    return taskSpecificationQuery.data;
  }
)

const selectTaskStandardSpecificationList = createSelector(
  selectTaskSpecificationList,
  (taskSpecificationList) => taskSpecificationList?.filter((item) => item.isStandard)
)

const selectTaskUserSpecificationList = createSelector(
  selectTaskSpecificationList,
  (specList) => specList?.filter((item) => !item.isStandard)
)

const selectActiveTaskUserSpecificationList = createSelector(
  selectTaskSpecificationList,
  (specList) => {
    return specList?.filter((item) => item.specificationSubType === CoreState.Specification.TaskSpecificationSubTypes.Scope && !item.isDisabled)
  }
)

export { selectActiveTaskUserSpecificationList, selectTaskSpecificationList, selectTaskStandardSpecificationList, selectTaskUserSpecificationList };


