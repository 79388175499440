import { Box, SxProps, Theme } from "@mui/material";

interface BudgetItemListEstimatedTemplateProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  onClick?(): void;
}
const BudgetItemListEstimatedTemplate = (props: BudgetItemListEstimatedTemplateProps) => {
  const { children, sx, onClick } = props;

  return (
    <Box
      sx={{
        display: "grid",
        alignItems: "center",
        gridTemplateAreas: `
          'name qty rate final variation budget action'
        `,
        gridTemplateColumns: `minmax(auto, 675px) 100px 125px 125px 125px minmax(125px, auto) min(30px)`,
        py: 1.5,
        mx: -2,
        px: 2,
        gap: 1,
        ...sx
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

export default BudgetItemListEstimatedTemplate;