import { endOfDay, startOfDay, differenceInDays } from 'date-fns';
import * as Yup from 'yup';

const TaskViewQualityAssuranceDefectsAddValidation = Yup.object().shape({
  category: Yup.string()
    .required("Please select a category"),
  finish: Yup.date()
    .required("Rectified date is required.")
    .test("validExpiry", "Date cannot be in the past", (value: any) => {
      const today = endOfDay(new Date());
      const date = startOfDay(new Date(value));
      return differenceInDays(today, date) <= 0;
    }),
  title: Yup.string()
    .required("Please enter a title")
    .max(100, "100 characters is the max limit"),
  description: Yup.string()
    .required("Please enter a description")
    .max(500, "500 characters is the max limit")
});

export default TaskViewQualityAssuranceDefectsAddValidation;
