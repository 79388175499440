import { CoreComponents, CoreState } from "@build-buddy/core";
import { Home as HomeIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Box, Card, CardContent, Collapse, IconButton, LinearProgress, Skeleton, Typography, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import ProjectBreakdownItem from "modules/project/_components/project-breakdown/ProjectBreakdownItem";
import { useProjectParams } from "modules/project/_hooks";
import { selectProjectCompletedPercent } from "modules/project/_selectors";
import { selectDashboardProjectStageList } from "modules/project/dashboard/dashboard-project/_selectors";
import { useState } from "react";

const FinancialsSummaryCompleted = () => {
  const theme = useTheme();
  const params = useProjectParams();

  // States
  const [open, setOpen] = useState(false)

  // queries
  const getProjectStageListQuery = CoreState.Project.getProjectStageList.useQuery(
    { projectId: params.projectId },
    { skip: !params.projectId }
  );

  // Selectors
  const projectStageList = useSelector((s) => selectDashboardProjectStageList(s, { projectId: params.projectId }));
  const projectCompletedPercent = useSelector((s) => selectProjectCompletedPercent(s, { projectId: params.projectId }));

  return (
    <CoreComponents.PreContent
      isLoading={getProjectStageListQuery.isLoading}
      loader={
        <Skeleton
          variant="rounded"
          height={75} width={'100%'}
          sx={{ borderRadius: 4 }}
        />
      }
      isEmpty={!projectStageList}
      error={<></>}
    >
      <Card sx={{ position: 'absolute', right: 0, left: 0, zIndex: 20 }}>
        <CardContent sx={{ pb: '0px !important' }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: 'pointer',
              justifyContent: 'space-between',
              pb: 2
            }}
            onClick={() => setOpen(prev => !prev)}
          >
            <Box>
              <CoreComponents.OutlinedIcon
                icon={HomeIcon}
                sx={{
                  border: "none",
                  background: theme.palette.primary.main,
                  color: theme.palette.common.white,
                  fontSize: 24,
                }}
              />
            </Box>
            <Box
              sx={{
                ml: 2,
                mr: 1.5,
                width: '100%'
              }}
            >
              <Typography variant="body2" sx={{ mb: 0.75 }}> {projectCompletedPercent}% of all tasks completed </Typography>
              <LinearProgress variant="determinate" color="success" value={85} />
            </Box>
            <IconButton sx={{ mr: -1 }}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </Box>
          <Collapse in={open} >
            {projectStageList.map((ps, idx) => (
              <ProjectBreakdownItem
                key={idx}
                name={ps.name}
                total={ps.progress.totalTasks}
                completed={ps.progress.completedTasks}
                percent={ps.progress.completedPercent}
              />
            ))}
          </Collapse>
        </CardContent>
      </Card>
    </CoreComponents.PreContent>
  )
}
export default FinancialsSummaryCompleted;