import { CoreComponents, CoreState, useMode } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useTaskViewParams } from "../_hooks";
import { selectShowVariationsTab } from "../_selectors";
import { Button, Box } from "@mui/material";
import { MoreVert as MoreVertIcon, Close as CloseIcon, Done as DoneIcon } from "@mui/icons-material";

const useVm = (taskVariation: CoreState.Variation.TaskVariation) => {
  const taskViewParams = useTaskViewParams();
  const params= { projectId: taskViewParams.projectId, stageId: taskViewParams.stageId, taskId: taskViewParams.taskId }

  const [acceptOrRejectVariationMutation, acceptOrRejectVariationQuery] = CoreState.Variation.acceptOrRejectVariation.useMutation();
  const [updateTaskVariationAction, updateTaskVariationQuery] = CoreState.Variation.updateTaskVariation.useMutation();
  const isAwaitingApproval = taskVariation.status === CoreState.Variation.TaskVariationStatus.AwaitingApproval;
  const isInReview = taskVariation.status === CoreState.Variation.TaskVariationStatus.InReview;

  // Selectors
  const showActions =  useSelector((s) => selectShowVariationsTab(s, params));

  const updateVariation = (taskVariation: CoreState.Variation.TaskVariation) => {
    updateTaskVariationAction({
      projectId: taskViewParams.projectId,
      stageId: taskViewParams.stageId,
      taskId: taskViewParams.taskId,
      variationId: taskVariation.id,
      taskVariation: {
        name: taskVariation.name,
        description: taskVariation.description,
        status: CoreState.Variation.TaskVariationStatus.Completed,
        specificationType: 'Variation',
        additionalDays: taskVariation.additionalDays,
        cost: taskVariation.cost
      }
    })
  }
  return {
    acceptOrRejectVariationMutation,
    acceptOrRejectVariationQuery,
    updateVariation,
    isAwaitingApproval,
    isInReview,
    showActions,
    isLoading: acceptOrRejectVariationQuery.isLoading || updateTaskVariationQuery.isLoading
  }
}

const TaskViewVariationActions = (props: any) => {
  const vm = useVm(props.taskVariation);
  const mode = useMode();
  const jobView = useTaskViewParams();
  const projectId = jobView?.projectId;
  const variationId = props.taskVariation?.id;

  if (!vm.showActions) return null;
  return (
    <>
      <CoreComponents.Loader show={vm.isLoading} />
      {mode.md &&
        <>
          {vm.isInReview &&
            <Button
              variant="contained"
              size="small"
              onClick={() => vm.updateVariation(props.taskVariation)}
            >
              Mark as Completed
            </Button>
          }
          {vm.isAwaitingApproval &&
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                size="small"
                sx={{
                  px: { xs: 1, md: 2 },
                  py: { xs: 1, md: 0.5 },
                  minWidth: { xs: "auto", md: "inherit" },
                }}
                onClick={
                  () => vm.acceptOrRejectVariationMutation(
                    {
                      action: 'approve',
                      projectId: projectId,
                      variationId
                    }
                  )
                }
                disabled={vm.acceptOrRejectVariationQuery.isLoading}
              >

                {mode.md ? "Accept" : <DoneIcon />}
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  px: { xs: 1, md: 2 },
                  py: { xs: 1, md: 0.5 },
                  minWidth: { xs: "auto", md: "inherit" },
                }}
                onClick={
                  () => vm.acceptOrRejectVariationMutation(
                    {
                      action: 'reject',
                      projectId: projectId,
                      variationId
                    }
                  )
                }
                disabled={vm.acceptOrRejectVariationQuery.isLoading}
              >
                {mode.md ? "Decline" : <CloseIcon />}
              </Button>
            </Box>
          }
        </>
      }

      {/* Mobile Actions */}
      {(mode.xs && !mode.md) &&
        <CoreComponents.MoreMenu
          icon={MoreVertIcon}
          IconProps={{ edge: "end", sx: { ml: 1 } }}
        >
          {vm.isInReview &&
            <CoreComponents.MoreMenuItem
              text="Mark as completed"
              icon={DoneIcon}
              onClick={() => vm.updateVariation(props.taskVariation)}
            />
          }
          {vm.isAwaitingApproval &&
            <>
              <CoreComponents.MoreMenuItem
                text="Accept"
                icon={DoneIcon}
                onClick={
                  () => vm.acceptOrRejectVariationMutation(
                    {
                      action: 'approve',
                      projectId: projectId,
                      variationId
                    }
                  )
                }
              />
              <CoreComponents.MoreMenuItem
                text="Decline"
                icon={CloseIcon}
                onClick={
                  () => vm.acceptOrRejectVariationMutation(
                    {
                      action: 'reject',
                      projectId: projectId,
                      variationId
                    }
                  )
                }
              />
            </>
          }
        </CoreComponents.MoreMenu>
      }

    </>
  )
}

export default TaskViewVariationActions;