import { CoreModules, CoreRouting } from "@build-buddy/core";
import UserProfile from "./UserProfile";

const UserProfileRouteDefinitions = {
  ...CoreModules.User.UserRouteDefinitions,
  USER_PROFILE: {
    path: "/user/profile",
    component: UserProfile,
  },
}

const UserProfileRoutes = () => {
  return (
    <CoreRouting.Routes routes={[UserProfileRouteDefinitions.USER_PROFILE]} />
  )
}
export { UserProfileRoutes, UserProfileRouteDefinitions }
