import { CoreRouting } from "@build-buddy/core";
import { Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { selectProjectTaskId } from "modules/project/_selectors";
import { selectProjectTaskCompanyRatingId, selectProjectTaskIsReviewRequired } from "modules/project/_selectors/selectProjectTaskReview";
import TaskViewReviewRouteDefinitions from "../../../task-view-review/TaskViewReviewRouteDefinitions";
import { useTaskViewReviewParams } from "../../../task-view-review/_hooks";

interface TaskViewReviewActionsProps {
  parentId?: string;
  taskId?: string;
  stageId?: string;
}

const TaskViewReviewActions: React.FC<TaskViewReviewActionsProps> = (props) => {
  const { projectId, stageId, taskId } = useTaskViewReviewParams();
  const routing = CoreRouting.useRouting();

  // selectors
  const projectTaskId = useSelector((s) => selectProjectTaskId(s, { projectId, taskId, stageId }));
  const companyRatingPublicId = useSelector((s) => selectProjectTaskCompanyRatingId(s, { projectId, taskId: props.taskId || projectTaskId, }));
  const hasReviewRequested = useSelector((s) => selectProjectTaskIsReviewRequired(s, { projectId, taskId: props.taskId || projectTaskId }));

  const isEdit = Boolean(hasReviewRequested && companyRatingPublicId);

  //event handlers
  const handleReviewAdd = () => {
    routing.go(TaskViewReviewRouteDefinitions.TASK_VIEW_REVIEW);
  }

  const handleReviewEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const params = { reviewId: companyRatingPublicId }
    routing.go(TaskViewReviewRouteDefinitions.TASK_VIEW_REVIEW_EDIT, { params });
  }

  if (!hasReviewRequested) return null;

  return (
    <>
      {!isEdit && <Button
        fullWidth
        variant="contained"
        onClick={handleReviewAdd}
      >
        Write Review
      </Button>}
      {isEdit && <Button
        fullWidth
        variant="contained"
        onClick={handleReviewEdit}
      >
        Edit review
      </Button>}
    </>
  )
}
export default TaskViewReviewActions;