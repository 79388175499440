import { CoreState } from "@build-buddy/core";
import { Box, Card, CardContent, RadioGroup, useTheme } from "@mui/material";
import PaymentOptionsCreditCard from "./PaymentOptionsCreditCard";
import PaymentOptionsPayTo from "./PaymentOptionsPayTo";

export interface PaymentOptionsProps {
  name: string;
  creditCard?: any;
  payTo?: any;
  paymentType: any;
  amount: number;
  onChange(e: any): void;
}
const PaymentOptions = (props: PaymentOptionsProps) => {
  const { name, paymentType, creditCard, payTo, amount, onChange } = props;
  const theme = useTheme();

  // locals
  const creditCardNumber = creditCard?.number;
  const creditCardName = creditCard?.name;
  const creditCardExp = creditCard?.expiry;
  const payToName = payTo?.name;
  const payToBank = payTo?.bank;
  const payToAddress = payTo?.address;

  return (
    <>
      <Card sx={{ flex: 3 }}>
        <CardContent>
          <RadioGroup
            name={name}
            value={paymentType}
            onChange={onChange}
          >
            <PaymentOptionsPayTo 
              show={paymentType === CoreState.Financial.PaymentType.PayTo}
              name={payToName}
              address={payToAddress}
              bank={payToBank}
              amount={amount}
            />
            <Box
              sx={{
                my: 0.75,
                mx: -2,
                px: 2,
                borderBottom: `solid 1px ${theme.palette.grey[100]}`
              }}
            />
            <PaymentOptionsCreditCard
              show={paymentType === CoreState.Financial.PaymentType.CreditCard}
              number={creditCardNumber}
              name={creditCardName}
              expiry={creditCardExp}
            />
          </RadioGroup>
        </CardContent>
      </Card>
    </>
  )
}
export default PaymentOptions;