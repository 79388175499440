import { CoreState } from "@build-buddy/core";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Slide } from "@mui/material";
import { useSelector } from "common/state/store";
import React from "react";
import { useParams } from "react-router-dom";
import WizardViewStepContainerStepNumber from "./WizardViewStepContainerStepNumber";

type WizardViewStepContainerStepProps = {
  expanded: boolean;
  stepIndex: number;
  summaryComponent?: React.ReactElement;
  contentComponent?: React.ReactElement;
  footerComponent?: React.ReactElement
}

const WizardViewStepContainerStep = (props: WizardViewStepContainerStepProps) => {
  const {
    expanded,
    stepIndex,
    summaryComponent,
    contentComponent,
    footerComponent
  } = props;

  // params
  const { wid } = useParams<any>();
  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);
  const getWizardQueryParams = { wizardId: wid, userId: currentUserId };

  // queries
  const [getWizardStepAction] = CoreState.Wizard.getWizardStep.useLazyQuery();

  // locals
  const currentMilestone = useSelector((s) => CoreState.Wizard.selectWizardMilestoneCurrent(s, getWizardQueryParams));
  const currentStep = useSelector((s) => CoreState.Wizard.selectWizardStepCurrent(s, getWizardQueryParams));
  const currentStepIndex = useSelector((s) => CoreState.Wizard.selectWizardStepCurrentIndex(s, getWizardQueryParams));
  const steps = useSelector((s) => CoreState.Wizard.selectWizardStepList(s, getWizardQueryParams));

  // events
  const handleClick = () => {
    if (!currentUserId || !currentMilestone || !currentStep) return;
    if (!steps || stepIndex === currentStepIndex) return;
    const payload = {
      wizardId: wid,
      userId: currentUserId,
      milestoneKey: currentMilestone.key,
      stepKey: steps[stepIndex].key
    }
    getWizardStepAction(payload);
  }

  return (
    <Slide
      in={true}
      direction="up"
      mountOnEnter={true}
      timeout={{ enter: 300 }}
    >
      <Accordion
        expanded={expanded}
        sx={{
          px: { xs: 3, md: 5 },
          py: 3,
          mb: 1.5,
          "&.Mui-expanded:last-of-type": {
            marginBottom: 1.5
          },
          boxShadow: "0px 4px 16px rgba(1, 11, 19, 0.04)",
          borderRadius: "12px",
        }}
      >
        <AccordionSummary onClick={() => handleClick()}>
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
            }}
          >
            <Box
              sx={{
                position: "relative",
                bottom: 3,
                mb: { xs: 2, md: 0 }
              }}
            >
              <WizardViewStepContainerStepNumber
                label={(stepIndex + 1).toString()}
              />
            </Box>
            <Box>
              {summaryComponent}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              ml: {
                xs: 0,
                md: 7
              }
            }}
          >
            {contentComponent}
          </Box>
        </AccordionDetails>
        <AccordionActions>
          <Box
            sx={{
              flex: 1,
              ml: {
                xs: 0,
                md: 7
              }
            }}
          >
            {footerComponent}
          </Box>
        </AccordionActions>
      </Accordion>
    </Slide>
  )
}

export default WizardViewStepContainerStep;