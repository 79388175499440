import { CoreRouting } from "@build-buddy/core";
import Support from "./Support";
import SubmitFeedback from "./SubmitFeedback";

const HelpRoutesRouteDefinitions = {
  SUBMIT_FEEDBACK: {
    path: "/help/submit-feedback",
    component: SubmitFeedback
  },
  SUPPORT: {
    path: "/support",
    component: Support
  },
};

const RootHelpRoutes = [
  HelpRoutesRouteDefinitions.SUBMIT_FEEDBACK,
  // HelpRoutesRouteDefinitions.SUPPORT,
];

const HelpRoutes = () => {
  return (
    <>
      <CoreRouting.Routes routes={RootHelpRoutes} />
    </>
  );
};

export { HelpRoutesRouteDefinitions, HelpRoutes };
