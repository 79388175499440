import { CoreState, CoreUtils } from '@build-buddy/core'
import { Stack, Typography } from '@mui/material'

type PaymentBreakdownProps = {
  amount: number;
  currentMethod: CoreState.Wallet.PaymentGateway;
}

const PaymentBreakdown = ({ amount, currentMethod }: PaymentBreakdownProps) => {
  // GST and payment amounts
  const total = amount;
  const gst = total / 11;
  const subtotal = total / 1.1;
  const creditCardSurcharge = currentMethod === CoreState.Wallet.PaymentGateway.Card? 'WAIVED' : 0;
  const grandTotal = total;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant='body1'>Subtotal</Typography>
        <Typography variant='h6'>{CoreUtils.Formatter.currency(subtotal)}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={0.5}>
        <Typography variant='body1'>GST</Typography>
        <Typography variant='h6'>{CoreUtils.Formatter.currency(gst)}</Typography>
      </Stack>
      {!!creditCardSurcharge &&
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={0.5}>
          <Typography variant='body1'>Credit Card Surcharge</Typography>
          <Typography variant='h6'>{creditCardSurcharge}</Typography>
        </Stack>
      }
      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1.5}>
        <Typography fontSize={16}>Total Amount</Typography>
        <Typography variant='h4'>{CoreUtils.Formatter.currency(grandTotal)}</Typography>
      </Stack>
    </>
  )
}

export default PaymentBreakdown