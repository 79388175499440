import { CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useParams } from "react-router-dom";
import WizardViewMilestone from "./wizard-view-milestone/WizardViewMilestone";
import WizardHeader from "./WizardHeader";

const WizardView = () => {
  // params
  const { wid } = useParams<any>();
  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);
  const getWizardQueryParams = { wizardId: wid, userId: currentUserId };

  // queries
  const getWizardQuery = CoreState.Wizard.getWizard.useQuery(getWizardQueryParams);

  // locals
  const currentMilestone = useSelector((s) => CoreState.Wizard.selectWizardMilestoneCurrent(s, getWizardQueryParams));
  const currentMilestoneIndex = useSelector((s) => CoreState.Wizard.selectWizardMilestoneCurrentIndex(s, getWizardQueryParams));
  const currentStepIndex = useSelector((s) => CoreState.Wizard.selectWizardStepCurrentIndex(s, getWizardQueryParams));
  const steps = useSelector((s) => CoreState.Wizard.selectWizardStepList(s, getWizardQueryParams));
  const milestones = useSelector((s) => CoreState.Wizard.selectWizardMilestoneList(s, getWizardQueryParams));
  const isLoading = getWizardQuery.isLoading;

  if (!currentMilestone) return null;

  return (
    <Box>
      <WizardHeader 
        steps={["Initialise", ...milestones.map((x: any) => x.title)]} 
        currentStep={currentMilestoneIndex + 1} 
      />
      <WizardViewMilestone
        loading={isLoading}
        currentStepIndex={currentStepIndex}
        currentMilestone={currentMilestone}
        steps={steps}
      />
    </Box>
  )
}
export default WizardView;