import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Box, Collapse, Fade, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import qs from "qs";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFinancialRoutesParams } from "../../FinancialsRoutes";
import BudgetItem from "../budget-item/BudgetItem";
import BudgetItemListEstimated from "./budget-item-list-estimated/BudgetItemListEstimated";
import BudgetItemListTracking from "./budget-item-list-tracking/BudgetItemListTracking";
import BudgetListItemSkeleton from "./BudgetListItemSkeleton";

import _ from "lodash";

export enum BudgetItemListMode {
  Tracking = "tracking",
  Estimate = "estimate"
}

export interface BudgetItemListProps {
  defaultMode: BudgetItemListMode;
  filters?: any;
}

const useSearch = (query: any) => {
  const [args, setArgs] = useState(query.originalArgs);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (_.isEqual(query.originalArgs, args) || !query.originalArgs || !query.isFetching) return setIsSearching(false);
    
    setIsSearching(true);
    setArgs(query.originalArgs);
  }, [query]);

  return {
    isSearching
  }
}

const BudgetItemList = (props: BudgetItemListProps) => {
  const { defaultMode, filters } = props;
  const routing = CoreRouting.useRouting();
  const theme = useTheme();
  const { search, pathname } = useLocation();
  const { code } = qs.parse(search, { ignoreQueryPrefix: true }) as { code?: string }

  // params
  const { projectId } = useFinancialRoutesParams();
  const costCentreListByProjectParams = { projectId, filters };

  // queries
  const costCentreListByProjectQuery = CoreState.Financial.getCostCentreSummaryListByProject.useQuery(costCentreListByProjectParams);

  // locals
  const refs = useRef<any>({});
  const [mode, setMode] = useState<Record<string, BudgetItemListMode>>({});
  const isLoading = Boolean(!costCentreListByProjectQuery.isUninitialized && (costCentreListByProjectQuery.isLoading));
  const isFetching = costCentreListByProjectQuery.isFetching;
  const { isSearching } = useSearch(costCentreListByProjectQuery);
  const costCentreSummaryList = useSelector(s => CoreState.Financial.selectCostCentreSummaryListByProject(s, costCentreListByProjectParams));

  // events
  const handleShow = (c: string) => {
    const codesSet = new Set(code?.split(","));
    codesSet.has(c) ? codesSet.delete(c) : codesSet.add(c);
    routing.go(pathname, { persistSearch: true, filters: [{ "code": Array.from(codesSet) }] })
  }

  const handleSwitch = (code: string, e: any) => {
    const mode = Boolean(e.target.checked) ? BudgetItemListMode.Tracking : BudgetItemListMode.Estimate;
    setMode(prevMode => ({ ...prevMode, [code]: mode }));
  }

  const getMode = (c: string) => {
    // finds the mode of the switch, otherwise uses the default mode provided
    return mode[c] || defaultMode;
  }

  const getExpanded = (c: string) => {
    // if c is inside the code query string, then its expanded
    return code?.includes(c)
  }

  // side effects
  useEffect(() => {
    // if no items or the code is not a string, then exit
    if (!costCentreSummaryList?.length || typeof code !== "string") return;
    // using the code, find the last element in the array and use the ref to scroll into view
    const element = refs.current[code.split(",").filter(Boolean).pop() || ""];
    element?.scrollIntoView();
  }, [costCentreSummaryList?.length])

  return (
    <CoreComponents.PreContent
      isLoading={isLoading || isSearching}
      loader={<BudgetListItemSkeleton />}
      isEmpty={Boolean(!costCentreSummaryList?.length)}
      empty={<> ERR </>}
    >
      <CoreComponents.Loader show={isFetching} fullscreen />
      {costCentreSummaryList?.map((costCentreSummary, i) =>
        <Box
          key={i}
          sx={{
            scrollMarginTop: "4rem",
            mx: -2,
            px: 2,
            py: 1.5,
            background: theme.palette.grey[50],
            borderBottom: `solid 1px ${theme.palette.grey[200]}`,
            "&:last-of-type": {
              borderBottom: "none",
              pb: 0
            }
          }}
          ref={(el: any) => refs.current[costCentreSummary.costCentre.code] = el}
        >
          <BudgetItem
            code={costCentreSummary.costCentre.code}
            name={costCentreSummary.costCentre.name}
            allocated={costCentreSummary.summary.allocated}
            accepted={costCentreSummary.summary.accepted}
            paid={costCentreSummary.summary.paid}
            budget={costCentreSummary.summary.budget}
            overBudget={costCentreSummary.summary.overBudget}
            projectedOverBudget={costCentreSummary.summary.projectedOverBudget}
            savings={costCentreSummary.summary.savings}
            projectedSavings={costCentreSummary.summary.projectedSavings}
            IconProps={{
              sx: {
                transform: `rotate(${getExpanded(costCentreSummary.costCentre.code) ? "90deg" : "0"})`
              }
            }}
            actions={getExpanded(costCentreSummary.costCentre.code) &&
              <Fade in={true}>
                <Box sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "relative",
                  top: 2
                }}>
                  <CoreComponents.Switch
                    name="switch"
                    checked={(getMode(costCentreSummary.costCentre.code) === BudgetItemListMode.Tracking)}
                    innerlabels={["Tracking", "Estimated"]}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleSwitch(costCentreSummary.costCentre.code, e)}
                    sx={{
                      flex: 1,
                      maxWidth: "200px",
                      background: theme.palette.grey[600],
                      textAlign: "center"
                    }}
                  />
                </Box>
              </Fade>
            }
            onClick={() => handleShow(costCentreSummary.costCentre.code)}
          >
            <Collapse
              in={getExpanded(costCentreSummary.costCentre.code)}
              unmountOnExit={true}
            >
              {getMode(costCentreSummary.costCentre.code) === BudgetItemListMode.Tracking &&
                <BudgetItemListTracking costCentreCode={costCentreSummary.costCentre.code} />
              }
              {getMode(costCentreSummary.costCentre.code) === BudgetItemListMode.Estimate &&
                <BudgetItemListEstimated costCentreCode={costCentreSummary.costCentre.code} />
              }
            </Collapse>
          </BudgetItem>
        </Box>
      )}
    </CoreComponents.PreContent>
  )
}
export default BudgetItemList;