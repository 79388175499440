import { CoreComponents, CoreRouting, CoreState } from '@build-buddy/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaskViewQuoteListAcceptedQuote } from '../../_selectors';
import { useTaskViewPaymentsParams } from '../_hooks';

const TaskViewPaymentsReceiptViewer = () => {
  const routing = CoreRouting.useRouting();
  const dispatch = useDispatch()

  // params
  const { projectId, taskId, paymentScheduleId, stageId, quoteId } = useTaskViewPaymentsParams();
  const quoteListParams = { projectId, stageId, taskId };

  // selectors
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, quoteListParams));

  // params
  const quoteDocListParams = { projectId, quoteId, taskId };
  const quoteReceiptParams = { projectId, quoteId, taskId, paymentScheduleId };

  // queries
  const quoteDocListQuery = CoreState.File.getDocListByTask.useQuery(quoteDocListParams);

  // Generate receipt
  const [generateReceiptAction, generateReceiptQuery] = CoreState.Payment.generatePaymentReceipt.useMutation();

  // Selector
  const receipt = useSelector((s) => CoreState.File.selectReceiptByPaymentSchedule(s, quoteReceiptParams));
  const fileState = useSelector((s) => CoreState.File.selectFileState(s, receipt?.id));

  useEffect(() => {
    if (!acceptedQuote) return
    if (receipt) return
    generateReceiptAction({
      projectId,
      taskId,
      paymentScheduleId,
      quoteId: acceptedQuote?.id,
      quoteRequestId: quoteId
    })
  }, [])

  useEffect(() => {
    if (!receipt) return
    dispatch(CoreState.File.downloadDocByType(receipt));
  }, [receipt])

  // event handlers
  const handleBack = () => {
    routing.back();
  }

  return (
    <CoreComponents.DocumentViewer
      open={true}
      doc={receipt as any}
      fileState={fileState}
      onClose={handleBack}
      isLoading={generateReceiptQuery.isLoading || quoteDocListQuery.isLoading}
      loader={<CoreComponents.DocumentViewerLoading onClose={handleBack} />}
      isEmpty={!receipt}
      empty={<CoreComponents.DocumentViewerEmpty onClose={handleBack} />}
      isError={generateReceiptQuery.isError}
      error={<CoreComponents.DocumentViewerError onClose={handleBack} error={generateReceiptQuery.error as CoreState.Common.Error} />}
    />
  )
}

export default TaskViewPaymentsReceiptViewer;