import { Box, useTheme } from "@mui/material";
import ProjectSelect from "../ProjectSelect";

const MobileHeader = () => {
  const theme = useTheme();
  return (
    <Box sx={{mb: '7.5rem'}}>
      <Box
        sx={{
          background: theme.palette.common.white,
          px: 3,
          py: 1.5,
          mb: 2,
          position: 'fixed',
          top: 0,
          zIndex: theme.zIndex.appBar,
          width: '100%',
          borderBottom: `1px solid ${theme.palette.grey[100]}`
        }}
      >
        <ProjectSelect />
      </Box>
    </Box>
  );
};

export default MobileHeader;