import { Box, useTheme } from "@mui/material";
import React from "react";

interface StripedBarProps {
  color: string | undefined;
  width: number;
}
const StripedBar = (props: StripedBarProps) => {
  const { color, width } = props;
  const theme = useTheme();
  const c = color || theme.palette.grey[600];

  return (
    <Box
      sx={{
        height: "8px",
        width: `${width}%`,
        position: "relative",
        borderRadius: 0,
        border: `solid 1.5px ${c}`,
        background: `repeating-linear-gradient(
          115deg,
          ${c},
          ${c} 1.5px,
          ${theme.palette.common.white} 1.5px,
          ${theme.palette.common.white} 6px
        )`
      }}
    />
  )
}
export default StripedBar;