import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog } from "@mui/material";
import { useSelector } from "common/state/store";
import TaskViewDocumentsRouteDefinitions from "../TaskViewDocumentsRouteDefinitions";
import { useTaskViewDocumentsParams } from "../_hooks";
import TaskViewDocumentsAddForm from "./TaskViewDocumentsAddForm";

const TaskViewDocumentsAdd = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, stageId, taskId, tagCode } = useTaskViewDocumentsParams();
  const getTagListByTaskDocumentParams = { projectId, stageId, taskId };

  // queries
  const getTagListByTaskDocumentQuery = CoreState.Tag.getTagListByTaskDocument.useQuery(getTagListByTaskDocumentParams);

  // locals
  const tagList = useSelector((s) => CoreState.Tag.selectTagListByTaskDocument(s, getTagListByTaskDocumentParams));
  const tag = tagList.find(t => t.code === tagCode);
  const isLoading = getTagListByTaskDocumentQuery.isLoading || getTagListByTaskDocumentQuery.isUninitialized;

  // events
  const handleClose = () => {
    routing.go(TaskViewDocumentsRouteDefinitions.TASK_VIEW_DOCUMENTS);
  };

  return (
    <Dialog
      open={true}
      onClose={() => { }}
    >
      <CoreComponents.DialogHeader title="Upload a document" onClose={() => handleClose()} />
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={false}
      >
        <TaskViewDocumentsAddForm
          tag={tag as CoreState.Tag.Tag}
          onSuccess={() => handleClose()}
        />
      </CoreComponents.PreContent>
    </Dialog>

  )
}
export default TaskViewDocumentsAdd;