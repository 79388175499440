
import * as yup from "yup";

const TaskViewQuotesAcceptValidation = yup.object().shape({
  name: yup.string().required('Please enter your name.'),
  // TEMPORARY REMOVAL
  // sow: yup
  //   .bool()
  //   .oneOf([true], "Please accept the SOW Agreement"),      
  tnc: yup
    .bool()
    .oneOf([true], "Please accept the Terms and Conditions"),
});

export default TaskViewQuotesAcceptValidation;
