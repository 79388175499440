import { useEffect } from "react";
import { useParams } from "react-router";
import { Dialog, DialogContent } from "@mui/material";
import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { TaskViewRouteDefinitions, useTaskViewParams } from "modules/project/task/task-view"
import TaskViewScopeOfWorkSpecForm from "./TaskViewScopeOfWorkSpecForm";

const useVm = () => {
  const qs = useParams<any>();
  const params = useTaskViewParams();
  const getTaskSpecificationQuery = CoreState.Specification.getTaskSpecification.useQuery({ ...params, specId: qs.spid })
  const [updateTaskSpecificationAction, updateTaskSpecificationQuery] = CoreState.Specification.updateTaskSpecification.useMutation();
  const updateTaskSpecification = (values: any) => {
    updateTaskSpecificationAction({
      ...params,
      taskSpecification: {
        name: values.name,
        description: values.description
      }
    })
  }
  return {
    params,
    taskSpec: getTaskSpecificationQuery.data,
    isLoading: getTaskSpecificationQuery.isLoading,
    isSaving: updateTaskSpecificationQuery.isLoading,
    isSaved: updateTaskSpecificationQuery.isSuccess,
    updateTaskSpecification,
  }
}

const TaskViewScopeOfWorkSpecEdit = () => {
  const vm = useVm();
  const routing = CoreRouting.useRouting();

  const handleClose = () => {
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_SCOPE_OF_WORK);
  }

  const handleSubmit = (values: any) => {
    vm.updateTaskSpecification(values)
  }

  useEffect(() => {
    if (!vm.isSaved) return;
    handleClose();
  }, [vm.isSaved]);

  return (
    <Dialog open={true}>
      <CoreComponents.Loader show={vm.isSaving} />
      <CoreComponents.DialogHeader title="Edit Specification" onClose={() => handleClose()} />
      <DialogContent>
        <CoreComponents.PreContent
          isLoading={vm.isLoading}
          isEmpty={Boolean(!vm.taskSpec)}
          loader={<>LOADING</>}
          empty={<>EMPTY</>}
        >
          <TaskViewScopeOfWorkSpecForm
            taskSpec={vm.taskSpec}
            onSubmit={(values) => handleSubmit(values)}
            onClose={() => handleClose()}
          />
        </CoreComponents.PreContent>
      </DialogContent>
    </Dialog>
  )
}
export default TaskViewScopeOfWorkSpecEdit;
