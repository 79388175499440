import { Theme } from "@emotion/react";
import { SxProps, Box } from "@mui/material";

interface BudgetVariationTemplateProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}
const BudgetVariationTemplate = (props: BudgetVariationTemplateProps) => {
  const { children, sx } = props;
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateAreas: `
          'label estimate variation'
        `,
        gridTemplateColumns: "auto 25% 25%",
        gridTemplateRows: "50px",
        rowGap: 1,
        alignItems: "center",
        ...sx
      }}
    >
      {children}
    </Box>
  )
}
export default BudgetVariationTemplate;