
import { FC, useState } from "react";
import { Box, SvgIcon, Collapse, Link } from "@mui/material";
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from "@mui/icons-material";

interface MoreDetailsProps {
  fields: any;
  data: any;
}
const MoreDetails: FC<MoreDetailsProps> = (props: MoreDetailsProps) => {
  const [open, setOpen] = useState(false);
  const icon = open ? ExpandLessIcon : ExpandMoreIcon;
  const text = open ? "Less" : "More";
  const html = props.data?.length ? props.data[0].html : "";

  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpen(!open);
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Link 
        component="div"
        variant="caption" 
        onClick={handleClick}
        underline="none"
        sx={{ 
          display: "flex", 
          alignItems: "center", 
          cursor: "pointer",
          mb: 0.5
        }}
      > 
        <Box sx={{ mr: 0.25 }}>{text} details</Box> 
        <SvgIcon component={icon} fontSize="small" />
      </Link>
      <Collapse in={open}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Collapse>
    </Box>
  )
}
export default MoreDetails;