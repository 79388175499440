import { FC, useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { Dimension } from "common/components";

interface FormDimensionProps {
  data: any;
  fields: any;
}
const FormDimension: FC<FormDimensionProps> = (props: FormDimensionProps) => {
  const formik = useFormikContext<any>();
  const formikVals = props.fields.map((f: any) => formik.values[f.inputId]);
  const formikErrs = props.fields.map((f: any) => formik.errors[f.inputId]);
  const [dimensions, setDimensions] = useState<any>({});
  const [image, setImage] = useState<any>();
  const dimensionKeys = Object.keys(dimensions);
  const colors = ["#f44336", "#8bc34a", "#9c27b0", "#2196f3", "#ffc107", "#ff5722", "#00bcd4"];

  useEffect(() => {
    // create blank objects
    let ds: any = {};

    // loop through the fields to generate the objects
    props.fields.forEach((f: any, index: number) => {
      const label = f.inputRef;
      const value = formik.values[f.inputId] || 0;
      const color = colors[index];
      const min = f.validations.find((v: any) => v.type === "min")?.value || 0;
      const max = f.validations.find((v: any) => v.type === "max")?.value || 99999;
      const error = formik.errors[f.inputId];
      // create the dimensions object. This is used across the dimensions component
      ds[f.inputId] = { 
        label, 
        value, 
        error, 
        min: Number(min), 
        max: Number(max),
        color 
      };
    });

    // set the dimensions
    setDimensions(ds);
  }, []);

  useEffect(() => {
    if(!dimensionKeys.length) return;
    const ds: any = { ...dimensions };
    dimensionKeys.forEach((k: string) => {
      ds[k].value = formik.values[k];
      ds[k].error = formik.errors[k];
    });
    setDimensions(ds);
  }, [...formikVals, ...formikErrs]);

  useEffect(() => {
    if (!props.data?.length || !props.data[0].image) return;
    setImage(props.data[0].image.data);
  }, [props.data])

  const handleUpdate = (dimensions: any) => {
    // create blank values object
    let value: any = {};
    // loop through the updated dimension and populate the values object
    dimensionKeys.forEach((key: string) => value[key] = dimensions[key].value);
    // update formik values
    formik.setValues({ ...formik.values, ...value }, );
  }

  if (!image) return null;

  return (
    <Dimension
      dimensions={dimensions}
      design={image}
      onUpdate={handleUpdate}
    />
  )
}
export default FormDimension;
