import { Tooltip, useTheme } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { ProjectTask } from "modules/project/_models";

type ProjectTaskActionsOverdueProps = {
  projectTask: ProjectTask
}
const ProjectTaskActionsOverdue = (props: ProjectTaskActionsOverdueProps) => {
  const theme = useTheme();
  if (!props.projectTask.task.isOverdue) return null;
  return (
    <Tooltip title="Overdue">
      <ErrorIcon sx={{ fontSize: 28, color: theme.palette.error.main }} />
    </Tooltip>
  )
}
export default ProjectTaskActionsOverdue;