import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { BuildBuddyStateType } from "common/state/store";
import { SelectProjectParams } from "./selectProject";

/**
 * Uses queries: CoreState.Project.getProject
 *
 * @returns The security context of the requester in the project
 */
interface ProjectAccessLevelParams extends SelectProjectParams { }
const selectCurrentProjectRequesterSecurityContext = createSelector(
  [
    (state: BuildBuddyStateType, _) => CoreState.Project.getUserProjectList.select()(state),
    (_, params: ProjectAccessLevelParams) => params,
  ],
  (projects, meta) => {
    if (!projects) return;
    return projects.data?.find(project => project.id === meta.projectId)?.requesterSecurityContext;
  }
);

const selectIsBuildBuddyAdmin = createSelector(
  selectCurrentProjectRequesterSecurityContext,
  (requesterSecurityContext) => {
    return requesterSecurityContext?.isBuildBuddyAdmin
  }
)

export const selectIsBuildBuddyHelpDesk = createSelector(
  selectCurrentProjectRequesterSecurityContext,
  (requesterSecurityContext) => {
    return requesterSecurityContext?.isBuildBuddyHelpDesk
  }
)

const selectIsMemberOfAnOrganisation = createSelector(
  selectCurrentProjectRequesterSecurityContext,
  (requesterSecurityContext) => {
    return requesterSecurityContext?.isMemberOfAnOrganisation
  }
)

const selectProjectAccessLevel = createSelector(
  selectCurrentProjectRequesterSecurityContext,
  (requesterSecurityContext) => {
    return requesterSecurityContext?.projectAccessLevel
  }
)

export const selectProjectAccessLevelIsEditor = createSelector(
  selectProjectAccessLevel,
  (accessLevel) => accessLevel === CoreState.Project.ProjectAccessLevel.Supervisor
)