import Config from "./config.json";
import { Config as BaseConfig } from "@build-buddy/core"

const getConfig = () => {
  return {
    ...BaseConfig,
    ...Config
  }
}

export default getConfig;