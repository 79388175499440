import { createSelector } from "@reduxjs/toolkit";
import { CoreUtils } from "@build-buddy/core";
import { selectProjectTaskListFilteredByStatus, SelectProjectTaskListFilteredByStatusParams } from "modules/project/_selectors";

export interface SelectDashboardTaskListParams extends SelectProjectTaskListFilteredByStatusParams {
}
const selectDashboardTaskList = createSelector(
  selectProjectTaskListFilteredByStatus,
  (taskList) => {
    if (!taskList || !taskList.length) return [];
    return taskList.filter(t => t.task.isCurrent && t.task.isRequired && !t.task.isHidden);
  }
)

export interface SelectDashboardTasksFilteredParams extends SelectDashboardTaskListParams {
  search?: string
}
export const selectDashboardTaskListFiltered = createSelector(
  [
    (state, params: SelectDashboardTasksFilteredParams) => ({
      taskList: selectDashboardTaskList(state, params),
      search: params.search
    })
  ],
  (result) => {
    if (!result.taskList) return [];
    const filterSearch = result.search || "";
    return result.taskList
      .map(t => {
        if (!t) return null;
        if (!t.task.name) return t;
        return (t.task?.name.search(new RegExp(filterSearch, "i")) >= 0) ? t : undefined
      })
      .filter(CoreUtils.TypeGuards.nonNullable);
  }
)

export interface SelectDashboardTasksFilteredAndSortedParams extends SelectDashboardTasksFilteredParams {
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
}
export const selectDashboardTaskListFilteredAndSorted = createSelector(
  [
    (state, params: SelectDashboardTasksFilteredAndSortedParams) => ({
      taskList: selectDashboardTaskListFiltered(state, params),
      sortBy: params.sortBy,
      sortOrder: params.sortOrder
    }),
    (_, params: SelectDashboardTasksFilteredAndSortedParams) => (params)
  ],
  (result, params) => {
   return result.taskList;
  }
)
