import TaskViewIntMeetingOutline from "./TaskViewIntMeetingOutline";
import { TaskViewTabs } from "../_common";

const TaskViewIntMeetingContent = () => {
  return (
    <>
      <TaskViewIntMeetingOutline />
      <TaskViewTabs />
    </>
  )
}
export default TaskViewIntMeetingContent;