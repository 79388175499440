
import { useRouteMatch } from "react-router";
import TaskViewQualityAssuranceRouteDefinitions from "../../TaskViewQualityAssuranceRouteDefinitions";

const useTaskViewQualityAssuranceDefectsParams = () => {
  const match = useRouteMatch<any>([
    TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_VIEW.path,
    TaskViewQualityAssuranceRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE_DEFECT_ADD_WITH_QA.path
  ]);

  return {
    qualityAssuranceId: match?.params.qaId,
    defectId: match?.params.did
  };
}
export default useTaskViewQualityAssuranceDefectsParams