import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useTaskViewParams } from "../../_hooks";
import { selectTaskViewQuoteListAcceptedQuote } from "../../_selectors";

type TaskViewQualityAssuranceDefectsGuardProps = {
  children: any;
}
const TaskViewQualityAssuranceDefectsGuard = (props: TaskViewQualityAssuranceDefectsGuardProps) => {
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const taskListByParentParams = { projectId, stageId, taskId };
  const taskViewQuoteListAcceptedQuoteParams = { projectId, stageId, taskId };

  // selectors
  const acceptedQuote = useSelector((s) => selectTaskViewQuoteListAcceptedQuote(s, taskViewQuoteListAcceptedQuoteParams));
  const isTaskWorkInProgress = useSelector((s) => CoreState.Task.selectTaskListByParentIsWorkInProgress(s, taskListByParentParams));

  if (!Boolean(acceptedQuote && isTaskWorkInProgress)) return null;

  return props.children;
}
export default TaskViewQualityAssuranceDefectsGuard;