import TaskViewCompanyDetails from "./TaskViewCompanyDetails";
import TaskViewCompanyDetailsReviews from "./TaskViewCompanyDetailsReviews";

const TaskViewCompanyRouteDefinitions = {
  TASK_VIEW_COMPANY_DETAILS: {
    label: "Company Details",
    path: "/*/stage/:sid/task/:tid/company-details",
    component: TaskViewCompanyDetails
  },
  TASK_VIEW_COMPANY_DETAILS_REVIEWS: {
    path: "/*/stage/:sid/task/:tid/company-details/reviews",
    component: TaskViewCompanyDetailsReviews,
  },
}

export default TaskViewCompanyRouteDefinitions;