import {
  Box,
  Card,
  CardContent,
  Skeleton
} from "@mui/material";
import { FC } from "react";

const TaskViewReviewSkeleton: FC = () => {

  return (
    <Box sx={{ m: "0 auto", mt: 2, width: 588 }}>
      <Card sx={{ padding: "20px 48px", mb: 2 }}>
        <CardContent>
          <Skeleton height={35} width="30%" />
          <Skeleton />
        </CardContent>
      </Card>
      <Card>
        <CardContent sx={{ pl: 6, pr: 6, pt: 5, pb: 5 }}>
          <Box>
            <Skeleton variant="rectangular" height="50vh" width="100%" />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TaskViewReviewSkeleton;
