import { Box } from "@mui/material";
import { FC, useEffect } from "react";
import WizardViewMilestoneStep from "../wizard-view-milestone/WizardViewMilestoneStep";

type WizardViewStepContainerProps = {
  selectedIndex: number;
  steps: Array<any> | undefined;
  currentMilestone: any;
}

const WizardViewStepContainer: FC<WizardViewStepContainerProps> = (props: WizardViewStepContainerProps) => {
  const { steps, currentMilestone } = props;

  useEffect(() => {
    document.body.classList.add("show-scroll");
    return () => document.body.classList.remove("show-scroll");
  }, []);

  return (
    <Box>
      {steps?.map((step, idx) => (
        <Box key={idx}>
          <WizardViewMilestoneStep
            key={idx}
            step={step}
            milestone={currentMilestone}
            currentIndex={idx}
          />
        </Box>
      ))}
    </Box>
  )
}
export default WizardViewStepContainer;