import { useState } from "react";
import { Box, Popover, Stack, Typography, useTheme } from "@mui/material";

interface LegendProps {
  legend: Array<{ name: string, color: string }>
}
const Legend = (props: LegendProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!props.legend || !props.legend.length) return null;

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Typography
        variant='caption'
        sx={{
          color: theme.palette.primary.main,
          fontWeight: 600,
          cursor: "pointer"
        }}
        onClick={handleClick}
      >
        View legend details
      </Typography>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 1.5 }}>
          {props.legend.map((x, i) => (
            <Stack
              key={`${x.name}-${i}`}
              direction="row"
              alignItems="center"
              sx={{
                mb: 0.75,
                '&:last-of-type': {
                  mb: 0
                }
              }}
            >
              <Box
                sx={{
                  background: x.color,
                  width: 15,
                  height: 3,
                  mr: 1,
                  position: 'relative',
                  bottom: 1.5
                }}
              />
              <Typography> {x.name} </Typography>
            </Stack>
          ))}
        </Box>
      </Popover>
    </Box>

  )
}
export default Legend;

