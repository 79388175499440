import { CoreState } from "@build-buddy/core";
import { createAction } from "@reduxjs/toolkit";

interface SwitchProjectParams {
  project: CoreState.Project.Project;
}
const switchProject = createAction(
  "app/switchProject",
  (params: SwitchProjectParams) => {
    return {
      payload: params.project
    }
  })

export default switchProject;
