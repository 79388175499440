import { CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import TaskViewRouteDefinitions from "../../../TaskViewRouteDefinitions";
import { useTaskViewParams } from "../../../_hooks";
import { selectShowQualityAssuranceTab } from "../../../_selectors/selectShowQualityAssuranceTab";

const useTaskViewTypesTabsQualityAssurance = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const qualityAssuranceParams = { projectId, stageId, taskId };
  const projectTaskParams = { projectId, stageId, taskId };

  // queries
  const qualityAssuranceQuery = CoreState.QualityAssurance.getQualityAssuranceListByTask.useQuery(qualityAssuranceParams);

  // selectors
  const count = useSelector((s) => CoreState.QualityAssurance.selectQualityAssuranceListByTaskLength(s, projectTaskParams));
  const showQualityAssuranceTab = useSelector((s) => selectShowQualityAssuranceTab(s, projectTaskParams));

  if (!showQualityAssuranceTab) return null;

  return {
    ...TaskViewRouteDefinitions.TASK_VIEW_QUALITY_ASSURANCE,
    label: "Quality Assurance",
    count
  }
}
export default useTaskViewTypesTabsQualityAssurance;