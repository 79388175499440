import { Typography, useTheme } from "@mui/material";
import TaskViewQuotesTemplate from "./TaskViewQuotesListTemplate";

const TaskViewQuotesHeader = () => {
  const theme = useTheme();
  return (
    <TaskViewQuotesTemplate
      sx={{
        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
        mb: 2.5,
        pb: 1,
        display: {
          xs: "none",
          md: "grid"
        }
      }}
    >
      <Typography variant="subtitle2" sx={{ gridArea: "company", textTransform: "uppercase", ml: 0.5 }}> Company </Typography>
      <Typography variant="subtitle2" sx={{ gridArea: "rating", textTransform: "uppercase" }}> Rating </Typography>
      <Typography variant="subtitle2" sx={{ gridArea: "quote", textTransform: "uppercase" }}> Quote </Typography>
      <Typography variant="subtitle2" sx={{ gridArea: "actions", textTransform: "uppercase" }}> </Typography>
    </TaskViewQuotesTemplate>
  )
}
export default TaskViewQuotesHeader;