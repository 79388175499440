
import { CoreState } from '@build-buddy/core';
import { Home as HomeIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Avatar, Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { CommonState } from "common";
import { Project } from "common/components/display";
import { useSelector } from "common/state/store";
import { selectProject } from "modules/project/_selectors";
import { selectIsBuildBuddyHelpDesk } from "modules/project/_selectors/selectProjectAccessLevel";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import ProjectSelectList from "./ProjectSelectList";

const ProjectSelect = () => {
  const theme = useTheme();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const ref = useRef<any>(null);

  //selectors
  const currentContextProjectId = useSelector(CommonState.App.selectCurrentProjectId);
  const currentProject = useSelector((state) => selectProject(state, { projectId: currentContextProjectId || '' }))
  const isBuildBuddyHelpDesk = useSelector((state) => selectIsBuildBuddyHelpDesk(state, { projectId: currentContextProjectId }));

  // Query
  const projectQuery = CoreState.Project.getProject.useQuery({ projectId: currentContextProjectId }, { skip: !currentContextProjectId })

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleAddClick = () => {
    setAnchorEl(null);
    history.push({ pathname: "/wizard/create", state: { isCreateNew: true } });
  };

  const handleSwitchClick = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        onClick={handleClick}
        direction="row"
        alignItems="center"
        sx={{
          cursor: "pointer",
          width: {
            xs: "100%",
            md: 'fit-content'
          },
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-start' }
        }}
        ref={ref}
      >
        <Avatar sx={{ bgcolor: theme.palette.primary.main, mr: 1.5, height: 32, width: 32 }}>
          <HomeIcon sx={{
            fontSize: 20,
            bottom: 2,
            color: theme.palette.common.white,
          }} />
        </Avatar>
        <Project
          project={currentProject as CoreState.Project.Project}
          isBuildBuddyHelpDesk={isBuildBuddyHelpDesk}
          isLoading={projectQuery.isLoading || projectQuery.isFetching}
        />
        <KeyboardArrowDownIcon sx={{ ml: 1 }} fontSize="small" />
      </Stack>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          style: {
            padding: 0,
            width: ref?.current?.clientWidth,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        autoFocus={isBuildBuddyHelpDesk ? false : true}
      >
        <ProjectSelectList
          onClick={handleSwitchClick}
        />
        <MenuItem
          sx={{
            py: 1.5,
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              background: theme.palette.primary.dark,
            },
          }}
          onClick={handleAddClick}
        >
          <Stack alignItems="center" direction="row">
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Start New Project
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};
export default ProjectSelect;