import { CoreComponents, CoreState } from "@build-buddy/core";
import { Box, Card, CardContent, Grow, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { useFormik } from "formik";
import { useTaskViewParams } from "../../_hooks";
import { selectTaskViewQuoteListAcceptedQuoteCompanyName } from "../../_selectors";
import TaskViewReviewDetailsValidation from "./TaskViewReviewDetailsValidation";

interface TaskViewReviewDetailsProps {
  onNextButtonClick: (values: CoreState.Reviews.Review) => void;
  onBackButtonClick: () => void;
  initialValues?: CoreState.Reviews.Review;
  isLoading?: boolean;
  isEdit?: boolean;
}
const TaskViewReviewDetails = (props: TaskViewReviewDetailsProps) => {
  const theme = useTheme();
  const params = useTaskViewParams();
  const { projectId, stageId, taskId } = params;
  const taskViewReviewParams = { projectId, stageId, taskId };

  //selectors
  const companyName = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteCompanyName(s, taskViewReviewParams));

  const loadInitialValues = (initialValues?: CoreState.Reviews.Review) => {
    return {
      reviewContent: initialValues?.reviewContent ?? "",
      positiveExperienceContent: initialValues?.positiveExperienceContent ?? "",
      improvementsContent: initialValues?.improvementsContent ?? ""
    }

  }

  const initialValues = loadInitialValues(props.initialValues);

  const formik = useFormik<any>({
    initialValues,
    validationSchema: TaskViewReviewDetailsValidation,
    onSubmit: (values) => {
      props.onNextButtonClick(values);
    },
    enableReinitialize: true,
  })

  return (
    <Stack direction="column" alignItems="center" sx={{ mt: 2 }}>
      <Typography variant="h1">{props.isEdit ? "Edit Review" : "Write a Review"}</Typography>
      <Grow in={true}>
        <Box sx={{ m: "0 auto", mt: 2 }}>
          <Card >
            <Box sx={{
              borderBottom: `solid 1px ${theme.palette.grey[200]}`,
              pt: 2,
              pb: 2
            }}>
              <CoreComponents.Stepper
                steps={["Score", "Details"]}
                current={1}
              />
            </Box>
            <CardContent>
              <Typography variant="h2" sx={{ mb: 2 }}>Leave Your Review<Box component="span" sx={{ color: theme.palette.primary.main }}>*</Box></Typography>
              <CoreComponents.FormikTextbox
                placeholder="Please tell us your overall experience working with the pro"
                name="reviewContent"
                formik={formik}
                multiline={true}
                rows={6}
                maxRows={6}
                fullWidth
              />
              <Typography variant="h2" sx={{ mb: 2 }}> Provide more information about your experience </Typography>
              <>
                <CoreComponents.Label
                  label={`What did you like about working with ${companyName}?`}
                  InputLabelProps={{ sx: { mb: 1, fontSize: '12px' } }}
                />
                <CoreComponents.FormikTextbox
                  name="positiveExperienceContent"
                  formik={formik}
                  multiline={true}
                  rows={6}
                  maxRows={6}
                  placeholder="What factors contributed to your experience, and in what ways do you believe this company excels?"
                  fullWidth
                />
              </>
              <>
                <CoreComponents.Label
                  label="What would you improve about their work?"
                  InputLabelProps={{ sx: { mb: 1, fontSize: '12px' } }}
                />
                <CoreComponents.FormikTextbox
                  name="improvementsContent"
                  formik={formik}
                  multiline={true}
                  rows={6}
                  maxRows={6}
                  placeholder="Please provide your feedback honestly, with the aim of being constructive and helpful."
                  fullWidth
                />
              </>
              <CoreComponents.Actions
                onCancelClick={() => props.onBackButtonClick()}
                onSubmitClick={() => formik.handleSubmit()}
                cancelText="Back"
                submitText="Submit Review"
              />
            </CardContent>
          </Card>

        </Box>
      </Grow>
    </Stack>
  )
}
export default TaskViewReviewDetails;