import { Skeleton } from "@mui/material";

const FinancialsSkeleton = () => {
  return (
    <>
      <Skeleton variant="rectangular" width="100%" height="60px" sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" width="100%" height="50vh" />
    </>
  )
}
export default FinancialsSkeleton;