import { createSelector } from "@reduxjs/toolkit";
import { selectTaskViewQuoteListAcceptedQuote } from "../../_selectors";

interface SelectCompanyByPaymentScheduleParams {
  projectId: string;
  stageId: string;
  taskId: string;
  paymentScheduleId: string;
}
export const selectCompanyByPaymentSchedule = createSelector(
  [
    (state, params: SelectCompanyByPaymentScheduleParams) => selectTaskViewQuoteListAcceptedQuote(state, {projectId: params.projectId, stageId: params.stageId, taskId: params.taskId})
  ], 
  (acceptedQuote) => {
    if(!acceptedQuote) return;
    const rtn = acceptedQuote?.company
    return rtn
  }
)