import { ProjectRoutesDefinitions } from "modules/project/ProjectRoutes";
import { FinancialRoutesDefinition } from "modules/project/financials/FinancialsRoutes";
import { UserProfileRouteDefinitions } from "modules/user/profile";
import { InvitationRouteDefinitions } from "modules/invitation/InvitationRoutes";
import { UserWalletRouteDefinitions } from "modules/user/wallet/WalletModule";

import OnboardingRouteDefinitions from "modules/onboarding/OnboardingRouteDefinitions";
import { ChatRouteDefinitions } from "@build-buddy/core";

import TaskViewRouteDefinitions from "modules/project/task/task-view/TaskViewRouteDefinitions";
import { MyTeamsRoutesDefinitions } from "modules/project/my-teams/MyTeamsRoutes";
import { WizardRoutesDefinitions } from "modules/wizard/WizardRoutes";
import { CompanyRoutesDefinitions } from "modules/company/CompanyRoutes";
import { HelpRoutesRouteDefinitions } from "modules/help/HelpRoutes";

interface RouteDefintion {
  [key: string]: {
    [key: string]: any;
  };
}

const RouteDefinitions: RouteDefintion = {
  Onboarding: {
    ...OnboardingRouteDefinitions,
  },
  Company: {
    ...CompanyRoutesDefinitions
  },
  Chat: {
    ...ChatRouteDefinitions,
  },
  Project: {
    ...ProjectRoutesDefinitions,
    ...FinancialRoutesDefinition,
  },
  Task: {
    ...TaskViewRouteDefinitions,
  },
  Invitation: {
    ...InvitationRouteDefinitions
  },
  Help: {
    ...HelpRoutesRouteDefinitions
  },
  
  // TODO: Remove and update
  UserProfile: {
    ...UserProfileRouteDefinitions,
  },
  OrganisationProfile: {
    ...MyTeamsRoutesDefinitions,
  },
  Wallet: {
    ...UserWalletRouteDefinitions
  },
  Wizard: {
    ...WizardRoutesDefinitions
  },
};

export { RouteDefinitions };
