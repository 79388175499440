import { Card, CardContent, Stack, Typography } from "@mui/material";
import { Chart, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, } from "@progress/kendo-react-charts";
import CostsChartSkeleton from "./CostsChartSkeleton";
import useCostsChart from "./useCostsChart";

const CostsChart = () => {
  const { chart, isLoading } = useCostsChart();

  if (isLoading) return <CostsChartSkeleton />
  if (!chart) return <> EMPTY </>

  return (
    <Card>
      <CardContent>
        <Stack direction="row" sx={{ mt: 0.5, mb: 3 }}>
          <Typography variant="h5" sx={{ flex: 1 }}>Costs Breakdown</Typography>
        </Stack>
        <Chart>
          <ChartValueAxis>
            <ChartValueAxisItem
              min={0}
              labels={{ format: `$${0}` }}
            />
          </ChartValueAxis>
          <ChartSeries>
            {chart.map((b: any, idx: number) => (
              <ChartSeriesItem
                key={idx}
                type="column"
                data={b.data}
                name={b.name}
                stack={b.stack}
                color={b.color}
              />
            ))}
          </ChartSeries>
        </Chart>
      </CardContent>
    </Card>
  )
}
export default CostsChart;