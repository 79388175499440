import { Typography } from "@mui/material";

const SavingsCardEmpty = () => {
  return (
    <>
      <Typography variant="h1" sx={{ my: 1.25 }}> Awaiting initial pricing estimate </Typography>
      <Typography variant="body2"> Once your estimate has been uploaded we can calculate your savings </Typography>
    </>
  )
}
export default SavingsCardEmpty;