import { CoreModules } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import { useTaskViewParams } from "../_hooks";

const TaskViewDescription = () => {
  // params
  const { projectId, stageId, taskId } = useTaskViewParams();
  const taskParams = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));

  // locals
  const url = projectTask?.task.extra?.overviewUrl;

  if (!url) return null;

  return (
    <CoreModules.Task.TaskView.TaskViewDescription url={url} />
  )
}
export default TaskViewDescription;
