import { FC } from "react";
import { Box } from "@mui/material";
import OptionItem from "./OptionItem";

interface OptionListProps {
  value: any;
  items: Array<{
    title: string;
    caption?: string;
    description?: string;
    value: any;
    disabled?: boolean;
  }>;
  error?: boolean;
  onChange(value: any): void;
}
const OptionList: FC<OptionListProps> = (props: OptionListProps) => {
  const handleClick = (item: any) => props.onChange(item.value);

  const determineActive = (item: any) => {
    return Array.isArray(props.value) ? 
      props.value.includes(item.value) : 
      item.value === props.value; 
  }

  return (
    <Box sx={{borderRadius:"0.75rem"}}>
      {props.items.map((item, index) => 
        <OptionItem 
          key={index}
          title={item.title} 
          caption={item.caption}
          description={item.description} 
          sx={{ mb: 1.5 }}
          active={determineActive(item)}
          error={props.error}
          onClick={() => handleClick(item)}
          disabled={item.disabled}
        />
      )}
    </Box>
  );
};
export default OptionList;
