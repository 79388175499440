import { CoreComponents, CoreState } from "@build-buddy/core";
import { Card, Stack } from "@mui/material";
import { useFormik } from "formik";
import { getConfig } from "common";

const TestProject = () => {
  const [createTestProjectAction, createTestProjectQuery] = CoreState.Project.createTestProject.useMutation();

  const formik = useFormik({
    initialValues: {
      project: ""
    },
    onSubmit: (values: any) => {
      createTestProjectAction({
        projectOverlayDefinitionCode: values.project
      })
    }
  })

  if(getConfig().DisableTestProjectCreation === 'true') return null;

  return (
    <>
      <CoreComponents.Loader show={createTestProjectQuery.isLoading} />
      <Card
        sx={{
          width: "80%",
          height: "80%",
          margin: "30% auto",
          padding: "10%"
        }}
      >
        <Stack>
          <CoreComponents.FormikTextbox
            name="project"
            label="Code"
            formik={formik}
          />
          <CoreComponents.Actions
            onSubmitClick={() => formik.handleSubmit()}
            submitText="Generate Test Workflow"
          />
        </Stack>
      </Card>
    </>
  )
}

export default TestProject;