import { CoreComponents, CoreHooks, CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { AppBar, Box, Button, Card, CardContent, Toolbar, Typography, useTheme } from "@mui/material";
import { CommonState } from "common";
import { Logo, Offset } from "common/components";
import { useDispatch, useSelector } from "common/state/store";
import { ProjectRoutesDefinitions } from "modules/project/ProjectRoutes";
import { WizardRoutesDefinitions } from "modules/wizard/WizardRoutes";
import { useEffect } from "react";

const InitialInvitation = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mode = CoreHooks.useMode();
  const routing = CoreRouting.useRouting();
  
  // params
  const userId = useSelector(CoreState.User.selectCurrentUserId);  
  const getInviteListByUserParams = { userId };

  // queries
  const getProjectListByUser = CoreState.Project.getUserProjectList.useQuery(); // TODO: Update
  const getInviteListByUserQuery = CoreState.Membership.getInviteListByUser.useQuery(getInviteListByUserParams)

  // locals
  const offsetSpace = mode.md ? 4 : 12;
  const isLoading = Boolean(getProjectListByUser.isLoading || getProjectListByUser.isFetching);
  const projects = getProjectListByUser.data; // TODO: update w/ selector
  const project = projects?.[0]; // TODO: update w/ selector
  const invites = useSelector((s) => CoreState.Membership.selectInviteListByUser(s, getInviteListByUserParams));

  useEffect(() => {
    if(!project || isLoading) return;    
    dispatch(CommonState.App.switchProject({ project }))
    const params = { pid: project.id };
    routing.go(ProjectRoutesDefinitions.PROJECT, { params });
  }, [isLoading])
  
  useEffect(() => {
    if(Boolean(invites && invites.length) || isLoading || Boolean(project)) return;
    routing.go(WizardRoutesDefinitions.WIZARD_CREATE);
  }, [invites, isLoading])

  return (
    <>
      <AppBar
        sx={{
          background: theme.palette.background.paper,
          boxShadow: "none"
        }}
      >
        <Toolbar
          sx={{
            minHeight: "64px",
            px: 3,
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
              py: { xs: 2, md: 0 },
              flexDirection: {
                xs: "column",
                md: "row"
              }
            }}
          >
            <Box
              sx={{
                position: { xs: "relative", md: "absolute" },
                left: { xs: 0, md: "24px" },
                mb: { xs: 1, md: 0 },
                mr: { xs: 0, md: 3 }
              }}
            >
              <Logo />
            </Box>
            <Box
              sx={{
                overflow: "hidden",
                width: "100%",
                maxWidth: "900px",
              }}
            >
              <CoreComponents.Stepper
                steps={["Invitations"]}
                current={0}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Offset space={offsetSpace} />

      <CoreComponents.Loader show={isLoading} />

      <CoreModules.Membership.Invitation />
      
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row"
              }
            }}
          >
            <Typography
              variant="h1"
              sx={{
                flex: 1,
                mb: {
                  xs: 2,
                  sm: 0
                }
              }}
            >
              Not your project?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => routing.go(WizardRoutesDefinitions.WIZARD_CREATE)}
            >
              Create a new Project
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
export default InitialInvitation;