import TaskViewReviewAdd from "./TaskViewReviewAdd";
import TaskViewReviewEdit from "./TaskViewReviewEdit";

const TaskViewReviewRouteDefinitions = {
  TASK_VIEW_REVIEW: {
    path: "/*/stage/:sid/task/:tid/*/review/",
    component: TaskViewReviewAdd,
  },
  TASK_VIEW_REVIEW_EDIT: {
    path: "/*/stage/:sid/task/:tid/*/review/:reviewId",
    component: TaskViewReviewEdit,
  },
}

export default TaskViewReviewRouteDefinitions;






