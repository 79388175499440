import { ChatIcon, CoreRouting } from "@build-buddy/core";
import { IconButton } from "@mui/material";
import { useSelector } from "common/state/store";
import useTaskViewParams from "../../../_hooks/useTaskViewParams";
import { selectTaskViewFirstTaskSelectTaskIsNotDiyAndNotPending } from "../../../_selectors";
import TaskViewMessagesRouteDefinitions from "../../../task-view-messages/TaskViewMessagesRouteDefinitions";

interface TaskViewMessageActionsProps {
  children: React.ReactNode;
}
const TaskViewMessageActions = (props: TaskViewMessageActionsProps) => {
  // hooks
  const { projectId, stageId, taskId } = useTaskViewParams();
  const routing = CoreRouting.useRouting();

  //params
  const taskParams = { projectId, stageId, taskId };

  // selectors
  const showMessageButton = useSelector((s) => selectTaskViewFirstTaskSelectTaskIsNotDiyAndNotPending(s, taskParams));

  return (
    <>
      {props.children}
      {showMessageButton &&
        <IconButton
          onClick={() => routing.go(TaskViewMessagesRouteDefinitions.TASK_VIEW_MESSAGES.path)}
        >
          <ChatIcon />
        </IconButton>
      }
    </>
  )
}

export default TaskViewMessageActions;