import TaskView from "./TaskView";
import TaskViewCompanyRouteDefinitions from "./task-view-company-details/TaskViewCompanyRouteDefinitions";
import TaskViewVariationsRouteDefinitions from "./task-view-variations/TaskViewVariationsRouteDefinitions";
import TaskViewDescriptionRouteDefinitions from "./task-view-description/TaskViewDescriptionRouteDefinitions";
import TaskViewDocumentsRouteDefinitions from "./task-view-documents/TaskViewDocumentsRouteDefinitions";
import TaskViewMessagesRouteDefinitions from "./task-view-messages/TaskViewMessagesRouteDefinitions";
import TaskViewPaymentsRouteDefinitions from "./task-view-payments/TaskViewPaymentsRouteDefinitions";
import TaskViewQualityAssuranceRouteDefinitions from "./task-view-quality-assurance/TaskViewQualityAssuranceRouteDefinitions";
import TaskViewQuotesRouteDefinitions from "./task-view-quotes/TaskViewQuotesRouteDefinitions";
import TaskViewReviewRouteDefinitions from "./task-view-review/TaskViewReviewRouteDefinitions";
import TaskViewScopeOfWorkRouteDefinitions from "./task-view-scope-of-work/TaskViewScopeOfWorkRouteDefinitions";

const TaskViewRouteDefinitions = {
  TASK_VIEW: {
    path: "/*/stage/:sid/task/:tid",
    component: TaskView
  },
  ...TaskViewVariationsRouteDefinitions,
  ...TaskViewScopeOfWorkRouteDefinitions,
  ...TaskViewDescriptionRouteDefinitions,
  ...TaskViewMessagesRouteDefinitions,
  ...TaskViewCompanyRouteDefinitions,
  ...TaskViewDocumentsRouteDefinitions,
  ...TaskViewQuotesRouteDefinitions,
  ...TaskViewPaymentsRouteDefinitions,
  ...TaskViewReviewRouteDefinitions,
  ...TaskViewQualityAssuranceRouteDefinitions
}

export default TaskViewRouteDefinitions;