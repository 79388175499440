import { CoreState, useMode } from "@build-buddy/core";
import { DesktopMac as DesktopIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Unavailable } from "common/components";
import { useSelector } from "common/state/store";

type DesignerGuardProps = {
  children: any
}
const DesignerGuard = (props: DesignerGuardProps) => {
  const { children } = props;
  const mode = useMode();

  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);

  if (isLimited) {
    return (
      <Unavailable>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Our Designer Tools tell you everything that you need to know about your block of land and identifies which standard Build Buddy
          floor plans will fit and comply with your local council requirements.
        </Typography>
        <Typography variant="body1">
          To unlock this feature, complete watching all videos and then schedule and attend your Discovery Appointment
          with a member of the Build Buddy Expert Guidance and Support Team.
        </Typography>
      </Unavailable>
    )
  }

  if (mode.isMobile) {
    return (
      <Unavailable
        icon={DesktopIcon}
        title="Available on desktop only"
      >
        <Typography variant="body1">
          Our Designer Tools tell you everything that you need to know about your block of land and identifies which standard Build Buddy
          floor plans will fit and comply with your local council requirements.
        </Typography>
      </Unavailable>
    )
  }

  return children;
}
export default DesignerGuard;