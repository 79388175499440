import { CoreModules, CoreRouting } from "@build-buddy/core";
import TaskViewQuotesRouteDefinitions from "../TaskViewQuotesRouteDefinitions";
import useTaskViewQuotesCompanyParams from "../_hooks/useTaskViewQuotesCompanyParams";
import { Box } from "@mui/material";

const TaskViewQuotesCompany = () => {
  const routing = CoreRouting.useRouting();
  const { companyId } = useTaskViewQuotesCompanyParams();

  const handleReviewsClick = () => {
    routing.go(TaskViewQuotesRouteDefinitions.TASK_VIEW_QUOTES_COMPANY_REVIEWS)
  }

  const handleClose = () => {
    routing.go(TaskViewQuotesRouteDefinitions.TASK_VIEW_QUOTES)
  }

  return (
    <CoreModules.Task.TaskView.TaskViewSubView
      open={true}
      onClose={() => handleClose()}
    >
      <Box sx={{ mx: -3, mt: -5 }}>
      <CoreModules.Company.CompanyProfile
        companyId={companyId}
        onReviewsClick={() => handleReviewsClick()}
      />
      </Box>
    </CoreModules.Task.TaskView.TaskViewSubView>
  )
}
export default TaskViewQuotesCompany;