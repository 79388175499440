import { Skeleton, Stack, alpha, useTheme } from '@mui/material';

const ProjectSkeleton = () => {
  const theme = useTheme();
  return (
    <Stack direction={'column'}>
      <Skeleton
        variant='rectangular'
        width={120}
        sx={{ background: alpha(theme.palette.primary.main, 0.2) }}
      />
      <Skeleton variant='rectangular' width={200} sx={{ mt: 1 }} />
    </Stack>
  )

}

export default ProjectSkeleton