import { CoreComponents, CoreState } from "@build-buddy/core";
import { selectQualityAssuranceListLengthByStage } from "@build-buddy/core/dist/state/quality-assurance";
import { Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTask, selectProjectTaskStageDefinitionName, selectProjectTaskStatus } from "modules/project/_selectors";
import { useEffect, useState } from "react";
import { useTaskViewParams } from "../../_hooks";

const TaskViewQualityCheckActions = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  const [open, setOpen] = useState(false);

  // params
  const taskParams = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, taskParams));
  const projectTaskStageDefinitionName = useSelector((s) => selectProjectTaskStageDefinitionName(s, taskParams));
  const qualityAssuranceItemLength = useSelector((s) => selectQualityAssuranceListLengthByStage(s, {
    ...taskParams,
    stageName: projectTaskStageDefinitionName
  }))

  // mutations
  const [completeTaskAction, completeTaskQuery] = CoreState.Task.completeTask.useMutation()

  // vars
  const showActions = (projectTaskStatus !== ProjectTaskStatus.Completed)
  const isSaving = completeTaskQuery.isLoading;

  const handleComplete = () => {
    if (!projectTask) return;
    completeTaskAction({
      projectId: projectId,
      parentId: projectTask.task.parentId,
      taskId: projectTask.task.id
    })
  }

  const handleNoMajorDefectsClick = () => {
    if (qualityAssuranceItemLength) return setOpen(true);
    handleComplete();
  }

  useEffect(() => {
    if (!isSaving) return;
    setOpen(false);
  }, [isSaving])

  if (!showActions) return null;

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      <CoreComponents.Confirmation
        open={open}
        title="Mark Complete"
        text="There are outstanding items in the Quality Checklist. By marking this task complete you confirm that you are happy with the quality of the works and wish to proceed."
        cancelText="Cancel"
        confirmText="Confirm"
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        onConfirm={handleComplete}
        loading={isSaving}
      />
      <Button
        fullWidth
        variant="contained"
        onClick={handleNoMajorDefectsClick}
      >
        Proceed - No Major Defects
      </Button>
    </>
  )
}
export default TaskViewQualityCheckActions
