import { Card, CardContent, Stack, Typography } from "@mui/material";
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem } from "@progress/kendo-react-charts";
import Legend from "../legend/Legend";
import useTasksChart from "./useNoOfTasksChart";

const NoOfTasksChart = () => {
  const { chart, isLoading } = useTasksChart();

  if (isLoading) return <> LOADING </>
  if (!chart) return <> EMPTY </>

  return (
    <Card>
      <CardContent>
        <Stack direction="row" sx={{ mt: 0.5, mb: 3 }}>
          <Typography variant="h5" sx={{ flex: 1 }}> No. of Tasks </Typography>
          <Legend legend={chart.legend} />
        </Stack>
        <Chart style={{ height: "300px" }}>
          <ChartLegend visible={false} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={chart.xAxis}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              min={0}
              name="task"
            />
          </ChartValueAxis>
          <ChartSeries>
            {chart.data.map((b: any, idx: number) => (
              <ChartSeriesItem
                key={idx}
                type="column"
                axis="task"
                data={b.data}
                name={b.name}
                stack={b.stack}
                color={b.color}
              />
            ))}
          </ChartSeries>
        </Chart>
      </CardContent>
    </Card>
  )
}
export default NoOfTasksChart;

